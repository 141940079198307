function getCookieByKey(key){
  let cookie = undefined;
  document.cookie.split('; ').find(row => {
    let key_value = row.split("=");
    if(key_value[0] == key){
      cookie = key_value;
      return true;
    }
  })

  return cookie;
}

function createUpdateCookie(key, value){
  document.cookie = key + "=" + value + "; expires=Thu, 31 Dec 2038 12:00:00 UTC;";
}

export { getCookieByKey, createUpdateCookie }