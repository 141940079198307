import React from 'react';
import LBlogo from '../../images/lightbox-search-logo.svg';
import StatsBoxesOSHeader from '../StatsBoxesOSHeader';
import '../../css/Header.css';
import {OSHeaderContainer} from '../../styles/Header';
import { connect } from 'react-redux';
import logoPath from '../../helpers/logo-path';

const OnScrollHeader = (props) =>{
    const logo_path = logoPath(props.user_logged);
    return(
        <OSHeaderContainer>
            <div className="firstDiv">
                {
                logo_path?
                <img src={logo_path} alt="user_logo"/>
                :<img src={LBlogo} alt="logo"/>
                }
            </div>
            <div className="secondDiv">
                <StatsBoxesOSHeader/>
            </div>
        </OSHeaderContainer>      
    );
}

const mapStateToProps = (state) => {
    return {
        user_logged: state.user_logged
    }
}

export default connect(mapStateToProps, null)(OnScrollHeader);