import React from 'react'
import ReactTooltip from 'react-tooltip'
import { Col, Row } from 'reactstrap'
import '../../css/Tooltip.css'


const SEOTooltip = ({ title, tooltip, children }) => {
    const { name: tooltipName, description: tooltipDescription, content, code } = tooltip
    return (
        <>
            <span className="seo-metric-title" data-tip data-for={'tooltip-' + code} style={{textDecoration: title ? 'underline' : 'none'}}>
                {title ? title : children}
            </span>
            <ReactTooltip border={true} type="info" effect="solid" id={'tooltip-' + code}>
                <Row className="tooltipBody">
                    <Col xs={12} md={12} lg={12}>
                        {(tooltipName) ?
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <h6><b>{tooltipName}</b></h6>
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                        {(content) ?
                            <Row>
                                <Col xs={12}>
                                    <div className="tooltipContent" >
                                        <i>{content}</i>
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                        {(tooltipDescription) ?
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        {tooltipDescription}
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                    </Col>
                </Row>
            </ReactTooltip>
        </>
    )
}

export default SEOTooltip
