import React from 'react';
import { ButtonDropdownStyled, ButtonStyled } from '../../../styles/Button'
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { Button } from './ButtonCommon'

export const Dropdown = ({ children, className, onClick, items = [], ...rest }) => {
    const [isOpen, setOpen] = React.useState(false)

    return (
        <ButtonDropdownStyled className={`d-flex align-items-center justify-content-between`} isOpen={isOpen} {...rest} toggle={() => setOpen(prevState => !prevState)} >
            <Button {...rest} onClick={onClick} className={`m-0 py-2 ${className}`}>
                {children}</Button>
            <DropdownToggle caret className="m-0" />
            <DropdownMenu
                right
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: 2
                    },
                }}
                style={{ minWidth: "138px" }}
            >
                {items.map((item, i) => <DropdownItem key={i} onClick={item.onClick}> {item.text} </DropdownItem> )}
            </DropdownMenu>
        </ButtonDropdownStyled>
    )

}
