import React, { Component } from 'react'
import './../css/File.css'


class FilesTrashLink extends Component {

  render() {
    return <div>
      <input type="button" className={ "trash-button " + (!this.props.enabled ? "trash-empty-button" : "trash-full-button")} disabled={!this.props.enabled} onClick={this.props.changeCurrentPath} />
    </div>
  }
}

export default FilesTrashLink