import React from 'react';
import {StatBox} from './StatBox'
import {LightBoxScoreStyled} from '../../styles/StatBox'
import {connect} from "react-redux";


const LightboxScore = ({ score, ...rest}) => {
    const scoreRound = Math.round(score)
    return (
        <StatBox classNameContainer={'d-flex flex-column align-items-center justify-content-between'} title={'Lightbox Score'} {...rest}>
            <LightBoxScoreStyled score={scoreRound}>
                <p className={'value'}>
                    {scoreRound}
                </p>
            </ LightBoxScoreStyled>
            <LightBoxScoreStyled>
                <p className={'max'}>
                    Maximum: <span>100 </span>
                </p>
                <p className={'min'}>
                    Minimum: <span>-100 </span>
                </p>
            </LightBoxScoreStyled>
        </StatBox>
    )
}
const mapStateToProps = (state, props) => {
    return {
        score: state.stats.lumentus_score,
        tooltips: state.tooltips.filter(tooltip => tooltip.code === '001')
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LightboxScore)
