import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input, InputGroupText, Row, Badge, Col } from 'reactstrap'
import arrowUp from '../../images/icons/arrow-circle-up.svg';
import popupImage from '../../images/popup-image.svg';
import '../../css/PopUp.css';
class PopUp extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    const { isOpen, timeout, onChangeVisibility, disable, onChangeDisable } = this.props;
    return (
      <Modal size='lg' className='PopUp' isOpen={isOpen} modalTransition={{ timeout: timeout }} backdropTransition={{ timeout: timeout }} toggle={() => onChangeVisibility('cancel')}>
        <ModalHeader className="custom-border-radius-header">
          <Row className='align-items-center'>
            <h3><Badge className='mr-4 p-2'>New feature</Badge></h3>
            <h3 className='modal-title'>Generate Custom Action Plans</h3>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Row className='justify-content-between'>
            <Col>
              <p className='body-title'>Discover the Paths to Stronger Online Profiles</p>
              <p className='body-content'>
                Lightbox Search provides a 360-degree overview of search results and search engine results pages (SERPs) for any search term or phrase.
                <br /><br />
                Analyze search results and generate custom action plans with the newest feature from Lightbox Search. Our system will reveal threats and opportunities then provide next steps you can take to improve results and online profiles for any search term. 
              </p>
              <div className='body-bottom d-flex align-items-center mt-2'>
                <img className='mr-3' src={arrowUp} alt='arrow up icon'></img>
                <p>Start generating customized action plans by entering a search term above.</p>
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-center'>
                <img src={popupImage} alt='pop up image'></img>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='d-flex align-items-center justify-content-between'>
          <InputGroup className='container-check'>
            <Input checked={disable} onChange={onChangeDisable} addon type="checkbox" />
            <InputGroupText>Don't Show this message again</InputGroupText>
          </InputGroup>
          <Button className='mr-3' color="primary" onClick={() => onChangeVisibility('ok')}>OK</Button>
        </ModalFooter>
      </Modal>
    )
  }
}


export default PopUp