import React, {Component} from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'


class PaginationList extends Component {
  
  constructor(props) {
    super(props)
  }

  render(){
    const { data, onChange } = this.props
    if(!(data.data && data.data.length)){
      return null;
    }
    
    return (
      <div className='Pagination'>
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={data.current_page == 1 || !data.current_page}>
            <PaginationLink previous onClick={() => onChange(data.current_page -1 )} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>{data.current_page} / {data.last_page}</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={data.current_page == data.last_page}>
            <PaginationLink next onClick={() =>  onChange(data.current_page +1) } />
          </PaginationItem>
        </Pagination>
      </div>
    )
  }
}




export default PaginationList