import React from 'react';
import LocationMenu from '../../LocationMenu'


class LocationMenuSchedule extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <LocationMenu
                {...this.props}
            />
        );


    }
}

export default LocationMenuSchedule;
