import React, { cloneElement } from 'react'
import { Row } from "reactstrap"

const RadioGroup = ({ options, value, disabledStyle, setDisabledStyle, onChangeSentiment, isTopStories, topStoryIndex }) => {

    const items = options.map(option => {
        let className = option.label.props.className;
        const isActive = value && value.value === option.value;
        if (isActive) {
            className += " sentiment-active";
        }
        else if (disabledStyle) {
            className += " sentiment-disabled";
        }

        return cloneElement(option.label, {
            className,
            onClick: () => {
                setDisabledStyle(false);
                onChangeSentiment(option, topStoryIndex)
            },
            value: option.value
        });
    })

    return (
        <Row className={isTopStories && "justify-content-center mt-3"}>{items}</Row>
    );
}

export default RadioGroup

