import React from 'react';
import {Alert, Modal} from 'reactstrap'
import styled from 'styled-components'

export default class Error extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {error, confirmError} = this.props
        return error && (
            <AlertStyled className={this.props.className} color='danger' onClick={confirmError}>
                <div className="body">
                    <p> {error} </p>
                </div>
            </AlertStyled>
        );
    }
}
const AlertStyled = styled(Alert)`

  p {
    text-align: center;
    line-height: 1.4em;
    font-size: 1.2em;
    font-weight: 400;
    margin: .5rem 0;
    padding: 0;
  }



`