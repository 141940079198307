import React from 'react'
import { exportDOMElementAsPicture } from '../helpers/exportDOMElementAsPicture';
import { DownloadIconStyled } from '../styles/DownloadDOMElement';
import DonwloadIcon from '../images/icons/download-chart-icon.svg';

export const DownloadDOMElement = ({ element, addFrame = false, className = '', fileName = null }) => {
    if (navigator.userAgent.includes('Firefox')) {
        return null;
    }

    const handleDownload = () => {
        let elementId, offsetWidth;
        const elementIsAdvertisement = fileName === 'advertisement';
        const elementIsRow = element.includes('result-') || element.includes('top-stories');
        const downloadIcon = document.getElementById(`${element}-download-icon`);
        const targetElement = document.getElementById(element);

        if (elementIsRow) {
            const parent = targetElement.parentNode;
            parent.id = `${element}-parent`;
            offsetWidth = parent.offsetWidth;
            elementId = parent.id;
        } else {
            elementId = element;
        }

        const paginateButtons = document.getElementsByClassName('paginate-btn');
        const overrideConfirmations = document.getElementsByClassName('override-section');
        const topRowCheckboxes = document.getElementsByClassName("top-row-checkboxes");

        //* Hide elements
        if (elementIsRow || elementIsAdvertisement) {
            if (elementIsRow) targetElement.style.marginLeft = '18px';

            for (let i = 0; i < topRowCheckboxes?.length; i++) {
                topRowCheckboxes[i].setAttribute("viewBox", "1.6 1 14.5 12.5");
            }

            for (let i = 0; i < paginateButtons.length; i++) {
                paginateButtons[i].style.display = 'none';
            }

            for (let i = 0; i < overrideConfirmations.length; i++) {
                overrideConfirmations[i].style.display = 'none';
            }

            downloadIcon.style.visibility = 'hidden';
        } else {
            downloadIcon.style.display = 'none';
        }
        if (addFrame) targetElement.style.border = '2px solid #CFE1EA';

        //* Download element as .png
        const name = fileName ?? element.toString().replace(" ", "-").toLowerCase();
        exportDOMElementAsPicture(elementId, name, offsetWidth);

        //* Expose hidden elements
        if (elementIsRow || elementIsAdvertisement) {
            if (elementIsRow) targetElement.style.marginLeft = '';

            for (let i = 0; i < topRowCheckboxes.length; i++) {
                topRowCheckboxes[i].setAttribute("viewBox", "0 0 12 10");
            }

            for (let i = 0; i < paginateButtons.length; i++) {
                paginateButtons[i].style.display = 'block';
            }

            for (let i = 0; i < overrideConfirmations.length; i++) {
                overrideConfirmations[i].style.display = 'block';
            }

            downloadIcon.style.visibility = '';
        } else {
            downloadIcon.style.display = 'block';
        }
        if (addFrame) targetElement.style.border = '';
    };


    return (
        <DownloadIconStyled id={`${element}-download-icon`} onClick={handleDownload} className={`${className} download-arrow`}>
            <img src={DonwloadIcon} alt="download-icon" className="pl-1 pb-1" />
        </DownloadIconStyled>
    );
}
