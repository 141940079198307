import React from 'react'
import Table from './TableSchedule'
import Layout from './Layout'
import Modal from './Modal'
import Form from './Form'
import Paginate from './Paginate'
import Loading from '../common/Loading'
import Error from './Error'
import Alert from './Alert'
import '../../css/Schedules.css'

export default class Schedule extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            schedules,
            isOpen,
            contentEdit,
            closeModal,
            openModal,
            edit,
            create,
            toggle,
            loading,
            deleteSchedule,
            next,
            prev,
            nextPage,
            prevPage,
            error,
            page,
            lastPage,
            confirmError,
            alert,
            confirmAlert,
            onChangeReportsEmail,
            isReportsEmail
        } = this.props
        return (
            <Layout>
                {alert ? <Alert alert={alert} confirmAlert={confirmAlert}/> : error && !isOpen ?
                    <Error error={error} confirmError={confirmError}/> : null}
                <Table schedules={schedules}
                       openModal={openModal}
                       onChangeReportsEmail={onChangeReportsEmail}
                       isReportsEmail={isReportsEmail}
                       deleteSchedule={deleteSchedule}/>
                <Modal isOpen={isOpen}
                       toggle={toggle}
                       title={contentEdit ? 'Edit scheduled search' : 'Create a new scheduled search'}>
                    <Error className='mt-2 mb-4' error={error} confirmError={confirmError}/>
                    <Form content={contentEdit}
                          callback={contentEdit ? edit : create}
                          loading={loading}
                          closeModal={closeModal}
                    />
                </Modal>
                <Paginate page={page} lastPage={lastPage} next={next} prev={prev} nextPage={nextPage}
                          prevPage={prevPage}/>
                <Loading loading={loading}/>
            </Layout>
        )
    }
}
