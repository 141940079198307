import React, { Component } from 'react'
import Header from '../components/Header'
import PopUp from '../components/common/PopUp'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCookieByKey, createUpdateCookie } from '../helpers/cookies'
import {pageView} from '../helpers/ga-page-view'
import {Layout} from "../components/Layout/Default";


class SearchPage extends Component {

  constructor(props) {
    super(props)
    this.cookie = getCookieByKey(this.props.username);

    this.state = {
      is_test:false,
      popUpIsOpen: this.cookie == undefined ? true : false,
      disablePopUp: false,
    }

  }
 componentDidMount() {
    pageView()
 }

  componentWillReceiveProps(nextProps){
    if(nextProps.username != undefined){
      let cookie = getCookieByKey(nextProps.username);
      cookie == undefined ? null : this.setState({ popUpIsOpen: false });
    }
  }

  componentWillMount(){
    const { match: { params } } = this.props;
    params.test !== undefined ? this.setState({is_test:true}) : ''
  }


  handleChangePopUpVisibility = (action) => {
    if(action === 'ok'){
      if(this.state.disablePopUp) {
        createUpdateCookie(this.props.username, "Don't Show")
      }
    }
    this.setState({
      popUpIsOpen: !this.state.popUpIsOpen
    })
  }

  render() {

    if (this.props.auth_info === null) {
      return <Redirect to='/login'  />
    }

    return (
      <Layout>
        <PopUp
          isOpen={this.state.popUpIsOpen}
          onChangeVisibility={this.handleChangePopUpVisibility}
          disable={this.state.disablePopUp}
          onChangeDisable={() => {this.setState({disablePopUp: !this.state.disablePopUp})}}
          timeout={1000}
        />
      {
        this.props.search.main !== "" ? <Redirect to="/results" /> : <Header resultsPage="0" test={this.state.is_test} />
      }
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    search: state.search,
    auth_info: state.auth_info,
    username: state.user_logged.username
  }
}

export default connect(mapStateToProps, null)(SearchPage)
