import React from 'react';
import { StatBoxStyled } from "../../styles/StatBox";
import { DownloadDOMElement } from '../DownloadDOMElement';

export const StatBox = ({ title, classNameContainer, children, border, ...rest }) => {
    const hideDownloadIcon = rest.hideDownloadIcon ?? true;

    return (
        <StatBoxStyled {...rest}>
            <div id={title} className={`container-stat-box ${border ? 'border-stat-box' : ''} ${classNameContainer}`}>
                <div className={'title'}> 
                    <h3>{title}</h3>
                    {!hideDownloadIcon && <DownloadDOMElement element={title} addFrame /> }
                </div>
                {children}
            </div>
        </StatBoxStyled>
    )
}

