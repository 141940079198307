import React, { Component } from 'react'
import { Row, Modal, ModalHeader, ModalBody, Container } from 'reactstrap'
import './../css/Login.css'
import './../css/IosModal.css'
import './../css/AutosaveModal.css'
import { connect } from 'react-redux'
import fetchClient from '../helpers/fetch-client'
import Spinner from './Spinner'
import DashboardChart from './DashboardChart'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { dateFormat } from '../config/date'

class AutoSaveModal extends Component {
  constructor(props) {
    super(props)
    const auth_info = JSON.parse(localStorage.getItem('token_info'))
    const data = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
      }
    }
    this._isMounted = false
    this.state = {
      loading: true,
      message: "",
      message_type: "",
      charts_data:[],
      start_date:null,
      end_date:null
    }
    this.autoSaveFile = this.autoSaveFile.bind(this)
    this.fetchDashboardData = this.fetchDashboardData.bind(this)
    this.setEndDate = this.setEndDate.bind(this)
    this.setStartDate = this.setStartDate.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.autoSaveFile()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  autoSaveFile(){
    let state = {
        ...this.props.state,
        overrides : {
            ...this.props.overrides
        },
    };

    let params = {
      path: '/',
      title: '.autosave',
      description: '',
      content: JSON.stringify(state),
      confirm: 1
    };
    const me = this
    fetchClient().post(process.env.REACT_APP_API_ENDPOINT + "/fs", JSON.stringify(params))
      .then((response) => {
        if(response.status === 201){
          me.fetchDashboardData()
        }else{
          me._isMounted && me.setState({ message: 'An error occur saving the file, please retry.', message_type: 'error', loading: false })
        }
      }, (error) => {
        me._isMounted && me.setState({ message: 'An error occur saving the file, please contact with the administrator.', message_type: 'error', loading: false })
    })
  }


  fetchDashboardData() {
    let url = process.env.REACT_APP_API_ENDPOINT + '/dashboard/' + this.props.search

    if (this.state.start_date && this.state.end_date && this.state.start_date > this.state.end_date) {
      this._isMounted && this.setState({message:'Invalid range of dates, StartDate cannot be greather than EndDate.',message_type:'error'})
      return
    }

    let start = this.state.start_date ? moment(new Date(this.state.start_date)).format("YYYYMMDD") : '00000000'
    let end = this.state.end_date ? moment(new Date(this.state.end_date)).format("YYYYMMDD") : '00000000'

    let loc_id = window.loc_id || 2840;

    url+= '/' + start
    url+= '/' + end
    url+= '?loc_id=' + loc_id


    let me = this
    return fetchClient().get(url, this.state.headers)
    .then((response) => {
      if (response.status !== 200) {
          throw response.message
      } else {
          if (response.data && Object.values(response.data).filter(item=>item).length) {
              me.setState({charts_data:response.data, message:"", message_type:""})
          }else {
              me.setState({message:'Not found information for the term '+this.props.search, message_type:'error'})
          }
      }
    }).catch(function (error) {
        me._isMounted && me.setState({ message: 'Can\'t get the data information, please refresh the page.', message_type: 'error' })
    }).finally(() => {
        me._isMounted && this.setState({ loading: false })
    })
  }

  resetAndclose = () => {
    this.props.close()
  }


  setStartDate(date) {
      this._isMounted && this.setState({start_date: date})
  }

  setEndDate(date) {
      this._isMounted && this.setState({end_date: date})
  }

  render() {

    const datepickerprops = {
      offset: {
          enabled: false,
          offset: '0, 0'
      },
      preventOverflow: {
          enabled: true,
          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
          boundariesElement: 'viewport'
      }
    }

    return (
      <div>
        <Modal isOpen={this.props.open} className="files-modal autosave-modal">
        <Container fluid={true}>
          <ModalHeader>
            <Row className="button-close-row" >
            <button type="button" className="btn btn-secondary ios-close-button" onClick={()=>this.resetAndclose()}>
                <svg aria-hidden="true" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" color="black"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
              </button>
            </Row>
            <Row className="dashboard-firstrow">
              <div className="historic-date-title">
                <p className="title">HISTORICAL DATA FOR</p>
                <p>{this.props.search}</p>
                {
                  this.props.state !== undefined && this.props.state.search !== undefined && this.props.state.search.canonical_name !== undefined ?
                  <p className="location-label">{this.props.state.search.canonical_name}</p>
                  :
                  null
                }
              </div>
              <div className="inputs-section">
                <label htmlFor="dp1">Start Date:</label>
                <DatePicker
                    className="dashboard-input form-control"
                    selected={this.state.start_date}
                    value={this.state.start_date}
                    onKeyDown={(e)=>e.preventDefault()}
                    onChange={date => this.setStartDate(date)}
                    dateFormat={moment(this.state.start_date).format(dateFormat)}
                    popperPlacement="top-end"
                    showYearDropdown={true}
                    popperModifiers={datepickerprops}
                    id = "dp1"
                    disabled={this.state.loading}
                    isClearable={true}
                />
                <label htmlFor="dp2">End Date:</label>
                <DatePicker
                    className="dashboard-input form-control"
                    selected={this.state.end_date}
                    value={this.state.end_date}
                    onKeyDown={(e)=>e.preventDefault()}
                    onChange={date => this.setEndDate(date)}
                    dateFormat={moment(this.state.end_date).format(dateFormat)}
                    popperPlacement="top-end"
                    popperModifiers={datepickerprops}
                    showYearDropdown={true}
                    id="dp2"
                    disabled={this.state.loading}
                    isClearable={true}
                />

                <button className={`dashboard-button ${this.props.search && !this.state.loading ? 'enabled' : 'disabled' }`} onClick={()=>this.fetchDashboardData()} disabled={this.state.loading}>
                  Submit
                </button>
              </div>
            </Row>
          </ModalHeader>

          <ModalBody>
            { this.state.message != "" ?
                (<span className="position-relative form-group">
                  <div className={"save-modal-message alert fade show error-message " + (this.state.message_type === 'error' ? 'alert-danger' : (this.state.message_type === 'info' ? 'alert-primary' : 'alert-success'))} role="alert"> {this.state.message} </div>
                </span>)
              : null
            }

            {this.state.loading ?
              <span className='new-folder-row icon-search-loading'><Spinner/></span>
            :
              Object.keys(this.state.charts_data).map((item)=>{
                return <DashboardChart key={item} title={item} data={this.state.charts_data[item]}></DashboardChart>
              })
            }
          </ModalBody>
          </Container>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps, null)(AutoSaveModal)
