import styled from 'styled-components';
import {styles} from './variables';


export const HeaderTopStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  .save-warning{
    bottom: -2.5rem;
    margin:0;
    text-align: left;
  }
  .container-icon {
    position: relative;
    width: 1.0606rem;
    height: 1.0606rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${({isSearch}) => isSearch ? "#fff" : styles.purple};
      transform-origin: center center;
      transform: rotate(45deg);
      border-radius: 3px;
    }
  }

  .item-automated-search {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      color: ${({isSearch}) => isSearch ? "#fff" : styles.secondaryColor};
      font-size: 1em;
      font-weight: 400;
      font-family: ${styles.fontPrimary};
    }
  }

  .icon-search {
    width: 1rem;
    position: relative;
    z-index: 2;

    path {
      fill: ${({isSearch}) => !isSearch ? "#fff" : styles.purple};
    }
  }
`

export const HeaderContainerStyled = styled.div`
  flex-grow: 1;
  padding: 1rem calc(1.875rem + 1%);
  background-color: ${({isSearch}) => !isSearch ? "#fff" : styles.purple};
`
export const HeaderStyled = styled.section`
  flex-grow: 1;
  background-color: #fff;
  .container-messages {
    padding: 0 calc(1.875rem + 1%);

    .alert {
      margin-bottom: 1rem !important;
      width: 100% !important;
    }
  }
  .reset-button{
    color:${styles.primaryColor};
    cursor:pointer;
    &:hover{
      text-decoration:underline;
    }
  }
`

export const SectionSearchStyled = styled.section`
  flex-grow: 1;
  display: flex;
  padding: 1rem calc(1.875rem + 1%);
  flex-direction: column;
  align-items: center;

  .row {
    width: 100%;
  }

  #location-holder {
    flex-grow: 1 !important;
  }

  .date-saved {
    flex-grow: 0.2;
  }
  .save-warning{
    text-align: left;
    position:absolute;
    margin-top:8%;
  }
  .date-saved span {
    font-weight: 400;
    line-height: 1em;
    font-size: 0.875em;
    font-family: ${styles.fontRoboto};
    color: ${styles.textColor};
  }
`
export const OSHeaderContainer = styled.div`
  width: 90%;
  height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .firstDiv{
    width: 20%;
    text-align: center;
  }
  .secondDiv{
    width: 80%;
    text-align: start;
  }
`