import styled from 'styled-components';
import { styles } from './variables';

export const SidebarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${styles.widthSidebar};
  transition: transform .5s ease-in-out;
  transform: translate3d(${({ active }) => active ? '0' : '-100%'},0,0);
  z-index: 10;
  height: 100%;
  max-width: 100%;
  background-color: #fff;
  border-right: 1px solid #dee2e6;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
 .header {
   display: flex;
   margin: 1.5rem 0;
   justify-content: center;
   align-items: center;
   .logo {
     padding: 1rem;
   }
    .logo-header {
     width: 3.6581rem;
     max-width: 100%;
    }
 }
  .menu {
    list-style: none;
    margin: 0rem 1.35rem;
    ul {
      padding: 0;
      margin: .5rem 0;
      a {
        font-family: ${styles.fontPrimary};
        font-size: 1em;
        margin: .5rem 0;
        padding: 0.8rem 1rem;
        display:flex;
        align-items: center;
        font-weight: 600;
        color: ${styles.primaryColor};
        height: 100%;
        flex: 1;
        border-radius: 10px;
        transition: background-color .4s ease-in-out;
        padding: 1rem 0.5rem;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
        .icon {
          width: 1rem;
          margin-right: 1rem;
          path {
            transition: fill .4s ease-in-out;
            fill: ${styles.secondaryColor};
          }
        }
        &:hover, &.active {
          color: #fff;
          background-color: #374c8b;
          .icon path {
            fill: #fff;
          }
        }
      }
    }
  }
  .footer-sidebar {
    margin: 0.5rem 1.35rem;
    a {
      font-family: ${styles.fontPrimary};
      font-size: 1em;
      margin: 0;
      cursor: pointer;
      padding: 0.3rem 1rem;
      display:flex;
      text-align: center;
      justify-content: center;
      &:hover{
        text-decoration: none;
        opacity: 0.8;
      }
      align-items: center;
      color: ${styles.primaryColor} !important;
      &:active,&:focus {
        text-decoration:none;
      }
      font-weight: 400;
      flex: 1;
    }
    .avatar {
      text-transform: uppercase;
      padding: 1rem;
      margin: .4rem 0;
      background-color:  #27AE60;
      width: 3.578rem;
      height:  3.578rem;
      border-radius: 50%;
      color: #fff;
      font-weight: 700;
      display:flex;
      align-items:center;
      justify-content:center;
    }
    .name {
      color: #000;
    }
  }
  @media screen and (max-width: 1366px) {
    .header {
      margin: 0;
    }
    .footer-sidebar {
      margin: 0;
    }
  }
`
