import React from 'react';
import {
    Button
} from 'reactstrap'
import styled from 'styled-components'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'


export default class Paginate extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {next, prev, nextPage, prevPage , page , lastPage} = this.props
        return (
            <PaginateStyled>
                <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={!prev}>
                        <PaginationLink previous onClick={prevPage} />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink> {page+"/"+lastPage} </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={!next}>
                        <PaginationLink next onClick={nextPage} />
                    </PaginationItem>
                </Pagination>
            </PaginateStyled>
        );
    }
}
const PaginateStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 3% 0;
   .pagination {
     &, li , button {
       box-shadow: none !important;
       font-size: 1em;
       font-weight: 600;
     }
     &,li {
       background-color: transparent !important;
     }
     button {
       background-color: #fff !important;
     }
   }
  .page-link {
    color: #35a9e0 !important;
  }
  .btn {
    font-size: 1em;
    color: #5b666f !important;
    background-color: #fff !important;
    outline: none !important;
    border: solid 0.0625rem #90b9d9 !important;
    box-shadow: 0 0 0.0625rem #35a9e0;

    &:hover {
      opacity: .8;
    }

 
    &:active {
      opacity: .7;
    }
  }
`
