import { CustomInput, Popover } from 'reactstrap'
import React, { Component } from 'react'
import './../css/File.css'
import folderIcon from './../images/folder-icon.svg'
import fileIcon from './../images/file-icon.svg'
import graphIcon from './../images/pie-graph-icon.svg'
import fileOptionsIcon from './../images/options-icon.svg'
import Moment from 'react-moment'
import Spinner from './Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { dateFormat } from '../config/date'


class FSRow extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.titleInput = React.createRef()

        const auth_info = JSON.parse(localStorage.getItem('token_info'))
        const data = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
            }
        }

        this.state = {
          headers: data,
          title:'',
          type:'',
          description:'',
          timeline:0,
          title_readOnly:true,
          description_readOnly:true,
          title_changed:false,
          description_changed:false,
          checked:this.props.checked,
          popoverOpen: false,
          actionOptions: this.props.actionOptions,
          editingName: false,
          loading:false
        }
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.executeAction = this.executeAction.bind(this),
        this.updateNameItem = this.updateNameItem.bind(this),
        this.updateDescriptionFile = this.updateDescriptionFile.bind(this)
        this.loadFile = this.loadFile.bind(this)
    }

    handleOutsideClick(e) {
      // ignore clicks on the component itself
      if (this.node && this.node !== undefined && this.node.contains(e.target)) {
        return
      }

      this.toggle();
    }

    toggle() {
      if (!this.state.popoverOpen) {
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }

      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    }

    componentDidMount() {
      this._isMounted = true
      this.setState({
        title: this.props.info.title,
        type: this.props.info.type,
        timeline: this.props.info.timeline,
        description: this.props.info.description
      })
      window.addEventListener("resize", this.updateDimensions.bind(this))
    }

    componentWillMount(){
      this.setState({width: window.innerWidth});
    }

    componentWillUnmount() {
      this._isMounted = false
      window.removeEventListener("resize", this.updateDimensions.bind(this))
    }

    componentDidUpdate(props) {
      if (this.state.timeline !== props.info.timeline) {
        this.setState({timeline:props.info.timeline})
      }
    }

    updateDimensions() {
      this.setState({ width: window.innerWidth })
    }

    changeReadOnly = (e=null) => {
      let current = this.props.currentPath.startsWith('/trash') ? 'trash' : 'notrash'

      if (current !== 'trash') {
        this.props.onShowMessage({'type':'', 'message':''})
        this._isMounted && this.setState({title_changed:false,description_changed:false})

        if (!e || e.target.classList.contains('title')) {
          this._isMounted && this.setState({title_readOnly:!this.state.title_readOnly, editingName:this.state.title_readOnly})
        }else {
          this._isMounted && this.setState({description_readOnly:!this.state.description_readOnly})
        }
      }
    }

    handleOnKeyUp = (e) => {
        let id = e.currentTarget.dataset.id
        if (e.key === 'Enter') {
          this.changeReadOnly(e)
          if (e.target.classList.contains('title') && this.state.title_changed){
            this.updateNameItem()
          }else if(!e.target.classList.contains('title') && this.state.description_changed) {
            this.updateDescriptionFile(id)
          }
        }else {
          let TABKEY = 9
          if(e.keyCode !== TABKEY) {
            if (e.target.classList.contains('title')) {
              if(!this.state.title_changed) {
                this._isMounted && this.setState({title_changed:true})
              }
            }else {
              if(!this.state.description_changed) {
                this._isMounted && this.setState({description_changed:true})
              }
            }
          }
        }
    }

    handleOnBlur = (e) => {
      if(this.props.currentPath.startsWith('/trash')) return
      let id = e.currentTarget.dataset.id
      this.changeReadOnly(e)
      if (e.target.classList.contains('title') && this.state.title_changed){
        this.updateNameItem()
        this.setState({editingName: false})
      } else if(!e.target.classList.contains('title') && this.state.description_changed) {
        this.updateDescriptionFile(id)
      }
    }

    handleOnChangeTitle = (e) => {
      this._isMounted && this.setState({title:e.target.value})
    }

    handleOnChangeDescription = (e) => {
      this._isMounted && this.setState({description:e.target.value})
    }

    handleChangeCheckbox() {
      this.props.changeSelectedItems(this.props.id, !this.props.checked)
    }

    executeAction(action) {
      let path = this.props.currentPath.endsWith('/') ? this.props.currentPath + this.props.info.title : this.props.currentPath +'/'+ this.props.info.title
      this.props.executeAction(action, {
        fromFSRow: path
      })
    }

    editRowName() {
      if (!this.props.intoModal) {
        this.changeReadOnly()
        this.titleInput.current.focus()
        this.setState({editingName: true})
      }
    }

    updateNameItem() {
      if(this.props.currentPath.startsWith('/trash')) return

      const title = this.state.title.trim()

      if (title !== this.props.info.title) {
        if ( title === '' || title.indexOf('trash') !== -1 || title.indexOf('..') !== -1 || title.indexOf('@') !== -1 || title.indexOf('./') !== -1 || title.indexOf('/') !== -1 || title.indexOf('\\') !== -1 || title.indexOf('*') !== -1 || title.toLowerCase().indexOf('.autosave') !== -1 || (title.length == 1 && title == 0)){
          this._isMounted && this.props.onShowMessage({ message: 'Invalid format title, cannnot be empty and cannot contains [".." "trash" "@" "./" "/" "\\" "*"] characters.', type: 'error' })

          return
        }

        //the loader dissapear when redraw the row and the state loading is false
        this.setState({loading:true})
        this.props.executeAction(
          'move',
          {
            path: this.props.currentPath.endsWith('/') ? this.props.currentPath + this.props.info.title : this.props.currentPath +'/'+ this.props.info.title ,
            new_path: this.props.currentPath.endsWith('/') ? this.props.currentPath+title : this.props.currentPath+'/'+title ,
            action:'rename'
          }
        )
        this.setState({loading:true})
      }
    }

    updateDescriptionFile() {
      if(this.props.currentPath.startsWith('/trash')) return

      const description = this.state.description.trim()

      if (description !== this.props.info.description) {
        this.setState({loading:true})
        this.props.executeAction('update-description', {
            path: this.props.currentPath,
            title: this.props.info.title,
            description: this.state.description
          }
        )
        this.setState({loading:true})
      }
    }

    loadFile(created_at) {
      if(this.props.loadFile !== null){
        this.props.loadFile(this.props.newPath, created_at, this.props.first)
      }
    }

    render() {
        let src_path = this.props.info.type == 'file' ? fileIcon : folderIcon
        var colSpanChange = this.state.width < 600 ? 3 : 1
        var type = this.props.info.type == 'file' ? 'file' : 'folder'
        var selectedClass = this.props.checked ? 'selected-row' : ''
        let src_graph = graphIcon
        let current = this.props.currentPath.startsWith('/trash') ? 'trash' : 'notrash'
        let options = this.state.actionOptions.filter( option => {
                        return (option.place === current && ((type === 'folder' && option.value !== 'timeline' && option.value !== 'removetimeline') || type === 'file' ))
                      }).filter( option => {
                          return type === 'folder' ||
                            (
                              type === 'file' && this.state.timeline !== undefined && this.state.timeline ? option.value !== 'timeline' :
                              type === 'file' && this.state.timeline !== undefined && !this.state.timeline ? option.value !== 'removetimeline' : null
                            )

                      }).map( (item,index) => {
                          var functionCall = ''
                          switch(item.callAction) {
                            case "editRowName":
                              functionCall = this.editRowName.bind(this)
                              break
                            case "executeAction":
                              functionCall = ()=>this.executeAction(item.value)
                              break
                            case "moveAction":
                              functionCall = () => this.props.toggleMoveModal(this.props.id)
                              break
                            default:
                              functionCall = ''
                              break
                          }
                          return (
                          <div key={index}>
                            <a href="#" onClick={functionCall !== '' ? eval(functionCall) : null}>
                              { item['value'] !== 'timeline' && item['value'] !== 'removetimeline' ? item['value'].charAt(0).toUpperCase() + item['value'].slice(1) + ' ' +type : item['label']}
                            </a>
                          </div>)
                      })

        return (
          this.state.loading ?
                <div className='new-folder-row icon-search-loading'><Spinner/></div>
              :
          <tr className={selectedClass}>
            <td>
              { !this.props.intoModal ? <CustomInput id={this.props.id} type="checkbox" checked={this.props.checked} onChange={this.handleChangeCheckbox}/>  : null }
            </td>

            <td colSpan={colSpanChange} onClick={this.props.info.type == 'dir' ? () => {if(!this.state.editingName){ this.props.changeCurrentPath() }} : ( current !== 'trash' && (this.props.intoModal === undefined || (this.props.intoModal !== undefined && !this.props.intoModal)) ? () => { if(!this.state.editingName){this.loadFile(this.props.info.create_at)} } : null)}>

              {
                this.state.timeline !== undefined && this.state.timeline ? <img src={src_graph} width="20"></img> : <img src={src_path} width="20"></img>
              }
              <input type="text" readOnly={!this.props.intoModal ? this.state.title_readOnly : true}
                ref={this.titleInput}
                onDoubleClick={this.changeReadOnly}
                onChange={this.handleOnChangeTitle}
                onKeyUp={this.handleOnKeyUp}
                onBlur={this.handleOnBlur}
                value={this.state.title}
                className={'file-component title ' + (!this.props.intoModal ? (this.state.title_readOnly ? 'readOnly' : '') : 'readOnly') }
                data-id={this.props.info.id}
              />


            </td>

            <td>


              {
                type === 'file' && (this.props.intoModal === undefined || this.props.intoModal !== undefined && !this.props.intoModal) ?
                  <input
                    type="text"
                    readOnly={this.state.description_readOnly}
                    onDoubleClick={this.changeReadOnly}
                    onChange={this.handleOnChangeDescription}
                    onKeyUp={this.handleOnKeyUp}
                    onBlur={this.handleOnBlur}
                    value={this.state.description}
                    className={'file-component description ' + (this.state.description_readOnly ? 'readOnly' : '') }
                    data-id={this.props.info.id}
                  />
                : null
              }
            </td>

            <td><Moment date={this.props.info.create_at} format={dateFormat}></Moment></td>
            { !this.props.intoModal ?
              <td ref={node => { this.node = node }}>
                  <div className='d-flex justify-content-center'>
                          <img  id={"rowId_"+this.props.id} className="file-options-icon" src={fileOptionsIcon} width="20" onBlur={this.toggle} onClick={this.toggle} />
                <Popover  placement="bottom"
                          boundariesElement="window"
                          isOpen={this.state.popoverOpen} target={"rowId_"+this.props.id} >
                  { options }
                </Popover>
                  </div>
              </td>
              : <td></td>
            }
          </tr>
        )
    }
}

export default FSRow
