import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './../css/Login.css'
import logo from './../images/lightbox-search-logo.svg'
import axios from 'axios';
import { saveTokenInfo } from '../actionCreators'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { validateEmail, validatePassword } from '../helpers/string-validations'
import { setMessage } from './../helpers/messages'
import Spinner from './Spinner'

class PasswordReset extends Component {
	constructor(props) {
        super(props)
        this._isMounted = false
        this.state = {
            password: null,
            password_confirmation: null,
            success_message:null,
            error_message:null,
            error_reset_email: false,
            login_form_visible:true,
            loading:false,
            redirect:false
        }
        this.handleResetKeyUp = this.handleResetKeyUp.bind(this)
        this.validateToken = this.validateToken.bind(this)
        this.reset = this.reset.bind(this)
        this.saveValueInLocalStorage = this.saveValueInLocalStorage.bind(this)
    }

    componentDidMount() {
        this._isMounted = true

        if(this.props.userData.email != '' && this.props.userData.token != ''){
        	if ( !validateEmail(this.props.userData.email) || !this.validateToken(this.props.userData.token) ) {   
    			this.setState({login_form_visible: false, error_message: 'Invalid Url'})
    			return
            }
        }
    }

    handleResetKeyUp(event) { 
    	let key = event.target.name;
	    let value = event.target.value;
    	let obj = {}
    	obj[key] = value
    	
        this._isMounted && this.setState(obj)
        
        if (this.state.error_reset_email) { 
            this._isMounted && this.setState({error_reset_email:null, error_message:null, redirect: false})
        } else {
        	this._isMounted && this.setState({success_message:null})
        }
    }

    validateToken(token) {
    	const tokenLength = 60
    	if(token.length >= tokenLength) return true
    	else return false
    }

	saveValueInLocalStorage(message, messageType){
		var value = '';
    	if (localStorage.hasOwnProperty(messageType)) {
    		value = JSON.parse( localStorage.getItem(messageType) );    		
    	} else {
    		value = message
    		localStorage.setItem(messageType, JSON.stringify(value));
    	}
    	return value
	}

    reset(){
    	let messageType = ''
    	let success_message = ''
    	let error_message = ''
    	let value = '';
    	
    	if( (this.state.password && this.state.password != '') && (this.state.password === this.state.password_confirmation) ){
    		
    		if ( !validatePassword(this.state.password) ) {   
    			messageType = 'valid_field_error'
    			error_message = 'Password must be between 6 and 20 characters. It must contain at least one number and both uppercase and lowercase letters and one special character(#?!@$%^&*-).'
    			value = this.saveValueInLocalStorage(error_message, messageType)

    			this._isMounted && this.setState({error_reset_email: true, error_message: value })
    			return
            }

            this.setState({loading:true})

            let url= process.env.REACT_APP_API_ENDPOINT + '/password/reset' 

	        let parameters = {
	            'email' : this.props.userData.email,
	            'token' : this.props.userData.token,
	            'password' : this.state.password,
	            'password_confirmation' : this.state.password_confirmation
	        } 

	        axios.post(url, parameters)
		        .then(response => {
		            if(response.status == 200){
		                if(response.data.success == true){
                            setMessage('success', response.data.message)
		                	this.setState({error_reset_email: false, error_message: null, success_message: value, redirect: true})
		                } else {		                	
		                	if(Array.isArray(response.data.message.password)){
		                		let messages = response.data.message.password
		                		messageType = 'valid_field_error'
			                	
			                	for(var i = 0; i < messages.length; i++){
			                		error_message += messages[i]+' '
			                	}
		                	} else {
		                		messageType = 'password_error'
		                		error_message = response.data.message
		                	}
		                	
		                	this.setState({error_reset_email: true, error_message: error_message, success_message: null})		                	
		                }
		            } 
		            this._isMounted && this.setState({loading:false})
		        })
		        .catch(error => { 
		            this._isMounted && this.setState({loading:false, error_reset_email: true, error_message: 'Unable to reset password. Please, try again.'})
		        })
    	} else {  
    		this._isMounted && this.setState({error_reset_email: true, error_message: 'The passwords do not match.', success_message: null})
    	}
    }

    componentWillUnmount() {
        this._isMounted = false 
    }

  	render() {
  		
  		if (this._isMounted && this.state.redirect === true) {
      		return <Redirect to='/login' />
    	}
	    return (
	      	<div>
	      		{
                this.state.login_form_visible ? 
		        	<Form className="form-login">
	                    <div>
	                        <img src={logo} className="standard-logo-login" alt="logo" />
	                    </div>
	                    {  
                            (this.state.success_message !== null) ? 
                                <div className="alert alert-success fade show" role="alert">
                                    { this.state.success_message }
                                </div>
                            : ''
                        }

                        {
                            (this.state.error_message !== null) ?
                                <div className="alert alert-danger fade show error-message" role="alert">
                                    { this.state.error_message }
                                </div>
                            : ''
                        }
	                    <FormGroup>
	                        <Input type="hidden" name="token" id="token" defaultValue= {this.props.userData.token}/>
	                    </FormGroup>
	                    <FormGroup>
	                        <Input type="hidden" name="email" id="email" defaultValue= {this.props.userData.email}/>
	                        <Label for="email">Email</Label>
	                    </FormGroup>
	                    <FormGroup>
	                        <Input type="password" name="password" id="password" placeholder="password placeholder" onKeyUp={this.handleResetKeyUp} required="required"/>
	                        <Label for="password">Password</Label>
	                    </FormGroup>
	                    <FormGroup>
	                        <Input type="password" name="password_confirmation" id="passwordConfirmation" placeholder="password placeholder" onKeyUp={this.handleResetKeyUp} required="required"/>
	                        <Label for="passwordConfirmation">Password Confirmation</Label>
	                    </FormGroup>
	                    {
	                        (this.state.loading) ?
	                        <div className='icon-search-loading'><Spinner/></div>
	                    	: 
	                    	<Button className="submit-btn" onClick={this.reset}>Reset Password</Button>
	        			}
	                </Form>
	                :
                	<div className="alert alert-danger fade show" role="alert">
                        { this.state.error_message }
                    </div>
	            }
	      	</div>
	    );
  	}
}

export default connect(null, null)(PasswordReset)