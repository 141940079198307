import React from 'react';
import {StatBox} from './StatBox'
import {AdvertisingInfoStyledCPC} from '../../styles/StatBox'
import {getCpcEstimated} from "../../helpers/cpc";
import Spinner from '../Spinner';


const AdvertisingInfo = ({ cpc, competition, fetchData, ...rest}) => {

    return (
        <StatBox classNameContainer={`d-flex flex-column align-items-center ${competition || competition === null ?'justify-content-between' : ''}`}
                 title={'Advertising Info'} {...rest}>

        {competition || competition===0 ?
                <>
                <AdvertisingInfoStyledCPC>
                    <p className={'subtitle'}>
                        cpc
                    </p>
                    <p className={'value'}>
                        ${cpc ? (+cpc.toFixed(2)).toLocaleString('en') : 0}
                    </p>
                    <p className='money'>
                        (USD)
                    </p>
                </ AdvertisingInfoStyledCPC>
                <div>
                    <p>
                        Competition: <b>{competition && competition.toFixed(2)} ({getCpcEstimated(competition || 0)})</b>
                    </p>
                </div>
                </>
            : competition === null ?
            <>
            <AdvertisingInfoStyledCPC>
                <p className={'subtitle'}>
                    cpc
                </p>
                <p className={'value'}>
                    ${cpc ? (+cpc.toFixed(2)).toLocaleString('en') : 0}
                </p>
                <p className='money'>
                    (USD)
                </p>
            </ AdvertisingInfoStyledCPC>
            <div>
                <p>
                    Competition: <b>{"0"} ({getCpcEstimated(competition || 0)})</b>
                </p>
            </div>
            </>
            : <div className='icon-monthly-search-loading'><Spinner/></div>
        }  
        </StatBox>
    )
}
export default AdvertisingInfo;
