import React from 'react';
import LightboxScore from './statsBoxes/LightBoxScore';
import SentimentDistributionBox from './SentimentDistributionBox';
import CategoryDistributionBox from './CategoryDistributionBox';
import {StatBoxesScaled} from "../styles/StatBox";
import './../css/StatsBoxes.css';

const StatsBoxesOSHeader = () => {
    return(
        <div className={`stats-boxes`}>
        <div className="container">
            <StatBoxesScaled>
                <LightboxScore flexBasis={'33%'}/>
                <SentimentDistributionBox border flexBasis={'33%'}/>
                <CategoryDistributionBox border flexBasis={'33%'}/>
            </StatBoxesScaled>
        </div>
        </div>
    );
}

export default StatsBoxesOSHeader;