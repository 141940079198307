import React from 'react'
import { UncontrolledTooltip } from "reactstrap";

export default class Tooltip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {message, target} = this.props;
        return (
            <React.Fragment>
                <UncontrolledTooltip placement="bottom" target={target}>
                    <span style={{ color: "white" }} href="#" id="Tooltip">
                        {message}
                    </span>
                </UncontrolledTooltip>
            </React.Fragment>
        )
    }
}
