import React, { Component } from 'react'
import { connect } from 'react-redux'
import './../css/Profile.css'
import { Line, Bar } from 'react-chartjs-2';
import { Container, Row, Col, Card, CardHeader, CardFooter, CardBody } from 'reactstrap'
import moment from 'moment'
import { categories as categories_ } from "../config/categories"
import { getCategory } from '../helpers/select-constants'
import { dateFormat } from '../config/date'
import { DownloadDOMElement } from './DownloadDOMElement';
class DashboardChart extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false

        this.chartnames = {
            score:'Current Score',
            sentiment:'Sentiment Distribution',
            category:'Category Distribution',
            monthly:'Current Search Volume',
        }
        this.colors = {
            positive:'rgba(50,152,68,1)',
            neutral:'rgba(248,188,28,1)',
            negative:'rgba(208,2,27,1)'
        }
        this.sizes = {
            small: {height: 300, width: 450},
            medium: {height: 350, width: 550},
            large: {height: 600, width: 900}
        }

        this.state = {
            loading: false,
            data: this.chartData(),
            graph_size: this.getGraphSize()
        }

        this.scoreChartData = this.scoreChartData.bind(this)
        this.sentimentChartData = this.sentimentChartData.bind(this)
        this.categoryChartData = this.categoryChartData.bind(this)
    }

    componentDidMount() {
        this._isMounted = true
        window.addEventListener("resize", () => (this.updateDimensions()));
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getGraphSize(){
        if (window.innerWidth > 2000) {
            return "large"
        }else if (window.innerWidth > 1182) {
            return "medium"
        }else {
            return "small"
        }
    }

    updateDimensions() {
        this._isMounted && this.setState({
            graph_size: this.getGraphSize()
        })
      }

    chartData() {
        switch(this.props.title) {
            case 'score':
            case 'monthly':
                return this.scoreChartData()
                break
            case 'sentiment':
                return this.sentimentChartData()
                break
            case 'category':
                return this.categoryChartData()
                break
            default:
                return null
                break
        }
    }

    scoreChartData() {
        return {
          labels: this.props.data ? Object.keys(this.props.data).map(item=> moment(item).format(dateFormat)) : null,
          datasets: [
            {
                label: this.chartnames[this.props.title] + (this.props.title === 'score' ? ' %' : ''),
                fill: false,
                borderDash: [5, 5],
                lineTension: 0.1,
                data: this.props.data ? Object.values(this.props.data) : null,
            }
          ]
        }
    }

    sentimentChartData() {
        const sentiment = {
            positive:[],
            neutral:[],
            negative:[]
        }

        this.props.data && Object.values(this.props.data).forEach(item=>{
            Object.keys(item).map(elem => {
                if (elem !== 'unselected') sentiment[elem].push(item[elem])
            })
        })

        const response = {
            fill: false,
            borderDash: [5, 5],
            lineTension: 0.1,
            labels: this.props.data ? Object.keys(this.props.data).map(item=> moment(item).format(dateFormat)) : null,
        }


        response.datasets = Object.keys(sentiment).map(ds=>{
            return {
                label: ds.charAt(0).toUpperCase() + ds.slice(1),
                borderColor: this.colors[ds],
                pointBackgroundColor: this.colors[ds],
                data: sentiment[ds],
            }
        })

        return response
    }

    categoryChartData() {
        const response = {
            fill: false,
            borderDash: [5, 5],
            lineTension: 1.0,
            labels: this.props.data ? Object.keys(this.props.data).map(item=> moment(item).format(dateFormat)) : null,
            datasets: []
        }

        const categories = {}

        const cat_colors = {}
        Object.entries(categories_).map(([key,value]) => cat_colors[key] = value.color )

        this.props.data && Object.values(this.props.data).forEach(item=>{
            Object.keys(item).map(elem => {
                if (categories[getCategory(elem)] == undefined) {
                    categories[getCategory(elem)] = []
                }
            })
        })

        this.props.data && Object.values(this.props.data).forEach(item=>{

            Object.keys(categories).map(cat=>{
                if (item[getCategory(cat)] !== undefined) {
                    categories[getCategory(cat)].push(item[getCategory(cat)])
                }else {
                    categories[getCategory(cat)].push(0)
                }
            })
        })

        Object.keys(categories).map(cat=>{
            const label_cat = categories_[getCategory(cat)]['name']
            response.datasets.push({
                label: label_cat,
                data: categories[cat],
                backgroundColor: cat_colors[cat],
                borderWidth: 0,
                yAxisID: cat
            })
        })
        return response
    }

    render() {
        if(this.props.title === 'category'){
            var yAxes = [{ ticks: { min:0, max:10}  }]
            this.state.data.datasets.map(ds=> {
                yAxes.push({
                    display: false,
                    ticks: {min: 0, max: 10, beginAtZero: true, stepSize: 1, step: 1},
                    id: ds.yAxisID
                })
            })
        }

        return (
            <div>
                <Container className="graphic-card">
                    <Row className="graphic-card">
                        <Col xs={12} lg={12}>
                            <Card id={`${this.chartnames[this.props.title]}-chart`}>
                                <DownloadDOMElement 
                                    element={`${this.chartnames[this.props.title]}-chart`} 
                                    addFrame
                                    className="float-right mr-2 mt-2"
                                />
                                <CardHeader>{this.chartnames[this.props.title]}</CardHeader>
                                <CardBody>
                                    <Row className="graphic-card">
                                        <Col xs={12} lg={12}>
                                            {
                                                this.props.title === 'category' ?
                                                    <Bar
                                                        data={this.chartData()}
                                                        redraw={true}
                                                        responsive={true}
                                                        maintainAspectRatio={false}
                                                        width={this.sizes[this.state.graph_size].width}
                                                        height={this.sizes[this.state.graph_size].height}
                                                        options={{
                                                            scaleStartValue: 0,
                                                            scales: {
                                                                yAxes: yAxes,
                                                                xAxes: [{
                                                                    barPercentage: 0.8,
                                                                    categoryPercentage: 0.6,
                                                                }],

                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Line
                                                        width={this.sizes[this.state.graph_size].width}
                                                        height={this.sizes[this.state.graph_size].height}
                                                        data={this.chartData()}
                                                        redraw={true}
                                                        responsive={true}
                                                        maintainAspectRatio={false}
                                                    />
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth_info: state.auth_info
    }
}

export default connect(mapStateToProps, null)(DashboardChart)
