import React, { Component } from 'react'
import { Button, FormFeedback, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { connect } from 'react-redux'
import fetchClient from '../../helpers/fetch-client'
import { rankSentiment } from '../../helpers/select-constants'
import Spinner from '../../components/Spinner'
import '../../css/OverridesForm.css'
import { setOverride } from '../../actionCreators';

const base_url = process.env.REACT_APP_API_ENDPOINT;
const element = "sentimentoverride";
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

class SentimentForm extends Component {

    constructor(props){
        super(props)
        
        this.state = {
            loading: false,
            message: null,
            createDisabled: true
        }

        if(props.overrides.item){
            this.state.item = {
                ...props.overrides.item,
                term: props.overrides.item.term ? props.overrides.item.term : props.overrides.term,
                location: {
                   loc_id: props.overrides.item.location,
                   canonical_name: props.overrides.item.location_name
                }
            }
        }
        
    }
    
    componentDidMount(){
        this.setSentiment();
    }
    
    setSentiment = () => {
        let sentiment = null
        if(this.props.overrides.item) {
            sentiment = rankSentiment.find((option) => {
                return option.value === this.props.overrides.item.sentiment
            })
        }
        this.setState({
            item: {...this.state.item, sentiment: sentiment}
        })
    }
    
    toggleLoader = () => {
        this.setState({
          ...this.state,
          loading : !this.state.loading
        });
    }
    
    handleChange = (evt) => {
        this.setState({
            item: {...this.state.item, [evt.target.name]: evt.target.value},
            createDisabled: !!!evt.target.value.trim().length,
            message: (!!!evt.target.value.trim().length) ? "This field is required" : null
        })
    }
    
    handleMessages = (messages) => {
        this.setState({
          ...this.state,
          messages : {...messages}
        });    
    }
    
    handleChangeSentiment = (evt) => {
        this.setState({
            item: {...this.state.item, sentiment: evt},
        })
    }
    
    handleLocation = (location) => {
        this.setState({
            item: {...this.state.item, location: location}
        })
    }
    
    handleDeleteItem = (item) => {
        let data = {
            item: item,
            type: this.props.overrides.selected_list,
            modal_is_open: true,
            modal_type: 'delete'
        }
        this.props.onItemAction(data)
    }

    create = () => {
        let item = {
            ...this.state.item
        };

        item.location = null;
        item.status = "Pending save";
        item.currentOverride = true;
        this.props.updateCurrentOverride(item)
        this.cancel();
    }
    
    delete =  () => {
        this.toggleLoader();
        const {item} = this.state;
        fetchClient().delete(base_url+'/'+element+'/'+item.id)
            .then((response) => {
                let data = { 
                    list: response.data.data,
                    selected_list: 'sentiment',
                    term : item.term
                }
                this.props.refreshListData(data);
                this.cancel(true);
                this.toggleLoader();
            })
            .catch(
                (error) => {
                    this.toggleLoader();
                    this.handleMessages(error.response.data.errors);
                }
            )  
    } 
    
    cancel = (messages = false) => {
        let success = {type : 'success',text : 'Success'};
        let data = {
            item: {},
            type: this.props.overrides.selected_list,
            modal_is_open: false,
            modal_type: "",
            messages: messages ? success : false
        }
        this.props.onItemAction(data)
    }
    
    
    render(){
        const {
            overrides
        } = this.props;
        
        const {
            item,
            messages,
            loading
        } = this.state;
        return (
            <Modal isOpen={overrides.modal_is_open} className={"overrideForm"}>
                {overrides.modal_type ? <ModalHeader>{overrides.modal_type.capitalize()} Sentiment Override</ModalHeader> : ""}
                <ModalBody className="overrideForm">            
                    <div className='OverridesForm'>
                        <InputGroup>
                            <Input disabled={overrides.modal_type === 'delete'} placeholder="Paste the url here" value={item.url ||  ''} name="url" className='url-input' onChange={this.handleChange}/>
                            {this.state.message && <FormFeedback style={{ display:'block'}}>{this.state.message}</FormFeedback>}
                        </InputGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {loading ? <Spinner /> : null }
                    <Button 
                        color="secondary"
                        className="btnCancelOverride"
                        onClick={() => (this.cancel())}
                    >Cancel</Button>
                    {
                        overrides.modal_type === "create" && 
                            <Button 
                                disabled={this.state.createDisabled}
                                color="primary"
                                onClick={this.create}
                                className={"btnCreateCurrentOverride"}
                            >Create</Button>
                    }
                    {
                        overrides.modal_type === "delete" && 
                            <Button 
                                disabled={loading}
                                color="danger"
                                onClick={this.delete}
                            >Delete</Button>    
                    }
                </ModalFooter>
            </Modal>  
        );
    }
}

const mapStateToProps = (store) => {
    return {
        overrides: store.overrides,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onItemAction: (data) => {
            dispatch(setOverride(data))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SentimentForm)
