import React from 'react';
import styled from 'styled-components'
import Spinner from '../../Spinner'

export default class Loading extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {loading} = this.props;
        return (
            <LoadingStyled loading={loading}>
                {loading && <Spinner/>}
            </LoadingStyled>
        );
    }

}

const LoadingStyled = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  transition: opacity .5s ease-in-out;
  display:  ${({loading}) => loading ? 'flex' : 'none'};
  z-index: ${({loading}) => loading ? 99999 : -1};
  opacity: ${({loading}) => +loading};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`