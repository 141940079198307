import React from 'react'
import getFormattedURL from '../../helpers/getFormattedURL'
import { SEOHeaderStyle } from '../../styles/SEOModal'
import { getColorScore } from '../../styles/variables'
import { DownloadIconStyled } from '../../styles/DownloadDOMElement';
import DonwloadIcon from '../../images/icons/download-chart-icon.svg';
import html2canvas from "html2canvas";
import saveAs from "file-saver"

const SEOHeader = ({ score, URL, title, elementId }) => {
    const scoreColor = getColorScore(score)
    const formattedURL = getFormattedURL(URL)

    const handleDownload = () => {
        let content = document.getElementById(elementId)

        showModalBorder(false)
        showWarningScroll(false)

        html2canvas(content, getHtml2CanvasOptions(content))
            .then((canvas) => {
                let img = canvas.toDataURL('image/png')
                saveAs(img, `SEO Analysis ${title}.png`)
            })
            .finally(() => {
                showModalBorder(true)
                showWarningScroll(true)
            })
    }

    const showModalBorder = (isVisible) => {
        let content = document.getElementById(elementId).firstChild
        if (!isVisible) {
            content.classList.remove('modal-content')
            content.style.backgroundColor = "#FFF"
        } else {
            content.classList.add('modal-content')
            content.style.backgroundColor = null
        }
    }

    const showWarningScroll = (isVisible) => {
        let seoWarnings = document.getElementsByClassName('seo-warnings').item(0)

        if (!!seoWarnings) {
            if (!isVisible) {
                seoWarnings.style.overflowX = 'visible'
                seoWarnings.style.maxHeight = 'unset'
            } else {
                seoWarnings.removeAttribute('style')
                seoWarnings.style.maxHeight = '42.69rem'
            }
        }
    }

    const getHtml2CanvasOptions = (element) => {
        return {
            x: 0,
            scrollY: 0,
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
            backgroundColor: "#FFF"
        }
    }

    return (
        <SEOHeaderStyle scoreColor={scoreColor}>
            <div class="flex-wrapper">
                <div class="col-11 p-0">
                    <h3>SEO Analysis:</h3>
                </div>
                <div class="col-1 p-0 text-center">
                    <DownloadIconStyled
                        onClick={handleDownload}
                        className="seo-modal-download-arrow"
                        data-html2canvas-ignore="true"
                    >
                        <img src={DonwloadIcon} alt="download-icon" className="pl-1 pb-1" />
                    </DownloadIconStyled>
                </div>
            </div>

            <div className="flex-wrapper">
                <span className="score">{score ?? ''}</span>
                <div className="separator" />
                <div className='flex-column'>
                    <p className="url">{formattedURL ?? ''}</p>
                    <a href={URL} target='_blank' className="title">{title ?? ''}</a>
                </div>
            </div>

        </SEOHeaderStyle>
    )
}

export default SEOHeader
