import React, { Component } from 'react'
import {
    calculateLumentusScore,
    changeAutomation,
    changeCurrentPath,
    changePagesAutomate,
    clearEmptyCacheWarning,
    clearSearch,
    exportChanges,
    exportLoading,
    fillTooltips,
    search,
    search_finished,
    subSearch
} from '../actionCreators'
import './../css/Header.css'
import { connect } from 'react-redux'
import SaveFileModal from './SaveFileModal'
import { timeout } from 'promise-timeout'
import Spinner from './Spinner'
import { validateEmail, validateUrl } from '../helpers/string-validations'
import { cleanMessages, getMessages } from '../helpers/messages'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import fetchClient from '../helpers/fetch-client'
import HeaderResult from '../components/Header/index'
import OnScrollHeader from './Header/OnScrollHeader'
import LoadSEOModalIcons from './seoModal/LoadSEOModalIcons'
import { exportToXLXS } from '../helpers/export-xlsx'

let requests_index = 0
let requests_status = [true]

class Header extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false

        this.state = {
            main_search: "",
            sub_search: null,
            saveFileModalOpen: false,
            loading: false,
            export_loading: false,
            auto_saving: false,
            error_message: null,
            success_message: null,
            warning_min_length: null,
            activeClass: '',
            saveFileMessage: null,
            exportFileMessage: null,
            buttonSaveOpen: false,
            screenshotIsInProgress: false
        }

        this.handleChangeSubsearch = this.handleChangeSubsearch.bind(this)
        this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this)
        this.executeSearch = this.executeSearch.bind(this)
        this.saveAsChangesHandler = this.saveAsChangesHandler.bind(this)
        this.saveChangesHandler = this.saveChangesHandler.bind(this)
        this.toggleSaveChangesModal = this.toggleSaveChangesModal.bind(this)
        this.handleOnPaste = this.handleOnPaste.bind(this)
        this.exportChangesHandler = this.exportChangesHandler.bind(this)
        this.exportChangesHandlerXLSX = this.exportChangesHandlerXLSX.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.isLoadedAllRows = this.isLoadedAllRows.bind(this)
        this.toggleButtonSave = this.toggleButtonSave.bind(this)
        this.toggleAutoSaveModal = this.toggleAutoSaveModal.bind(this)
        this.resetMessages = this.resetMessages.bind(this)
        this.cancelSearch = this.cancelSearch.bind(this)
        this.subSearch = this.subSearch.bind(this)
    }

    toggleButtonSave() {
        this.setState({
            buttonSaveOpen: !this.state.buttonSaveOpen
        });
    }

    componentWillMount() {
        this.timer = null
    }

    componentDidMount() {
        this._isMounted = true
        this.props.fillTooltips()
        var lastScrollTop = 0;
        window.addEventListener('scroll', (event) => {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            let classFixed = 'normal';
            if (window.pageYOffset > 700) {
                classFixed = 'fixed';
            } else if (st < lastScrollTop && (window.pageYOffset < 700 && window.pageYOffset > 600)) {
                classFixed = 'fixed up';
            }
            lastScrollTop = st <= 0 ? 0 : st;
            this._isMounted && this.setState({
                activeClass: classFixed
            })
        });
        this.props.clearEmptyCacheWarning()

        //reset the breadcrumb path
        this.props.changeCurrentPath('/')
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    subSearch() {
        if (this.state.sub_search !== null) {
            this.props.subSearch(this.state.sub_search)
            this.props.calculateLumentusScore()
        }
    }

    handleChangeSubsearch(ev) {
        this._isMounted && this.setState({
            sub_search: ev.target.value
        })
        if (ev && ev.key === 'Enter') this.subSearch()
        return

        let resetSearch = false
        if (ev && ev.key === 'Backspace' && ev.target.value.length === 0) {
            resetSearch = true
        }

        this._isMounted && this.setState({
            sub_search: ev.target.value
        })

        clearTimeout(this.timer)
        this.props.clearEmptyCacheWarning()

        if (ev.target.value.length >= 3 || resetSearch) {
            this._isMounted && this.setState({ warning_min_length: null })
            let me = this
            this.timer = setTimeout((ev) => {
                me.props.subSearch(!resetSearch ? me.state.sub_search : '')
                me.props.calculateLumentusScore()
            }, 100)
        } else {
            this._isMounted && this.setState({ warning_min_length: 'Enter min 3 characters to subsearch' })
        }
    }

    handleSearchKeyUp(ev) {
        const value = ev.target.value
        this._isMounted && this.setState({
            main_search: value
        })

        if (ev.key === 'Enter' && !this.state.loading) {
            if (this.state.main_search.trim() !== "") this.executeSearch()
        }
    }

    handleOnPaste(ev) {
        let pastedText = undefined
        if (window.clipboardData && window.clipboardData.getData) { // IE
            pastedText = window.clipboardData.getData('Text')
        } else if (ev.clipboardData && ev.clipboardData.getData) {
            pastedText = ev.clipboardData.getData('text/plain')
        }

        if (pastedText !== undefined) {
            this._isMounted && this.setState({
                main_search: pastedText
            })
        }
    }

    executeSearch() {
        if (this.state.main_search.trim() !== "") {

            let message = ''

            if (validateEmail(this.state.main_search.trim()) || validateUrl(this.state.main_search.trim())) {
                message = 'The search term can not be an url or email address.'
                this._isMounted && this.setState({ error_message: message, loading: false })
                return
            }

            requests_index++
            requests_status[requests_index] = true
            this._isMounted && this.setState({ loading: true, error_message: null })

            // Sanitize
            let term = this.state.main_search.replace(/[^0-9a-zA-Z. ]/g, '')

            this.props.search(term.trim(), this.props.test !== undefined ? this.props.test : null, requests_index, this.props.automation, this.props.pagesToAutomate).catch(error => {

                switch (error) {
                    case "timeout":
                        message = 'Search Timed Out. Please try again';
                        break
                    default:
                        if (error.response && error.response.data.message) {
                            message = error.response.data.message
                        } else {
                            message = 'Can\'t connect to server'
                        }
                        break
                }
                this._isMounted && this.setState({ error_message: message, loading: false })
            })
        }
    }

    isLoadedAllRows() {
        this.resetMessages()
        let finished = !this.props.fetching_ids.filter(item => item < this.props.visible_rows).length

        if (this.props.uploaded_file) {
            finished = true
        }

        return finished
    }

    saveAsChangesHandler() {
        if (this.isLoadedAllRows()) {
            this._isMounted && this.setState({ saveFileModalOpen: true })
        } else {
            this._isMounted && this.setState({ saveFileMessage: 'Wait until all the modules are loaded' })
        }
    }

    saveChangesHandler() {
        if (this.isLoadedAllRows()) {
            let uploaded_file = this.props.uploaded_file

            let parse = uploaded_file.split('/')
            let title = parse[parse.length - 1].trim()
            let path = uploaded_file.substr(0, uploaded_file.lastIndexOf(title))

            if (title !== "" && path !== "") {
                let params = {
                    path: path,
                    title: title,
                    content: JSON.stringify(this.props.state),
                    confirm: 1
                }

                this.setState({
                    loading: true
                })

                var me = this
                fetchClient().post(process.env.REACT_APP_API_ENDPOINT + "/fs", JSON.stringify(params))
                    .then((response) => {
                        if (response.status !== 201) {
                            me._isMounted && me.setState({ error_message: 'An error occur saving the file, please retry.' })
                        } else {
                            me._isMounted && me.setState({ success_message: 'File saved succesfully' })
                        }
                    }, (error) => {
                        me._isMounted && me.setState({ error_message: 'An error occur saving the file, please contact with the administrator.' })
                    })
                    .finally(() => {
                        me.setState({
                            loading: false
                        })
                    })
            }

        } else {
            this._isMounted && this.setState({ saveFileMessage: 'Wait until all the modules are loaded' })
        }
    }

    exportChangesHandlerXLSX(state) {
        exportToXLXS(state);
    }

    toggleSaveChangesModal() {
        this._isMounted && this.setState({
            saveFileModalOpen: !this.state.saveFileModalOpen
        })
    }

    formatDate() {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        year = year.toString().substring(2);

        return [month, day, year].join('');
    }

    exportChangesHandler() {
        
        let paginateButtons = document.getElementsByClassName('paginate-btn')
        let overrideConfirmations = document.getElementsByClassName('override-section')
        let downloadArrows = document.getElementsByClassName('download-arrow')

        const exportPdf = async () => {
            this.props.exportLoading(true)
            let titles = document.getElementsByClassName('top_story_title');
            for (let i = 0; i < titles.length; i++) {
                titles[i].style.maxHeight = '72px';
                titles[i].style.display = 'block';
            }

            toggleElementsByClassName(paginateButtons, false)
            toggleElementsByClassName(overrideConfirmations, false)
            toggleElementsByClassName(downloadArrows, false)

            const input = document.getElementById('root');

            var body = document.body,
                html = document.documentElement;

            var height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);


            var me = this
            html2canvas(input, { height, scale: 1 })
                .then((canvas) => {
                    var width = canvas.width;
                    var height = canvas.height;
                    var millimeters = {};
                    millimeters.width = Math.floor(width * 0.264583)
                    millimeters.height = Math.floor(height * 0.264583)

                    var imgData = canvas.toDataURL(
                        'image/jpeg')
                    var doc = new jsPDF("p", "mm", "a4")
                    doc.deletePage(1)
                    doc.addPage(millimeters.width, millimeters.height)
                    doc.addImage(imgData, 'JPEG', 0, 0, null, null, "lightbox", 'MEDIUM')
                    var sufix = this.formatDate()
                    doc.save('Lightbox-' + sufix + '.pdf')

                    let titles = document.getElementsByClassName('top_story_title');
                    for (let i = 0; i < titles.length; i++) {
                        titles[i].style.maxHeight = '';
                        titles[i].style.display = '';
                    }
                })
                .finally(async () => {
                    me._isMounted && me.setState({
                        export_loading: false,
                        screenshotIsInProgress: false
                    },
                        () => {
                            toggleElementsByClassName(paginateButtons, true)
                            toggleElementsByClassName(overrideConfirmations, true)
                            toggleElementsByClassName(downloadArrows, true)
                        }
                    )
                    me.props.exportLoading(false)
                })
        }

        if (this.isLoadedAllRows()) {
            this._isMounted && this.setState({
                export_loading: true,
                screenshotIsInProgress: true
            }, () => exportPdf())
        } else {
            this._isMounted && this.setState({ exportFileMessage: 'Wait until all the modules are loaded' })
        }

        const toggleElementsByClassName = (elements, isVisible) => {
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = isVisible ? 'block' : 'none';
            }
        }
    }

    toggleAutoSaveModal() {
        this._isMounted && this.setState({
            autoSaveModalOpen: !this.state.autoSaveModalOpen
        })
    }

    resetMessages() {
        this._isMounted && this.setState({ saveFileMessage: null, exportFileMessage: null, autoSaveMessage: null })
    }

    cancelSearch() {
        requests_status[requests_index] = false
        this.setState({ loading: false })
        this.props.clearSearch()
    }

    handleChangeAutomation = () => {
        if (this.props.resultsPage !== "1" && !this.state.loading) {
            this.props.changePagesToAutomate(this.props.automation ? 0 : 1)
            this.props.changeAutomation()
        }
    }
    handleChangePagesToAutomate = (changeAmount) => {
        if (changeAmount === 'sum' && this.props.pagesToAutomate < 10) {
            this.props.changePagesToAutomate(this.props.pagesToAutomate + 1)
        } else if (changeAmount === 'subtract' && this.props.pagesToAutomate > 0) {
            if (this.props.pagesToAutomate === 1) {
                this.props.changeAutomation()
            }
            this.props.changePagesToAutomate(this.props.pagesToAutomate - 1)
        }
    }

    render() {
        let messages = getMessages()
        cleanMessages()
        return (
            <div className={`header-area normal`}>
                <HeaderResult
                    onSearch={this.executeSearch}
                    loading={this.state.loading}
                    toggleHistoricalData={this.props.onTogglHistoricalData}
                    exportPDF={this.exportChangesHandler}
                    exportXLSX={this.exportChangesHandlerXLSX}
                    screenshotIsInProgress={this.state.screenshotIsInProgress}
                    exportFileMessage={this.state.exportFileMessage}
                    automation={this.props.automation}
                    itemsAlreadyLoaded={this.props.itemsAlreadyLoaded}
                    onChangeAutomation={this.handleChangeAutomation}
                    incrementPages={() => this.handleChangePagesToAutomate('sum')}
                    decrementPages={() => this.handleChangePagesToAutomate('subtract')}
                    cancelSearch={this.cancelSearch}
                    onSaveAs={this.saveAsChangesHandler}
                    onSave={this.saveChangesHandler}
                    search_data={this.props.search_data}
                    onSubSearch={this.subSearch}
                    onChangeSubSearch={this.handleChangeSubsearch}
                    onSearchKeyUp={this.handleSearchKeyUp}
                    onPaste={this.handleOnPaste}
                    searchInputHasData={this.state.main_search.trim() !== ""}
                    warning_min_length={this.state.warning_min_length}
                    messages={messages}
                    errorMessage={this.state.error_message}
                    successMessage={this.state.success_message}
                    saveFileMessage={this.state.saveFileMessage}
                    disableButton={!!this.props.fetching_ids.filter(item => item < this.props.visible_rows).length}
                    generateActionPlan={this.props.generateActionPlan}
                    actionPlanModal={this.props.actionPlanModal}
                    handleActionPlanModal={this.props.handleActionPlanModal}
                    showActionPlanButton={this.props.showActionPlanButton}
                    loadPlan={this.props.loadPlan}
                    generatePlanMessage={this.props.generatePlanMessage}
                />
                {
                    (this.state.activeClass === 'fixed' || this.state.activeClass === 'fixed up') ?
                        <div className={`header-area ${this.state.activeClass} onScrollHeader`}>
                            <OnScrollHeader />
                        </div>
                        : null
                }
                {
                    (this.props.resultsPage === "1") ?
                        <SaveFileModal close={this.toggleSaveChangesModal} open={this.state.saveFileModalOpen} />
                        : null
                }
                {
                    (this.state.loading) ?
                        <div
                            className={"spinner-loading search_page_spinner" + ((this.props.resultsPage === "1") ? " result_page" : " search_page")}>
                            <Spinner /></div>
                        : ''
                }
                <LoadSEOModalIcons />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        search_data: state.search,
        state: state,
        ranking: state.ranking,
        visible_rows: state.visible_rows,
        warning_empty_cache: state.warning_empty_cache,
        uploaded_file: state.uploaded_file,
        fetching_ids: state.fetching_ids,
        export_loading: state.export_loading,
        automation: state.search.automation,
        pagesToAutomate: state.search.pagesToAutomate,
        file_created_at: state.created_at
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        search: async (term, test = '', req_i, automation, pagesAutomate) => {
            let loc_id = window.loc_id;
            let canonical_name = window.loc_canonical_name;
            let url = process.env.REACT_APP_API_ENDPOINT + '/search/' + term + '?loc_id=' + loc_id + '&canonical_name=' + canonical_name + '&automation=' + Number(automation);
            if (automation) {
                url = url + '&pages_automate=' + pagesAutomate
            }
            if (test && test !== '')
                url += '/' + test

            let time = process.env.REACT_APP_API_TIMEOUT !== undefined && !isNaN(process.env.REACT_APP_API_TIMEOUT) ? process.env.REACT_APP_API_TIMEOUT : 60000
            return timeout(
                fetchClient().get(url), time)
                .then((response) => {
                    if (!requests_status[req_i]) return
                    if (response.status !== 200) {
                        throw response.message
                    } else {
                        dispatch(search_finished(term, response.data, loc_id, canonical_name))
                    }
                }).then(() => {
                    if (!requests_status[req_i]) return
                    dispatch(search(term))
                })
                .catch(function (error) {
                    if (!requests_status[req_i]) return
                    throw 'Can\'t connect to server, contact with the administrator'
                })
        },
        subSearch: (val) => {
            dispatch(subSearch(val))
        },
        calculateLumentusScore: () => {
            dispatch(calculateLumentusScore())
        },
        clearSearch: () => {
            dispatch(clearSearch())
        },
        exportChanges: () => {
            dispatch(exportChanges())
        },
        fillTooltips: () => {
            let urlTooltips = process.env.REACT_APP_API_ENDPOINT + '/tooltips'

            fetchClient().get(urlTooltips)
                .then(result => {
                    if (result !== undefined) {
                        dispatch(fillTooltips(result.data.data))
                    }
                }).catch(function (error) {
                    throw error
                })
        },
        clearEmptyCacheWarning: () => {
            dispatch(clearEmptyCacheWarning())
        },
        changeCurrentPath: (val) => {
            dispatch(changeCurrentPath(val))
        },
        exportLoading: (status) => {
            dispatch(exportLoading(status))
        },
        changeAutomation: () => {
            dispatch(changeAutomation())
        },
        changePagesToAutomate: (numberPages) => {
            dispatch(changePagesAutomate(numberPages))
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)