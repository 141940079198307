import React from 'react';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AutomatedSearchStyled} from "../../styles/AutomationPages";
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export const AutomationPages = ({increment , decrement , disabled ,  value , max = 10 , min = 1, ...rest }) => {

    return (
        <AutomatedSearchStyled {...rest}>
            <span>Pages 1 to </span>
            <Button className="btn-control" onClick={decrement} disabled={(value <= min) || disabled}>
                <FontAwesomeIcon className="icon" icon={faMinus}
                />
            </Button>
            {value}
            <Button className="btn-control" onClick={increment} disabled={(value >= max) || disabled}>
                <FontAwesomeIcon className="icon" icon={faPlus}
                />
            </Button>
        </AutomatedSearchStyled>
    )
}
