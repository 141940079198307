import React from 'react'
import {Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import moment from 'moment'
import { faClock, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { dateFormat } from '../../../config/date';

export default class RowTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openOptions: false,
            openModal: false
        }
        this.toggle = this.toggle.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    toggle = () => this.setState(prevState => ({
        openOptions: !prevState.openOptions
    }))
    toggleModal = () => this.setState(prevState => ({
        openModal: !prevState.openModal
    }))

    render() {
        const {openModal, deleteSchedule, item} = this.props
        return (
            <React.Fragment>
                <Row>
                    <td>
                        <FontAwesomeIcon className="icon-toggle ml-2" icon={faClock}
                                         color="#35A9E0"/>
                    </td>
                    <td className='name'>
                        {item.term}
                    </td>
                    <td>
                        {item.location_name.country + ', ' + item.location_name.name}
                    </td>
                    <td className='text-capitalize'>
                        {item.period}
                    </td>
                    <td>
                        {`${moment(item.start_date).format(dateFormat)}${item.end_date ? ' - ' + moment(item.end_date).format(dateFormat) : ''}`}
                    </td>
                    <td>
                        {item.created_at ? moment(item.created_at).format(dateFormat) : 'None'}
                    </td>
                    <td>

                        <div className='d-flex justify-content-center align-center'>
                            <ButtonDropdown isOpen={this.state.openOptions} toggle={this.toggle}>
                                <DropdownToggle>
                                    <FontAwesomeIcon className="icon-toggle" icon={faEllipsisV}
                                                     color="#35A9E0"/>

                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className='edit' onClick={() => openModal(item)}> Edit </DropdownItem>
                                    <DropdownItem
                                        className='delete'
                                        onClick={() => this.setState({openModal: true})}> Delete </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>

                    </td>
                </Row>
                <ModalStyled size='sm' centered isOpen={this.state.openModal} toggle={this.toggleModal}>
                    <ModalBody>
                        <div className="body">
                            <p> Are you sure to delete this schedule? </p>
                        </div>
                        <div className="footer">
                            <Button className='btn' onClick={() => this.setState({openModal: false})}>
                                Cancel
                            </Button>
                            <Button className='btn delete' onClick={() => {
                                deleteSchedule(item.id)
                                this.setState({openModal: false})
                                this.setState({openModal: false})
                            }}>
                                Delete
                            </Button></div>
                    </ModalBody>
                </ModalStyled>
            </React.Fragment>)


    }
}
const ModalStyled = styled(Modal)`
  .modal-content {
    max-width: 15rem;

    margin: auto;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    overflow: hidden !important;
    border-radius: 20px !important;

    .btn {
      font-size: 1em;

    }

    .modal-body {
      .body {
        display: flex;
        justify-content: center;

        p {
          color: #35A9E0;
          text-transform: uppercase;
          text-align: center;
          line-height: 1.4em;
          font-size: 1.2em;
          font-weight: 600;
          margin: calc(3% + 1rem) 0;
          padding: 0;
        }
      }

      .footer {
        display: flex;
        justify-content: space-around;
      }

      .btn {
        margin: 0 .5rem;
        font-size: 1em;
        color: #5b666f !important;
        background-color: #fff !important;
        outline: none !important;
        border: solid 0.0625rem #5b666f !important;
        box-shadow: 0 0 0.0625rem #5b666f;

        &.delete {
          color: #721c24 !important;
          background-color: #f8d7da !important;
          border-color: #f5c6cb !important;
        }

        &:hover {
          opacity: .8;
        }

        &:active {
          opacity: .7;
          outline: none !important;
          box-shadow: 0 0 0.0625rem #5b666f;
        }
      }
    }
  }
`
const Row = styled.tr`
  td {
    font-size: 1em;
    color: #212529;
  }

  .icon-toggle {
    font-size: 1.7em;

  }

  .dropdown-item {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #212529;

    &.edit {
      color: #ffc107;
    }

    &.delete {
      color: #dc3545;
    }

    &:hover {
      background-color: #35a9e0;
      color: #fff;
      box-shadow: 0 0 2px #35a9e0 !important;
    }

    &:active {
      background-color: #35a9e0 !important;
    }
  }

  .name {
    color: #35A9E0 !important;
    font-weight: 600;
  }

  .btn, .btn-secondary {
    outline: none !important;
    background-color: transparent !important;
    color: #212529;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

`
