import React, { useState } from 'react'
import SEOWarningItem from './SEOWarningItem'
import Spinner from '../Spinner'

const SEOBodyWarnings = ({ warnings }) => {
    return (
        <>
            <div className="warnings-header">WARNINGS</div>
            {
                warnings &&
                warnings.map((item) =>
                    <SEOWarningItem title={item[1].title} description={item[1].description}/>
                )
            }
        </>
    )
}

export default SEOBodyWarnings
