function setMessage(type, msg) {
  let messages = []

  if (localStorage.hasOwnProperty('messages')){
    messages = JSON.parse(localStorage.getItem('messages'))
  }

  let setted = false
  for (let i = 0; i < messages.length; i++) {
    let m = messages[i]
    if (m.msg === msg) {
      setted = true
    }
  }
  
  if (!setted){
    messages.push({
      msg: msg,
      type: type
    })
    localStorage.setItem('messages', JSON.stringify(messages))
  }
}

function getMessages(){
  let messages = []

  if (localStorage.hasOwnProperty('messages')){
    messages = JSON.parse(localStorage.getItem('messages'))
  }

  return messages
}

function cleanMessages(){
  localStorage.setItem('messages', JSON.stringify([]))
}

export { setMessage, getMessages, cleanMessages }