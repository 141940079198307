import React from 'react'
import '../css/Spinner.css'

const Spinner = ({ inRowFooter }) => {
    return (
        <div className="result-score low-value-score">
            <div className={`ball-pulse ${inRowFooter && 'pr-4'}`}>
                <div/><div/><div/>
            </div>
        </div>
    )
}

export default Spinner
