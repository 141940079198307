import { CustomInput, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { Component } from 'react'
import { saveUserFS, changeCurrentPath, importFileToStore, clearUploadedFile, importAutomatedFileToStore } from '../actionCreators'
import axios from 'axios'
import { connect } from 'react-redux'
import FSRow from './FSRow'
import Spinner from './Spinner'
import FilesBreadcrum from './FilesBreadcrum'
import FilesToolbar from './FilesToolbar'
import MoveFilesModal from './MoveFilesModal'

import FilesTrashLink from './FilesTrashLink'
import NewFolderRow from './NewFolderRow'
import fetchClient from '../helpers/fetch-client'
import { Redirect } from 'react-router-dom'


class FilesNavigation extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false

    const auth_info = JSON.parse(localStorage.getItem('token_info'))
    const data = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
      }
    }

    this.endpoints = [
      { action: 'move', url: '/fs/move', method: 'post' },
      { action: 'trash', url: '/fs/trash', method: 'post' },
      { action: 'delete', url: '/fs/delete', method: 'post' },
      { action: 'save', url: '/fs', method: 'post' },
      { action: 'empty-trash', url: '/fs/empty-trash', method: 'post' },
      { action: 'restore', url: '/fs/restore', method: 'post' },
      { action: 'load', url: '/fs/file', method: 'get' },
      { action: 'update-description', url: '/fs/description', method: 'post' },
      { action: 'timeline', url: '/fs/timeline', method: 'post' },
      { action: 'removetimeline', url: '/fs/removetimeline', method: 'post' },
    ]

    this.actionOptions = [
      { value: "", label: "Select", place: "" },
      { value: "edit", label: "Edit", place: 'notrash', callAction: 'editRowName' },
      { value: "trash", label: "Trash Items", place: 'notrash', callAction: 'executeAction' },
      { value: "move", label: "Move Items", place: 'notrash', callAction: 'moveAction' },
      { value: "timeline", label: "Add to timeline", place: 'notrash', callAction: 'executeAction' },
      { value: "removetimeline", label: "Remove from timeline", place: 'notrash', callAction: 'executeAction' },
      { value: "restore", label: "Restore Items", place: 'trash', callAction: 'executeAction' },
      { value: "delete", label: "Delete permanently", place: 'trash', callAction: 'executeAction' },
    ]

    this.state = {
      headers: data,
      message: '',
      message_type: '',
      permanent_message: false,
      hasTrashedItems: false,
      loading: false,
      user_FS: [],
      current_path: props.currentPath || '/',
      current_path_modal: props.currentPath || '/',
      selectedItems: [],
      allItemsChecked: false,
      rows: [],
      creatingFolder: false,
      resetSelect: false,
      visibleModalPrompt: false,
      promptMessage: null,
      currentAction: null,
      width: 0,
      moveFilesModal: false,
      filesToMove: [],
      trying_loading: null,
      trying_restore: null,
      redirect: false,
      created_at: null
    }
    this.fetchFiles = this.fetchFiles.bind(this)
    this.parseData = this.parseData.bind(this)
    this.showMessage = this.showMessage.bind(this)
    this.changeCurrentPath = this.changeCurrentPath.bind(this)
    this.reDrawList = this.reDrawList.bind(this)
    this.changeSelectedItems = this.changeSelectedItems.bind(this)
    this.onFinishCreateFolder = this.onFinishCreateFolder.bind(this)
    this.checkTrashHasElements = this.checkTrashHasElements.bind(this)
    this.handleChangeAllCheckboxes = this.handleChangeAllCheckboxes.bind(this)
    this.clearMessage = this.clearMessage.bind(this)
    this.togglePromptModal = this.togglePromptModal.bind(this)
    this.toggleMoveFilesModal = this.toggleMoveFilesModal.bind(this)
    this.loadFile = this.loadFile.bind(this)
    this.validateRequiredFields = this.validateRequiredFields.bind(this)
    this.importFile = this.importFile.bind(this)
    this.finishRender = this.finishRender.bind(this)
    this.unMountNewRow = this.unMountNewRow.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchFiles(true)
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillMount() {
    this.setState({ width: window.innerWidth })
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }

  showMessage(data) {
    this._isMounted && this.setState({ message: data.message, message_type: data.type })
    if (data.permanent_message !== undefined)
      this._isMounted && this.setState({ permanent_message: data.permanent_message })
  }

  clearMessage() {
    this._isMounted && this.setState({ message: '', message_type: '' })
  }

  resetSelectActions() {
    this._isMounted && this.setState({ resetSelect: true })
  }

  changeCurrentPath(val) {
    if (this._isMounted && !this.state.permanent_message) this.clearMessage()
    if (this._isMounted && this.state.permanent_message) this._isMounted && this.setState({ permanent_message: false })
    this.resetSelectActions()
    this._isMounted && this.setState({ selectedItems: [], current_path: val, current_path_modal: val, creatingFolder: false }, () => {
      if (this.props.intoModal) this.props.changePath(val)
      !this.props.intoModal ? this.props.changeCurrentPath(val) : null
      this.reDrawList()
    })
  }

  browseFS(currentFS, current_path_array) {
    if (current_path_array.length === 0) return currentFS

    for (var i = 0; i < currentFS.length; i++) {
      if (currentFS[i]['@name'] === current_path_array[0]) {
        let keys = Object.keys(currentFS[i])
        let cs = []
        for (var x = 0; x < keys.length; x++) {
          if (!keys[x].startsWith("@")) cs.push(currentFS[i][keys[x]])
        }

        return this.browseFS(cs, current_path_array.slice(1, current_path_array.length))
      }
    }

    return []
  }

  reDrawList() {
    let current_path = this.state.current_path

    let fs = []
    if (current_path !== '' && current_path !== '/') {

      while (current_path.charAt(0) === '/') {
        current_path = current_path.substr(1);
      }

      let current_path_array = current_path.split('/')

      fs = this.browseFS(this.props.FS, current_path_array, 0)
    } else {
      let keys = Object.keys(this.props.FS)
      for (var i = 0; i < keys.length; i++) {
        if (!keys[i].startsWith("@")) fs.push(this.props.FS[keys[i]])
      }
    }

    let structure = this.parseData(fs, false)
    this._isMounted && this.setState({ user_FS: structure })
  }

  parseData(data, first_time = true) {
    if (data) {
      let keys = Object.keys(data)
      let response
      let result = []

      if (!first_time) {
        response = keys.filter(item => {
          return !item.startsWith("@") && item !== '.autosave' ? data[item] : null
        })

        response.forEach(item => {
          result.push(data[item])
        })

      } else {
        let id = keys[0]
        response = Object.keys(data[id]).filter(item => {
          return !item.startsWith("@") && item !== '.autosave' ? data[id][item] : null
        })

        response.forEach(item => {
          result.push(data[id][item])
        })
      }
      return result
    }
  }

  finishRender() {
    this.props.intoModal ? this.props.finishRender() : null
  }

  fetchFiles(first_time = false) {
    if (this._isMounted && !this.state.loading) {
      this._isMounted && this.setState({ loading: true })
    }

    let url = process.env.REACT_APP_API_ENDPOINT + '/fs'
    let me = this
    return fetchClient().get(url, this.state.headers)
      .then((response) => {

        if (response.status !== 200) {
          throw response.message
        } else {
          let structure = me.parseData(JSON.parse(response.data))
          me.props.saveUserFS(structure)
          if (first_time) {
            me._isMounted && me.setState({
              user_FS: structure
            })
          } else {
            me.reDrawList()
          }
        }

      }).catch(function (error) {
        me._isMounted && me.setState({ message: 'Can\'t get the files, please refresh the page.', message_type: 'error' })
      }).finally(() => {

        me._isMounted && me.setState({ loading: false })

        let rows = me.state.user_FS.filter(item => {
          item !== 'trash'
        })
        me._isMounted && me.setState({
          rows: rows
        })

        me.finishRender()
        me.checkTrashHasElements()
      })
  }

  checkTrashHasElements() {
    let trash = this.props.FS.filter(item => {
      return item['@name'] === 'trash'
    })

    let trashElements = []

    if (trash.length) {
      let keys = Object.keys(trash[0])
      trashElements = keys.filter(item => {
        return !item.startsWith('@')
      })
    }
    this.setState({ hasTrashedItems: trashElements.length }, () => {
      if (this.state.current_path.startsWith('/trash') && !trashElements.length) {
        this.changeCurrentPath('/')
      }
    })
  }

  changeSelectedItems(id, state) {
    let si = this.state.selectedItems
    if (!Array.isArray(si)) si = []
    si[id] = state
    let aic = this.state.allItemsChecked
    if (!state) aic = false
    this._isMounted && this.setState({ selectedItems: si, allItemsChecked: aic })
  }

  handleChangeAllCheckboxes() {
    let mapIds = this.state.selectedItems
    let aic = !this.state.allItemsChecked
    if (aic) {
      mapIds = []
      for (var i = this.state.user_FS.length - 1; i >= 0; i--) {
        if (this.state.user_FS[i]['@name'] !== 'trash') {
          mapIds[i] = true
        }
      }
    }

    this.setState({
      selectedItems: mapIds,
      allItemsChecked: aic
    })
  }

  createFolder() {
    this.setState({
      creatingFolder: true
    })
  }

  onFinishCreateFolder() {
    this.setState({ creatingFolder: false }, () => {
      this.fetchFiles()
    })
  }

  emptyTrash() {
    this.setState({
      promptMessage: 'Do you want to empty trash?',
      currentAction: 'empty-trash',
      visibleModalPrompt: true,
    })
  }

  importFile(data, pathfile) {
    let scheduled_search = data.scheduled_search;
    delete data.scheduled_search;
    data = JSON.stringify(data);
    if (scheduled_search) {
      return this.props.importAutomatedFileToStore(data, pathfile);
    } else {
      return this.props.importFileToStore(data, pathfile);
    }
  }

  executeAction(action, data = {}) {
    let aux_data = data;
    if (this._isMounted && !this.state.loading) {
      this._isMounted && this.setState({ loading: true })
    }

    if (!Object.keys(data).length)
      data = {}

    this._isMounted && this.setState({ currentAction: action })

    //reset data for modal
    this.togglePromptModal(true)

    let endpoint = this.endpoints.filter(item => {
      return item.action === action
    })[0]


    let params = []
    var paths = []

    if (Object.keys(data).length && data['fromFSRow'] !== undefined && data['confirm'] === undefined) {
      // action from some row and not from select
      let path = data['fromFSRow'].startsWith('/trash') ? data['fromFSRow'].split('/trash')[1] : data['fromFSRow']
      paths.push(path)
    } else {
      if (action === 'move') {
        paths.push(data['path'])
      } else if (action === 'trash') {
        paths = this.state.selectedItems.map((item, index) => {
          if (item) return this.state.current_path !== '/' ? this.state.current_path + '/' + this.state.user_FS[index]['@name'] : this.state.current_path + this.state.user_FS[index]['@name']
        })
      } else if (action === 'restore') {
        let prefix = this.state.current_path !== '/trash' && this.state.current_path.indexOf('/trash') !== -1 ? this.state.current_path.split('/trash')[1] : ''

        paths = this.state.selectedItems.map((item, index) => {
          if (item) return prefix + '/' + this.state.user_FS[index]['@name']
        })
      } else if (action === 'load') {
        if (data['confirm'] !== undefined && data['confirm'] && this.state.trying_loading !== null) {
          paths.push(this.state.trying_loading)
        } else if (data['fromFSRow'] !== undefined) {
          paths.push(data['fromFSRow'])
        }
      } else if (action === 'update-description') {
        paths.push(data['path'])
      } else if (action === 'empty-trash') {
        paths = []
      } else if (action === 'delete') {
        paths = this.state.selectedItems.map((item, index) => {
          if (item) return this.state.user_FS[index]['@name'].startsWith('/trash') ? this.state.user_FS[index]['@name'].split('/trash')[1] : this.state.user_FS[index]['@name']
        })
      } else {
        paths = this.state.selectedItems.map((item, index) => {
          if (item) return this.state.current_path !== '/' ? this.state.current_path + '/' + this.state.user_FS[index]['@name'] : this.state.current_path + this.state.user_FS[index]['@name']
        })
      }
    }

    if (action !== 'empty-trash' && !paths.length) {
      this.setState({ loading: false })
      return
    }

    //clean (remove trash if it is inside the array)
    paths = paths.map(item => {
      return item !== 'trash' && item !== '' && item !== ',' ? item : null
    }).filter(item => item)

    if (action !== 'empty-trash' && !paths.filter((item) => { return item !== '' }).length) {
      this.setState({ loading: false })
      return
    }

    params = { path: paths.join(',') }
    if (action === 'restore') {
      params['confirm'] = data['confirm'] !== undefined ? data['confirm'] : (this.state.trying_restore ? 1 : 0)

      if (this.state.trying_restore !== null) {
        this.setState({ trying_restore: null })
      }
    } else if (action === 'move') {
      if (data['new_path'] === undefined || data['action'] === undefined) {
        this.setState({ loading: false })
        return
      }
      params['new_path'] = data['new_path']
      params['action'] = data['action']
    } else if (action === 'update-description') {
      params = data
    }

    axios.defaults.headers.common['Authorization'] = this.state.headers.headers.Authorization
    var me = this
    switch (endpoint['method']) {
      case 'get':
        if (this.props.search !== '' && Object.keys(data).length && (data['confirm'] === undefined || data['confirm'] !== 1)) {
          this._isMounted && this.setState({ visibleModalPrompt: true, currentAction: 'load', promptMessage: 'You have an unsaved search open. Do you want to clear your current search and upload this file?' })
        } else {
          var path = this.state.trying_loading
          if (data['confirm'] !== undefined && data['confirm'] && this.state.trying_loading) {
            this.setState({ trying_loading: null })
          }

          fetchClient().get(process.env.REACT_APP_API_ENDPOINT + endpoint['url'], { params: params })
            .then(function (response) {
              if (response.status === 200) {
                try {
                  let data = JSON.parse(response.data.data)
                  if (me.validateRequiredFields(data)) {
                    delete data['checksum']
                    delete data['auth_info']
                    data['created_at'] = aux_data.created_at;;
                    me.importFile(data, path)

                    me._isMounted && me.setState({ redirect: true })
                  } else {
                    me._isMounted && me.setState({ message: "An error occur loading the file, please try again.", message_type: 'error' })
                  }
                } catch (e) {
                  me._isMounted && me.setState({ message: "An error occur loading the file, please try again.", message_type: 'error' })
                }

              } else {
                me._isMounted && me.setState({ message: "An error occur loading the file, please try again.", message_type: 'error' })
              }
            }).finally(() => {
              me._isMounted && me.setState({ loading: false })
            })
        }
        break
      case 'post':
        fetchClient().post(process.env.REACT_APP_API_ENDPOINT + endpoint['url'], JSON.stringify(params))
          .then(function (response) {
            if (action === 'move') {
              if (response.status === 201) {
                me._isMounted && me.setState({ message: response.data.message, message_type: 'success', permanent_message: true })

                me.fetchFiles()

              } else {
                me._isMounted && me.setState({ message: response.data.message, message_type: 'error' })
              }
            } else {
              if (response.status === 201) {
                me._isMounted && me.setState({ message: response.data.message.success !== undefined ? response.data.message.success : response.data.message, message_type: 'success', permanent_message: true })

                me.fetchFiles()
              } else if (response.status === 200 && action === 'restore') {
                //show message to confirm that the files will be replaced
                let message = response.data.message
                me.fetchFiles()
                me._isMounted &&
                  me.setState({
                    promptMessage: message,
                    visibleModalPrompt: true,
                    currentAction: 'restore',
                    trying_restore: response.data['paths_force_restore'] !== undefined ? response.data['paths_force_restore'] : params['path']
                  })
              } else if (response.status === 204 && action === 'empty-trash') {
                me._isMounted && me.setState({ message: 'Trash was empty succesfully', message_type: 'success', permanent_message: true })
                me.fetchFiles()
                me.changeCurrentPath('/')
              } else if (response.status === 204 && action === 'delete') {
                me._isMounted && me.setState({ message: paths.join(' - ') + " deleted succesfully", message_type: 'success', permanent_message: true })
                me.fetchFiles()
              } else {
                me._isMounted && me.setState({ message: response.data.message, message_type: 'error' })
              }
            }
          }).catch(err => {

            if (action === "move") {
              me._isMounted && me.setState({
                message: "Can't " + data['action'] + " " + paths.join(' - ') + " to " + params['new_path'],
                message_type: 'error'
              })
            } else if (action === "timeline") {
              me._isMounted && me.setState({
                message: "Can't add to timeline " + paths.join(' - '),
                message_type: 'error'
              })
            } else if (action === "removetimeline") {
              me._isMounted && me.setState({
                message: "Can't remove from timeline " + paths.join(' - '),
                message_type: 'error'
              })
            } else {
              me._isMounted && me.setState({
                message: "An error occur, please contact with the administrator.",
                message_type: 'error'
              })
            }
          }).finally(() => {
            me._isMounted && me.setState({ selectedItems: [], loading: false })

            if (me.props.uploaded_file && me.props.uploaded_file !== "") {
              //if exists a file imported and not more exists, remove flag for show only save as button
              fetchClient().get(process.env.REACT_APP_API_ENDPOINT + '/fs/check', { params: { path: me.props.uploaded_file } })
                .then(function (response) {
                  if (!response.data.data) {
                    me.props.resetImportFile()
                  }
                })
            }
          })
        break
    }
  }

  togglePromptModal(forceHide = null) {
    this._isMounted && this.setState({
      visibleModalPrompt: forceHide ? false : !this.state.visibleModalPrompt,
      promptMessage: null,
      trying_restore: null,
      currentAction: null
    })
  }

  toggleMoveFilesModal(row = null) {

    if (!this.state.moveFilesModal) {

      let si = this.state.selectedItems

      if (row !== null) {
        si = []
        si[row] = true
      }

      let filesToMove = []
      let cp = this.state.current_path

      if (cp === '/' || cp === undefined) {
        cp = ""
      }

      for (var i = 0; i < si.length; i++) {
        if (si[i]) filesToMove.push(cp + "/" + this.state.user_FS[i]['@name'])
      }

      this._isMounted && this.setState({
        moveFilesModal: !this.state.moveFilesModal,
        selectedItems: si,
        filesToMove: filesToMove
      })
    } else {
      this._isMounted && this.setState({
        moveFilesModal: false,
        selectedItems: [],
        filesToMove: []
      })
    }
  }

  loadFile(path, created_at, first = false,) {
    if (created_at !== null)
      this.setState({ created_at: created_at });

    this.setState({ loading: true })

    if (first) {
      this._isMounted && this.setState({ trying_loading: path }, () => {
        this.executeAction('load', { fromFSRow: path, created_at: this.state.created_at })
      })
    } else {
      this.executeAction('load', { fromFSRow: path, confirm: 1, created_at: this.state.created_at })
    }
  }

  validateRequiredFields = (data) => {
    let result = true
    //TODO: must implement new fields for savedLocations
    return result
    let required_fields = {
      'checksum': null,
      'search': ['main', 'sub'],
      'stats': ['lumentus_score', 'lumentus_score_bonus', 'sentiment', 'searches'],
      'ranking': ['order', 'data'],
      'related_searches': [],
      'knowledge_graph': null,
      'knowledge_graph_imported': null,
      'auth_info': ['token_type', 'expires_in', 'access_token', 'refresh_token'],
      'user_logged': ['name', 'username', 'email', 'revoked'],
      'user_files': []
    }

    if (data) {
      const keys = Object.keys(required_fields)
      for (const key of keys) {
        if (data.key !== 'undefined') {
          if (required_fields[key] != null) {
            switch (typeof required_fields[key]) {
              case 'string':
                if (typeof data.key !== 'string') {
                  result = false
                }
                break
              case 'Object':
                required_fields[key].map((item) => {
                  if (data.key[item] === 'undefined') {
                    result = false
                  }
                })
                break
              default:
                break
            }
          }
        } else {
          result = false
        }
      }

    }
    return result
  }

  unMountNewRow() {
    this.setState({ creatingFolder: false })
  }

  render() {
    var currentAction = this.state.currentAction ? (this.state.currentAction.charAt(0).toUpperCase() + this.state.currentAction.slice(1)).replace('-', ' ') : null
    var colSpanChange = this.state.width < 600 ? 3 : 1

    if (this.state.redirect) {
      // redirect to home if signed up
      return <Redirect to="/results" />
    }

    return (
      <div className="container file-component" style={{ maxHeight: this.props.height || '100%' }}>
        <div className="table-responsive">
          <Modal isOpen={this.state.visibleModalPrompt} toggle={() => {
            this.setState({ loading: false });
            this.togglePromptModal(true)
          }} className="question-force-login">
            <ModalHeader toggle={() => {
              this.setState({ loading: false });
              this.togglePromptModal(true)
            }}>
              {this.state.currentAction ? currentAction : null}
            </ModalHeader>
            <ModalBody>
              {
                typeof this.state.promptMessage === 'string' && this.state.promptMessage !== '' ?
                  <div className={"save-modal-message alert fade show error-message alert-info my-0"}
                    role="alert"> {this.state.promptMessage} </div>
                  : null
              }

              {
                this.state.promptMessage && this.state.promptMessage.success !== undefined ?
                  <div className={"save-modal-message alert fade show error-message alert-success my-0"}
                    role="alert"> {this.state.promptMessage.success} </div>
                  : null
              }
              {
                this.state.promptMessage && this.state.promptMessage.error !== undefined ?
                  <div className={"save-modal-message alert fade show error-message alert-danger my-0"}
                    role="alert"> {this.state.promptMessage.error} </div>
                  : null
              }
              {
                this.state.promptMessage && this.state.promptMessage.info !== undefined ?
                  <div className={"save-modal-message alert fade show error-message alert-info my-0"}
                    role="alert"> {this.state.promptMessage.info} </div>
                  : null
              }
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={currentAction === 'Load' ?
                () => this.loadFile(this.state.currentAction)
                : (currentAction === 'Restore' ?
                  () => this.executeAction('restore', { fromFSRow: this.state.trying_restore })
                  : (currentAction === 'Empty trash' ?
                    () => this.executeAction('empty-trash')
                    : () => this.togglePromptModal))}>
                {currentAction === 'Load' ? "Upload" : "Yes"}
              </Button>
              <Button color="secondary" onClick={() => { this.setState({ loading: false }); this.togglePromptModal(true) }}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <MoveFilesModal
            open={this.state.moveFilesModal}
            close={() => this.toggleMoveFilesModal()}
            executeAction={(action, data) => this.executeAction(action, data)}
            filesToMove={this.state.filesToMove}
            selectedItems={this.state.selectedItems}
          />

          {
            this.state.message !== "" ?
              <div className={"alert fade show error-message " + (this.state.message_type === 'error' ? 'alert-danger' : 'alert-success')} role="alert"> {this.state.message} </div>
              : null
          }
          {
            this.state.loading ?
              <div className='icon-search-loading'><Spinner /></div>
              :
              <table className={"table table-hover " + (this.props.intoModal ? 'into-modal' : null)} style={{ transform: 'scaleX(0.95)' }}>

                <thead className="table-title">
                  <tr>
                    <th colSpan="4"><h2>
                      <FilesBreadcrum
                        currentPathAttr={this.state.current_path_modal}
                        changeCurrentPath={(val) => this.changeCurrentPath(val)}
                        intoModal={this.props.intoModal}
                      ></FilesBreadcrum></h2></th>
                    <th>{!this.props.current_path.startsWith('/trash') && !this.props.intoModal ? <h2><FilesTrashLink changeCurrentPath={() => this.changeCurrentPath('/trash')} enabled={this.state.hasTrashedItems}></FilesTrashLink></h2> : null} </th>
                  </tr>
                </thead>

                <thead>
                  <tr>
                    <FilesToolbar
                      intoModal={this.props.intoModal}
                      selectedItems={this.state.selectedItems.length}
                      isDisabled={this.state.selectedItems.filter(item => item).length}
                      createFolder={this.createFolder.bind(this)}
                      emptyTrash={this.emptyTrash.bind(this)}
                      currentPath={this.state.current_path}
                      changeCurrentPath={(val) => this.changeCurrentPath(val)}
                      actionOptions={this.actionOptions}
                      resetSelect={this.state.resetSelect}
                      executeAction={(action) => this.executeAction(action)}
                      toggleMoveModal={() => this.toggleMoveFilesModal()}
                      hasTrashedItems={this.state.hasTrashedItems}
                    ></FilesToolbar>
                  </tr>
                </thead>

                <thead className="file-columns-titles row-style">
                  <tr>
                    <th>{!this.props.intoModal ? <CustomInput id="allCheckbox" type="checkbox" checked={this.state.allItemsChecked} onChange={() => this.handleChangeAllCheckboxes()} /> : null} </th>
                    <th className="title-size" colSpan={colSpanChange}>Title</th>
                    <th>{!this.props.intoModal ? "Description" : null}</th>
                    <th>Created</th>
                    <th>{!this.props.intoModal ? "Options" : null}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.creatingFolder ?
                      <NewFolderRow
                        currentPath={this.state.current_path}
                        onFinish={this.onFinishCreateFolder}
                        onShowMessage={(data) => this.showMessage(data)}
                        onClearMessage={() => this.clearMessage()}
                        unmountMe={() => this.unMountNewRow()}
                      ></NewFolderRow>
                      :
                      null
                  }

                  {
                    this.state.user_FS ?
                      this.state.user_FS.map((item, index) => {
                        if (item['@name'] !== 'trash') {

                          let data = { type: item['@type'], title: item['@name'], description: item['@description'], timeline: item['@in_timeline'], create_at: item['@created_at'] }
                          let new_path = this.state.current_path_modal !== '/' ? this.state.current_path_modal + '/' + data.title : this.state.current_path_modal + data.title

                          return this.props.intoModal === undefined ||
                            this.props.fromSaveModal !== undefined && this.props.fromSaveModal ||
                            (//if moving, not show the same selected items in the modal
                              this.props.fromMoveModal !== undefined && this.props.fromMoveModal &&
                              ((this.props.selectedItems !== undefined && !this.props.selectedItems.length) ||
                                (this.props.filesToMove !== undefined && this.props.filesToMove.length && !this.props.filesToMove.includes(new_path)))
                            ) ?

                            <FSRow
                              id={index}
                              intoModal={this.props.intoModal}
                              key={index + "-" + item['@name'] + "-" + item['@description']}
                              info={data}
                              currentPath={this.state.current_path}
                              actionOptions={this.actionOptions}
                              checked={this.state.selectedItems[index]}
                              onShowMessage={(data) => this.showMessage(data)}
                              changeCurrentPath={() => this.changeCurrentPath(new_path)}
                              loadFile={!this.props.intoModal ? this.loadFile : null}
                              changeSelectedItems={this.changeSelectedItems}
                              newPath={new_path}
                              first={true}
                              executeAction={(action, data = null) => this.executeAction(action, data)}
                              toggleMoveModal={(row) => this.toggleMoveFilesModal(row)} >
                            </FSRow>
                            : null
                        }
                      })
                      :
                      <tr>
                        <td>No files</td>
                      </tr>
                  }
                </tbody>
              </table>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    FS: state.user_fs,
    current_path: state.files_current_path,
    search: state.search.main,
    uploaded_file: state.uploaded_file
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserFS: (val) => {
      dispatch(saveUserFS(val))
    },
    changeCurrentPath: (val) => {
      dispatch(changeCurrentPath(val))
    },
    importAutomatedFileToStore: (json, pathfile) => {
      dispatch(importAutomatedFileToStore(json, pathfile))
    },
    importFileToStore: (json, pathfile) => {
      dispatch(importFileToStore(json, pathfile))
    },
    resetImportFile: () => {
      dispatch(clearUploadedFile())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FilesNavigation)
