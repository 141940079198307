import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './../css/Login.css'
import logo from './../images/lightbox-search-logo.svg'
import axios from 'axios';
import { saveTokenInfo, addLocalStorageVer } from '../actionCreators'
import { connect } from 'react-redux'
import { validateEmail } from '../helpers/string-validations'
import { getMessages, cleanMessages } from './../helpers/messages'
import Spinner from './Spinner'
import initialState from '../db.json'

import { ThemeConsumer } from 'styled-components'

class Login extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        
        this.state = {
            email:"",
            password:"",
            success_message:null,
            error_message:null,
            error_email:null,
            error_password:null,
            login_form_visible:true,
            email_reset:null,
            error_reset_email:null,
            loading:false,
            login_disabled: false,
            modal: false,
            forced_login:false,
            localstorage_version: process.env.REACT_APP_LOCALSTORAGE_VERSION
        }

        this.login = this.login.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.toggleForm = this.toggleForm.bind(this)
        this.toggle = this.toggle.bind(this)
        this.force_login = this.force_login.bind(this)        
        this.handleResetKeyUp = this.handleResetKeyUp.bind(this)
        this.resetPassword = this.resetPassword.bind(this)
    }

    componentDidMount() {
        this._isMounted = true
    }

    handleKeyUp(ev) {
        var key = ev.target.type
        var val = ev.target.value
        var obj  = {}
        obj[key] = val
        this._isMounted && this.setState(obj)

        if (this.state.error_email && this.state.email !== ""){
            this._isMounted && this.setState({error_email: null})
        }

        if (this.state.error_password && this.state.password !== ""){
            this._isMounted && this.setState({error_password: null})
        }

        if (ev.key === 'Enter' && !this.state.loading) {
            this.login()
        }

        if(this.state.password_reset_message){
            this._isMounted && this.setState({password_reset_message: null})
        }
    }

    toggleForm() {
        this._isMounted && this.setState({login_form_visible:!this.state.login_form_visible, email_reset:null,error_message:null,error_reset_email:false})
    }

    resetPassword () {
        if(this.state.email_reset && this.state.email_reset.trim() !== ""){
            if (!validateEmail(this.state.email_reset.trim())) {  
                this._isMounted && this.setState({error_reset_email: true, error_message: "Please set a correct email."})
                return
            }

            this._isMounted && this.setState({loading:true, login_disabled:true})

            let resetUrl= process.env.REACT_APP_API_ENDPOINT + '/password/email' 

            let resetEmail = {
                'email' : this.state.email_reset
            }  
            axios.post(resetUrl, resetEmail)
                .then(res => {                    
                    if(res.status == 200){
                        if(res.data.success == true){
                            this.setState({error_reset_email: false, error_message: null, success_message: res.data.message})
                            this.toggleForm()
                        } else {
                            this.setState({error_reset_email: true, error_message: res.data.message, success_message: null})
                        }
                    }                     
                    this._isMounted && this.setState({loading:false, login_disabled:false})
                })
                .catch(err => { 
                    this.setState({error_reset_email: true, error_message: "Unable to send reset link."})
                    this._isMounted && this.setState({loading:false})
                })
        } else {
            this._isMounted && this.setState({error_reset_email: true, error_message: "Introduce an email."})
        }
    }

    handleResetKeyUp(ev) { 
        this._isMounted && this.setState({email_reset:ev.target.value})
        
        if (this.state.error_reset_email) { 
            this._isMounted && this.setState({error_reset_email:null, error_message:null})
        }
    }

    login() {
        if (this.state.email.trim() !== "" && this.state.password.trim() !== "" && !this.state.loading) {
            
            if (!validateEmail(this.state.email.trim())) {
                this._isMounted && this.setState({error_email: true, error_message: "Please set a correct email"})
                return
            }
            this._isMounted && this.setState({loading:true})

            let url = process.env.REACT_APP_API_ENDPOINT + '/token'

            const data = {
                grant_type:'password',
                client_id: process.env.REACT_APP_OAUTH_CLIENT_ID ? process.env.REACT_APP_OAUTH_CLIENT_ID : 2,
                client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET ? process.env.REACT_APP_OAUTH_CLIENT_SECRET : 'zeu3zbBEJtuH87zZdVHcUBMojItoAspEzLiLmHnJ',
                username: this.state.email,
                password: this.state.password,
                scope:'*',
                forced_login: this.state.forced_login !== false ? 1 : 0
              };
              var self = this;
              
              axios.post(url, data )
                .then(res => {
                    if (res.status === 200){
                        self._isMounted && this.setState({loading:false})

                        const currentState = localStorage.getItem("state");

                        if(currentState) {
                            if(currentState.localstorage_version || currentState.localstorage_version === "0"){
                                if(currentState.localstorage_version !== process.env.REACT_APP_LOCALSTORAGE_VERSION){
                                    initialState.localstorage_version = process.env.REACT_APP_LOCALSTORAGE_VERSION
                                    localStorage.setItem("state", initialState);
                                }
                            }  else {
                                this.props.addLocalStorageVer(process.env.REACT_APP_LOCALSTORAGE_VERSION);
                            }
                        }

                        if (res.data !== undefined && res.data.access_token !== undefined){
                            this.props.saveTokenInfo(res.data)
                        }
                        else if (res.data !== undefined && res.data.already_user_logged !== undefined) {
                            self.toggle()
                        }
                    }else {
                        self.setState({error_message: 'Error'})    
                        self._isMounted && this.setState({loading:false})
                    }                    
                })
                .catch(err => {
                    self.setState({error_message:"The user credentials were incorrect."})
                    self._isMounted && this.setState({loading:false})
                })
        }else{
            this._isMounted && this.setState({error_message: "Fields Required"})
            if (this.state.email.trim() === "") {
                this._isMounted && this.setState({error_email: true})
            }
            if (this.state.password.trim() === "") {
                this._isMounted && this.setState({error_password: true})
            }
        }
    }

    toggle() {
        this._isMounted && this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    force_login() {
        this.toggle()
        this._isMounted && this.setState(
            {forced_login:true}, () => {
                return this.login()
        })
    }

    render() {
        let messages = getMessages()
        cleanMessages()
        return (
            <ThemeConsumer>
                {theme =>
            <div className="login-page">
            {
            
                this.state.login_form_visible ? 
                    <div>
                        
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className="question-force-login">
                        <ModalHeader toggle={this.toggle}>Another User already logged</ModalHeader>
                        <ModalBody>
                            Do you want to continue login and logout the another session ?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.force_login}>Yes</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                        </Modal>
            
                    
                        <Form className="form-login">
                            <div>
                                <img src={theme.logo} className={theme.class_logo_login} alt="logo" />
                            </div>

                            {
                                messages.map((message, i) => { 
                                    let alert = null
                                    switch(message.type) {
                                        case 'info':
                                            alert = (<div className="alert alert-info fade show error-message" role="alert" key={"alert-" + i}> { message.msg } </div>)
                                            break;
                                        case 'error':
                                            alert = (<div className="alert alert-danger fade show error-message" role="alert" key={"alert-" + i}> { message.msg } </div>)
                                            break;
                                        case 'success':
                                            alert = (<div className="alert alert-success fade show error-message" role="alert" key={"alert-" + i}> { message.msg } </div>)
                                            break;
                                    }  
                                    if(alert !== null) return alert
                                })
                            }

                            {  
                                (this.state.success_message !== null) ? 
                                    <div className="alert alert-success fade show" role="alert">
                                        { this.state.success_message  }
                                    </div>
                                : ''
                            }

                            {
                                (this.state.error_message !== null) ?
                                    <div className="alert alert-danger fade show error-message" role="alert">
                                        {this.state.error_message}
                                    </div>
                                : ''
                            }

                            <FormGroup>
                                <Input type="email" name="email" id="email" className={this.state.error_email ? 'error' : ''} placeholder="email" onKeyUp={this.handleKeyUp} onPaste={this.handleOnPaste} />
                                <Label for="email">Email</Label>
                            </FormGroup>
                            <FormGroup>
                                <Input type="password" name="password" id="password" className={this.state.error_password ? 'error' : ''} placeholder="password" onKeyUp={this.handleKeyUp} onPaste={this.handleOnPaste} />
                                <Label for="password">Password</Label>
                            </FormGroup>
                            { (this.state.loading) ? <div className='icon-search-loading'><Spinner/></div>  : <Button className="submit-btn"  onClick={this.login}>Sign in</Button> }
                            <a href="#" onClick={this.toggleForm}>Forgot Password?</a>
                        </Form>

                    </div>
                : 
                    <Form className="form-login">

                        <div>
                            <img style={theme.styles_logo_login} src={logo} className="standard-logo-login" alt="logo" />
                        </div>

                        {  
                            (this.state.error_message !== null) ? 
                                <div className="alert alert-danger fade show error-message" role="alert">
                                    { this.state.error_message }
                                </div>
                            : ''
                        }

                        <FormGroup>
                            <Input type="email" name="email" id="resetEmail" className={this.state.error_reset_email ? 'error' : ''} placeholder="name@email.com" onKeyUp={this.handleResetKeyUp} />
                            <Label for="resetEmail">Email</Label>
                        </FormGroup>

                        {
                            (this.state.loading) ?
                            <div className='icon-search-loading'><Spinner/></div>
                            : 
                            <Button className="submit-btn"  onClick={this.resetPassword}>Send Link Reset Password</Button>
                        }

                        <a href="#" onClick={this.toggleForm} style={this.state.loading ? {pointerEvents: "none"} : null}>Login?</a>
                    </Form>
            }
            </div>
                }</ThemeConsumer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        revoked: state.user_logged.revoked,
        user_logged: state.user_logged,
        localstorage_version: process.env.REACT_APP_LOCALSTORAGE_VERSION
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        saveTokenInfo: (val) => {
            dispatch(saveTokenInfo(val))
        },
        addLocalStorageVer: (id, action) => {
            dispatch(addLocalStorageVer(id, action))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)