import styled from "styled-components";
import { styles } from './variables';

export const SentimentLegendStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: .5rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    .listItem {
        padding: 2px 6px;
        box-width: 6px;
    }
    .legendColoredBox {
        width: 6px;
        height: 12px;
    }
    .legendText {
        color: ${styles.textColor};
        white-space: nowrap;
        margin-left: 5px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
        font-style: normal;
    }
`

export const CategoryLegendStyled = styled.div`
    margin: .5rem 0 0 .5rem;
    .flex-container {
        display: flex;
        flex-wrap: wrap; 
        margin: 0 0 0 0.3rem;
        justify-content: space-between;
    }
    .listItem {
        margin: 0;
        padding: 2px 0;
        width: 100px;
    }
    .legendContainer {
        display: flex;
    }
    .legendColoredBox {
        width: 6px;
        height: 12px;
    }
    .legendText {
        color: ${styles.textColor};
        white-space: nowrap;
        margin-left: 5px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
        font-style: normal;
    }
    .footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`
export const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .divSize {
        width: 105px;
    }
`