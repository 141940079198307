import React from 'react';
import {Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledTooltip} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import moment from 'moment';

import Cell from './Cell';

const format = 'll'
const formatCreated = 'DD/MM/YYYY'

export default class Row extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {order, item, messages, success} = this.props;
        return (
            <React.Fragment>
                <StyleRow>
                    <Cell content={item.term} target="term" message={messages.term} order={order}/>
                    <Cell content={item.location} target="location" message={messages.location} order={order}/>
                    <Cell className='text-capitalize' content={item.period} target="period" message={messages.period} order={order}/>
                    <Cell
                        content={`${moment(item.start_date).format(format)}`}
                        target="start_date"
                        message={messages.start_date}
                        order={order}
                    />
                    <Cell
                        content={item.end_date ? `${moment(item.end_date).format(format)}` : "-"}
                        target="end_date"
                        message={messages.end_date}
                        order={order}
                    />
                    <Cell content={item.automated_pages} target="automated_pages" message={messages.automated_pages} order={order}/>
                    <Cell content={item.file_path} target="file_path" message={messages.file_path} order={order}/>
                    <td>
                        {
                            success ?
                            <FontAwesomeIcon className="icon-toggle ml-2" icon='check' color="green"/> :
                            <FontAwesomeIcon className="icon-toggle ml-2" icon='times' color="#ff583b"/>
                        }
                    </td>
                </StyleRow>
            </React.Fragment>)
    }
}


const StyleRow = styled.tr`
  td {
    font-size: 1em;
    color: #212529;
  }
  .icon-toggle {
    font-size: 1.7em;

  }
`
