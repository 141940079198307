import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {Container, Row} from 'reactstrap'
import TermsByUserSelect from '../components/TermsByUserSelect'
import './../css/Dashboard.css'
import DashboardChart from '../components/DashboardChart'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import Spinner from '../components/Spinner'
import moment from 'moment'
import LocationMenu from '../components/LocationMenu'
import {pageView} from '../helpers/ga-page-view'
import {Layout} from "../components/Layout/Default"
import { dateFormat } from '../config/date'

class DashboardPage extends Component {

    constructor(props) {
        super(props)

        const auth_info = JSON.parse(localStorage.getItem('token_info'))
        const data = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
            }
        }

        this.state = {
            headers: data,
            search: this.props.search,
            start_date: null,
            end_date: null,
            charts_data: [],
            message: '',
            message_type: null
        }

        this.showMessage = this.showMessage.bind(this)
        this.fetchDashboardData = this.fetchDashboardData.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.setEndDate = this.setEndDate.bind(this)
    }


    componentDidMount() {
        this._isMounted = true
        pageView()
    }

    fetchDashboardData() {
        if (!this.state.search) return

        let url = process.env.REACT_APP_API_ENDPOINT + '/dashboard/' + this.state.search

        if (this.state.start_date && this.state.end_date && this.state.start_date > this.state.end_date) {
            this._isMounted && this.setState({
                message: 'Invalid range of dates, StartDate cannot be greather than EndDate.',
                message_type: 'error'
            })
            return
        }

        let start = this.state.start_date ? moment(new Date(this.state.start_date)).format("YYYYMMDD") : '00000000'
        let end = this.state.end_date ? moment(new Date(this.state.end_date)).format("YYYYMMDD") : '00000000'

        url += '/' + start
        url += '/' + end

        let loc_id = window.loc_id
        url += '?loc_id=' + loc_id

        this._isMounted && this.setState({loading: true, message: '', message_type: null, charts_data: []})

        let me = this
        return axios.get(url, this.state.headers)
            .then((response) => {
                if (response.status !== 200) {
                    throw response.message
                } else {
                    if (response.data && Object.values(response.data).filter(item => item).length) {
                        me.setState({charts_data: response.data})
                    } else {
                        me.setState({
                            message: 'Not found information for the term ' + this.state.search,
                            message_type: 'error'
                        })
                    }
                }

            }).catch(function (error) {
                me._isMounted && me.setState({
                    message: 'Can\'t get the data information, please refresh the page.',
                    message_type: 'error'
                })
            }).finally(() => {
                me._isMounted && this.setState({loading: false})
            })
    }

    setStartDate(date) {
        this._isMounted && this.setState({start_date: date})
    }

    setEndDate(date) {
        this._isMounted && this.setState({end_date: date})
    }

    showMessage(data) {
        this._isMounted && this.setState({message: data.message, message_type: data.message_type})
    }

    render() {

        if (this.props.auth_info == null) {
            return <Redirect to='/'/>
        }
        const datepickerprops = {
            offset: {
                enabled: false,
                offset: '0, 0'
            },
            preventOverflow: {
                enabled: true,
                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: 'viewport'
            }
        };


        return (
            <Layout>
                <div>
                    <div className="alertContainer">{
                        this.state.message !== "" ?
                            <div
                                className={"alert fade show error-message " + (this.state.message_type === 'error' ? 'alert-danger' : 'alert-success')}
                                role="alert"> {this.state.message} </div>
                            : null
                    }
                    </div>

                    <Container fluid={true}>
                        <Row className="dashboard-firstrow">
                            <label htmlFor="terms"> Term:</label>
                            <TermsByUserSelect
                                term={this.state.search}
                                showMessage={(data) => this.showMessage(data)}
                                handleChange={(item) => this.setState({search: item})}
                                resetStateTerm={() => this.setState({search: null})}
                                id="terms"
                            ></TermsByUserSelect>
                            <label htmlFor="dp1">Start Date:</label>
                            <DatePicker
                                className="dashboard-input form-control"
                                selected={this.state.start_date}
                                value={this.state.start_date}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={date => this.setStartDate(date)}
                                dateFormat={moment(this.state.start_date).format(dateFormat)}
                                popperPlacement="top-end"
                                showYearDropdown={true}
                                popperModifiers={datepickerprops}
                                id="dp1"
                                isClearable={true}
                            />
                            <label htmlFor="dp2">End Date:</label>
                            <DatePicker
                                className="dashboard-input form-control"
                                selected={this.state.end_date}
                                value={this.state.end_date}
                                onKeyDown={(e) => e.preventDefault()}
                                showYearDropdown={true}
                                onChange={date => this.setEndDate(date)}
                                popperPlacement="top-end"
                                popperModifiers={datepickerprops}
                                dateFormat={moment(this.state.end_date).format(dateFormat)}
                                id="dp2"
                                isClearable={true}
                            />

                            <button className={`dashboard-button ${this.state.search ? 'enabled' : 'disabled'}`}
                                    onClick={this.fetchDashboardData} disabled={!this.state.search}>Submit
                            </button>

                        </Row>
                        <Row className="dashboard-location" style={{padding: '10px'}}>
                            <label htmlFor="location-menu"
                                   style={{paddingTop: '5px', paddingRight: '10px'}}> Location:</label>
                            <LocationMenu id="location-menu" resultsPage={false} dashboardPage={true}/>
                        </Row>
                        <Row className="dashboard-charts">
                            {
                                this.state.loading ?
                                    <div className='icon-search-loading'><Spinner/></div>
                                    :

                                    Object.keys(this.state.charts_data).map((item) => {
                                        return <DashboardChart key={item} title={item}
                                                               data={this.state.charts_data[item]}></DashboardChart>
                                    })
                            }
                        </Row>
                    </Container>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth_info: state.auth_info,
        search: state.search.main
    }
}

export default connect(mapStateToProps, null)(DashboardPage)
