import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import './../css/KnowledgeGraph.css'
import { connect } from 'react-redux'
import KnowledgeGraphDetails from './KnowledgeGraphDetails';
import Tooltip from './Tooltip'
import '../css/Tooltip.css'
import { DownloadDOMElement } from './DownloadDOMElement';

class KnowledgeGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageHasError: false
        }
    }

    render() {
        let classRender = "knowledge-graph card"
        this.props.knowledge_graph?.detailedDescription?.articleBody ? classRender += ' knowledge-graph-description' : ''
        let hasImage = this.props.knowledge_graph?.image ?? false
        const displayingImage = hasImage && !this.state.imageHasError

        return (
            this.props.knowledge_graph?.name ?
                <Col lg={4} className={classRender} id="knowledge-graph">
                    {<div>
                        {   <>
                                {displayingImage && 
                                    <Row className="d-flex justify-content-end">
                                        <DownloadDOMElement element="knowledge-graph" addFrame className="mt-1 mr-1"/>
                                    </Row>
                                }
                                <Row className={this.props.knowledge_graph.detailedDescription !== undefined ? 'row-with-border-bottom d-flex justify-content-between' : ''}>
                                    <Col xs={8}>
                                        <div className="knowledge-name">
                                            <h2>{this.props.knowledge_graph?.name ?? ''}</h2>
                                            <p>{this.props.knowledge_graph?.description ?? ''}</p>
                                        </div>
                                    </Col>
                                    {displayingImage ?
                                        <Col xs={4}>
                                            <img
                                                src={this.props.knowledge_graph?.image}
                                                onError={() => this.setState({ imageHasError: true })}
                                            />
                                        </Col>
                                    :
                                        <DownloadDOMElement element="knowledge-graph" addFrame className="mb-4 mr-2"/>
                                    }
                                </Row>
                            </>
                        }
                        {
                            this.props.knowledge_graph?.detailedDescription?.articleBody &&
                            <Row>
                                <Col md={12} className="knowledge-description">
                                    {this.props.knowledge_graph.calculated_wikipedia_data !== undefined && this.props.knowledge_graph.calculated_wikipedia_data === true ?
                                        <div className="alert alert-danger fade show error-message" role="alert">This description was extracted from the first result of wikipedia, may be not correct.</div>
                                        : null
                                    }
                                    <p>{this.props.knowledge_graph.detailedDescription.articleBody}&nbsp;
                                        {
                                            this.props.knowledge_graph.detailedDescription.url !== undefined && this.props.knowledge_graph.detailedDescription.url !== "" ?
                                                this.props.knowledge_graph.detailedDescription.url_type !== undefined && this.props.knowledge_graph.detailedDescription.url_type !== "" ?
                                                    <a target="_blank" href={this.props.knowledge_graph.detailedDescription.url}>
                                                        {this.props.knowledge_graph.detailedDescription.url_type}
                                                    </a>
                                                    :
                                                    <a target="_blank" href={this.props.knowledge_graph.detailedDescription.url}>
                                                        Wikipedia
                                                    </a>
                                                : null
                                        }
                                    </p>
                                </Col>

                                {
                                    this.props.knowledge_graph?.wikipedia &&
                                    <KnowledgeGraphDetails wikipedia={this.props.knowledge_graph.wikipedia} peopleAlsoSearch={this.props.knowledge_graph.people_also_search_for} peopleAlsoSearchLink={this.props.knowledge_graph.people_also_search_for_link} />
                                }
                            </Row>
                        }
                        <div className="knowledgeGraphIcon" >
                            {
                                this.props.tooltips.map((tooltip, i) => {
                                    return (tooltip.code === '008') ? <Tooltip key={tooltip.code} tooltip={tooltip} /> : ''
                                })
                            }
                        </div>
                    </div>
                    }
                </Col>
                :
                <div style={{ height: '45px' }} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
        knowledge_graph: state.knowledge_graph,
        knowledge_graph_imported: state.knowledge_graph_imported,
        tooltips: state.tooltips.filter(tooltip => tooltip.code === '008')
    }
}

export default connect(mapStateToProps, null)(KnowledgeGraph)
