import html2canvas from "html2canvas";

export const exportDOMElementAsPicture = (elementId, fileName, offsetWidth) => {
  let html = document.getElementsByTagName('HTML')[0];
  let body = document.getElementsByTagName('BODY')[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;
  let data = document.getElementById(elementId);
  let action_plan = document.getElementById('action-plan');

  let newWidth = data.scrollWidth - data.clientWidth;
  if (newWidth > data.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  };

  html.style.width = htmlWidth + 'px';
  body.style.width = bodyWidth + 'px';

  let comp_height = 0;
  if(action_plan){
    comp_height = action_plan.offsetHeight;
  }
  
  const options = {
    scrollY: (elementId === 'related-searches' ? (!!document.getElementById('action-plan') ? (-window.scrollY + (comp_height)) : -window.scrollY) : -window.scrollY),
    scrollX: -window.scrollX,
  };
  if (!!offsetWidth) {
    options.width = offsetWidth;
  };

  data.style.position = "relative";
  data.getElementsByTagName('div').maxWidth = "90%";

  html2canvas(data, options).then((canvas) => {
    let image = canvas.toDataURL('image/png', 1.0);
    saveAs(image, `${fileName}.png`);
  });
  data.getElementsByTagName("div").maxWidth = null;
}

const saveAs = (blob, fileName) => {
  let elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';

  (document.body || document.documentElement).appendChild(elem);

  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  };

  URL.revokeObjectURL(elem.href);
  elem.remove();
}