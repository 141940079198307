import React from 'react'
import Tooltip from './Tooltip';

export default class Cell extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {className, content, target, message, order} = this.props;
        return (
            <React.Fragment>
                <td style={{border: message && message.length ? "1px solid #ff0000" : ""}} id={target+"_"+order}>
                    {content}
                </td>
                {
                    message && message.length ?
                    <Tooltip message={message} target={target+"_"+order}></Tooltip>:
                    null
                }
            </React.Fragment>
        )
    }
}
