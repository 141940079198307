import React from 'react';
import ScheduleBatch from '../components/Schedule/Batch'
import {Layout} from "../components/Layout/Default";

const formatDate = "YYYY-MM-DD HH:mm";

export default class ScheduleBatchPage extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Layout>
                <ScheduleBatch/>
            </Layout>
        )
    }

}
