import fetchClient from '../helpers/fetch-client';


const saveLocations = async (locations) => {
    let url = process.env.REACT_APP_API_ENDPOINT + '/saved-locations'
    locations = locations.filter(x => x != null)

    let that = this;
    fetchClient().post(url, JSON.stringify(locations))
        .then( (response) => {
	          if (response.status !== 200){
	              throw response.message
	          } else {
	              console.log('SUCCESFULLY PERSISTED SAVED LOCATION TO BACKEND')
	          }
        }).catch(function(error) {
              console.log('ERROR WITH FETCHING SAVED LOCATIONS');
        })
}

export default saveLocations 