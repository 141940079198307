import React, { Component } from 'react'
import Header from '../components/Header'
import StatsBoxes from '../components/StatsBoxes'
import Ranking from '../components/Ranking'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './../css/SearchLoader.css'
import RelatedSearches from '../components/RelatedSearches';
import KnowledgeGraph from '../components/KnowledgeGraph';
import { Button } from 'reactstrap'
import { incrementVisibleRows } from '../actionCreators'
import HistoricChart from '../components/HistoricChart'
import { pageView } from '../helpers/ga-page-view'
import { Layout } from "../components/Layout/Default";
import ActionPlanContainer from '../components/ActionPlan/ActionPlanContainer';
import fetchClient from '../helpers/fetch-client'

class ResultsPage extends Component {

  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      activeClass: '',
      page: 1,
      autoSaveModalOpen: false,
      itemsAlreadyLoaded: !this.props.fetching_ids.filter(item => item < this.props.visible_rows).length,
      generatePlan: false,
      loading: {
        loaderOne: 0,
        loaderTwo: 0,
        loaderThree: 0
      },
      actionPlanModal: false,
      toggleModal: false,
      showActionPlanBadge: true,
      changed_manually: false,
      generatePlanMessage: '',
      exportFileMessage: null,
      autoSaveMessage: null
    }
    this.loadMore = this.loadMore.bind(this)
    this.handleChangeModalOpenHistoricalData = this.handleChangeModalOpenHistoricalData.bind(this)
    this.generateActionPlan = this.generateActionPlan.bind(this)
    this.handleActionPlanModal = this.handleActionPlanModal.bind(this)
    this.showActionPlanOnDashboard = this.showActionPlanOnDashboard.bind(this)
    this.resetMessages = this.resetMessages.bind(this)
    this.isLoadedAllRows = this.isLoadedAllRows.bind(this)
  }

  componentDidMount() {
    document.body.style.overflowX = "hidden";
    pageView();
    this._isMounted = true;
    var lastScrollTop = 0;
    window.addEventListener('scroll', (event) => {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      let classFixed = 'normal';
      if (window.pageYOffset > 700) {
        classFixed = 'fixed';
      } else if (st < lastScrollTop && (window.pageYOffset < 700 && window.pageYOffset > 600)) {
        classFixed = 'fixed up';
      }
      lastScrollTop = st <= 0 ? 0 : st;
      this._isMounted && this.setState({
        activeClass: classFixed
      })
    })
    let pagenum = 1
    if (this.props.visible_rows !== undefined) {
      if (this.props.visible_rows % 10 === 0) {
        pagenum = this.props.visible_rows / 10
      } else {
        pagenum = Math.floor(this.props.visible_rows / 10)
      }
    }
    if (this.state.page !== pagenum) {
      this.setState({ page: pagenum })
    }
  }

  componentWillUnmount() {
    document.body.style.overflowX = "auto";
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    let finished;
    if(nextProps.visible_rows > nextProps.ranking.length) {
      const newProps = {...nextProps};
      newProps.visible_rows = nextProps.ranking.length
      finished = !newProps.fetching_ids.filter(item => item < newProps.visible_rows).length
    } else {
      finished = !nextProps.fetching_ids.filter(item => item < nextProps.visible_rows).length
    }

    this.setState({
      itemsAlreadyLoaded: finished
    })

    if (!nextProps.regenerateActionPlan) {
      this.setState({
        actionPlanModal: nextProps.regenerateActionPlan,
        generatePlan: nextProps.regenerateActionPlan,
        loading: {
          loaderOne: 0,
          loaderTwo: 0,
          loaderThree: 0
        }
      })
    }
  }

  loadMore() {
    //before load more uncollapse first hidden page
    let buttons = document.getElementsByClassName('paginate-btn');
    let buttonFound = false
    for (let i = 0; i < buttons.length; i++) {
      let button = buttons[i]
      if (button.dataset.id != null && button.classList.contains('closed-state')) {
        button.click();
        buttonFound = true
        break
      }
    }
    if (!buttonFound) {
      this.props.incrementVisibleRows();
      this.setState({ page: (this.props.visible_rows / 10) })
    }
  }

  handleChangeModalOpenHistoricalData() {
    this.setState({
      autoSaveModalOpen: !this.state.autoSaveModalOpen
    })
  }


  resetMessages() {
    this._isMounted && this.setState({ generatePlanMessage: '' })
  }

  isLoadedAllRows() {
    this.resetMessages()
    let finished = !this.props.fetching_ids.filter(item => item < this.props.visible_rows).length

    if (this.props.uploaded_file) {
      finished = true
    }

    return finished
  }

  showActionPlanOnDashboard() {
    this.setState({
      showActionPlanBadge: !this.state.showActionPlanBadge
    })
  }

  handleActionPlanModal() {
    this.setState({
      toggleModal: !this.state.toggleModal
    })
  }

  async generateActionPlan() {
    if (this.isLoadedAllRows()) {
      this.setState({
        generatePlanMessage: '',
        generatePlan: true,
      })

      setTimeout(() => this.setState(prevState => ({
        ...prevState,
        loading: {
          ...this.state.loading,
          loaderOne: 100
        }
      })), 1000);

      setTimeout(() => this.setState(prevState => ({
        ...prevState,
        loading: {
          ...this.state.loading,
          loaderTwo: 100
        }
      })), 2000);

      let generalModules = [];
      let topStoriesModules;
      let haveTopStories;

      for (const [key, value] of Object.entries(this.props.ranking.data)) {
        if (value.type && value.type === 'top_stories') {
          topStoriesModules = value.resume;
          haveTopStories = true;
          generalModules.push({
            isTopStory: true,
            modulesData: value.resume,
          })
        } else {
          generalModules.push({
            isTopStory: false,
            modulesData: value.relevance,
            totalScore: value.totalScore
          })
        }
      }

      const data = {
        term: this.props.search.main,
        loc_id: this.props.search.loc_id ? this.props.search.loc_id : '',
        location: this.props.search.canonical_name,
        seoData: generalModules.slice(0, 10)
      }

      return fetchClient()
        .post(process.env.REACT_APP_API_ENDPOINT + "/action-plan", data)
        .then((response) => response)
        .catch((error) => this.handleActionPlanError())
    } else {
      this.setState({ generatePlanMessage: 'Wait until all the modules are loaded' })
    }
  }

  handleActionPlanError = () => {
    this.setState({
      generatePlan: false,
      generatePlanMessage: 'Something went wrong. Please try again.',
      loading: {
        loaderOne: 0,
        loaderTwo: 0,
        loaderThree: 0
      },
      actionPlanModal: false,
    });
  }

  handleActionPlanSuccess = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        loaderThree: 100
      },
      actionPlanModal: true,
    })
  }

  render() {
    if (this.props.auth_info === null) {
      return <Redirect to='/login' />
    }

    //need to show load more button if any pages are collapsed
    let havePagesCollapsed = false
    try {
      havePagesCollapsed = (Object.values(JSON.parse(localStorage.getItem('visibility_rows'))).indexOf('hide') != -1)
    } catch (err) { }

    return (
      (this.props.search.main === "") ? <Redirect to="/search" /> :

        <Layout>
          <Header
            onTogglHistoricalData={this.handleChangeModalOpenHistoricalData}
            resultsPage="1"
            itemsAlreadyLoaded={this.state.itemsAlreadyLoaded}
            generateActionPlan={this.generateActionPlan}
            actionPlanModal={this.state.actionPlanModal}
            handleActionPlanModal={this.handleActionPlanModal}
            showActionPlanOnDashboard={this.showActionPlanOnDashboard}
            showActionPlanButton={this.state.showActionPlanBadge}
            loadPlan={this.state.generatePlan}
            generatePlanMessage={this.state.generatePlanMessage}
            handleActionPlanError={this.handleActionPlanError}
            handleActionPlanSuccess={this.handleActionPlanSuccess}
          />
          {
            <div>
              <StatsBoxes />

              <div className={`container ranking ${this.state.activeClass}`}>
                <HistoricChart onTogglHistoricalData={this.handleChangeModalOpenHistoricalData} modalIsOpen={this.state.autoSaveModalOpen} search={this.props.search.main} />
                <div className="col-lg-8 ranking-cards">
                  <Ranking page={this.state.page} getModules={this.getModules} />
                  {
                    havePagesCollapsed || (Object.keys(this.props.ranking.data).length > 10 && this.props.visible_rows < Object.keys(this.props.ranking.data).length) ?
                      <div>
                        <Button className="btn btn-lg paginate-btn loadMore" onClick={this.loadMore}>Load More...</Button>
                      </div>
                      : ''
                  }
                </div>
                {!!this.props?.search?.automation && this.state.showActionPlanBadge && (
                    <ActionPlanContainer
                      loadPlan={this.state.generatePlan}
                      actionPlanModal={this.state.actionPlanModal}
                      handleLoaders={this.state.loading}
                      generateActionPlan={this.generateActionPlan}
                      handleActionPlanModal={this.handleActionPlanModal}
                      modal={this.state.toggleModal}
                      generatePlanMessage={this.state.generatePlanMessage}
                      handleActionPlanError={this.handleActionPlanError}
                      handleActionPlanSuccess={this.handleActionPlanSuccess}
                    />
                  )
                }
                
                {!!this.props.related_searches.length && <RelatedSearches />}
              </div>
            </div>
          }
        </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    search: state.search !== undefined ? state.search : { main: '', sub: '' },
    ranking: state.ranking !== undefined ? state.ranking : [],
    knowledge_graph: state.knowledge_graph,
    related_searches: state.related_searches,
    auth_info: state.auth_info,
    visible_rows: state.visible_rows,
    fetching_ids: state.fetching_ids,
    uploaded_file: state.uploaded_file,
    regenerateActionPlan: state.regenerateActionPlan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    incrementVisibleRows: () => {
      dispatch(incrementVisibleRows())
    },
    change_score_manually: (id, value) => {
      dispatch(change_score_manually(id, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage)
