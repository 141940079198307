import React from 'react'
import { RowBodyStyled, Truncation, Date } from "../../styles/RowResult";
import { Col } from "reactstrap";
import { truncateText } from "../../helpers/truncateText"

export const RowBody = ({ result, showImage }) => {
    const TitleWrapper = showImage ? Truncation : React.Fragment
    return (
        <RowBodyStyled>
            <Col sm={'12'} className={'flex-grow-1'}>
                {showImage && result.image && <div style={{ height: 150, backgroundImage: `url(${result.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', marginBottom: '10%' }} ></div>}
                <TitleWrapper className='top_story_title'>
                    <h3>
                        <a href={result.url || result.link} target="_blank" className="result-title">
                            {result?.title?.length < 55 ? result.title : truncateText(result.title, 45)}
                        </a>
                    </h3>
                </TitleWrapper>
                {!showImage && <p className="body">{result.extract || result.description}</p>}
            </Col>
            <Col sm={'12'} className="text-left pt-2">
                <Date>
                    {result.pubDate}
                </Date>
            </Col>
        </RowBodyStyled>
    )
}
