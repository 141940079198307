import React from 'react';
import { TopBarMenuStyled } from "../../styles/RowResult";
import { CheckboxLightbox as Checkbox } from "../common/Checkbox";
import { DownloadDOMElement } from '../DownloadDOMElement';
import Spinner from "../Spinner";

export const RowTopBarMenu = ({
                                  domain,
                                  changeCompetitive,
                                  changeControlled,
                                  competitive,
                                  controlled,
                                  loadCompetitive,
                                  loadControlled,
                                  id,
                                  ...rest
                              }) => {
    return (
        <TopBarMenuStyled className={'mb-2'} {...rest}>
            { domain && <span className={'domain'}> {domain.replace(/https?:\/\//,"").replace(/\/$/,'')}</span> }

            <div className={'d-flex flex-row row align-items-center justify-content-center'}>
                {loadControlled ? <div className="spinner-loading tag-controlled"><Spinner/></div> :
                    <Checkbox id={`checkbox-controlled-${id}`} onChange={changeControlled} checked={controlled}
                            className='mx-2' fontSize={'0.75em'} label={'Controlled'} disabled={loadControlled}
                    />
                }
                {loadCompetitive ? <div className="spinner-loading tag-controlled"><Spinner/></div> :
                    <Checkbox id={`checkbox-competitive-${id}`} onChange={changeCompetitive} checked={competitive}
                            className='mx-2' fontSize={'0.75em'} label={'Competitive'} disabled={loadCompetitive}
                    />
                }        
                <DownloadDOMElement 
                    element={rest.isAdvertisement ? 'advertisement' : `result-${id}`} 
                    fileName={rest.isAdvertisement ? 'advertisement' : 'row-result'}
                />
            </div>
        </TopBarMenuStyled>
    )
}
