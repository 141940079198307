import React from 'react'
import { categories } from '../config/categories'


export const rankSentiment = [
  {
    value: "unselected",
    label: <span className="container-select-sentiment select-sentiment step"><span
      className="select-sentiment step step-inner bg-unselected">S</span></span>
  },
  {
    value: "positive",
    label: <span className="container-select-sentiment select-sentiment step"><span
      className="select-sentiment step step-inner bg-positive">S</span></span>
  },
  {
    value: "neutral",
    label: <span className="container-select-sentiment select-sentiment step"><span
      className="select-sentiment step step-inner bg-neutral">S</span></span>
  },
  {
    value: "negative",
    label: <span className="container-select-sentiment select-sentiment step"><span
      className="select-sentiment step step-inner bg-negative">S</span></span>
  }
]


export const optionsSelectCategory = Object.entries(categories).map(([key, value], i) => (
  
  {

    position: i,
    value: key,
    label: <span className='dropdown-select'>{value.name}</span>,
    background_color: '#ededed',
    color: value.color
  }))

// these are valid options. Managed and Owned were removed,
// but we save both categories in the other array to show they if the user loads an old file.
export const categoriesRemoved = ['managed', 'owned']
export const validOptionsSelectCategory = Object.keys(categories).filter((key) => !categoriesRemoved.includes(key))

export const findCategory = (item) => item ? (categories[item] ? item : Object.keys(categories)[0]) : null

export const getCategory = (item) => findCategory(item)

export const lengthCategories = Object.keys(categories).filter((key) => !categoriesRemoved.includes(key)).length
