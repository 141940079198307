import React, { Component } from "react";
import './CustomAlert.css'

class CustomAlert extends Component {
    constructor() {
        super();
    }
    
    render() {
        const { message, type, visible } = this.props.data;
        if(!visible){
            return "";
        }
        return (
            <div className={`alert custom-alert ${type=='danger' ? "alert-danger" : "alert-success"}`} role="alert">
                {message}
            </div>
        );
    }
}

export default CustomAlert;
