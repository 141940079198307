import React from 'react';
import { HeaderTopStyled } from '../../styles/Header'
import { connect } from 'react-redux'
import { Button } from '../common/buttons/ButtonCommon'
import { CheckboxLightbox } from '../common/Checkbox'

import { changeAutomation, changePagesAutomate, exportLoading, } from "../../actionCreators";
import { AutomationPages } from "./AutomationPages";
import { Dropdown } from '../common/buttons/DropdownButton';

const HeaderTop = ({
    automation = false, pagesToAutomate, exportFileMessage,
    loading, isSearch = false,
    toggleHistoricalData, exportPDF, exportXLSX,
    screenshotIsInProgress,
    changePagesToAutomate, changeAutomation, incrementPages, decrementPages,
    disableButton,
    actionPlanModal,
    handleActionPlanModal,
    showActionPlanButton,
    loadPlan,
    generatePlanMessage,
    uploaded_file,
    state
}) => {
    const isNotFile = uploaded_file === "" ? true : false

    const handleExportXLSXClick = () => {
        exportXLSX(state);
    }

    const handleChangeAutomation = () => {
        if (isSearch && !loading) {
            changePagesToAutomate(automation ? 0 : 1)
            changeAutomation()
        }
    }

    const handleChangePagesToAutomate = (changeAmount) => {
        if (changeAmount === 'sum' && pagesToAutomate < 10) {
            this.props.changePagesToAutomate(pagesToAutomate + 1)
        } else if (changeAmount === 'subtract' && pagesToAutomate > 0) {
            if (pagesToAutomate === 1) {
                changeAutomation()
            }
            changePagesToAutomate(pagesToAutomate - 1)
        }
    }

    const ActionPlanButton = () => (
        actionPlanModal ?
            (<Button className={'mr-3 py-2'}
                icon={<img src={require('../../images/icons/clipboard-list.svg').default} alt='' />}
                onClick={handleActionPlanModal}>
                View Plan
            </Button>) :
            (<Button className={'mr-3 py-2'}
                disabled={loadPlan}
                icon={<img src={require('../../images/icons/clipboard-list.svg').default} alt='' />}
                onClick={() => document.querySelector("#action-plan-badge").scrollIntoView({ behavior: 'smooth' })}
            >
                Generate Plan
            </Button>)
    )

    return (
        <HeaderTopStyled isSearch={isSearch}>
            <div>
                <div className='item-automated-search'>
                    <div className='container-icon mr-3'>
                        <svg className='icon-search' width="12" height="13" viewBox="0 0 12 13" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.4141 8.35156L8.25 6.5L11.4141 4.67188C11.6016 4.57812 11.6719 4.34375 11.5547 4.15625L10.8047 2.86719C10.7109 2.67969 10.4766 2.60938 10.2891 2.72656L7.125 4.55469V0.875C7.125 0.6875 6.9375 0.5 6.75 0.5H5.22656C5.03906 0.5 4.85156 0.6875 4.85156 0.875V4.55469L1.6875 2.72656C1.5 2.63281 1.26562 2.67969 1.17188 2.86719L0.421875 4.15625C0.304688 4.34375 0.375 4.57812 0.5625 4.67188L3.72656 6.5L0.5625 8.35156C0.375 8.44531 0.304688 8.67969 0.421875 8.86719L1.17188 10.1562C1.26562 10.3438 1.5 10.3906 1.6875 10.2969L4.85156 8.46875V12.125C4.85156 12.3359 5.03906 12.5 5.22656 12.5H6.75C6.9375 12.5 7.125 12.3359 7.125 12.125V8.46875L10.2891 10.2969C10.4766 10.3906 10.7109 10.3438 10.8047 10.1562L11.5547 8.86719C11.6719 8.67969 11.6016 8.44531 11.4141 8.35156Z"
                                fill="#9B51E0" />
                        </svg>
                    </div>
                    <span> Smart Automate is <b> {automation ? 'on' : 'off'} </b></span>
                    {isSearch && <>
                        <CheckboxLightbox id={'checkbox-automated'} disabled={loading} className='mx-2' onChange={handleChangeAutomation}
                            checked={automation}
                            color={'#fff'} colorCheck={"#329844"} borderColor={"#fff"}
                            backgroundColor={"#fff"} />

                        <AutomationPages disabled={loading || !automation} paddingButton={'0'} fontSize={'0.875em'}
                            sizeIcon={'0.57375em'} sizeButton={'1.625rem'} className='mx-3'
                            value={pagesToAutomate} increment={incrementPages} decrement={decrementPages} />
                    </>}

                </div>
            </div>
            <div>
                {!isSearch && !screenshotIsInProgress && <div className='d-flex flex-wrap position-relative'>
                    {!!automation && <div>
                        {showActionPlanButton && isNotFile && <ActionPlanButton />}
                        {generatePlanMessage !== '' && showActionPlanButton &&
                            <span className="save-warning position-absolute">{generatePlanMessage}</span>
                    }
                    </div>}
                    <div>
                        <Button className={'mr-3 py-2'}
                            icon={<img src={require('../../images/icons/arrow-to-bottom.svg').default} alt='' />}
                            onClick={exportPDF}>
                            Export PDF
                        </Button>
                        {exportFileMessage &&
                            <span className="save-warning position-absolute">{exportFileMessage}</span>
                        }
                    </div>
                    <div>
                        <Button className={'mr-3 py-2'}
                            icon={<img src={require('../../images/icons/arrow-to-bottom.svg').default} alt='' />}
                            onClick={handleExportXLSXClick}>
                            Export XLSX
                        </Button>
                    </div>
                    <Button className={'py-2'}
                        icon={<img src={require('../../images/icons/chart-bar.svg').default} alt='' />}
                        onClick={toggleHistoricalData}
                        disabled={disableButton}
                    >
                        View Historical Data
                    </Button>                    
                </div>}
            </div>
        </HeaderTopStyled>
    )
}

const mapStateToProps = (state) => {
    return {
        automation: state.search.automation,
        pagesToAutomate: state.search.pagesToAutomate,
        uploaded_file: state.uploaded_file,
        state: state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        exportLoading: (status) => {
            dispatch(exportLoading(status))
        },
        changeAutomation: () => {
            dispatch(changeAutomation())
        },
        changePagesToAutomate: (numberPages) => {
            dispatch(changePagesAutomate(numberPages))
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop)
