import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ActionPlanCard from './ActionPlanCard';
import arrowDown from '../../images/icons/arrow-to-bottom.svg';
import '../../css/ActionPlanModal.css';

const ActionPlanModal = ({ modal, openModal, showResults, openCollapse, exportPdf, data }) => {
  const { searchTerm, location, date, threats, opportunities } = data;

  return (
    <div>
      <Modal isOpen={modal} toggle={openModal} id='action-plan-modal'>
        <ModalHeader className='action-plan-modal-header' toggle={openModal}>
          <div className='d-flex align-items-center justify-content-between header-items'>
            <h3 className='mb-0 header-title'>Lightbox Search Action Plan</h3>
            <Button
              onClick={exportPdf}
              className='action-plan-modal-button'
              data-html2canvas-ignore="true"
            >
              <img className='mr-2' src={arrowDown} />
              Download
            </Button>
          </div>
          <Row className='pt-5'>
            <Col className='col-8'>
              <ul className='search-data' style={{ padding: '0px' }}>
                <li>Search term: <b>{searchTerm}</b></li>
                <li>Location: <b>{location}</b></li>
                <li>Date saved: <b>{date}</b></li>
              </ul>
            </Col>
            <Col className='col-4 d-flex justify-content-end'>
              <Row>
                <Col>
                  <div>
                    <p className='action-points'>Threats</p>
                    <p className='action-points-number critical-found'>{threats?.length}</p>
                  </div>
                </Col>
                <Col className='ml-4'>
                  <div>
                    <p className='action-points'>Opportunities</p>
                    <p className='action-points-number opportunity-found'>{opportunities?.length}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody className='action-plan-modal-body' id='modal-body'>
          <p className='modal-text'>
          An analysis of your search results reveals the threats and opportunities listed below. 
          Improve the precision of Lightbox Search findings by adjusting the Smart Automate sentiment and category classifications with your own custom overrides.&nbsp;
            <a 
              id="learn-more-action-plan"
              href='https://lightboxsearch.com/what-to-do/accurate-action-plans' 
              target='_blank'
            >
              <u>Learn more.</u>
            </a>
          </p>
          <ActionPlanCard
            showResults={showResults}
            openCollapse={openCollapse}
            id='Threats'
            data={threats}
          />
          <ActionPlanCard
            showResults={showResults}
            openCollapse={openCollapse}
            id='Opportunities'
            data={opportunities}
          />
          <Col className='d-flex align-items-center justify-content-center flex-column my-5 footer'>
            <p className='footer-contact'>Would you like our experts to help you further?</p>
            <Row>
              <span className='mx-3'>
                <FontAwesomeIcon className='mr-1' icon={faPhone} color='#0786CD' />
                212.235.0248
              </span>
              <span className='mx-3'>
                <FontAwesomeIcon className='mr-1' icon={faEnvelope} color='#0786CD' />
                info@lightboxsearch.com
              </span>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ActionPlanModal
