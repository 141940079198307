import React, { Component } from 'react'
import Select from 'react-select'

class FilesToolbar extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        
        const auth_info = JSON.parse(localStorage.getItem('token_info'))
        const data = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
            }
        }

        this.state = {
            headers: data,
            selectOptions: this.props.actionOptions,
            currentAction:  { value: "", label: "Select", place:"" }
        }

        this.handleSelectAction = this.handleSelectAction.bind(this)
    }

    componentDidMount() {
      this._isMounted = true
      this.handleSelectAction({ value: "", label: "Select" })
      //this is redirecting to the root on each action that call to fetchFiles 
      //if (this.props.currentPath !== '/') this.props.changeCurrentPath('/')
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    handleSelectAction(action) {
      this._isMounted && this.setState({
        currentAction:action
      })
    }

    submitAction() {
      if (this.props.selectedItems && this.state.currentAction && this.state.currentAction.value !== ''){
        if(this.state.currentAction.value == 'move'){
          this.props.toggleMoveModal()
          return
        } 
        this.props.executeAction(this.state.currentAction.value)
        this.setState({currentAction:  { value: "", label: "Select", place:"" }})
      }
    }
    
    addNewRow() {
      this.props.createFolder()
    }

    emptyTrash() {
      this.props.emptyTrash()
    }

    render() {
        let current = this.props.currentPath.startsWith('/trash') ? 'trash' : 'notrash'
        let btn_disabled = !this.state.currentAction || (this.state.currentAction.value !== undefined && this.state.currentAction.value === '')
        let current_options = this.state.selectOptions.filter(option => {
                                return (option.place === current || option.place === "") && option.value !== 'edit' && option.value !== 'removetimeline'
                              })
        
        if(this.state.currentAction.place !== "" && this.state.currentAction.place !== current){
          this.setState({currentAction:this.state.selectOptions[0]})
        }
        
        return (
          <React.Fragment>
            <th className="action-text">
              { !this.props.intoModal ? "Action:" : null }
            </th>
            <th colSpan="2">
              { !this.props.intoModal ?
              <React.Fragment>
                <Select className="toolbar-select"
                  isSearchable={false}
                  isDisabled={!this.props.isDisabled}
                  value={this.state.currentAction}
                  onChange={this.handleSelectAction}
                  options={current_options}
                />              
                <input type="button" className="go-button" value="Go"
                  disabled={btn_disabled} 
                  onClick={this.submitAction.bind(this)}
                />
              </React.Fragment>
              : null }
            </th>
            <th colSpan="2">
            {
              current !== 'trash' ? 
                <input type="button" className="new-folder" value="New Folder" onClick={this.addNewRow.bind(this)}/>
              : 
                this.props.hasTrashedItems ?
                  <input type="button" className="empty-trash-button" value="Empty Trash" onClick={this.emptyTrash.bind(this)}/>
                : null
            }
            </th>
          </React.Fragment>
        )
    }
}

export default FilesToolbar 