import React from 'react';
import { Card, CardHeader, CardTitle, Collapse, CardBody, CardText, CardSubtitle, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMinus, faExclamationTriangle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '../../css/ActionPlanModal.css';
import getFormattedURL from '../../helpers/getFormattedURL';

const ActionPlanCard = ({ openCollapse, showResults, id, data }) => {
  if (!data?.length) return null;
  return (
    <>
      <div onClick={() => showResults(id)} className={`issue-type ${id === 'Threats' ? 'critical-type' : id === 'Opportunities' ? 'opportunity-type' : 'warning-type'}`}>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
          {id === 'Threats' ? 'Threats' : id === 'Opportunities' ? 'Opportunities' : 'Warnings'}
        </span>
        <FontAwesomeIcon icon={!openCollapse[id] ? faEye : faMinus} />
      </div>
      {data.map((item, index) =>
        <Collapse id='action-plan-issues' isOpen={openCollapse[id]} key={index}>
          <Card className='card-action' id='action-plan-issues'>
            <CardHeader className='card-action-header'>
              {id === 'Threats' ? (
                <>
                  <CardTitle className='critical-found-issue critical-found m-0'>Critical Issue Found:</CardTitle>
                  <h3>{item?.message?.headline}</h3>
                </>
              ) : id === 'Opportunities' ? (
                <>
                  <CardTitle className='opportunity-found-issue opportunity-found m-0'>Optimization Opportunity Found:</CardTitle>
                  <h3>{item?.message?.headline}</h3>
                </>
              ) : (
                <>
                  <CardTitle className='warning-found-issue warning-found m-0'>Lorem ipsum dolor sit amet:</CardTitle>
                  <h3>{item.message?.headline}</h3>
                </>
              )}
            </CardHeader>
            <hr />


            <CardBody className='card-action-body pt-0'>
              <CardSubtitle className='url'>{getFormattedURL(item.value?.resume?.[0]?.url)}</CardSubtitle>

              <a href={item.value?.resume?.[0]?.url} target="_blank">
                <CardTitle className='result-title'>
                  {item.value?.resume?.[0]?.title}
                </CardTitle>
              </a>
              {item.value?.resume?.[0]?.title && <hr />}

              <Row>
                <Col>
                  <CardText className='importance'>Why this matters:</CardText>
                  <CardText className='importance-content'>{item.message?.why_this_matters}</CardText>
                </Col>
                <Col>
                  <CardText className='action-items'>What to do:</CardText>
                  <ol>
                    {Array.isArray(item.message?.what_to_do) &&
                      item.message?.what_to_do.map(actionItem => (
                        <li>
                          {actionItem.url
                            ? <a href={actionItem.url} target="_blank">
                              {actionItem.message}
                              <FontAwesomeIcon icon={faExternalLinkAlt} className='ml-1' />
                            </a>
                            : actionItem.message
                          }
                        </li>
                      ))}
                  </ol>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br />
        </Collapse>
      )}
    </>
  )
}

export default ActionPlanCard
