import React from 'react'
import SEOCheckboxTooltip from './SEOCheckboxTooltip'
import SEOTooltip from './SEOTooltip'


const SEOBodyTableRow = ({ data }) => {
    const { icon, title, analysisMessage, analysisColor, tooltip, override, dataUnavailableTooltip } = data
    const timedOut = analysisMessage === 'Timed Out Connection, please retry the search.'
    const isLoadSpeed = title === 'Load Speed'
    const backLinksRow = title === 'BackLinks' && !timedOut
    
    return (
        <div className="seo-table-row">
            {icon}
            <div className="metric-body">
                <SEOTooltip title={title} tooltip={tooltip[0]} />
                {isLoadSpeed &&
                    <a href={data.checkLoadSpeedLink} target="_blank" className="load-speed-link">
                        {data.checkLoadSpeedMessage}
                    </a>
                }
            </div>
            <div className="analysis-body" style={{width: backLinksRow ? '62%' : '38%'}}>
                { dataUnavailableTooltip ? 
                    <SEOTooltip tooltip={dataUnavailableTooltip[0]}>                 
                        <div className="analysis-label" style={{ backgroundColor: analysisColor, fontSize: timedOut ? '12px' : '', padding: timedOut ? '6px 20px' : ''}}>
                            {analysisMessage}
                        </div> 
                    </SEOTooltip>
                    :
                    <div className="analysis-label" style={{ backgroundColor: analysisColor, fontSize: timedOut ? '12px' : '', padding: timedOut ? '6px 20px' : ''}}>
                        {analysisMessage}
                    </div>
                }
            </div>
            <div className="override-body" style={{width: backLinksRow ? '0%' : '%24'}}>
                {override.hasOverride &&
                    <SEOCheckboxTooltip
                        checked={override.checked}
                        onChange={override.onChange}
                        tooltip={override.overrideTooltip?.[0]}
                    />
                }

            </div>
        </div>
    )
}

export default SEOBodyTableRow
