import React, { Component } from 'react'
import { Button, FormFeedback, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import Spinner from '../../components/Spinner'
import '../../css/OverridesForm.css'
import { updateOverrides, setOverride } from '../../actionCreators';

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

class TermForm extends Component {
    constructor(props){
        super(props)
        let category = null
        this.state = {
            loading: false,
            item : props.overrides.item,
            searchDisabled: true,
            message : null,
        }
        if(props.overrides.item){
            this.state.item = {
                ...props.overrides.item,
                term: props.overrides.item.term ? props.overrides.item.term : props.overrides.term,
                location: {
                   loc_id: props.overrides.item.location,
                   canonical_name: props.overrides.item.location_name
                }
            }
        }
        
    }

    toggleLoader = () => {
        this.setState({
          ...this.state,
          loading : !this.state.loading
        });
    }
    
    handleChange = (evt) => {
        this.setState({
            item: {...this.state.item, [evt.target.name]: evt.target.value},
            searchDisabled: !!!evt.target.value.trim().length,
            message: (!!!evt.target.value.trim().length) ? "This field is required" : null
        })
    }

    searchIfTermAlreadyExist = (term) => {
        return this.props.overrides.list.data.find(element => element.term == term)
    }

    create = () => {
        if(this.searchIfTermAlreadyExist(this.state.item.term.trim()) == null){
            this.toggleLoader();
            let item = {
                ...this.state.item
            };
            item.location = item.location ? item.location.loc_id : "" 
            let data = { 
                list: [],
                selected_list: 'sentiment',
                term : this.state.item.term
            }
            this.props.onFetchList(data)
            this.cancel(true);
            this.toggleLoader();
        }else{
            this.setState({
                message: "The term already exist"
            })
        }
    }        

    cancel = (message = false) => {
        let success = {type : 'success',text : 'Success'};
        let data = {
            item: {},
            type: this.props.overrides.selected_list,
            modal_is_open: false,
            modal_type: "",
            messages: message ? success : message
        }
        this.props.onItemAction(data)
    }
    
    render(){
        const {
            overrides
        } = this.props;
        const {
            loading
        } = this.state;
        return ( 
            <Modal isOpen={overrides.modal_is_open} className={"overrideForm"}>
                {overrides.modal_type ? <ModalHeader>{overrides.modal_type.capitalize()} a new term</ModalHeader> :""}
                <ModalBody>            
                    <div className='OverridesForm'>
                        <InputGroup>
                            <Input placeholder="Term" name="term" className='term-input' maxLength={64} onChange={this.handleChange}/>
                            {this.state.message && <FormFeedback style={{ display:'block'}}>{this.state.message}</FormFeedback>}
                        </InputGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {loading ? <Spinner /> : null }
                    <Button 
                            color="secondary"
                            className="btnCancelOverride"
                            onClick={() => (this.cancel())}
                        >Cancel</Button>
                    {
                        overrides.modal_type === "create" && 
                            <Button 
                                disabled={this.state.searchDisabled}
                                color="primary"
                                onClick={this.create}
                                className={"btnCreateCurrentOverride"}
                            >Create</Button>
                    }
                </ModalFooter>
            </Modal> 
        );
    }
    
}

const mapStateToProps = (store) => {
    return {
        overrides: store.overrides,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchList: (data) => {
            dispatch(updateOverrides(data))
        },
        onItemAction: (data) => {
            dispatch(setOverride(data))
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TermForm)


