import React from 'react'
import FilesNavigation from '../../FilesNavigation'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Collapse} from 'reactstrap'

export default class FilesNavigationSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCollapse: false
        }
        this.toggle = this.toggle.bind(this)
    }

    toggle = () => this.setState(prevState => ({openCollapse: !prevState.openCollapse}))

    render() {
        const {currentPath, changePath} = this.props
        return (
            <FilesNavigationStyle isOpen={this.state.openCollapse}>
                <div className='header-navigation' onClick={() => {
                    if (!currentPath) changePath('/')
                        this.toggle()
                    }
                }
                >
                    <span> Select folder to save reports </span>
                    <FontAwesomeIcon className="icon-toggle " icon='angle-down'
                                     color="#35A9E0"/>
                </div>
                <Collapse isOpen={this.state.openCollapse}>
                    <FilesNavigation
                        intoModal
                        {...this.props}
                        height="30vh"
                        fromSaveModal
                        height="30vh"
                        finishRender={() => {
                        }}
                    />
                </Collapse>
            </FilesNavigationStyle>

        );
    }
}
const FilesNavigationStyle = styled.div`
  padding: .5rem;

  .header-navigation {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: .7;
    }

    span {
      font-size: 1em;
      font-weight: 700;
      padding: 0;
      color: #4F4F4F;
      margin: 0;
    }

    .icon-toggle {
      margin: 0 1rem;
      font-size: 2em;
      transition: transform .4s ease-in-out;
      transform: rotate(${({isOpen}) => isOpen ? 180 : 0}deg);
    }
  }
`