import React from 'react';
import { CheckboxStyled } from "../../styles/Checkbox";

export const CheckboxLightbox = ({
  onChange,
  checked,
  classNameLabel = '',
  id = `checkbox-${Math.random()}`,
  label,
  color = "#35A9E0",
  borderColor,
  backgroundColor,
  colorCheck,
  className,
  ...rest
}) => {
  return (
    <CheckboxStyled backgroundColor={backgroundColor} borderColor={borderColor} className={className} color={color}
      colorCheck={colorCheck} {...rest}>
      <input 
        className={"inp-cbx"} 
        id={id} 
        type="checkbox"
        onChange={onChange}
        checked={checked} 
        style={{ display: 'none' }} 
      />
      <label className="cbx" htmlFor={id}>
        <span>
          <svg  className="top-row-checkboxes" xmlns="http://www.w3.org/2000/svg" width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
        <span className={classNameLabel}>{label}</span>
      </label>
    </CheckboxStyled>
  )
}
