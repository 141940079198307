import styled from 'styled-components';
import {styles} from './variables';

export const RelatedSearchesStyled = styled.div`
  margin: auto;
  float: right;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  border-radius: 10px;
  border: 1px solid ${styles.gray};
  margin-left: 20px;
  width: calc(33.333333% - 20px);
  background: ${styles.whiteBackground};

  h3 {
    font-size: 1.125em !important;
    color: ${styles.textColor};
    font-weight: 600;
    padding: 0;
    margin-bottom: .25rem !important;
    text-align: left;
  }

  table {
    flex-grow: 1;
    font-family: ${styles.fontPrimary};
    background-color: transparent !important;
    th {
      background-color: transparent !important;
      color: ${styles.textColor};
      text-align: left;
      padding-right: .5rem;
      font-weight: 600;
      padding-bottom:  0.625rem;
      padding-top:  0.625rem;
      vertical-align: bottom;
      word-wrap: break-word;
      width: 25% !important;
      font-size: 0.8125em;
    }

    a {
      font-family: ${styles.fontRoboto};
      color: ${styles.secondaryColor};
      cursor: pointer;
      font-weight: 400;
    }
  tr:not(:last-child) {
    border-bottom: 1px solid #D4D4D4;
  }
    td {
      font-family: ${styles.fontRoboto};
      font-weight: 400;
      background-color: transparent !important;
      color: ${styles.textColor};
      font-size: 0.875em;
      text-align: left;
      padding-right: .5rem;
      padding-bottom:  0.625rem;
      padding-top:  0.625rem;
      vertical-align: bottom;
    }
  }
`
