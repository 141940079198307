import { useState } from 'react'
import { styles } from '../styles/variables'
import { Col, Row } from 'reactstrap'
import { LayoutRow } from "./RowResult/Layout"
import { RowBody } from "./RowResult/RowBody"
import { RowTopBarMenu } from "./RowResult/RowTopBar"
import CustomAlert from './common/CustomAlert'
import fetchClient from '../helpers/fetch-client'
import { connect } from 'react-redux'
import {
    changeAdControlled,
    changeAdCompetitive,
} from '../actionCreators'

const cleanUrl = (url) => {
    if (url) {
        const link = new URL(url)
        const { pathname, hostname, search, protocol } = link
        const proto = process.env.NODE_ENV === 'development' ? "http:" : protocol
        return `${proto}//${hostname}${pathname}${search}`
    }
    return url
}

const Ads = ({ ad, handleChangeAdControlled, handleChangeAdCompetitive }) => {
    const [controlledLoading, setControlledLoading] = useState(false)
    const [competitiveLoading, setCompetitiveLoading] = useState(false)
    const [customAlert, setCustomAlert] = useState({})

    if (!ad || ad && !ad.url) {
        return null
    }

    const showFetchError = (error = "There was an error") => {
        setCustomAlert(prev => ({
            ...prev,
            visible: true,
            message: error,
            type: 'danger'
        }))
        setTimeout(() => {
            setCustomAlert(prev => ({
                ...prev,
                visible: false,
                message: "",
                type: 'danger'
            }))
        }, 2000)
    }

    const handleControlledChange = async () => {
        const { type, url, competitive, controlled } = ad;
        const api_url = process.env.REACT_APP_API_ENDPOINT + "/domaintag";
        const data = { type, url, competitive };
        setControlledLoading(true)
        setCompetitiveLoading(true)
        try {
            data.controlled = controlled ? false : true;
            // update optimistically
            handleChangeAdControlled(!controlled)
            await fetchClient().post(api_url, data)
        } catch (error) {
            showFetchError()
            // cancel update if error occurs
            handleChangeAdControlled(!controlled)
        } finally {
            setControlledLoading(false)
            setCompetitiveLoading(false)
        }
    }

    const handleCompetitiveChange = async () => {
        const { type, url, competitive, controlled } = ad;
        const api_url = process.env.REACT_APP_API_ENDPOINT + "/domaintag";
        const data = { type, url, controlled };
        setCompetitiveLoading(true)
        setControlledLoading(true)
        try {
            data.competitive = competitive ? false : true;
            // update optimistically
            handleChangeAdCompetitive(!competitive)
            await fetchClient().post(api_url, data)
        } catch (error) {
            showFetchError();
            // cancel update if error occurs
            handleChangeAdCompetitive(!competitive);
        } finally {
            setCompetitiveLoading(false)
            setControlledLoading(false)
        }
    }

    return (

        <LayoutRow id="advertisement" className='card advertisement result text-start pt-0'>
            {
                customAlert.visible ? <CustomAlert data={customAlert} /> : ""
            }
            <Row className="d-flex justify-content-center">
                <div className="p-1 px-2 text-white" style={{ backgroundColor: styles.secondaryColor }}>Ad</div>
            </Row>
            <div className="card-body">
                <RowTopBarMenu
                    isAdvertisement
                    domain={ad.url}
                    changeControlled={handleControlledChange}
                    controlled={!!ad.controlled}
                    loadControlled={controlledLoading}
                    loadCompetitive={competitiveLoading}
                    changeCompetitive={handleCompetitiveChange}
                    competitive={!!ad.competitive}
                />
                <RowBody result={ad} />
                <Row className="pl-4 pr-4 mt-3">
                    {ad.links.map(link => {
                        const url = cleanUrl(link.url)
                        return (<Col className="col-6 d-flex flex-column align-items-start">
                            <RowBody result={{ ...link, url }} />
                        </Col>);
                    }
                    )}
                </Row>
            </div>
        </LayoutRow>
    )
}

const mapStateToProps = state => ({
    ad: state.ad
})
const mapDispatchToProps = (dispatch) => ({
    handleChangeAdControlled: (controlled) => {
        dispatch(changeAdControlled(controlled));
    },
    handleChangeAdCompetitive: (competitive) => {
        dispatch(changeAdCompetitive(competitive));
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Ads)