export const categories = {
    'unCategorized': {
        name: 'Uncategorized',
        color: '#afafaf',
        initialValue: 100
    },
    'newsMedia': {
        name: 'News',
        color: '#e8eff7',
        initialValue: 0
    },
    'socialMedia': {
        name: 'Social Media',
        color: '#5e7187',
        initialValue: 0
    },
    'corporateSite': {
        name: 'Corporate Site',
        color: '#9B51E0',
        initialValue: 0
    },
    'educationalSite': {
        name: 'Educational Site',
        color: '#CD4A00',
        initialValue: 0
    },
    'organizationSite': {
        name: "Organization Site",
        color: '#56CCF2',
        initialValue: 0
    },
    "personalSite": {
        name: 'Personal Site',
        color: '#56CCF2',
        initialValue: 0
    },
    "campaignSite": {
        name: 'Campaign Site',
        color: '#56CCF2',
        initialValue: 0
    },
    "governmentSite": {
        name: 'Government Site',
        color: '#56CCF2',
        initialValue: 0
    },
    'thirdParty': {
        color: '#27A560',
        name: 'Third-Party Profile',
        initialValue: 0
    },
    'blog': {
        name: 'Blog',
        color: '#1A0DAB',
        initialValue: 0
    },
    'review': {
        name: 'Review',
        color: '#F2994A',
        initialValue: 0
    },
    'e-commerce': {
        name: 'E-commerce',
        color: '#52BE80',
        initialValue: 0
    },
    "wikipedia": {
        color: '#76448A',
        name: "Wikipedia",
        initialValue: 0
    },
    'newsRelease': {
        name: 'News Release',
        color: '#E67E22',
        initialValue: 0
    },
    "document": {
        color: '#E74C3C',
        name: "Document",
        initialValue: 0
    },
    "paid": {
        name: 'Paid',
        color: '#D98880',
        initialValue: 0
    },
    "directory": {
        name: 'Directory',
        color: '#566246',
        initialValue: 0
    },
    "obituary": {
        name: 'Obituary',
        color: '#554640',
        initialValue: 0
    },
    "article": {
        name: 'Article',
        color: '#8AA29E',
        initialValue: 0
    },
    "dictionary": {
        name: 'Dictionary',
        color: '#B6C8A9',
        initialValue: 0
    }
}

export const defaultKey = 'unCategorized'
