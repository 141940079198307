import React, { Component } from 'react'
import { Col, Row, Button } from 'reactstrap'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import './../css/Ranking.css'
import RankingItem from './RankingItem'
import Ads from './Ads'
import { connect } from 'react-redux'
import { switchRanking, calculateLumentusScore, fillDataForPage, handleFetchingIdToStore, decrementVisibleRows } from '../actionCreators'
import Tooltip from './Tooltip'
import '../css/Tooltip.css'
import fetchClient from '../helpers/fetch-client';
import { getCookieByKey } from '../helpers/cookies';
import store from '../store';

const collapse = (ev) => {
    let id = (ev.target.dataset.id);
    document.getElementById(`page-button-${id}`).classList.add("closed-state");
    store.dispatch(decrementVisibleRows());
    //collapse all buttons with higher page number
    let buttons = document.getElementsByClassName('paginate-btn')
    for (let i = 0; i < buttons.length; i++) {
        let button = buttons[i]
        let id2 = button.dataset.id;
        if (id2 != null && Number(id2) > Number(id) && !button.classList.contains('closed-state')) {
            document.getElementById(`page-button-${id2}`).classList.add("closed-state");
            store.dispatch(decrementVisibleRows());
        }
    }
}

const SortableItem = SortableElement((props) => {
    const { value, position, status, index } = props;
    let block = position > 9 ? Math.floor(position / 10) : 0
    return (
        <li
            className={"noselect sortable-item block-" + (block + 1) + (status != undefined ? " " + status : "")}
            id={`result-${index}`}
        >
            {value}
        </li>
    );
})
const SortableList = SortableContainer(({ items, statePages }) => {

    return (
        <ul>
            {items.map((value, index) => (
                <li>
                    {index > 0 && index % 10 == 0 ?
                        <React.Fragment key={`fragment-${value['id']}`}>
                            <Button id={`page-button-${((index / 10) + 1)}`} key={`page-${((index / 10) + 1)}`} className={"btn btn-lg paginate-btn btn-primary loadMore " + (JSON.parse(localStorage.getItem('visibility_rows'))["status_" + ((index / 10) + 1)] == 'hide' ? "closed-state" : "")} data-id={((index / 10) + 1)} onClick={e => collapse(e)}>
                                Page {((index / 10) + 1)}
                            </Button>
                            <SortableItem key={`item-${value['id']}`} index={index} position={index} value={value['data']} status={JSON.parse(localStorage.getItem('visibility_rows'))["status_" + ((index / 10) + 1)]} />
                        </React.Fragment>
                        :
                        <SortableItem key={`item-${value['id']}`} index={index} position={index} value={value['data']} status={JSON.parse(localStorage.getItem('visibility_rows'))["status_" + (Math.floor(index / 10) + 1)]} />
                    }
                </li>
            ))}
        </ul>
    )
})

class Ranking extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.state = {
            items: [],
            fetching_pages: [],
            firstPopoverDontShowAgain: false,
            secondPopoverDontShowAgain: false,
            thirdPopoverDontShowAgain: false
        },
            this.quantityOfFetchedItems = 0
        this.cancelSelect = this.cancelSelect.bind(this)
        this.instanciateRankingItems = this.instanciateRankingItems.bind(this)
        this.isMobile = this.isMobile.bind(this)
        this.firstPopoverDontShowAgainRefresh = this.firstPopoverDontShowAgainRefresh.bind(this)
        this.secondPopoverDontShowAgainRefresh = this.secondPopoverDontShowAgainRefresh.bind(this)
        this.thirdPopoverDontShowAgainRefresh = this.thirdPopoverDontShowAgainRefresh.bind(this)
        this.getCookiesOverridesPopovers();
        this.rankingItemAlreadyFetch = this.rankingItemAlreadyFetch.bind(this)
    }

    cancelSelect(ev) {
        const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'a', 'svg', 'img']

        if (ev.target.classList.contains('dropdown-select')) return true
        if (disabledElements.indexOf(ev.target.tagName.toLowerCase()) !== -1) return true
        if (ev.target.classList.contains('step') || ev.target.classList.contains('select-category-image') || ev.target.classList.contains('css-11unzgr')) return true
        if (ev.target.parentNode.classList.contains('clamp-lines')) return true
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    rankingItemAlreadyFetch(e) {
        this.quantityOfFetchedItems++;
    }

    instanciateRankingItems(props = null) {
        let items = []
        let counter = props ? ((props.visible_rows > props.ranking.length) ? (props.ranking.length) : props.visible_rows) : ((this.props.visible_rows > this.props.ranking.length) ? (this.props.ranking.length) : (this.props.visible_rows))
        let visibility_rows = localStorage.getItem('visibility_rows') ? JSON.parse(localStorage.getItem('visibility_rows')) : {}
        
        for (var i = 0; i < counter; i++) {

            if (i > 0 && i % 10 == 0) {
                let name = 'status_' + ((i / 10) + 1)
                if (!visibility_rows[name] && i < counter) {
                    visibility_rows[name] = 'open'
                }
            }

            if (props && props.order[i] != undefined || this.props.order[i] != undefined) {
                let aux = {}
                aux['id'] = parseInt(props ? props.order[i].id : this.props.order[i].id);
                aux['data'] = <RankingItem
                    data={props ? props.ranking[props.order[i].id] : this.props.ranking[this.props.order[i].id]}
                    index={i}
                    id={aux['id']}
                    firstPopoverDontShowAgain={this.state.firstPopoverDontShowAgain}
                    secondPopoverDontShowAgain={this.state.secondPopoverDontShowAgain}
                    thirdPopoverDontShowAgain={this.state.thirdPopoverDontShowAgain}
                    firstPopoverDontShowAgainRefresh={this.firstPopoverDontShowAgainRefresh}
                    secondPopoverDontShowAgainRefresh={this.secondPopoverDontShowAgainRefresh}
                    thirdPopoverDontShowAgainRefresh={this.thirdPopoverDontShowAgainRefresh}
                    refreshDontShowAgain={this.refreshDontShowAgain}
                    rankingItemAlreadyFetch={this.rankingItemAlreadyFetch}
                    isAutomated={((aux['id'] < this.props.search.pagesToAutomate * 10) && !this.props.uploaded_file)}
                />
                items.push(aux)
            }
        }

        localStorage.setItem('visibility_rows', JSON.stringify(visibility_rows))

        this._isMounted && this.setState({
            items
        })
    }

    firstPopoverDontShowAgainRefresh() {
        this.setState({
            firstPopoverDontShowAgain: true
        }, () => { this.componentWillReceiveProps(this.props) })
    }

    secondPopoverDontShowAgainRefresh() {
        this.setState({
            secondPopoverDontShowAgain: true
        }, () => { this.componentWillReceiveProps(this.props) })
    }

    thirdPopoverDontShowAgainRefresh() {
        this.setState({
            thirdPopoverDontShowAgain: true
        }, () => { this.componentWillReceiveProps(this.props) })
    }

    getCookiesOverridesPopovers() {
        let cookie = getCookieByKey("showFirstPopoverOverrides");
        if (cookie != undefined) {
            if (cookie[1] == this.props.user_logged.username && cookie[1] != undefined) {
                this.state.firstPopoverDontShowAgain = true;
            }
        }

        cookie = getCookieByKey("showSecondPopoverOverrides");
        if (cookie != undefined) {
            if (cookie[1] == this.props.user_logged.username && cookie[1] != undefined) {
                this.state.secondPopoverDontShowAgain = true;
            }
        }

        cookie = getCookieByKey("showThirdPopoverOverrides");
        if (cookie != undefined) {
            if (cookie[1] == this.props.user_logged.username && cookie[1] != undefined) {
                this.state.thirdPopoverDontShowAgain = true;
            }
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.instanciateRankingItems()
        if (this.props.ranking.data !== undefined && this.props.ranking.data.isArray() && this.props.ranking.data.length) {
            this.props.calculateLumentusScore()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        localStorage.removeItem('visibility_rows');
    }

    componentWillReceiveProps(nextProps) {
        let aux_array = [];
        if(!Array.isArray(nextProps.ranking)){
            for (const key in nextProps.ranking) {
                aux_array.push(nextProps.ranking[key])
            }
        } else {
            aux_array = [...nextProps.ranking]
        }
        if(nextProps.visible_rows > (aux_array.length)) {
            const newProps = {...nextProps};
            newProps.visible_rows = aux_array.length
            this.instanciateRankingItems(newProps)
        } else {
            this.instanciateRankingItems(nextProps)
        }

        if (nextProps.page == 1) {
            var disable_to_call = false
            var i = 0
            if (aux_array[i].category === "newsMedia") i = 1

            for (var x = i; x < (nextProps.visible_rows > aux_array.length ? aux_array.length : 10); x++) {

                disable_to_call = aux_array[x].relevance !== undefined && aux_array[x].relevance.filter((item) => {
                    return item.status === "unrequested"
                }).length

                if (disable_to_call) {
                    break
                }
            }
        }

        if (disable_to_call !== undefined && disable_to_call)
            return

        const max_page = Math.trunc(Object.keys(this.props.ranking).length / 10)

        if (nextProps.page <= max_page &&
            (this.props.page !== nextProps.page ||
                this.props.ranking[this.props.visible_rows] !== undefined && this.props.ranking[this.props.visible_rows].relevance[0].status == "unrequested")
        ) {
            this.props.calculateLumentusScore()
        } else if (nextProps.page > max_page) {
            this.props.calculateLumentusScore()
        }
    }

    render() {

        return (
            <div className="container ranking">
                <Col lg={12} className="ranking-title">
                    <Row>
                        <Col id="col-1" xs={window.innerWidth <= 1366 ? 5 : 6} className="text-left">
                            <span className='titleAlign'><h5 className='rankingTitles'><b>Rank and Sentiment</b></h5></span>
                            {
                                this.props.tooltips.map((tooltip, i) => {
                                    return (tooltip.code === '003') ? <Tooltip key={tooltip.code} tooltip={tooltip} /> : ''
                                })
                            }
                        </Col>
                        <Col id="col-2" xs={window.innerWidth <= 1366 ? 7 : 6} className="text-right no-side-p">
                            <span className='titleAlign'><h5 className='rankingTitles'><b>Relevance Rank</b></h5></span>
                            {
                                this.props.tooltips.map((tooltip, i) => {
                                    return (tooltip.code === '004') ? <span class="pr-3"><Tooltip key={tooltip.code} tooltip={tooltip} /></span> : ''
                                })
                            }
                            <span className='titleAlign'><h5 className='rankingTitles'><b>Domain Authority</b></h5></span>
                            {
                                this.props.tooltips.map((tooltip, i) => {
                                    return (tooltip.code === '005') ? <span class="pr-3"><Tooltip key={tooltip.code} tooltip={tooltip} /></span> : ''
                                })
                            }
                            <span className='titleAlign'><h5 className='rankingTitles'><b>Select category</b></h5></span>
                            {
                                this.props.tooltips.map((tooltip, i) => {
                                    return (tooltip.code === '007') ? <Tooltip key={tooltip.code} tooltip={tooltip} /> : ''
                                })
                            }
                        </Col>
                    </Row>
                </Col>
                <Ads />
                <SortableList items={this.state.items} pressDelay={this.isMobile() ? 200 : 0} onSortEnd={this.props.onSortEnd} shouldCancelStart={this.cancelSelect} lockAxis={"y"} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        search: state.search,
        ranking: state.ranking.data,
        order: state.ranking.order,
        visible_rows: state.visible_rows,
        tooltips: state.tooltips,
        user_logged: state.user_logged,
        uploaded_file: state.uploaded_file
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSortEnd: ({ oldIndex, newIndex }) => {
            dispatch(switchRanking(oldIndex, newIndex))
            dispatch(calculateLumentusScore())
        },
        calculateLumentusScore: () => {
            dispatch(calculateLumentusScore())
        },
        fill_data_for_page: (data, page) => {
            dispatch(fillDataForPage(data, page))
        },
        handleFetchingIdToStore: (id, action) => {
            dispatch(handleFetchingIdToStore(id, action))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Ranking)
