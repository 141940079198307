export const styles = {
    fontPrimary: "'Inter', sans-serif" ,
    fontSecundary: "'Helvetica', sans-serif",
    fontRoboto: "'Roboto' , sans-serif",
    primaryColor: "#0786CD",
    secondaryColor: "#374c8b",
    gray: '#CFE1EA',
    textColorLight: '#4F4F4F',
    textColor: '#212529',
    purple: "#9B51E0",
    green: "#219653",
    yellow: '#F8BC1C',
    red: '#D0021B',
    redls:'#D0021B',
    yellowls:'#F4C430',
    grayls:'#909090',
    greenls:'#329844',
    whiteBackground: '#FFFFFF',
    widthSidebar: '15.8125rem',
    breakpoints: {
        sm: '600px',
        md: '992px',
        lg: '1280px',
    }
}
export const getColorScore = (score) => {
    if(score <= 25){
      return styles.redls;
    }else if(score >= 26 && score < 51) {
      return styles.yellowls;
    }else if (score >= 51 && score < 76) {
       return styles.grayls
    }else if (score >= 76) {
       return styles.greenls;
    }
    else return styles.textColor
}
