import styled from 'styled-components';
import {styles} from './variables';
import {Button, ButtonDropdown} from 'reactstrap'

export const ButtonStyled = styled(Button)`
  outline: none !important;
  box-shadow: none !important;
  width: ${({block}) => block? '100%' : 'auto'};
  color: ${({color})=> color || styles.secondaryColor} !important;
  font-weight: ${({fontWeight}) => fontWeight || 700} ;
  border-color: ${({borderColor}) => borderColor || '#CFE1EA'}  !important;
  font-family:  ${styles.fontPrimary};
  background-color: ${({backgroundColor}) => backgroundColor || 'initial' } !important;
  font-size: 1em;
  transition: all .4s ease-in-out;
  display:flex;
  align-items:center;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  &:active {
      opacity: .6;
  }
  &:hover {
    opacity: .8;
  }
 .icon {
     svg, img {
         min-width: 1rem;
         min-height: 1rem;
     }
   path {
    color: ${styles.secondaryColor};
   }
 }
`





export const ButtonDropdownStyled = styled(ButtonDropdown)`
  flex-grow: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  color: ${({color})=> color || styles.secondaryColor} !important;
  font-weight: ${({fontWeight}) => fontWeight || 700} ;
  border-color: ${({borderColor}) => borderColor || '#CFE1EA'}  !important;
  font-family:  ${styles.fontPrimary};
  background-color: ${({backgroundColor}) => backgroundColor || 'initial' } !important;
  font-size: 1em;
  transition: all .4s ease-in-out;
  display:flex;
  justify-content: space-between;
  padding: 0 !important;
  align-items:center;
  border-radius: 5px;
  position: relative;
  &:active {
      opacity: .6;
  }
  &:hover {
    opacity: .8;
  }
  padding-right: 2rem !important;
  .dropdown-menu {
    padding: 0;
  }
  .dropdown-item {
    flex-grow: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  color: ${({color})=> color || styles.secondaryColor} !important;
  font-weight: ${({fontWeight}) => fontWeight || 700} ;
  border-color: ${({borderColor}) => borderColor || '#CFE1EA'}  !important;
  font-family:  ${styles.fontPrimary};
  background-color: ${({backgroundColor}) => backgroundColor || 'initial' } !important;
  font-size: 1em;
  transition: all .4s ease-in-out;
  display:flex;
  justify-content: center;
    text-align: center;
  padding: .5rem .8rem ;
  align-items:center;
    border-radius: 5px;
  }
  .dropdown-toggle {
    width: 2rem;
    background-color: inherit !important;
    border-color: transparent !important;
    border-left: solid 1px rgba(255,255,255, 0.2) !important;
    outline: none !important;
    box-shadow: none !important;
    color: ${({color})=> color || styles.secondaryColor} !important;
    height: 100% !important;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right:0;
  }
`
