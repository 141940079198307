import React from 'react'
import { SentimentLegendStyled } from '../../styles/DoughnutsLegend'

const sentimentLegend = [
    { text: 'Positive', color: '#329844' },
    { text: 'Negative', color: '#d0021b' },
    { text: 'Neutral', color: '#f8bc1c' },
    { text: '' , color: ''} //invisible item to facilitate alignment
]

const SentimentDoughnutLegend = () => {
    return (
        <SentimentLegendStyled>
                {sentimentLegend.map((item) => {
                    return (
                        <li key={item.text} className="listItem">
                            <div style={{ display: 'flex' }}>
                                <div className="legendColoredBox" style={{ backgroundColor: item.color }} />
                                <p className="legendText">{ item.text }</p>
                            </div>
                        </li>
                    );
                })}
        </SentimentLegendStyled>
    )
}

export default SentimentDoughnutLegend
