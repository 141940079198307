import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import '../../css/OverridesForm.css'
import { Prompt } from 'react-router-dom'

class SentimentForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            visible: false,
            nextLocation: null
        }
    }

    leavePage = () => {
        if(this.props.callback){
            this.setState({
                visible: false
            }, () => {
                this.props.callback.parameter ? this.props.callback.callback(this.props.callback.parameter) : this.props.callback.callback()
                this.props.removeCallbackFromState()
            })
        }
        else{
            window.location.href = this.state.nextLocation
        }
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            visible: true,
            nextLocation: nextLocation.pathname
        })
        return false;
    }
    
    cancel = () => {
        this.setState({visible: false});
    }
    
    
    render(){
        const { enabled } = this.props
        return (
            <Fragment>
                <Prompt
                when={enabled}
                message={this.handleBlockedNavigation}
                />
                <Modal isOpen={this.state.visible} className={"overrideForm"}>
                    <ModalBody className="overrideForm">            
                        <div className='OverridesForm'>
                            <h4>You have unsaved changes.</h4>
                            <h4>Are you sure you want to leave this page without saving?</h4>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="secondary"
                            className="btnCancelOverride"
                            onClick={() => (this.cancel())}
                        >Cancel</Button>
                        <Button 
                            color="primary"
                            onClick={this.leavePage}
                            className={"btnCreateCurrentOverride"}
                        >Confirm</Button>
                    </ModalFooter>
                </Modal>  
            </Fragment>
        );
    }
}

export default SentimentForm
