import React, { Component } from 'react'
import './../css/File.css'
import AutoSaveModal from './AutoSaveModal'
import openModalIcon from './../images/open-modal-icon.svg'
import openModalIconDisabled from './../images/open-modal-icon-disabled.svg'
import { connect } from 'react-redux'

class HistoricChart extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false

    this.state = {
      autoSaveModalOpen:false,
      is_loaded: false
    }
    this.autoSaveHandler = this.autoSaveHandler.bind(this)
    this.toggleAutoSaveModal = this.toggleAutoSaveModal.bind(this)
    this.isLoadedAllRows = this.isLoadedAllRows.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    setTimeout(
      function() {
        this.setState({is_loaded: this.isLoadedAllRows()})
      }
      .bind(this),
      3000
    );
  }

  componentWillReceiveProps(props) {
    this.setState({is_loaded: this.isLoadedAllRows(props)})
  }

  autoSaveHandler() {
    if (this.isLoadedAllRows()) {
      this._isMounted && this.props.onTogglHistoricalData()
    }
  }

  toggleAutoSaveModal() {
    if (this.isLoadedAllRows()) {
      this.props.onTogglHistoricalData()
    }
  }

  isLoadedAllRows(props=null) {
    var search_finished = true
    var fetching_ids = props ? props.fetching_ids : this.props.fetching_ids  
    
    if (fetching_ids.length) {
      let limit = props ? props.visible_rows : this.props.visible_rows
      let hassubsearch = props ? props.subsearch !== "" : this.props.subsearch !== ""
      if (hassubsearch) limit = 10

      for (let i=0; i < limit; i++) {
        let id = props ? props.order[i].id : this.props.order[i].id
        if (fetching_ids.includes(id)) {
          search_finished = false
          break
        }
        
      }
    }
    return search_finished
  }

  render() {
    return (
      <React.Fragment>
      {
        this.props.modalIsOpen ?
          <AutoSaveModal search={this.props.search} close={()=>this.toggleAutoSaveModal()} open={this.props.modalIsOpen} />
        : null
      }
      </React.Fragment>
    )
    
  }
}

const mapStateToProps = (state) => {
  return {
      fetching_ids: state.fetching_ids,
      visible_rows: state.visible_rows,
      order: state.ranking.order,
      subsearch: state.search.sub,
      export_loading: state.export_loading
  }
}

export default connect(mapStateToProps, null)(HistoricChart)
