import React, { useState } from 'react';
import { Col } from "reactstrap";
import Select from "react-select";
import Tooltip from '../Tooltip';
import { categoryTooltip } from '../../config/overrideTooltips';
import CategoryConfirmation from './CategoryConfirmation';



const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        color: state.selectProps.menuColor,
        padding: "0px 10px",
        zIndex: 99999,
    }),

    container: (provided) => ({
        ...provided,
        outline: "none",
        fontSize: "13px"
    }),
    control: (provided) => ({
        ...provided,
        border: "none",
    }),
}

const CategoriesRow = ({
    id,
    isAutomated = true,
    overrideAction,
    setState,
    uploaded_file,
    changeCategory,
    optionsSelectCategory,
    selectCategoryDisabled,
    isTopStories,
    ...props
}) => {
    const [previousValue, setPreviousValue] = useState(optionsSelectCategory.find((cat) => cat.value === props.data.category));
    const [selectValue, setSelectValue] = useState(previousValue);
    const [confirmationMessage, setConfirmationMessage] = useState('Hidden');
    const canOverrideCategory = isAutomated && !uploaded_file;
    const isCategorySelectDisabled = canOverrideCategory ? selectCategoryDisabled : (!!isTopStories())

    const handleChange = (event) => {
        setSelectValue(event);
        changeCategory(event);
        setConfirmationMessage('AskConfirmation');
    }

    return (
        <Col className={'d-flex flex-column'}>
            <div className={'d-flex align-items-center'}>
                <h4 className={'mr-2'}>
                    Category
                </h4>
                {canOverrideCategory && <Tooltip tooltip={{code: `category-${id}`, ...categoryTooltip}} />}
            </div>
            {isCategorySelectDisabled ?
                <div className="mt-4 pt-1 font-weight-bold text-left" style={{ fontSize: '13px' }}>
                    {optionsSelectCategory.map(item => {
                        if (item.value === props.data.category) {
                            return item.label;
                        }
                    })}
                </div> : (
                    <Select
                        className="mt-3 pt-1 font-weight-bold text-left"
                        isSearchable={false}
                        value={selectValue}
                        automation={isAutomated && !uploaded_file}
                        onChange={handleChange}
                        options={optionsSelectCategory}
                        defaultValue={optionsSelectCategory[0]}
                        styles={customStyles}
                        width='200px'
                    />
                )}
            {canOverrideCategory &&
                <CategoryConfirmation
                    confirmationMessage={confirmationMessage}
                    setConfirmationMessage={setConfirmationMessage}
                    overrideAction={overrideAction}
                    changeCategory={changeCategory}
                    value={selectValue}
                    setSelectValue={setSelectValue}
                    previousValue={previousValue}
                    setPreviousValue={setPreviousValue}
                />
            }
        </Col>
    )
}
export default CategoriesRow;
