import React, { Component } from 'react'

class PeopleAlsoSearchItem extends Component {

  constructor(props) {
      super(props)
  }

  render() {
    return  (
            <div className="people-also-search-item">
              <a href={this.props.data.link} target="_blank">
                <img src={this.props.data.image} alt={this.props.data.name} />
                <p>{this.props.data.name}</p>
              </a>
            </div>
          )
  }
}

export default PeopleAlsoSearchItem