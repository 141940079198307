import React from 'react'
import { OverrideConfirmation } from './OverrideConfirmation'; 

const SentimentConfirmation = ({ 
    confirmationMessage, 
    setConfirmationMessage, 
    overrideAction,
    topStoryIndex,
    setDisabledStyle,
    changeSentiment,
    value,
    setRadioGroupValue,
    previousValue,
    setPreviousValue
}) => {
    const handleSave = (event) => {
        setPreviousValue(value);
        overrideAction(event, topStoryIndex);
        setConfirmationMessage('Saved');
    };
    const handleCancel = () => {
        setRadioGroupValue(previousValue);
        changeSentiment(previousValue, topStoryIndex);
        setDisabledStyle(true);
        setConfirmationMessage('Cancelled');
        setTimeout(() => setConfirmationMessage('Hidden') , 800);
    };

    return (
        <div style={{marginLeft: '-10px'}}>
            <OverrideConfirmation
                type="sentiment" 
                confirmationMessage={confirmationMessage} 
                handleSave={handleSave}
                handleCancel={handleCancel}
            />
        </div>
    ); 
}

export default SentimentConfirmation;