import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../../Sidebar';
import {styles} from '../../../styles/variables';

class Layout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.user_logged.email) {
            return <Redirect to='/' />
        }
        
        const {children} = this.props
        return (
            <Page>
                <Container>
                <Sidebar active/>
                <div className="wrapper">
                {children}
                </div>
                </Container>
            </Page>)
    }
}


const mapStateToProps = (state) => {
    return {
        user_logged: state.user_logged
    }
}

export default connect(mapStateToProps, {})(Layout)

const Page = styled.div`
  background-color: #F2F9FD;
  min-height: 100vh;
  height: 100%;
`
const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  min-height: 70vh;
  padding: calc(2rem + 5%) 1.5rem;
  .wrapper {
    margin-left: 0;
    position: relative;
    transition: margin .5s ease-in-out;
    &.full{
      margin-left: 0;
    }
    @media screen and (max-width: ${styles.breakpoints.lg}) {
      margin-left: 0;
    }
  }
`
