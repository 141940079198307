import React from 'react';
import {RowResultStyled} from "../../styles/RowResult";


export const LayoutRow = ({children, ...props}) => {
    return (
        <RowResultStyled {...props}>
            {children}

        </RowResultStyled>
    )
}
