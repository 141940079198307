import React from 'react'
import SEOBodyTableRow from './SEOBodyTableRow'

const SEOBodyTable = ({ data }) => {
    return (
        <>
            <div className="table-header">
                <div className="seo-metric-header">SEO METRIC</div>
                <div className="analysis-header">ANALYSIS</div>
                <div className="override-header">OVERRIDE</div>
            </div>
            <div className="table-body">
                {data.map((item) => <SEOBodyTableRow data={item} key={data.title} />)}
            </div>
        </>
    )
}

export default SEOBodyTable
