const sentiments = {"unselected": 0, "positive": 100, "neutral": 50, "negative": -100}
const sentimentValue = {"unselected": 1, "positive": 1, "neutral": 1, "negative": -1}

const position_weight = {
	10: 10,
	20: 4.75,
	30: 3.33,
	40: 2.5,
	50: 2,
	60: 1.6,
	70: 1.4285714286,
	80: 1.25,
	90: 1.11,
	100:1,
}

const calculateLumentusScore = (ranking, rows, knowledgegraph) => {

	let last_sentiment_modified = 0
	if (Object.values(ranking.data).length) {
		let visible_ids = ranking.order.slice(0,rows).map(item=>item.id)
		var last_sentiment_ids_modified_arr = Object.values(ranking.data).filter(item=> visible_ids.includes(item.id)).filter(item=>item.sentiment !== 'unselected').map(item=>item.id)

		var greather_posistion=0
		last_sentiment_ids_modified_arr.forEach(element => {
			for(i=0; i < ranking.order.length; i++) {
				if (ranking.order[i].id === element && i > greather_posistion) {
					greather_posistion = i
				}
			}
		})

		if(greather_posistion > 0){
			last_sentiment_modified = greather_posistion
		}
	}

	let total_score = 0
	let limit_rows_weight = 0

	if (last_sentiment_modified < 10 ){
		limit_rows_weight = 10
	} else {
		for (let i=1; i <= last_sentiment_modified; i++) {
			const result = (last_sentiment_modified+i) % 10
			if (result === 0) {
				limit_rows_weight = last_sentiment_modified+i
				break
			}
		}
	}

	let max_value = 0

	if (Object.keys(ranking.data).length && ranking.order.length && last_sentiment_ids_modified_arr.length) {
		for (var i = 0; i < limit_rows_weight; i++) {
			var score = 0

			if (ranking.order[i] !== undefined && ranking.order[i].id !== undefined && ranking.data[ranking.order[i].id] !== undefined
				&& ranking.data[ranking.order[i].id].sentiment !== undefined) {

				let sentiment = ranking.data[ranking.order[i].id].sentiment

				score += sentiments[sentiment]

				if (ranking.data[ranking.order[i].id].score_manually !== null && !isNaN(ranking.data[ranking.order[i].id].score_manually)) {

					let da = ranking.data[ranking.order[i].id].relevance.filter(item => {
						return item.module === 'domain_authority' && !isNaN(item.score)
					})

					if (sentiment === 'negative') {
						score -= ranking.data[ranking.order[i].id].score_manually
						score -= da.length && da[0].score !== undefined ? parseInt(da[0].score) : 0
					} else {
						score += ranking.data[ranking.order[i].id].score_manually
						score += da.length && da[0].score !== undefined ? parseInt(da[0].score) : 0
					}

				}else {
					var rr_plus_da = 0
					if(ranking.data[ranking.order[i].id].type == "top_stories"){
						let count = 0
						let topStoriesLength = ranking.data[ranking.order[i].id].resume.length <= 3 
							? ranking.data[ranking.order[i].id].resume.length 
							: 3

						// just take the first 3 topstories to calculate a score
						ranking.data[ranking.order[i].id].resume.forEach(story => {
							if(story.sentiment && count < topStoriesLength){
								rr_plus_da += sentimentValue[story.sentiment.value] * (story.score + story.domainAuthority + Math.abs(sentiments[story.sentiment.value]));
							}
							count ++
						});	
						//average topstories
						rr_plus_da = (isNaN(rr_plus_da)) ? 0 : rr_plus_da / topStoriesLength
					}else{
						ranking.data[ranking.order[i].id].relevance.filter( item => {
							rr_plus_da += parseInt(item.score)
						})
					}

					if(ranking.data[ranking.order[i].id].type == "top_stories"){
						score = rr_plus_da;
					}else{
						//manual override
						if (!!ranking.data[ranking.order[i].id].term_in_url_override){
							score += 15
						}
						if (!!ranking.data[ranking.order[i].id].page_title_override){
							score += 15
						}
						if (!!ranking.data[ranking.order[i].id].keyword_match_h1_override){
							score += 15
						}
						if (!!ranking.data[ranking.order[i].id].backlinks_override){
							score += 15
						}
						if (!!ranking.data[ranking.order[i].id].load_speed_override){
							score += 10
						}
						if (!!ranking.data[ranking.order[i].id].security_certificate_override){
							score += 10
						}
						if (!!ranking.data[ranking.order[i].id].click_depth_override){
							score += 5
						}
						if (!!ranking.data[ranking.order[i].id].internal_links_count_override){
							score += 5
						}
						if (!!ranking.data[ranking.order[i].id].external_links_count_override){
							score += 5
						}
						if (!!ranking.data[ranking.order[i].id].images_count_override){
							score += 5
						}

						if (sentiment === 'negative') {
							score -= rr_plus_da
						} else {
							score += rr_plus_da
						}
					}
				}

				var step_value = position_weight[limit_rows_weight] !== undefined ? position_weight[limit_rows_weight] : 1
				var weight = 100 - (i * step_value)
				weight = weight.toFixed(4)

				var row_max_val = weight * 300 / 100

				max_value += row_max_val
				score = (score * weight) / 100

				total_score += score
			}
		}
	}
	/*
	if(knowledgegraph !== null && Object.keys(knowledgegraph).length &&
		knowledgegraph.calculated_wikipedia_data !== undefined && !knowledgegraph.calculated_wikipedia_data &&
		knowledgegraph.detailedDescription !== undefined && Object.keys(knowledgegraph.detailedDescription).length) {
		total_score+=300
		max_value+=300
	}
	*/
	let percent_score = total_score / max_value
	let result = (percent_score * 100).toFixed(2)

	if (isNaN(result) || result == 0.00) {
		result = 0
	}

	if (Math.abs(result) > 100) {
		result = Math.sign(result) * 100
	}

	return result
}

export default calculateLumentusScore