const logoPath = (user_logged) =>{
    let path = '';
    const keys = Object.keys(user_logged);
    keys.forEach(key => {
        if (key === "user_preferences"){
            if (typeof user_logged.user_preferences === 'object' && user_logged.user_preferences !== null){
                if(user_logged.user_preferences.hasOwnProperty('logo')){
                    path=user_logged.user_preferences.logo;
                }
            }
        }
    });
    return path;
}

export default logoPath;