import styled from "styled-components";


export const AutomatedSearchStyled = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: ${({fontSize}) => fontSize || '1em'};
    .btn-control {
      background-color: #fff;
      border-radius: 6px;
      overflow: hidden;
      padding: ${({paddingButton}) => paddingButton || '0.8em'};
      color: ${({color}) => color || '#374c8b'} !important;
      width: ${({sizeButton}) => sizeButton || 'auto' }  !important;
      height: ${({sizeButton}) => sizeButton || 'auto' }  !important;
      line-height: 0 !important;
      margin: 0 .7rem;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
       
      .icon {
        color: ${({color}) => color || '#374c8b'} !important;
        font-size: ${({sizeIcon}) => sizeIcon || '0.9em'};
      }
    }
`
