import React from 'react';


export const IconSearch = (props) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.7812 14.2805L12.6562 11.1555C12.5 11.0305 12.3125 10.9368 12.125 10.9368H11.625C12.4688 9.84302 13 8.46802 13 6.93677C13 3.37427 10.0625 0.436768 6.5 0.436768C2.90625 0.436768 0 3.37427 0 6.93677C0 10.5305 2.90625 13.4368 6.5 13.4368C8 13.4368 9.375 12.9368 10.5 12.0618V12.593C10.5 12.7805 10.5625 12.968 10.7188 13.1243L13.8125 16.218C14.125 16.5305 14.5938 16.5305 14.875 16.218L15.75 15.343C16.0625 15.0618 16.0625 14.593 15.7812 14.2805ZM6.5 10.9368C4.28125 10.9368 2.5 9.15552 2.5 6.93677C2.5 4.74927 4.28125 2.93677 6.5 2.93677C8.6875 2.93677 10.5 4.74927 10.5 6.93677C10.5 9.15552 8.6875 10.9368 6.5 10.9368Z"
                fill="#0786CD" />
        </svg>)
}

export const IconLocation = (props) => (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M6.04688 18.2881C6.36328 18.7803 7.10156 18.7803 7.41797 18.2881C12.5508 10.9053 13.5 10.1319 13.5 7.38971C13.5 3.66315 10.4766 0.639709 6.75 0.639709C2.98828 0.639709 0 3.66315 0 7.38971C0 10.1319 0.914062 10.9053 6.04688 18.2881ZM6.75 10.2022C5.16797 10.2022 3.9375 8.97174 3.9375 7.38971C3.9375 5.84283 5.16797 4.57721 6.75 4.57721C8.29688 4.57721 9.5625 5.84283 9.5625 7.38971C9.5625 8.97174 8.29688 10.2022 6.75 10.2022Z" fill="#324A97" />
    </svg>
)

export const IconCalendar = (props) => (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.8333 2.50012H11.3333V0.875122C11.3333 0.687622 11.1458 0.500122 10.9583 0.500122H9.70825C9.4895 0.500122 9.33325 0.687622 9.33325 0.875122V2.50012H5.33325V0.875122C5.33325 0.687622 5.14575 0.500122 4.95825 0.500122H3.70825C3.4895 0.500122 3.33325 0.687622 3.33325 0.875122V2.50012H1.83325C0.989502 2.50012 0.333252 3.18762 0.333252 4.00012V15.0001C0.333252 15.8439 0.989502 16.5001 1.83325 16.5001H12.8333C13.6458 16.5001 14.3333 15.8439 14.3333 15.0001V4.00012C14.3333 3.18762 13.6458 2.50012 12.8333 2.50012ZM12.6458 15.0001H2.02075C1.89575 15.0001 1.83325 14.9376 1.83325 14.8126V5.50012H12.8333V14.8126C12.8333 14.9376 12.7395 15.0001 12.6458 15.0001Z" fill="#324A97" />
    </svg>
)

export const IconCalendarDay = props => (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M4.16675 12.0001H7.16675C7.41675 12.0001 7.66675 11.7814 7.66675 11.5001V8.50012C7.66675 8.25012 7.41675 8.00012 7.16675 8.00012H4.16675C3.8855 8.00012 3.66675 8.25012 3.66675 8.50012V11.5001C3.66675 11.7814 3.8855 12.0001 4.16675 12.0001ZM13.1667 2.50012H11.6667V1.00012C11.6667 0.750122 11.4167 0.500122 11.1667 0.500122H10.1667C9.8855 0.500122 9.66675 0.750122 9.66675 1.00012V2.50012H5.66675V1.00012C5.66675 0.750122 5.41675 0.500122 5.16675 0.500122H4.16675C3.8855 0.500122 3.66675 0.750122 3.66675 1.00012V2.50012H2.16675C1.323 2.50012 0.666748 3.18762 0.666748 4.00012V15.0001C0.666748 15.8439 1.323 16.5001 2.16675 16.5001H13.1667C13.9792 16.5001 14.6667 15.8439 14.6667 15.0001V4.00012C14.6667 3.18762 13.9792 2.50012 13.1667 2.50012ZM13.1667 14.8126C13.1667 14.9376 13.073 15.0001 12.9792 15.0001H2.35425C2.22925 15.0001 2.16675 14.9376 2.16675 14.8126V5.50012H13.1667V14.8126Z" fill="#324A97" />
    </svg>
)

export const IconBarChart = props => (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M10.375 8H11.5938C11.7812 8 12 7.8125 12 7.625V3.40625C12 3.21875 11.7812 3 11.5938 3H10.375C10.1875 3 10 3.21875 10 3.40625V7.625C10 7.8125 10.1875 8 10.375 8ZM13.375 8H14.5938C14.7812 8 15 7.8125 15 7.625V0.40625C15 0.21875 14.7812 0 14.5938 0H13.375C13.1875 0 13 0.21875 13 0.40625V7.625C13 7.8125 13.1875 8 13.375 8ZM4.375 8H5.59375C5.78125 8 6 7.8125 6 7.625V5.40625C6 5.21875 5.78125 5 5.59375 5H4.375C4.1875 5 4 5.21875 4 5.40625V7.625C4 7.8125 4.1875 8 4.375 8ZM7.375 8H8.59375C8.78125 8 9 7.8125 9 7.625V1.40625C9 1.21875 8.78125 1 8.59375 1H7.375C7.1875 1 7 1.21875 7 1.40625V7.625C7 7.8125 7.1875 8 7.375 8ZM15.5 10H2V0.5C2 0.25 1.75 0 1.5 0H0.5C0.21875 0 0 0.25 0 0.5V11C0 11.5625 0.4375 12 1 12H15.5C15.75 12 16 11.7812 16 11.5V10.5C16 10.25 15.75 10 15.5 10Z" fill="#324A97" />
    </svg>
)
export const IconHistory = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.75 8C15.7188 3.71875 12.25 0.25 8 0.25C5.90625 0.25 4.03125 1.09375 2.625 2.40625L1.09375 0.875C0.78125 0.5625 0.25 0.78125 0.25 1.21875V5.75C0.25 6.03125 0.46875 6.25 0.75 6.25H5.28125C5.71875 6.25 5.9375 5.71875 5.625 5.40625L4.03125 3.8125C5.0625 2.84375 6.46875 2.25 8 2.25C11.1562 2.25 13.75 4.84375 13.75 8C13.75 11.1875 11.1562 13.75 8 13.75C6.625 13.75 5.40625 13.3125 4.40625 12.5312C4.125 12.2812 3.6875 12.3125 3.40625 12.5938L3.0625 12.9375C2.75 13.25 2.78125 13.7812 3.125 14.0625C4.4375 15.125 6.15625 15.7812 8 15.75C12.25 15.75 15.75 12.2812 15.75 8ZM10.0938 10.4688L10.375 10.0625C10.6562 9.75 10.5938 9.28125 10.25 9.03125L9 8.03125V4.75C9 4.34375 8.65625 4 8.25 4H7.75C7.3125 4 7 4.34375 7 4.75V9L9.03125 10.5938C9.34375 10.8438 9.8125 10.7812 10.0938 10.4688Z" fill="#324A97" />
    </svg>
)
export const IconCalendarCheck = props => (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5ZM10.6562 8.25C10.7812 8.09375 10.7812 7.875 10.6562 7.71875L9.9375 7C9.8125 6.84375 9.5625 6.84375 9.40625 7L5.9375 10.4375L4.5625 9.0625C4.40625 8.90625 4.1875 8.90625 4.03125 9.0625L3.3125 9.75C3.1875 9.90625 3.1875 10.1562 3.3125 10.2812L5.65625 12.6562C5.8125 12.8125 6.0625 12.8125 6.1875 12.6562L10.6562 8.25Z" fill="#324A97" />
    </svg>
)

export const IconExchange = props => (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1 4.25C1 4.6875 1.3125 5 1.75 5H13.6562L12.7188 5.875C12.4062 6.1875 12.4062 6.65625 12.7188 6.96875L13.0312 7.28125C13.3438 7.59375 13.8125 7.59375 14.0938 7.28125L16.6875 4.71875C17.0938 4.34375 17.0938 3.6875 16.6875 3.3125L14.0938 0.75C13.8125 0.4375 13.3438 0.4375 13.0312 0.75L12.7188 1.0625C12.4062 1.375 12.4062 1.84375 12.7188 2.15625L13.6562 3H1.75C1.3125 3 1 3.34375 1 3.75V4.25ZM16.25 9H4.3125L5.25 8.15625C5.5625 7.84375 5.5625 7.375 5.25 7.0625L4.9375 6.75C4.625 6.4375 4.15625 6.4375 3.875 6.75L1.28125 9.3125C0.875 9.6875 0.875 10.3438 1.28125 10.7188L3.875 13.2812C4.15625 13.5938 4.625 13.5938 4.9375 13.2812L5.25 12.9688C5.5625 12.6562 5.5625 12.1875 5.25 11.875L4.3125 11H16.25C16.6562 11 17 10.6875 17 10.25V9.75C17 9.34375 16.6562 9 16.25 9Z" fill="#324A97" />
    </svg>
)
export const IconArrowToBottom = props => (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M9.70154 5.62109C9.83826 5.48438 9.83826 5.26562 9.70154 5.15625L9.15466 4.60938C9.04529 4.47266 8.82654 4.47266 8.68982 4.60938L6.11951 7.26172V0.453125C6.11951 0.289062 5.98279 0.125 5.79138 0.125H5.02576C4.86169 0.125 4.69763 0.289062 4.69763 0.453125V7.26172L2.15466 4.60938C2.01794 4.47266 1.79919 4.47266 1.68982 4.60938L1.14294 5.15625C1.00623 5.26562 1.00623 5.48438 1.14294 5.62109L5.18982 9.66797C5.32654 9.80469 5.51794 9.80469 5.65466 9.66797L9.70154 5.62109ZM10.3578 10.9531H0.514038C0.322632 10.9531 0.185913 11.1172 0.185913 11.2812V12.0469C0.185913 12.2383 0.322632 12.375 0.514038 12.375H10.3578C10.5219 12.375 10.6859 12.2383 10.6859 12.0469V11.2812C10.6859 11.1172 10.5219 10.9531 10.3578 10.9531Z" fill="#0786CD" />
    </svg>)

export const IconStart = props => (
    <svg className='icon-search' width="12" height="13" viewBox="0 0 12 13" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.4141 8.35156L8.25 6.5L11.4141 4.67188C11.6016 4.57812 11.6719 4.34375 11.5547 4.15625L10.8047 2.86719C10.7109 2.67969 10.4766 2.60938 10.2891 2.72656L7.125 4.55469V0.875C7.125 0.6875 6.9375 0.5 6.75 0.5H5.22656C5.03906 0.5 4.85156 0.6875 4.85156 0.875V4.55469L1.6875 2.72656C1.5 2.63281 1.26562 2.67969 1.17188 2.86719L0.421875 4.15625C0.304688 4.34375 0.375 4.57812 0.5625 4.67188L3.72656 6.5L0.5625 8.35156C0.375 8.44531 0.304688 8.67969 0.421875 8.86719L1.17188 10.1562C1.26562 10.3438 1.5 10.3906 1.6875 10.2969L4.85156 8.46875V12.125C4.85156 12.3359 5.03906 12.5 5.22656 12.5H6.75C6.9375 12.5 7.125 12.3359 7.125 12.125V8.46875L10.2891 10.2969C10.4766 10.3906 10.7109 10.3438 10.8047 10.1562L11.5547 8.86719C11.6719 8.67969 11.6016 8.44531 11.4141 8.35156Z"
            fill="#9B51E0"/>
    </svg>
)
