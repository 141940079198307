import React from 'react';
import {SectionSearchStyled} from '../../styles/Header'
import {Button as Btn} from '../common/buttons/ButtonCommon'
import {Dropdown} from '../common/buttons/DropdownButton'
import {Input} from '../common/Input'
import {connect} from "react-redux";
import {IconSearch} from "../common/IconsSVG";
import Moment from "react-moment";
import LocationMenu from "../LocationMenu";
import { dateFormat } from '../../config/date';


const SectionSearch = ({
                           cancelSearch,
                           onSave,
                           warning_min_length,
                           loading,
                           isSearch,
                           onSaveAs,
                           uploaded_file: isUploadedFile,
                           onChangeSubSearch,
                           search_data,
                           onSubSearch,
                           onSearch,
                           onSearchKeyUp,
                           onPaste,
                           screenshotIsInProgress,
                           searchInputHasData,
                           file_created_at: FileCreatedAt,
                           saveFileMessage
                       }) => {

    return (
        <SectionSearchStyled>
            <div className='d-flex flex-wrap row align-items-center justify-content-around my-2'>
                <Input readOnly={!isSearch || loading}
                       fontSize={'1.2em'}
                       fontWeight={700}
                       className={'input-search flex-grow-1 mr-3 my-1 '}
                       icon={<IconSearch/>}
                       colorIcon={'#1A0DAB'}
                       placeholder="Enter a search term"
                       defaultValue={search_data.main || ''}
                       onKeyUp={onSearchKeyUp}
                       onPaste={onPaste}
                       loading={loading}
                       cancel={cancelSearch}
                 />

                <LocationMenu hideClearLocation  className={'d-flex flex-wrap align-items-center justify-content-center'}  resultsPage={isSearch? '0' : '1'} dashboardPage={false} />
            </div>
            {!isSearch ? (
                    <div className='d-flex flex-grow-1 flex-wrap row align-items-center my-2'>
                        <Input className={'input-search flex-grow-1   mr-3 my-1 '}
                               iconAppend={<IconSearch/>} onClickAppend={onSubSearch}
                               placeholder="Search within results" defaultValue={search_data.sub}
                               onKeyUp={onChangeSubSearch}/>
                        {
                            warning_min_length ?
                                <div className="subsearch-warning">
                                    {warning_min_length}
                                </div>
                                :
                                null
                        }
                        <div className={'date-saved mr-2 d-flex flex-grow-1 align-items-center'}>
                            <span>  Date Saved: </span>
                            {FileCreatedAt !== undefined ?
                                <span><b> <Moment date={FileCreatedAt} format={dateFormat}/> </b></span>
                                :
                                <span><b> Not Saved </b></span>
                            }
                        </div>
                        {!screenshotIsInProgress &&
                        <div className='d-flex flex-wrap align-items-center'>
                        {isUploadedFile  ? <Dropdown onClick={onSave} items={[{text: 'Save As', onClick: onSaveAs}]} icon={
                            <img alt={''}
                                 src={require('../../images/icons/cloud-upload-alt.svg').default}/>} color={'#fff'}
                                                    backgroundColor={'#329844'} borderColor={'#329844'}
                                                    className='mx-2 py-2 px-2'
                                                    fontWeight={500}>
                            Save
                        </Dropdown> : <Btn onClick={onSaveAs} icon={
                            <img alt={''}
                                 src={require('../../images/icons/cloud-upload-alt.svg').default}/>} color={'#fff'}
                                           backgroundColor={'#329844'} borderColor={'#329844'}
                                           className='my-2 mx-2 py-2 px-3'
                                           fontWeight={500}>
                            Save as
                        </Btn>}
                        {saveFileMessage && 
                            <span className="save-warning">{saveFileMessage}</span>
                        }
                        <Btn color={'#fff'}
                             onClick={cancelSearch}
                             backgroundColor={'#D0021B'}
                             borderColor={'#D0021B'}
                             className='my-2 mx-2 py-2 px-3'
                             fontWeight={500}>
                            Clear Search
                        </Btn>
                        </div> }
                    </div>)
                : (<div className='d-flex flex-wrap row align-items-center justify-content-end'>
                    <Btn onClick={onSearch} color={searchInputHasData? '#fff' :  '#329844' }
                         backgroundColor={searchInputHasData? '#329844' : '#fff' }
                         borderColor={'#329844'}
                         className='my-2 py-2 px-3'
                         disabled={loading}
                         fontWeight={500}>
                        Search
                    </Btn>
                </div>)}
        </SectionSearchStyled>
    )
}
const mapStateToProps = (state) => {
    return {
        uploaded_file: state.uploaded_file,
        file_created_at: state.created_at
    }
}
export default connect(mapStateToProps)(SectionSearch)
