import React from 'react';
import {Modal, ModalBody, Button, Alert} from 'reactstrap'
import styled from 'styled-components'

export default class Error extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {alert, confirmAlert, color = 'success'} = this.props
        return alert && (
            <AlertStyled color={color} onClick={confirmAlert}>
                <div className="body">
                    <p> {alert} </p>
                </div>
            </AlertStyled>
        );
    }
}
const AlertStyled = styled(Alert)`
  p {
    text-align: center;
    line-height: 1.4em;
    font-size: 1.2em;
    font-weight: 400;
    margin: .5rem 0;
    padding: 0;
  }

`