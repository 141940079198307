import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {faSortUp} from '@fortawesome/free-solid-svg-icons'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {faQuestion} from '@fortawesome/free-solid-svg-icons'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import store from './store'
import {Provider} from 'react-redux'
import styled, {ThemeProvider} from 'styled-components';
import theme from 'styled-theming';
library.add(faCheck, faSortUp, faTimes, faQuestion)


ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>,
	document.getElementById('root')
)

registerServiceWorker()
