import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Collapse, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import DatePicker from "react-datepicker";
import moment from 'moment'
import Moment from 'react-moment'
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FilesNavigation from "../FilesNavigation";
import AutomatedSearch from '../Schedule/Form/AutomatedSearch'
import fetchClient from "../../helpers/fetch-client";
import CheckFilled from "../../images/check_filled.svg"
import Loading from '../common/Loading'
import Error from "../Schedule/Error"
import {ScheduleStyled} from "../../styles/ScheduleControl";
import {CheckboxLightbox as Checkbox} from '../common/Checkbox'
import { Button} from '../common/buttons/ButtonCommon'
import {IconCalendar, IconCalendarDay} from "../common/IconsSVG";
import SwitchEmailReport from '../emailReport/Switch'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import {styles} from '../../styles/variables'
import { dateFormat } from '../../config/date'

const OptionCheckbox = ['daily', 'weekly', 'monthly']
const defaultValues = {
    currentOptionCheck: 0,
    startDate: new Date(moment()),
    automated_pages: 1,
    endDate: null,
    isOpenModal: false,
    loading: false,
    saved: false,
    message: '',
    message_type: '',
    path: '/',
    error: ''
}
const formatDate = "YYYY-MM-DD HH:mm";
const formatDateSchedule = `${dateFormat} , hh:mm A`;
class ScheduleControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            ...defaultValues
        }
        this.save = this.save.bind(this)
        this.changeCurrentPath = this.changeCurrentPath.bind(this)
        this.handleReset = this.handleReset.bind(this);
        this.setAutomatedPages = this.setAutomatedPages.bind(this)

    }

    setAutomatedPages = (automated_pages = 1) => this.setState({automated_pages})

    changeCurrentPath(path) {
        this.setState({
            path
        })
    }

    handleReset() {
        this.setState({...defaultValues})
    }

    async save() {

        const {search: {main: term, loc_id}} = this.props.state;
        const params = {
            location: loc_id || 2840,
            file_path: this.state.path,
            term,
            automated_pages: this.state.automated_pages,
            period: OptionCheckbox[this.state.currentOptionCheck],
            start_date: moment(this.state.startDate).format(formatDate),
            end_date: this.state.endDate ? moment(this.state.endDate).format(formatDate) : null,
        }
        if (!params.end_date) delete params['end_date']
        this.setState({
            loading: true
        })
        try {
            const {
                status,
                data
            } = await fetchClient().post(process.env.REACT_APP_API_ENDPOINT + "/searchschedule", JSON.stringify(params))
            switch (status) {
                case 200 :
                    this.setState({
                        message: data.message,
                        message_type: 'info',
                        saved: true,
                        isOpenModal: false,
                        loading: false
                    })
                    break
                case 400 :
                    this.setState({
                        error: 'An error occur saving the file, please retry.',
                        saved: false,
                        isOpenModal: false,
                        loading: false
                    })
                    break
                default :
                    this.setState({
                        message: 'An error occur saving the file, please retry.',
                        message_type: 'error',
                        saved: false
                    })
                    break
            }
        } catch (err) {
            this.setState({
                error: err.response && err.response.data
                && err.response.data.errors &&
                Object.keys(err.response.data.errors)[0] ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'An error occur saving the file, please retry.',
                loading: false
            })
        }
    }

    confirmError = () => this.setState({error: ''})

    render() {
        return (
            <ScheduleStyled>
                <div className="header">
                    {this.state.showContent}
                    <span
                        className="toggle"
                        onClick={() => this.setState({showContent: !this.state.showContent})}>
                     <FontAwesomeIcon className="icon-toggle" icon={!this.state.showContent ? faPlus : faMinus} color={styles.primaryColor}/>Set Search Schedule</span>
                </div>
                <Collapse isOpen={this.state.showContent}>
                    <div className="content">
                        <div className={'flex-grow-1'}>
                            <span className={'title-section'}> Repeat </span>
                            <div className="options-checkbox">
                                {OptionCheckbox.map((item, i) => (
                                    <div className="option" key={i}>
                                        <Checkbox label={item} id={'item-checkbox' + i}
                                                  onChange={() => this.setState({currentOptionCheck: i})}
                                                  checked={i === this.state.currentOptionCheck}/>
                                    </div>))}
                            </div>
                        </div>
                        {this.state.currentOptionCheck !== null &&
                        <React.Fragment>
                            <div className="range-date">
                                <div className="date container-section justify-content-center">
                                    <div className={'position-relative'}>
                                        <span className={'title-section'}> Start Date </span>
                                        <div className={'d-flex align-items-center flex-grow-1'}>
                                        <label htmlFor='startDate'>
                                            <Moment format={formatDateSchedule}>
                                                {this.state.startDate}
                                            </Moment>
                                        </label>
                                            <IconCalendar />
                                        </div>
                                        <DatePicker className="date-picker" id="startDate"
                                                    selected={this.state.startDate}
                                                    onChange={date => this.state.endDate && date > this.state.endDate ? null : this.setState({startDate: date})}
                                        />
                                    </div>
                                </div>
                                <div className="date container-section justify-content-center">
                                    <div className={'position-relative'}>
                                        <span className={'title-section'}> End Date </span>
                                        <div className={'d-flex align-items-center flex-grow-1'}>
                                        <label htmlFor='endDate'>
                                            {this.state.endDate ? <Moment format={formatDateSchedule}>
                                                {this.state.endDate}
                                            </Moment> : 'Select a date'}
                                        </label>
                                            <div>
                                        <div className='clear-date'>
                                            <FontAwesomeIcon
                                                className={`icon-clear mx-2 ${this.state.endDate ? 'active' : null}`}
                                                icon='times' color="#35A9E0"
                                                onClick={() => this.setState({endDate: null})}/></div>
                                        </div>
                                        <IconCalendarDay />
                                        </div>
                                        <DatePicker className="date-picker" id="endDate"
                                                    selected={this.state.endDate ? new Date(this.state.endDate) : new Date(moment().endOf('month'))}
                                                    onChange={date => date < this.state.startDate ? null : this.setState({endDate: date})}/>
                                    </div>

                                </div>
                            </div>
                            <div className="options container-section">
                                {/*      <SwitchEmailReport fontSize={'0.8125em'} classNameCheckbox={'my-0'} label={'Send Email Reports'} />   */}
                                {
                                    !this.state.saved ?
                                    <Button
                                    color={'#fff'}
                                    block
                                    backgroundColor={'#329844'} borderColor={'#329844'}
                                    className='my-2 mx-0 py-1 px-3 flex-grow-1 text-center'
                                    fontWeight={500}
                                    onClick={() => !this.state.saved && this.setState({isOpenModal: !this.state.isOpenModal})}>Save schedule</Button>
                                    :<div className="d-flex flex-row justify-content-between w-100">
                                    <div className="reset-button" onClick={() => {this.setState({saved: !this.state.saved})}}>Reset</div>
                                    <div>
                                    <img alt={''} style={{width: '1rem'}}src={CheckFilled}/>&nbsp;Saved
                                    </div>
                                    </div>  
                                }
                            </div>
                        </React.Fragment>}

                    </div>
                </Collapse>
                <Loading loading={this.state.loading}/>
                <Modal className='modal-schedule' isOpen={this.state.isOpenModal} centered
                       toggle={() => this.setState({isOpenModal: !this.state.isOpenModal, error: ''})}>
                    <ModalHeader
                        className=''
                        toggle={() => this.setState({isOpenModal: !this.state.isOpenModal, error: ''})}> Save
                        Schedule </ModalHeader>
                    <ModalBody>
                        <Error className='mb-4 mt-2' error={this.state.error} confirmError={this.confirmError}/>
                        <AutomatedSearch className="my-3 is-modal" value={this.state.automated_pages}
                                         setValue={this.setAutomatedPages}/>
                        <div className={'schedule-name'}>
                            <h4> Schedule name </h4>
                            <h5> {this.props.state && this.props.state.search.main}-<span
                                style={{textTransform: 'capitalize'}}>{OptionCheckbox[this.state.currentOptionCheck]}</span>-{moment(this.state.startDate).format('MMM-DD-yyyy')}{this.state.endDate && '-' + moment(this.state.endDate).format('MMM-DD-yyyy')}
                            </h5>
                        </div>
                        <FilesNavigation
                            intoModal
                            height="50vh"
                            changePath={this.changeCurrentPath}
                            fromSaveModal
                            finishRender={() => {
                            }}
                        />
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-end align-items-center'>
                        <Button type="button" className="btn btn-save-data" onClick={this.save}
                                disabled={this.state.loading} color="white">Save</Button>
                    </ModalFooter>
                </Modal>
            </ScheduleStyled>
        )
    }

}


const mapStateToProps = (state) => ({state})

export default connect(mapStateToProps)(ScheduleControl)
