import React from 'react'
import { OverrideConfirmation } from './OverrideConfirmation';


const CategoryConfirmation = ({ 
    confirmationMessage,
    setConfirmationMessage, 
    overrideAction,
    changeCategory,
    value,
    setSelectValue,
    previousValue,
    setPreviousValue
}) => {
    const handleSave = (event) => {
        setPreviousValue(value);
        overrideAction(event);
        setConfirmationMessage('Saved');
    };
    const handleCancel = () => {
        setSelectValue(previousValue);
        changeCategory(previousValue);
        setConfirmationMessage('Cancelled');
        setTimeout(() => setConfirmationMessage('Hidden') , 800);
    };

    return (
        <OverrideConfirmation
            type="category"  
            confirmationMessage={confirmationMessage} 
            handleSave={handleSave}
            handleCancel={handleCancel}
        />
    ); 
}

export default CategoryConfirmation;