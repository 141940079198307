import styled from 'styled-components'
import { styles } from './variables'


export const SEOHeaderStyle = styled.div`
    margin: .7rem 0 .7rem .5rem;
    h3 {
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 125%;
        color: #272833;
    }

    .flex-wrapper {
        display: flex;
        align-items: center;
    }
    
    .score {
        color: ${props => props.scoreColor ? props.scoreColor : styles.gray};
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 38px;
    }

    .separator {
        border: 1px solid #D4D4D4;
        margin: 0 1rem;
        height: 40px;
    }

    .flex-column {
        flex-direction: column;
        justify-content: space-between;
    }
    
    .url {
        font-family: ${styles.fontSecundary};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: #21374A;
        margin: 0;
        margin-top: 10px;
    }

    .title {
        font-family: ${styles.fontSecundary};
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #1A0DAB;
        margin: 0;
    }
`

export const SEOCloseButtonStyle = styled.div`
    margin: 0 10px;

    .btn {
        padding: 10px 15px;
        background: #0786CD;
        border-radius: 5px;
        /* Text */
        font-family: ${styles.fontRoboto};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: ${styles.whiteBackground};
    }

    .cross {
        font-size: 16px;
        line-height: 16px;
        margin-right: .3rem;
        justify-content: center;
        img {
            margin-bottom: .1rem;
            width: 10px;
            height: 16px;
        }
    }
`

export const SEOBodyStyle = styled.div`
    display: flex;
    height: auto;
    width: 100%;
    .seo-table {
        background-color: ${styles.whiteBackground};
        width: 100%;
    }

    .table-header {
        display: flex;
        width: 100%;
        height: 43px;
        box-shadow: 0px 1px 0px #CFE1EA;
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.185em;
    }

    .seo-metric-header {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 38%;
        padding-left: 5rem;
    }
    .analysis-header {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 38%;
        padding-left: 1.5rem;
    }
    .override-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24%;
    }


    .seo-table-row {
        display: flex;
        height: 64px;
        box-shadow: 0px 1px 0px #CFE1EA;
    }
    .metric-body {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        width: 38%;
        padding-left: 3.2rem;
        span {
            font-family: ${styles.fontPrimary};
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #0786CD;
            text-transform: capitalize;
        }
        
        
    }
    .analysis-body {
        align-items: center;
        display: flex;
        width: 38%;
        padding-left: .3rem;
        .analysis-label {
            padding: 5px 20px;
            border-radius: 50px;
            font-family: ${styles.fontPrimary};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            color: ${styles.whiteBackground}
        }
    }
    .override-body {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 24%;
    }

    .load-speed-link {
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #0786CD;
        opacity: 0.7;
        margin-left: -.3rem;
    }

    .metric-tooltip {
        font-size: 12px;
        background-color: rgba(0,0,255,0.2);
    }

    [data-tooltip] {
        position: relative;
    }

    [data-tooltip]:hover::before {
        content: attr(data-tooltip);
        position: absolute;
        width: 100%;
        background-color: rgba(0,0,255,0.2);
        color: orange;
        padding: .25rem;
        top: -.5rem;
        left: 50%;
        transform: translate(-50%, -100%);
    }

    .seo-warnings {
        display: flex;
        flex-direction: column;
        background-color: #F4F8FA;
        width:30%;
        overflow-x: hidden;
        max-height: 42.69rem;
        white-space: pre-line;
    }
    
    .warnings-header {
        display: flex;
        align-items: center;
        padding: .2rem 0 0 1.5rem;
        width: 100%;
        height: 43px;
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.185em;
        color: #D0021B;
    }

    .warnings-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        overflow-y: scroll
    }

    .warning-item-box {
        display: flex;
        background-color: ${styles.whiteBackground};
        width: 90%;
        align-items: center;
        margin-top: .2rem;
        margin-bottom: .5rem;
        margin-left: .5rem;
    }

    .warning-icon-box {
        width: 17%;
        display: flex;
        justify-content: center;
        align-self: start;
        img {
            margin: 0 auto;
            margin-top: 0.7rem;
            height: 16px;
            width: 16px;
        }
    }

    .warning-content {
        width: 80%;
        display:flex;
        flex-direction: column;
        align-items: space-around;
        padding: .2rem 0;
    }

    .warning-item-title {
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        color: #D0021B;
        padding-top: .5rem;
    }

    .warning-item-description {
        font-family: ${styles.fontPrimary};
        font-style: normal;
        font-weight: normal;
        font-size: .8rem;
        margin-top: -.5rem;
    }

    .seo-spinner {
        margin: 1.5rem auto;
    }

    .seo-icon{
        align-self:center;
        padding-left: 1.8rem;
        width: 21px;
    }
`