import React from 'react'
import styled from 'styled-components'
import RowTable from './RowTable'
import {Button, Table,} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CheckboxLightbox} from '../../common/Checkbox'
import {Link} from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TitleHeader = [
    '',
    'Schedule Name',
    'Location',
    'Frequency',
    'Date range',
    'Created',
    'Options']
export default class TableSchedule extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {sort, schedules, openModal, deleteSchedule, onChangeReportsEmail, isReportsEmail} = this.props;
        return (
            <TableWrapper>
                <h1 className="title"> Scheduled Searches</h1>
                <div className='header-table'>
                    <Link to="/schedule/batch">
                        <Button className='btn-add mr-3'>
                            <FontAwesomeIcon className="icon-toggle mr-2" icon={faPlus} color="#35A9E0"/> Add Batch
                            Csv Schedule
                        </Button>
                    </Link>
                    <Button className='btn-add' onClick={() => openModal()}>
                        <FontAwesomeIcon className="icon-toggle mr-2" icon={faPlus}
                                         color="#35A9E0"/>
                        Add Schedule </Button>
                </div>
                <div style={{display:'none'}} >
                  <div className='d-flex flex-grow-1 align-items-center justify-content-start container-reports'>
                      <CheckboxLightbox id={'reports-email'} checked={isReportsEmail}
                                        onChange={onChangeReportsEmail}
                                        label={'Enable sending Schedule reports Email'}/>
                  </div>
                </div>
                <div style={{overflowX: "auto", marginRight:"-2.5%", marginLeft:"-2.5%"}}>

                    <TableStyle hover>
                        <thead>
                        <tr>
                            {TitleHeader.map((item, i) =>
                                <th key={i} /*onClick={() => sort(item)} */>
                                    {item}
                                </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {schedules.map((item, i) => <RowTable key={i} openModal={openModal}
                                                              deleteSchedule={deleteSchedule} item={item}/>)}
                        </tbody>
                    </TableStyle>
                </div>
            </TableWrapper>
        );
    }
}

const TableWrapper = styled.div`
      border: solid 1px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background-color: #fff;
      color: #5b666f;
      .btn {
        font-size: 1em;
      }
      .title {
        font-weight: 600;
        font-size: 1.5em;
        color: #4F4F4F;
        margin: 0;
        padding: 1.2rem;
        font-weight: 700;
        text-align: start;
        flex: 1;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      }
      .container-reports {
        padding: calc(2% + .5rem) 1.2rem;
        padding-top: 0;
      }
      .header-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(2% + .5rem) 1.2rem;
        .btn-add {
      color: #5b666f !important;
      font-weight: 700;
      background-color: transparent !important;
      outline: none !important;
      border: solid 0.0625rem #D4E2EB !important;
      box-shadow: 0 0 0.0625rem #D4E2EB;

      &:hover {
        opacity: .8;
      }
    }
        }

  .title {
    font-weight: 600;
    font-size: 1.5em;
    color: #4F4F4F;
    margin: 0;
    padding: 1.2rem;
    font-weight: 700;
    text-align: start;
    flex: 1;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
    `
const TableStyle = styled(Table)`
      overflow-x: scroll;
      color: #5b666f;
       th, td {
        line-height: 1em;
        color: #5b666f !important;
        text-align: start !important;
        vertical-align: middle;
        max-width: 10rem;
        padding: .4rem;
        &:first-child {
          padding-left: 1.2rem;
        }
        line-height: 1.3em;
      }
      .text-capitalize {
        text-transform: capitalize;
      } thead {
        background-color: #F9F9F9;

        th {
          padding: 1.3rem .5rem;
          font-weight: 700;
          color: #4F4F4F;
          font-size: 0.9375em;
        }
      }
    `
