import React, { useState, useEffect, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { categories } from '../config/categories'
import { getCategory } from '../helpers/select-constants'
import { StatBox } from "./statsBoxes/StatBox";
import { styles } from '../styles/variables'
import { CategoryDistributionStyled } from "../styles/StatBox";
import CategoryDoughnutLegend from './statsBoxes/CategoryDoughnutLegend'
import '../css/Tooltip.css'

const options = {
    cutoutPercentage: 65,
    options: {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        }
    },
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        onClick: (e) => e.stopPropagation(),
        display: false
    },
    tooltips: {
        fullWidth: true,
        fontSize: 10,
        titleFontSize: 10,
        bodyFontSize: 10,
        titleFontColor: styles.textColor,
        bodyFontColor: styles.textColor,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: styles.gray,
        boxShadow: '0 0 10px gray',
        mode: 'label',
        filter: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];

            //Hide "Other" tooltip
            if (typeof label == 'object') return false;

            return true;
        },
        callbacks: {
            title: (tooltipItem, data) => {
                //Hide "Other" tooltip
                if (tooltipItem.length === 0) return false;

                return [data.controlled[tooltipItem[0]?.index], data.competitive[tooltipItem[0]?.index]];
            },
        }
    }
}

const chart_data = {
    data_pie: {
        labels: Object.entries(categories).map(([key, value]) => {
            return `${value.initialValue}% ${value.name}`
        }),
        controlled: Object.keys(categories).map(() => '0% controlled'),
        competitive: Object.keys(categories).map(() => '0% competitive'),
        controlledPercentage: 0,
        competitivePercentage: 0,
        datasets: [{
            data: Object.values(categories).map((value) => value.initialValue),
            backgroundColor: Object.values(categories).map((value) => value.color),
            borderWidth: 0,
            hoverBackgroundColor: Object.values(categories).map((value) => value.color)
        }]
    }
}

const CategoryDistributionBox = (props) => {
    const [screenWidth, setScreenWidth] = useState(null)
    const [shouldRedraw, setShouldRedraw] = useState(false)
    const [size, setSize] = useState({ width: 210, height: 100 })
    const [legend, setLegend] = useState([])
    const doughnutRef = useRef(null)

    useEffect(() => {
        setLegend({ legend: doughnutRef.current.chartInstance.legend.legendItems })
        setScreenWidth(window.innerWidth)
        let resizeTimer;
        const setRedraw = () => {
            if (screenWidth !== window.innerWidth) {
                setShouldRedraw(true)
            }
        }
        const waitResize = () => {
            clearTimeout(resizeTimer)
            if (size.width !== document.getElementById('category-box').offsetWidth - 32 ||
                size.height !== document.getElementById('category-box').offsetHeight - 64) {
                setSize({
                    width: document.getElementById('category-box').offsetWidth - 32,
                    height: document.getElementById('category-box').offsetHeight - 80
                });
                resizeTimer = setTimeout(setRedraw, 500)
            }
        }
        window.addEventListener('resize', waitResize, false)
        return () => {
            window.removeEventListener('resize', waitResize, false)
        }
    }, [])

    useEffect(() => {
        setLegend({ legend: doughnutRef.current.chartInstance.legend.legendItems })
    }, [props.ranking])

    const recalculateDataChart = () => {
        const initialCategories = {}

        for (const key in categories)
            initialCategories[key] = 0
        const category = { ...initialCategories },
            controlledAmount = { ...initialCategories },
            competitiveAmount = { ...initialCategories }

        const CATEGORY_TOTAL_ROWS = 10

        for (let i = 0; i < CATEGORY_TOTAL_ROWS; i++) {

            const id = props.ranking.order[i].id
            const value = props.ranking?.data?.[id]
            if (value) {
                const isTopStory = value.resume.length > 1
                const controlled = value.controlled
                const competitive = value.competitive
                const validCategory = getCategory(value.category)

                category[validCategory] += 1
                if (!isTopStory && controlled) controlledAmount[validCategory] += 1
                if (!isTopStory && competitive) competitiveAmount[validCategory] += 1
            }
        }

        const sortedCategories = {}
        const otherSortedCategories = {}
        const sortedCategoriesAux = Object.entries(category).sort(([aKey, aValue], [bKey, bValue]) => bValue - aValue)

        const firstCategories = sortedCategoriesAux.slice(0, 5)
        const otherCategories = sortedCategoriesAux.slice(5)


        for (let i = 0; i < 5; i++) {
            if (firstCategories[i][1] > 0)
                sortedCategories[firstCategories[i][0]] = firstCategories[i][1];
        }


        for (let i = 0; i < Object.keys(otherCategories).length; i++) {

            if (otherCategories[i][1] > 0) {

                if (Object.keys(sortedCategories).length == 5) {

                    sortedCategories[otherCategories[i][0]] = otherCategories[i][1];
                }
                else if (Object.keys(sortedCategories).length == 6) {
                    if ('other' in sortedCategories) {
                        sortedCategories['other']++;
                        otherSortedCategories[otherCategories[i][0]] = otherCategories[i][1];
                    }
                    else {

                        let key = Object.keys(sortedCategories)[0]
                        let value = sortedCategories[key]
                        delete sortedCategories[key]
                        otherSortedCategories[key] = value;
                        otherSortedCategories[otherCategories[i][0]] = otherCategories[i][1];
                        sortedCategories['other'] = value + otherCategories[i][1];
                    }
                }
            }
        }

        const labelsData = [];

        Object.entries(sortedCategories).forEach(([key, value]) => {

            if (value.inactive) {
                if (sortedCategories[key]) {
                    labelsData.push((sortedCategories[key] * 100) / CATEGORY_TOTAL_ROWS + `% ${categories[key].name}`);
                } else {
                    delete sortedCategories[key]
                    delete controlledAmount[key]
                    delete competitiveAmount[key]
                    chart_data.data_pie.labels = chart_data.data_pie.labels.filter((value, index, arr) => {
                        return value != `0% ${value.name}`;
                    });
                    chart_data.data_pie.datasets[0].backgroundColor = chart_data.data_pie.datasets[0].backgroundColor.filter((value, index, arr) => {
                        return value != '#5b81dc';
                    });
                    chart_data.data_pie.datasets[0].hoverBackgroundColor = chart_data.data_pie.datasets[0].hoverBackgroundColor.filter((value, index, arr) => {
                        return value != '#5b81dc';
                    });
                }
            } else {
                if (key === 'other') {
                    const labels = otherCategories
                        .map(([key, value]) => {
                            return (otherSortedCategories[key] * 100) / CATEGORY_TOTAL_ROWS + `% ${categories[key]?.name ?? 'Other'}`
                        })
                        .filter(label => !label.startsWith('0%'))
                    labelsData.push(labels)

                } else {
                    labelsData.push((value * 100) / CATEGORY_TOTAL_ROWS + `% ${categories?.[key]?.name}`);
                }
            }
        })

        const controlledLabels = Object.keys(sortedCategories).map((key) => {
            if (key === "other") {
                return 'Other'
            } else {
                const percent = !controlledAmount[key] || !category[key] ? 0 : Math.round((+controlledAmount[key] * 100) / +category[key])
                return (+percent + '% Controlled ') + (100 - percent + '% Uncontrolled');
            }
        })
        const competitiveLabels = Object.keys(sortedCategories).map((key) => {
            if (key === "other") {
                return 'Other'
            } else {
                const percent = Math.round((+competitiveAmount[key] * 100) / +sortedCategories[key])
                return (+percent + '% Competitive ') + (100 - percent + '% UnCompetitive');
            }

        })

        const controlledTotal = Object.values(controlledAmount).reduce((a, b) => a + b, 0)
        const competitiveTotal = Object.values(competitiveAmount).reduce((a, b) => a + b, 0)
        const controlledPercentage = (controlledTotal * 100) / CATEGORY_TOTAL_ROWS;
        const competitivePercentage = (competitiveTotal * 100) / CATEGORY_TOTAL_ROWS;

        chart_data.data_pie.datasets[0].data = Object.values(sortedCategories)
        chart_data.data_pie.datasets[0].backgroundColor = Object.keys(sortedCategories).map((key) => (categories[key] && categories[key].color) || "#CD4A00")
        chart_data.data_pie.datasets[0].hoverBackgroundColor = Object.keys(sortedCategories).map((key) => (categories[key] && categories[key].color) || "#CD4A00")
        chart_data.data_pie.labels = labelsData
        chart_data.data_pie.title = Object.keys(sortedCategories).map(key => categories[key] && categories[key].name || 'Other');
        chart_data.data_pie.controlled = controlledLabels
        chart_data.data_pie.competitive = competitiveLabels
        chart_data.data_pie.controlledPercentage = controlledPercentage
        chart_data.data_pie.competitivePercentage = competitivePercentage
        return chart_data.data_pie
    }

    return (
        <StatBox
            id={'category-box'}
            classNameContainer={'d-flex flex-column align-items-center'}
            title='Category Distribution'
            {...props}
        >
            <div style={{ marginTop: '.5rem' }}>
                <Doughnut
                    ref={doughnutRef}
                    {...size}
                    data={recalculateDataChart}
                    options={options}
                    redraw={shouldRedraw}
                />
            </div>
            <CategoryDistributionStyled>
                <CategoryDoughnutLegend
                    data={legend?.legend}
                    competitive={Math.round(chart_data.data_pie.competitivePercentage)}
                    controlled={Math.round(chart_data.data_pie.controlledPercentage)}
                />
            </CategoryDistributionStyled>
        </StatBox>
    )
}

const mapStateToProps = state => {
    return {
        ranking: state.ranking,
        tooltips: state.tooltips.filter(tooltip => tooltip.code === '018')
    }
}
export default connect(mapStateToProps, null)(CategoryDistributionBox)