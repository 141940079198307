import React from 'react';
import Logo from '../../images/logos/logo.svg'
import {NavLink, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import {setUserLoggedInfo, logOut, clearSearch} from "../../actionCreators";
import axios from "axios";
import {SidebarStyle} from '../../styles/Sidebar'
import {IconBarChart, IconCalendarCheck, IconExchange, IconHistory, IconSearch} from "../common/IconsSVG";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import logoPath from '../../helpers/logo-path';

const Sidebar = (props) => {
    const auth_info = () => JSON.parse(localStorage.getItem('token_info'))
    const headers = () => ({
        headers: {
            'Content-Type': 'application/json',
            'Authorization':  auth_info() && auth_info().access_token ? `${auth_info().token_type} ${auth_info().access_token}` : ''
        }
    })

    const logout = () => {
       props.logout(headers())
    }

    const onClickNewSearch = () => {
        props.clearSearch();
    }

    React.useEffect(()=> {
       if(!props.user_logged.email) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/user', headers())
                .then(response => {
                    props.setUserLoggedInfo(response.data)
                }).catch( () => logout())
        }
    },[])
    
    const logo_path=logoPath(props.user_logged);
    return (
        <SidebarStyle {...props}>
            <div className={'d-flex flex-column justify-content-between'} style={{height: '100%'}}>
                <div>
            <div className={'header'}>
                <div className={'logo'}>
                    <Link to="/results">
                        {
                            logo_path?
                            <img alt={'user_logo'} className='logo-header' src={logo_path}/>
                            :
                            <img alt={''} className="logo-header"src={Logo}/>
                        }
                    </Link>
                </div>
            </div>
            <li className={'menu'}>
                <ul className='search'>
                    <NavLink 
                    exact 
                    activeClassName="active" 
                    to={!props.search.main? "/search" : '/results'}
                    onClick={onClickNewSearch}
                    > 
                    <IconSearch className={'icon'} /> New Search 
                    </NavLink>
                </ul>
                <ul className={'border-bottom'}>
                    <NavLink exact activeClassName="active" to="/dashboard"> <IconBarChart className={'icon'} />  Historical Data </NavLink>
                </ul>
                <ul className={'border-bottom'}>
                    <NavLink exact activeClassName="active" to="/my-files"> <IconHistory className={'icon'} />  Saved Searches </NavLink>
                </ul>
                <ul className={'border-bottom'}>
                    <NavLink exact activeClassName="active" to="/schedule"> <IconCalendarCheck  className={'icon'}/>  Scheduled Searches </NavLink>
                </ul>
                <ul>
                    <NavLink exact activeClassName="active" to="/overrides"><IconExchange  className={'icon'} />  Overrides </NavLink>
                </ul>
            </li>
                </div>
                <div className='footer-sidebar'>
                    <Link to="/profile"> <div className={'d-flex flex-grow-1 flex-column align-items-center justify-content-center'}>
                    <div className={'avatar'}>
                        {props.user_logged && props.user_logged.name &&  props.user_logged.name[0]  }
                    </div>
                        <div className={'name'}>
                            {props.user_logged && props.user_logged.name && props.user_logged.name  }
                        </div>
                    </div>  </Link>
                   <a className={'signOut'} onClick={logout}>Log out</a>
                </div>
            </div>
        </SidebarStyle>
    )
}

const mapStateToProps = (state) => {
    return {
        user_logged: state.user_logged,
        search: state.search? state.search : {main: '', sub: ''},
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setUserLoggedInfo: (val) => {
            dispatch(setUserLoggedInfo(val))
        },
        logout: (headers) => {
            dispatch(logOut())
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/logout', {}, headers)
        },
        clearSearch: () => {
            dispatch(clearSearch())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
