import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import FilesNavigation from '../components/FilesNavigation'
import Spinner from './Spinner'
import './../css/Login.css'
import './../css/IosModal.css'
import { connect } from 'react-redux'

class MoveFilesModal extends Component {
  constructor() {
    super()
    this._isMounted = false
    this.state = {
      new_path: "/",
      message:"",
      loading:true
    }
    this.changeCurrentPath = this.changeCurrentPath.bind(this)
    this.move = this.move.bind(this)
    this.resetAndclose = this.resetAndclose.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  changeCurrentPath(new_path) {
    this._isMounted && this.setState({
      new_path: new_path
    })
  }

  move(){
    this.setState({loading:true})
    let paths = this.props.filesToMove[0]
    for (var i = 1; i < this.props.filesToMove.length; i++) {
      paths += "," + this.props.filesToMove[i]
    }

    let params = {
      path: paths, 
      new_path: this.state.new_path,
      action:'move'
    }
    
    this.props.executeAction('move', params)
    this.setState({loading:false})
    this.resetAndclose()
  }

  resetAndclose() {
    this._isMounted && this.setState({ message: "", message_type:"", loading: true, finish: false, new_path: "/" })
    this.props.close()
  }

  render() {
    
    return (
        <Modal isOpen={this.props.open} className="files-modal">
          <ModalHeader>
              Destination Folder
              <button type="button" className="btn btn-secondary ios-close-button" onClick={this.resetAndclose}>
                <svg aria-hidden="true" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" color="black"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
              </button>
          </ModalHeader>
          <ModalBody>
            { this.state.message !== "" ?
                <div className={"alert fade show " } role="alert"> { this.state.message } </div>  
                : null
            }
            { !this.state.finish ?
              <React.Fragment>
                <div>
                  <FilesNavigation 
                    intoModal={true}
                    changePath={this.changeCurrentPath}
                    selectedItems={this.props.selectedItems}
                    fromMoveModal={true}
                    finishRender={()=>this.setState({loading:false})}
                    filesToMove={this.props.filesToMove}
                  />
                </div>
          
                { 
                  !this.state.loading ? 
                    <Button type="button" className="btn btn-secondary" onClick={()=>this.move()}>Move</Button>
                  : null
                }
              </React.Fragment>
              : null
            }
          </ModalBody>
        </Modal>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    state
  }
}

export default connect(mapStateToProps, null)(MoveFilesModal)
