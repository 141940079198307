import React from 'react';
import Schedule from '../components/Schedule'
import fetchClient from "../helpers/fetch-client";
import moment from "moment";
import {pageView} from '../helpers/ga-page-view'
import {connect} from 'react-redux'
import {setEmailsReport as setReports} from "../actionCreators";
import {Layout} from "../components/Layout/Default";

const {REACT_APP_API_ENDPOINT: baseEndpoint} = process.env;

const endpoints = (page, pageSize) => ({
    get: `${baseEndpoint}/searchschedule?pagesize=${pageSize}&page=${page}`,
    post: `${baseEndpoint}/searchschedule`,
    put: `${baseEndpoint}/searchschedule/`,
    delete: `${baseEndpoint}/searchschedule/`
})
const formatDate = "YYYY-MM-DD HH:mm";

class SchedulePage extends React.Component {
    constructor(props) {
        super(props);
        const auth_info = JSON.parse(localStorage.getItem('token_info'))
        this.state = {
            schedules: [],
            loading: false,
            isOpenModal: false,
            pageSize: 10,
            page: 1,
            last_page: 1,
            error: '',
            alert: '',
            next: false,
            prev: false,
            contentEdit: null,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
            }
        }

        this.editSchedule = this.editSchedule.bind(this)
        this.createSchedule = this.createSchedule.bind(this)
        this.deleteSchedule = this.deleteSchedule.bind(this)
        this.openModal = this.openModal.bind(this)
        this.getSchedules = this.getSchedules.bind(this)
        this.toggle = this.toggle.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.prevPage = this.prevPage.bind(this)
        this.confirmError = this.confirmError.bind(this)
        this.confirmAlert = this.confirmAlert.bind(this)
        this.onChangeReportsEmail = this.onChangeReportsEmail.bind(this)
    }

    onChangeReportsEmail = async (value) => {
        if (!this.state.loading) {
            const payload = {
                optin_report_email: +!this.props.isReportsEmail
            }
            this.setState({loading: true})
            const response = await fetchClient().post(process.env.REACT_APP_API_ENDPOINT + '/user-settings', payload)
            this.props.setEmailsReport(!this.props.isReportsEmail)
            this.setState({loading: false})

        }
    }

    getSchedules = (page = this.state.page) => {
        if (!this.state.loading) {
            this.setState({loading: true})
            fetchClient().get(endpoints(page, this.state.pageSize)['get'], this.state.headers).then(
                ({data: {data: {next_page_url, prev_page_url, data, last_page}}}) =>
                    this.setState({
                        schedules: data,
                        loading: false,
                        next: !!next_page_url,
                        prev: !!prev_page_url,
                        last_page
                    })
            ).catch(err => {
                this.setState({
                    loading: false,
                    error: err.response && err.response.data
                    && err.response.data.errors &&
                    Object.keys(err.response.data.errors)[0] ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'error'
                })
            })
        }
    }
    nextPage = () => {
        if (!this.state.loading && this.state.next)
            this.setState({page: this.state.page + 1}, () => this.getSchedules())

    }
    prevPage = () => {
        if (!this.state.loading && this.state.prev)
            this.setState({page: this.state.page - 1}, () => this.getSchedules())
    }
    confirmError = () => this.setState({error: ''})
    confirmAlert = () => this.setState({alert: ''})

    componentDidMount() {
        pageView()
        this.getSchedules()
    }

    closeModal = () => this.setState({isOpenModal: false, error: ''})

    openModal = (content) =>
        this.setState({isOpenModal: true, contentEdit: content || null})

    toggle = () => this.setState({isOpenModal: !this.state.isOpenModal, error: ''})

    editSchedule = async (content) => {
        if (!this.state.loading) {
            this.setState({loading: true})
            const {location, start_date, end_date, id, ...rest} = content
            const payload = {
                ...rest,
                location: +location || 2840,
                start_date: moment(start_date).format(formatDate),
                end_date: end_date ? moment(end_date).format(formatDate) : null
            }
            try {
                const {data, status} = await fetchClient().put(endpoints()['put'] + id, JSON.stringify(payload))
                switch (status) {
                    case 200 :
                        this.setState({
                            loading: false,
                            alert: 'The schedule has been edited successfully',
                            isOpenModal: false,
                            error: ''
                        }, () => this.getSchedules());
                        break;
                    default:
                        this.setState({loading: false, error: 'Error'})
                        break
                }

            } catch (err) {
                this.setState({
                    loading: false,
                    error: err.response && err.response.data
                    && err.response.data.errors &&
                    Object.keys(err.response.data.errors)[0] ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'error'
                })
            }
        }
    }

    createSchedule = async (content) => {
        if (!this.state.loading) {
            this.setState({loading: true})
            const {location, start_date, end_date, ...rest} = content

            const payload = {
                ...rest,
                location: +location || 2840,
                start_date: moment(start_date).format(formatDate),
                end_date: end_date ? moment(end_date).format(formatDate) : null
            }
            if (!payload.end_date) {
                delete payload['end_date']
            }
            try {
                const {data, status} = await fetchClient().post(endpoints()['post'], JSON.stringify(payload))
                switch (status) {
                    case 200 :
                        this.setState({
                            loading: false,
                            alert: 'The schedule has been created successfully',
                            isOpenModal: false,
                            error: ''
                        }, () => this.getSchedules())
                        break
                    default:
                        console.log(data)
                        this.setState({
                            error: 'Error in create schedule',
                            loading: false
                        })
                        break
                }
            } catch (err) {
                this.setState({
                    loading: false,
                    error: err.response && err.response.data
                    && err.response.data.errors &&
                    Object.keys(err.response.data.errors)[0] ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'error'
                })
            }
        }
    }

    deleteSchedule = async (id) => {
        if (!this.state.loading) {
            this.setState({loading: true})
            try {
                const data = await fetchClient().delete(endpoints()['delete'] + id, this.state.headers)
                if (data) this.setState({
                    loading: false,
                    page: this.state.schedules.length === 1 && this.state.page > 1 ? this.state.page - 1 : this.state.page,
                    alert: 'The schedule has been deleted successfully'
                }, () => this.getSchedules())
            } catch (err) {
                this.setState({
                    loading: false,
                    error: err.response && err.response.data
                    && err.response.data.errors &&
                    Object.keys(err.response.data.errors)[0] ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'error'
                })
            }
        }
    }

    render() {
        return (
            <Layout>
            <Schedule schedules={this.state.schedules}
                      create={this.createSchedule}
                      edit={this.editSchedule}
                      alert={this.state.alert}
                      openModal={this.openModal}
                      closeModal={this.closeModal}
                      deleteSchedule={this.deleteSchedule}
                      isOpen={this.state.isOpenModal}
                      isReportsEmail={!!this.props.isReportsEmail}
                      contentEdit={this.state.contentEdit}
                      toggle={this.toggle}
                      loading={this.state.loading}
                      next={this.state.next}
                      prev={this.state.prev}
                      nextPage={this.nextPage}
                      prevPage={this.prevPage}
                      lastPage={this.state.last_page}
                      page={this.state.page}
                      error={this.state.error}
                      confirmError={this.confirmError}
                      confirmAlert={this.confirmAlert}
                      onChangeReportsEmail={this.onChangeReportsEmail}
            />
            </Layout>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        isReportsEmail: state.user_logged.optin_report_email
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setEmailsReport: (val) => {
            dispatch(setReports(+val))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage)
