export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);

  } catch (err) {
    return undefined;
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // die
  }
}

export const removeStateFromLocalStorage = () => {
  try {
    localStorage.removeItem('state')
  }catch (err) {
    //die
  }
}

export const saveTokenInfo = (info) => {
  localStorage.setItem('token_info', JSON.stringify(info))
}

export const removeTokenInfo = () => {
  localStorage.removeItem('token_info')
}

export const saveUserPreferences = (info) => {
  localStorage.setItem('user_preferences', JSON.stringify(info))
}

export const removeUserPreferences = () => {
  localStorage.removeItem('user_preferences')
}