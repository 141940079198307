import React from 'react';
import {ButtonStyled} from '../../../styles/Button'

export const Button = ({children, icon,  ...rest}) => {

    return (
        <ButtonStyled {...rest}>
            {icon &&
             <div className='icon mr-2'>
                 {icon}
                </div>}
            {children}
        </ButtonStyled>
    )

}
