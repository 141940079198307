import styled from "styled-components";
import { getColorScore, styles } from './variables';
import { Row } from "reactstrap";


export const RowResultStyled = styled(Row)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: solid 1px ${styles.gray} !important;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  background-clip: border-box;
  margin-right: 0;
  margin-left: 0;
  flex-grow: 1;
  position: relative;
  padding: 1.2rem 2rem;
  @media screen and (max-width: 1366px) {
    margin-left: 1rem;
  }
  .index-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    color: #fff;
    padding: 1rem;
    font-size: 1em;
    font-family: ${styles.fontPrimary};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${styles.secondaryColor};
    font-weight: 700;
  }
`
export const TopBarMenuStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .domain {
    font-size: 0.75em;
    color: ${styles.textColor};
    font-family: ${styles.fontPrimary};
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 50ch;
    @media screen and (max-width: 1300px) {
      width: 40ch;
    }
  }
`
export const RowFooterStyled = styled(Row)`
  padding-top: 1rem;
  min-height: ${({ isTopStory }) => isTopStory ? '' : '7.6rem'};
  max-height: ${({ isTopStory }) => isTopStory ? '' : '7.6rem'};
  .column {
    flex-basis: 25%;
  }

  .domain-authority {
    flex-basis: ${({ isTopStory }) => isTopStory ? '' : '23%'};
    margin-top: -0.5rem;
    p {
      padding: 0;
      margin: 0px 2px;
      position: static;
    }
    .value {
      font-size: 2em;
      font-family: ${styles.fontPrimary};
      font-weight: 700;
      height: 2rem;
      margin: 0;
      margin-bottom: 0.5rem;
      transition: color .5s ease-in-out;
      color: ${({ domainAuthority: score }) => getColorScore(score)};
      }
      .title {
      color: ${styles.textColor};
      font-size: 0.9em;
      padding: .5rem 0;
      font-family: ${styles.fontPrimary};
      font-weight: 500;
    }
    .timeout {
      color: ${styles.red};
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }
  .domain-authority-spinner {
      margin-top: ${({ isTopStory }) => isTopStory ? '' : '2.3rem'};
      margin-right: ${({ isTopStory }) => isTopStory ? '' : '1rem'};
  
  }
  .seo-analysis-spinner {
    margin-bottom: ${({ isTopStory }) => isTopStory ? '' : '3px'};
  }
  .seo-analysis {
    color: ${styles.secondaryColor};
    align-items: center;
    padding: 0;
    margin: 0rem;
    flex-basis: ${({ isTopStory }) => isTopStory ? '' : '23%'};
    input {
      font-size: 2em;
      font-family: ${styles.fontPrimary};
      font-weight: 700;
      padding: 0;
      color: ${({ seoAnalysis: score }) => getColorScore(score)} !important;
      transition: color .4s ease-in-out;
      margin: 0px;
      width: 100%;
      outline: none !important;
      border: none !important;
      text-align: center;
      height: 2rem;
    }
  .result-analysis-btn {
      border: solid 1px ${styles.gray};
      border-radius: 10px;
      color: ${styles.secondaryColor};
      height: 2rem;
      font-size: 0.8125em;
      vertical-align: middle;
      display: flex;
      place-items: center;
      padding: .5rem .7rem;
      background-color: transparent !important;
      outline: none !important;
      padding-bottom: 1.2rem;
      padding-top: 1rem;
    }
  }
  border-top: ${({ isTopStory }) => isTopStory ? 'none' : `1px solid ${styles.gray}`};
  display: flex;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-right: ${({ isTopStory }) => isTopStory ? `2rem` : '0'};
  padding-left: ${({ isTopStory }) => isTopStory ? `2rem` : '1rem'};
  flex: 1;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;

  h4 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: ${styles.textColor};
    font-size: 0.75em;
  }

  .container-icon {
    position: relative;
    width: 1.0606rem;
    height: 1.0606rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;

    svg path {
      width: 100%;
      fill: #fff !important;;
      color: #fff;
    }
    &:focus {
      outline: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${styles.purple};
      transform-origin: center center;
      transform: rotate(45deg);
      border-radius: 3px;
    }
  }
  .icon-search {
    width: 1rem;
    position: relative;
    z-index: 2;
  }

  .select-sentiment {

  }
`

export const RowBodyStyled = styled(Row)`
  h3 {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 1.25em !important;
  }

  a {
    color: #1A0DAB !important;

  }

  .body {
    text-align: left;
    margin: .5rem 0;
    font-weight: 400;
    line-height: 1.56em;
    padding: 0;
    font-size: 0.9em;
    color: ${styles.textColor};
  }
`;

const Root = styled.div`
  width: ${props => props.size ? props.size : 20}px;
  height: ${props => props.size ? props.size : 20}px;
  position: relative;

  &::before {
    content: '';
    border-radius: 100%;
    border: 1px solid ${props => props.borderColor ? props.borderColor : '#DDD'};
    background: ${props => props.backgroundColor ? props.backgroundColor : '#FAFAFA'};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }`

const Fill = styled.div`
  background: ${props => props.fillColor ? props.fillColor : '#A475E4'};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${props => props.borderActive ? props.borderActive : '#A475E4'};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }

  }
`

export const Date = styled.span`
  font-size: 0.7rem;
  color: #1A0DAB;
  font-weight: 500;
`

export const Truncation = styled.div`
  overflow:hidden;
  line-height: 2rem;
  max-height: fit-content;
  -webkit-box-orient: vertical;
  display: block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
`
