export const sentimentTooltip = {
    name: 'Sentiment Override',
    content: 'Select your preferred sentiment using the color-coded buttons.',
    description: 'If saved, all future searches performed for this exact keyword and location combination will default to your sentiment override.'
};

export const categoryTooltip = {
    name: 'Category Override',
    content: 'Click on the category title and select your desired new category from the drop-down menu.',
    description: 'If saved, all future searches performed for this exact keyword and location combination will default to your category override.'
};