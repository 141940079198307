import React, { Component, Fragment } from 'react'
import { Alert, Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import classnames from 'classnames';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import fetchClient from '../../helpers/fetch-client'
import SentimentForm from './SentimentForm'
import CategoryForm from './CategoryForm'
import ConfirmLeavePage from './ConfirmLeavePage'
import TermForm from './TermForm'
import OverridesList from './OverridesList'
import Pagination from '../../components/Pagination'
import { updateOverrides, setOverride, regenerateActionPlan } from '../../actionCreators';
import '../../css/OverridesPage.css'
import plus from '../../images/plus.svg'
import DropdownMenu from '../../components/DropdownMenu'
import LocationMenu from '../../components/LocationMenu'
import { Link } from 'react-router-dom'
import pencil from '../../images/pencil.svg'
import Spinner from '../../components/Spinner'
import { categories as ct } from '../../config/categories'
import Select from 'react-select'
import { rankSentiment, optionsSelectCategory, getCategory } from '../../helpers/select-constants'
import { pageView } from '../../helpers/ga-page-view'
import { Layout } from "../../components/Layout/Default";

const base_url = process.env.REACT_APP_API_ENDPOINT

const sentiments = {
  unselected: <span className="select-sentiment step step-inner bg-unselected"></span>,
  positive: <span className="select-sentiment step step-inner bg-positive"></span>,
  neutral: <span className="select-sentiment step step-inner bg-neutral"></span>,
  negative: <span className="select-sentiment step step-inner bg-negative"></span>
}

const categories = {}
Object.entries(ct).map(([key, value]) => categories[key] = <span className='dropdown-select'>{value.name}</span>)



String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

class OverridesPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      search: "",
      isLoading: false,
      overrides: this.props.overrides,
      confirmLeaveCallback: false
    };
    this.cancel();
  }

  componentDidMount() {
    pageView()
    let data = {
      list: [],
      selected_list: 'term',
      term: ''
    }
    this.props.onFetchList(data)
    this.getTerms()
  }

  columns = {
    sentiment: [
      { name: "Url", attr: "formatted_url", alias: "url", width: '40%', },
      { name: "Location", attr: "formatted_location", alias: "location", width: '30%', },
      { name: "Sentiment", attr: "formatted_sentiment", alias: "sentiment", width: '20%', },
      { name: "Saved", attr: "status", alias: "updated_at", width: "30%" },
    ],
    category: [
      { name: "Url", attr: "formatted_url", alias: "url", width: '50%', },
      { name: "Category", attr: "formatted_category", alias: "category", width: '30%', },
      { name: "Saved", attr: "status", alias: "updated_at", width: "20%" },
    ],
    term: [
      { name: "Term", attr: "term", alias: "term", width: '30%', },
      { name: "Sentiments", attr: "sent_overrides", alias: "sent_overrides", width: '30%', },
      { name: "Categories", attr: "cat_overrides", alias: "cat_overrides", width: '30%', }
    ]
  }

  options = {
    sentiment: {
      menu: true,
      onEdit: (item) => (this.handleEditItem(item)),
      rowClickAction: (item) => this.handleEditItem(item),
      onDelete: (item) => (this.handleDeleteItem(item)),
      onSaveButton: (item) => (this.handleSaveSentimentCurrentOverride(item)),
      onCancelButton: (item) => (this.handleCancelEditOrCreateCurrentOverride(item))
    },
    category: {
      menu: true,
      onEdit: (item) => (this.handleEditItem(item)),
      rowClickAction: (item) => this.handleEditItem(item),
      onDelete: (item) => (this.handleDeleteItem(item)),
      onSaveButton: (item) => (this.handleSaveCategoryCurrentOverride(item)),
      onCancelButton: (item) => (this.handleCancelEditOrCreateCurrentOverride(item))
    },
    term: {
      rowClickAction: (item) => (this.rowAction(item)),
    }
  }

  cancel = (messages = false) => {
    let data = {
      item: {},
      type: this.props.overrides.selected_list,
      modal_is_open: false,
      modal_type: false,
      messages: messages
    }
    this.props.onItemAction(data)
  }

  rowAction = (item = {}) => {
    this.setState({
      search: item.term
    });
    this.getSentiments(item.term, 1);
  }

  handleCreateItem = (type = false) => {
    type = type ? type : this.props.overrides.selected_list;
    let data = {
      item: {
        type: type
      },
      type: this.props.overrides.selected_list,
      modal_is_open: true,
      modal_type: 'create'
    }

    this.props.onItemAction(data)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      overrides: nextProps.overrides
    })
  }

  updateCurrentOverride = (item) => {
    this.resetEditRowItem();
    this.resetCurrentOverride();
    window.currentLocation = undefined;
    this.setState({
      isLoading: true,
      overrides: this.state.overrides
    }, () => {
      this.state.overrides.current_override = item;
      this.state.edit_row_item = false;
      if (this.state.overrides.list.data)
        this.state.overrides.list.data.unshift(item);
      else
        this.state.overrides.list.data = [item]

      this.setState({
        overrides: this.state.overrides,
        isLoading: false
      })
    })
  }

  resetEditRowItem = () => {
    if (this.state.overrides.edit_row_item) {
      this.state.overrides.list.data = this.state.overrides.list.data.map((item) => {
        if (item.currentEdit)
          item.currentEdit = false
        return item;
      })
    }
  }

  resetCurrentOverride = () => {
    this.setState({
      isLoading: true
    }, () => {
      if (this.state.overrides.list.data) {
        const foundIndex = this.state.overrides.list.data.findIndex(element => element.currentOverride);
        if (foundIndex > -1)
          this.state.overrides.list.data.splice(foundIndex, 1);
      }
      this.setState({
        overrides: this.state.overrides,
        isLoading: false
      })
    });
  }

  handleEditItem = (item) => {
    if (!item.currentEdit && !item.currentOverride) {
      this.resetCurrentOverride();
      this.resetEditRowItem();
      this.setState({
        overrides: this.state.overrides,
        isLoading: true
      }, () => {
        item.currentEdit = true;
        item.status = "Pending update";
        this.state.overrides.edit_row_item = item;
        const foundIndex = this.state.overrides.list.data.findIndex(element => element.id === item.id);
        if (foundIndex > -1) {
          this.state.overrides.list.data[foundIndex] = item;
        }
        this.setState({
          overrides: this.state.overrides,
          isLoading: false
        })

      })
    }
  }

  handleDeleteItem = (item) => {
    item.type = this.props.overrides.selected_list;
    let data = {
      item: item,
      type: this.props.selected_list,
      modal_is_open: true,
      modal_type: 'delete'
    }
    this.props.onItemAction(data)
  }

  handleSearchTerm = (page = 1) => {
    const { selected_list } = this.props.overrides
    const term = this.state.search;
    if (selected_list === "sentiment") {
      this.getSentiments(term, page);
    }
    else if (selected_list === "category") {
      this.getCategories(term, page);
    }
    else {
      this.getTerms(term, page);
    }
  }

  getSentiments = (term = false, page = 1) => {
    this.setState({ search: term, isLoading: true })
    let search = term ? 'search=' + term + '&' : '';
    fetchClient().get(`${base_url}/sentimentoverride?${search}page=${page}`)
      .then(
        (response) => {
          this.state.overrides.list = response.data.data;
          this.state.overrides.selected_list = 'sentiment';
          this.state.overrides.term = term;
          this.state.overrides.current_override = false;
          this.state.overrides.edit_row_item = false;
        }
      ).catch(
        (error) => {
          throw 'Can\'t connect to server, contact with the administrator'
        }
      ).finally(
        () => {
          this.cancel()
          this.setState({ isLoading: false, overrides: this.state.overrides }, () => { this.props.onFetchList(this.state.overrides) })
        }
      )
  }

  getCategories = (term = false, page = 1) => {
    this.setState({ search: term, isLoading: true })
    let search = term ? 'search=' + term + '&' : '';
    fetchClient().get(`${base_url}/categoryoverride?${search}page=${page}`)
      .then(
        (response) => {
          this.state.overrides.list = response.data.data;
          this.state.overrides.selected_list = 'category';
          this.state.overrides.term = term;
          this.state.overrides.current_override = false;
          this.state.overrides.edit_row_item = false;
        },
      ).catch(
        (error) => {
          throw 'Can\'t connect to server, contact with the administrator'
        }
      ).finally(
        () => {
          this.setState({ isLoading: false, overrides: this.state.overrides }, () => { this.props.onFetchList(this.normalizeDataToSaveInStore()) })
        }
      )
  }

  refreshListData = (data) => {
    this.setState({
      isLoading: true
    }, () => {
      this.state.overrides.list = data.list
      this.state.overrides.selected_list = data.selected_list
      this.state.overrides.term = data.term
      this.setState({
        overrides: this.state.overrides,
        isLoading: false,
      }, () => {
        let overridesAux = this.normalizeDataToSaveInStore(this.state.overrides)
        this.props.onFetchList(overridesAux)
      })
    })
  }

  normalizeDataToSaveInStore = () => {
    let overridesAux = this.state.overrides;
    overridesAux.list.data.map((item) => {
      delete item.formatted_category;
      delete item.formatted_location;
      delete item.formatted_url;
      delete item.status;
      return item;
    })

    return overridesAux;
  }

  getTerms = (term = "", page = 1) => {
    this.setState({ search: term, isLoading: true })
    let search = 'search=' + term;
    fetchClient().get(`${base_url}/overrideterms?page=${page}&${search}`)
      .then(
        (response) => {
          let data = {
            list: response.data.data,
            selected_list: 'term',
            term: term
          }
          this.state.overrides.current_override = false;
          this.state.overrides.edit_row_item = false;
          this.props.onFetchList(data)
        },
      ).catch(
        (error) => {
          throw 'Can\'t connect to server, contact with the administrator'
        }
      ).finally(() => {
        this.cancel()
        this.setState({ isLoading: false })
      })
  }

  handleSaveSentimentCurrentOverride = (item) => {
    const element = "sentimentoverride";
    let aux = {
      location: item.location ? parseInt(item.location) : "",
      sentiment: item.sentiment ? item.sentiment : "",
      term: item.term,
      url: item.url,
      id: item.id
    }

    this.setState({
      isLoading: true
    }, () => {
      fetchClient().put(base_url + '/' + element + '/' + aux.id, aux)
        .then(
          (response) => {
            this.state.overrides.edit_row_item = false;
            this.state.overrides.current_override = false;
            this.state.overrides.list = response.data.data;
            this.state.overrides.selected_list = 'sentiment';
            this.state.overrides.term = item.term;

            this.setState({
              overrides: this.state.overrides,
            }, () => {
              window.currentLocation = undefined;
              let overridesAux = this.normalizeDataToSaveInStore(this.state.overrides)
              this.props.onFetchList(overridesAux)
              this.cancel({ type: 'success', text: 'Success' })
            })
          }
        )
        .catch(
          (error) => {
            let messages = "";
            for (let er in error.response.data.errors) {
              messages += error.response.data.errors[er].map(function (item) {
                return item.toString()
              });
            }
            this.cancel({ type: 'danger', text: messages })
          }
        )
        .finally(
          () => {
            this.setState({ isLoading: false })
            this.props.dispatchShowViewActionPlan(false)
          }
        )
    })
  }

  handleCancelEditOrCreateCurrentOverride = (item) => {
    if (item.currentEdit) {
      this.state.overrides.edit_row_item = false;
    } else if (item.currentOverride) {
      this.updateCurrentOverride({});
      this.state.overrides.currentOverride = false;
    }

    this.setState({
      overrides: this.state.overrides
    }, () => {
      if (this.state.overrides.selected_list == "sentiment") {
        this.getSentiments(item.term);
      } else if (this.state.overrides.selected_list == "category") {
        this.getCategories(item.term);
      }
    })
  }

  handleSaveCategoryCurrentOverride = (item) => {
    const element = "categoryoverride";
    let aux = {
      category: item.category ? item.category : "",
      term: item.term,
      url: item.url,
      id: item.id
    }
    this.setState({
      isLoading: true
    }, () => {
      fetchClient().put(base_url + '/' + element + '/' + aux.id, aux)
        .then(
          (response) => {
            this.state.overrides.edit_row_item = false;
            this.state.overrides.current_override = false;
            this.state.overrides.list = response.data.data;
            this.state.overrides.selected_list = 'category';
            this.state.overrides.term = item.term;

            this.setState({
              overrides: this.state.overrides,
            }, () => {
              window.currentLocation = undefined;
              let overridesAux = this.normalizeDataToSaveInStore(this.state.overrides)
              this.props.onFetchList(overridesAux)
              this.cancel({ type: 'success', text: 'Success' })
            })
          }
        )
        .catch(
          (error) => {
            let messages = "";
            for (let er in error.response.data.errors) {
              messages += error.response.data.errors[er].map(function (item) {
                return item.toString()
              });
            }
            this.cancel({ type: 'danger', text: messages })
          }
        )
        .finally(
          () => {
            this.setState({ isLoading: false })
            this.props.dispatchShowViewActionPlan(false)
          }
        )
    });
  }

  handleChangeSentimentOverride = (ev, item) => {
    item.sentiment = ev.value;
  }

  handleChangeCategoryOverride = (ev, item) => {
    item.category = ev.value;
  }

  handleLocation = (location, item) => {
    item.location = location.loc_id;
  }

  handleChangeUrl = (ev, item) => {
    item.url = ev.target.value
  }

  formatCategory = (item) => {
    return categories[item] ? categories[item] : "";
  }
  formatSentiment = (item) => {
    return sentiments[item] ? sentiments[item] : "";
  }

  formatSentimentsAndCategories = (list = []) => {
    if (list.list.data) {
      list.list.data.map(item => {
        if (item.category) {
          item.formatted_category = this.formatCategory(item.category);
        }
        if (item.sentiment) {
          item.formatted_sentiment = this.formatSentiment(item.sentiment);
        }

        if (item.currentOverride || item.currentEdit) {
          this.formatItemDropdown(item);
        }

        this.formatItemUrl(item);
        this.formatItemStatus(item);
        this.formatItemLocation(item);
        return item;
      });
    }
  }

  loadSentiment = (sentiment) => {
    let result = rankSentiment.filter((item) => item.value === sentiment)
    return result[0] || rankSentiment[0]
  }

  formatItemDropdown = (item) => {
    if (item.sentiment || item.type == "sentiment") {
      const customStyles = {
        valueContainer: (provided, state) => ({
          ...provided,
          height: "30px"
        }),
      }
      item.formatted_sentiment = <Fragment>
        <Select
          className={'option-select-override'}
          isSearchable={false}
          options={rankSentiment}
          styles={customStyles}
          onChange={(ev) => { this.handleChangeSentimentOverride(ev, item) }}
          defaultValue={item.sentiment ? this.loadSentiment(item.sentiment) : null}
        />
        <img src={pencil} className={"pencil_override"} />
      </Fragment>
    } else if (item.category || item.type == "category") {
      item.formatted_category = <Fragment>
        <DropdownMenu
          isIntoForm={true}
          onChange={(ev) => {
            this.handleChangeCategoryOverride(ev, item)
          }}
          options={optionsSelectCategory}
          align='center'
          value={getCategory(item.category)}
        />
        <img src={pencil} className={"pencil_override"} />
      </Fragment>
    }
  }

  formatItemStatus = (item) => {
    item.currentOverride || item.currentEdit ?
      item.status = <span style={{ color: "#D0021B" }}>{item.status}</span>
      :
      item.status = <span>{this.formatDate(item.updated_at)}</span>
  }

  formatItemUrl = (item) => {
    item.currentOverride || item.currentEdit ?
      item.formatted_url =
      <Fragment>
        <Input
          defaultValue={item.url}
          onChange={(ev) => this.handleChangeUrl(ev, item)}
          className={"url_item"}
        />
        <img src={pencil} className={"pencil_override"} />
      </Fragment>
      :
      item.formatted_url = item.url
  }


  formatItemLocation = (item) => {
    if (item.currentOverride || item.currentEdit) {
      item.formatted_location = <Fragment>
        <LocationMenu
          className={"LOCMENU"}
          location={(item.location != null) ?
            {
              canonical_name: item.location_name,
              loc_id: item.location,
              loc_name_lowercase: (item.location_name) ? item.location_name.toLowerCase() : ''
            }
            :
            null
          }
          id="location-menu"
          formOverrides={true}
          resultsPage={false}
          dashboardPage={false}
          onSelectLocation={(ev) => {
            this.handleLocation(ev, item)
          }}
          hideClearLocation={true}
          removeLocationCallback={() => {
            this.handleLocation({ loc_id: null }, item)
          }}
        />
        <img src={pencil} className={"pencil_override align-baseline"} />
      </Fragment>
    } else {
      item.formatted_location = item.location_name
    }
  }

  formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    year = year.toString().substring(2);

    return [month, day, year].join('/');
  }

  setConfirmCallback = (event, callback, arg = false) => {
    if (!this.state.isLoading) {
      if (!this.state.overrides.edit_row_item && !this.state.overrides.current_override) {
        this.setState({
          confirmLeaveCallback: false
        })
        event.preventDefault();
        callback(arg);
      }
      else {
        this.setState({
          confirmLeaveCallback: {
            callback: callback,
            parameter: arg
          }
        }, () => {
          event.preventDefault();
        })
      }
    } else {
      event.preventDefault();
    }
  }

  removeCallbackFromState = () => {
    this.setState({
      confirmLeaveCallback: false
    })
  }

  render() {
    const { auth_info, overrides } = this.props;
    this.formatSentimentsAndCategories(this.state.overrides)
    if (auth_info == null) {
      return <Redirect to='/' />
    }
    return (
      <Layout>
        <ConfirmLeavePage
          enabled={this.state.overrides.edit_row_item || this.state.overrides.current_override}
          callback={this.state.confirmLeaveCallback}
          removeCallbackFromState={this.removeCallbackFromState}
        />
        <div className='OverridesPage'>
          <div className="container containerOverridePage">
            {
              this.state.overrides.selected_list != 'term' ?
                <Nav tabs>
                  <NavItem className='item-nav col-md-6'>
                    <Link
                      to="#"
                      className={classnames({ active: this.state.overrides.selected_list === 'sentiment' }) + " nav-link"}
                      onClick={(event) => {
                        this.setConfirmCallback(event, this.getSentiments, this.state.overrides.term)
                      }}
                    >
                      Sentiment Overrides
                    </Link>
                  </NavItem>
                  <NavItem className='item-nav col-md-6'>
                    <Link
                      to="#"
                      className={classnames({ active: this.state.overrides.selected_list === 'category' }) + " nav-link"}
                      onClick={(event) => {
                        this.setConfirmCallback(event, this.getCategories, this.state.overrides.term)
                      }}
                    >
                      Category Override
                    </Link>
                  </NavItem>

                </Nav>
                :
                null
            }
            <Row className="search-group">
              <Col md={8}>
                <Link
                  to="#"
                  color="secondary" className="pull-left btn-all"
                  onClick={(event) => {
                    this.setConfirmCallback(event, this.getTerms, "")
                  }}>
                  All
                </Link>
                <span className="search-term">{this.state.overrides.term}</span>
              </Col>
              <Col md={4}>
                <InputGroup className='search-input'>
                  {
                    this.state.overrides.selected_list === 'term' &&
                    <Fragment>
                      <Input
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            this.handleSearchTerm()
                          }
                        }}
                        value={this.state.overrides.selected_list !== 'term' ? this.state.overrides.term : this.state.search}
                        onChange={(e) => this.setState({ search: e.target.value })}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="secondary" onClick={this.handleSearchTerm}>Search</Button>
                      </InputGroupAddon>
                    </Fragment>
                  }
                </InputGroup>
              </Col>
            </Row>
            <Row className='header-overrides'>
              <Col md={12}>
                {this.state.overrides.selected_list === 'sentiment' &&
                  <Link
                    to="#"
                    onClick={(event) => {
                      this.setConfirmCallback(event, this.handleCreateItem, "sentiment")
                    }}
                  >
                    <Button outline className='btn-new-override float-right' color="primary"><img
                      src={plus} />Add override</Button>
                  </Link>
                }
                {this.state.overrides.selected_list == 'category' &&
                  <Link
                    to="#"
                    onClick={(event) => {
                      this.setConfirmCallback(event, this.handleCreateItem, "category")
                    }}
                  >
                    <Button outline className='btn-new-override float-right' color="primary"><img
                      src={plus} />Add override</Button>
                  </Link>
                }
                {(this.state.overrides.selected_list != 'category' && overrides.selected_list != 'sentiment') &&
                  <Button outline className='btn-new-override float-right' color="primary"
                    onClick={() => this.handleCreateItem("term")}><img src={plus} />New term</Button>
                }
              </Col>
            </Row>
            {
              this.state.overrides.messages && this.state.overrides.messages.text ?
                <Alert color={this.state.overrides.messages.type}
                  style={{ marginTop: '10px' }}>{this.state.overrides.messages.text}</Alert>
                : ""
            }
            <TabContent activeTab={this.state.overrides.selected_list}>
              <TabPane tabId={this.state.overrides.selected_list}>
                <Row>
                  <Col sm="12" style={{ padding: "0px" }}>
                    {
                      (this.state.isLoading) ?
                        <div className="spinner-loading"><Spinner /></div>
                        :
                        ((this.state.overrides.list.data) ?
                          ((this.state.overrides.list.data.length > 0) ?
                            <OverridesList data={this.state.overrides.list}
                              columns={this.columns[this.state.overrides.selected_list]}
                              options={this.options[this.state.overrides.selected_list]} />
                            :
                            <p className={"empty_list"}>{this.state.overrides.selected_list.capitalize()} list
                              is empty</p>
                          )
                          :
                          <p className={"empty_list"}>{this.state.overrides.selected_list.capitalize()} list
                            is empty</p>)
                    }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <Pagination onChange={this.handleSearchTerm} data={this.state.overrides.list} />
            {
              this.state.overrides.item.type == 'sentiment' && this.state.overrides.modal_is_open &&
              <SentimentForm close={this.handleCloseModal} data={this.state.overrides.list}
                getSentiments={this.getSentiments}
                updateCurrentOverride={this.updateCurrentOverride}
                refreshListData={this.refreshListData} />
            }
            {
              this.state.overrides.item.type == 'category' && this.state.overrides.modal_is_open &&
              <CategoryForm close={this.handleCloseModal} data={this.state.overrides.list}
                getCategories={this.getCategories}
                updateCurrentOverride={this.updateCurrentOverride}
                refreshListData={this.refreshListData} />
            }
            {
              this.state.overrides.item.type == 'term' && this.state.overrides.modal_is_open &&
              <TermForm close={this.handleCloseModal} />
            }
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_info: state.auth_info,
    overrides: state.overrides,
    regenerateActionPlan: state.regenerateActionPlan,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchList: (data) => {
      dispatch(updateOverrides(data))
    },
    onItemAction: (data) => {
      dispatch(setOverride(data))
    },
    dispatchShowViewActionPlan: (value) => {
      dispatch(regenerateActionPlan(value))
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(OverridesPage)
