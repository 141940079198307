import React from 'react'
import SEOPageTitle from '../../images/icons/seo-pagetitle.svg'
import SEOURL from '../../images/icons/seo-url.svg'
import SEOBackLinks from '../../images/icons/seo-backlinks.svg'
import SEOLoadSpeed from '../../images/icons/seo-loadspeed.svg'
import SEOSSL from '../../images/icons/seo-ssl.svg'
import Checked from '../../images/icons/checkbox-checked.svg'
import UnChecked from '../../images/icons/checkbox-unchecked.svg'

const LoadSEOModalIcons = () => {
    return (
        <div style={{ display: 'none' }}>
            <img src={SEOPageTitle} />
            <img src={SEOURL} />
            <img src={SEOBackLinks} />
            <img src={SEOLoadSpeed} />
            <img src={SEOSSL} />
            <img src={Checked} />
            <img src={UnChecked} />
        </div>
    )
}

export default LoadSEOModalIcons
