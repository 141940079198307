import { createStore } from 'redux'
import findByTerm from './helpers/find-lumentus'
import data from './db.json'
import { loadState, saveState, removeStateFromLocalStorage, saveTokenInfo, removeTokenInfo, saveUserPreferences, removeUserPreferences } from './connectivity/localStorage'
import hash from 'object-hash'
import calculateLumentusScore from './helpers/lumentus-score'
import calculateSentiment from './helpers/calculate-sentiment'
import saveLocations from './helpers/save-locations'

import { setMessage, getMessages } from './helpers/messages'

const localStorageData = loadState()

const reducer = (state = (localStorageData !== undefined ? localStorageData.state : data), action) => {
  if (action.type === "CHANGE_CATEGORY") {
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            category: action.newCategory,
            category_override_id: action.override_id
          }
        }
      }
    }
  }

  if (action.type === "CHANGE_SENTIMENT") {
    if (!state?.ranking?.data[action.itemID]?.sentiment) return { ...state };
    let previousSentiment = state.ranking.data[action.itemID].sentiment

    if (action.topStoryIndex != null) {
      var _resume = state.ranking.data[action.itemID].resume;
      _resume[action.topStoryIndex] = {
        ..._resume[action.topStoryIndex],
        sentiment: {
          ..._resume[action.topStoryIndex].sentiment,
          value: action.newSentiment.sentiment,
          sentiment_override_id: action.override_id,
        }
      }
    }

    return {
      ...state,
      stats: {
        ...state.stats,
        sentiment: {
          ...state.stats.sentiment,
          [action.newSentiment]: state.stats.sentiment[action.newSentiment] + 1,
          [previousSentiment]: state.stats.sentiment[previousSentiment] - 1
        }
      },
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            resume: _resume ? _resume : state.ranking.data[action.itemID].resume,
            sentiment: action.topStoryIndex != null ? action.newSentiment.average : action.newSentiment,
            sentiment_override_id: action.override_id,
          }
        }
      }
    }
  }

  if (action.type === 'UPDATE_OVERRIDES_LIST') {
    return {
      ...state,
      overrides: {
        ...state.overrides,
        list: action.data.list,
        selected_list: action.data.selected_list,
        term: action.data.term
      }
    }
  }


  if (action.type === 'SET_OVERRIDE') {
    return {
      ...state,
      overrides: {
        ...state.overrides,
        item: action.data.item,
        modal_is_open: action.data.modal_is_open,
        modal_type: action.data.modal_type,
        messages: action.data.messages
      }
    }
  }

  if (action.type === "CHANGE_CONTROLLED") {
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            controlled: action.controlled
          }
        }
      }
    }
  }

  if (action.type === "CHANGE_COMPETITIVE") {
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            competitive: action.competitive
          }
        }
      }
    }
  }

  if (action.type === "CHANGE_AD_CONTROLLED") {
    return {
      ...state,
      ad: {
        ...state.ad,
        controlled: action.controlled
      }
    }
  }
  if (action.type === "CHANGE_AD_COMPETITIVE") {
    return {
      ...state,
      ad: {
        ...state.ad,
        competitive: action.competitive
      }
    }
  }

  if (action.type === "SET_MODULE_STATUS_IN_PROGRESS") {
    let item = Object.values(state.ranking.data).filter((item) => {
      return item.id === action.itemID
    })[0]

    let arrAux = []
    if (item) {
      item.relevance.forEach((aux) => {
        if (aux.status === "unrequested" && action.itemsToRequest.includes(aux.module)) {
          aux.status = "inProgress"
          aux.time_started = new Date().getTime()

          arrAux.push(aux)
        }
      })
    }
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            relevance: arrAux
          }
        }
      }
    }
  }

  if (action.type === "RANKING_SWITCH") {
    let order = state.ranking.order
    let newOrder = order

    if (action.oldRankPos < action.newRankPos) {
      let one = order.slice(0, action.oldRankPos)
      let two = order.slice(action.oldRankPos + 1, action.newRankPos + 1)
      let three = order.slice(action.newRankPos + 1, 100 + 1)
      newOrder = [...one, ...two, order[action.oldRankPos], ...three]
    } else {
      let one = order.slice(0, action.newRankPos)
      let two = order.slice(action.newRankPos, action.oldRankPos)
      let three = order.slice(action.oldRankPos + 1, 100 + 1)
      newOrder = [...one, order[action.oldRankPos], ...two, ...three]
    }

    //newOrder = [...newOrder, ...order.slice(11, 101)]

    return {
      ...state,
      ranking: {
        ...state.ranking,
        order: newOrder
      }
    }
  }

  if (action.type === "CALCULATE_LUMENTUS_SCORE") {
    let score = calculateLumentusScore(state.ranking, state.visible_rows, state.knowledge_graph)
    let sentiment = calculateSentiment(state.ranking)

    return {
      ...state,
      stats: {
        ...state.stats,
        sentiment: sentiment,
        lumentus_score: score
      }
    }
  }

  if (action.type === "SET_RELEVANCE") {
    if (action.data.length != 12) {
      return {
        ...state
      }
    }

    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            relevance: action.data
          }
        }
      }
    }
  }

  if (action.type === "SEARCH") {
    return {
      ...state,
      search: {
        ...state.search,
        main: action.term,
        sub: ""
      }
    }
  }

  if (action.type === 'CHANGE_AUTOMATION') {
    return {
      ...state,
      search: {
        ...state.search,
        automation: !state.search.automation
      }
    }
  }

  if (action.type === 'CHANGE_PAGES_AUTENTICATE') {
    return {
      ...state,
      search: {
        ...state.search,
        pagesToAutomate: action.data
      }
    }
  }

  if (action.type === "SEARCH_FINISHED") {
    return {
      ...state,
      search: {
        ...state.search,
        main: action.term,
        loc_id: action.loc_id,
        canonical_name: action.canonical_name,
        using_location: action.using_location,
        sub: ""
      },
      ranking: {
        ...state.ranking,
        data: action.data
      },
      ad: {
        ...action.ads.length && action.ads[0] || {}
      },
      related_searches: action.related_searches,
      knowledge_graph: action.knowledge_graph
    }
  }

  if (action.type === "KNOWLEDGE_GRAPH_FINISHED") {
    return {
      ...state,
      knowledge_graph: action.data
    }
  }

  if (action.type === "MONTHLY_SEARCH_FINISHED") {
    return {
      ...state,
      stats: {
        ...state.stats,
        searches: {
          ...action.data.primary_key,
          current_monthly_total: action.data.primary_key.search_volume,
          current_monthly_increase: action.data.primary_key.percent_diff
        }

      }
    }
  }

  if (action.type === "MONTHLY_SEARCH_ERROR") {
    return {
      ...state,
      stats: {
        ...state.stats,
        searches: {
          ...state.stats.searches,
          current_monthly_error_message: true
        }
      }
    }
  }

  if (action.type === "SORT_BY_TERM") {
    let mached = []
    let unmatched = []

    let data = Object.values(state.ranking.data)

    for (let i = 0; i < data.length; i++) {
      let result = data[i]
      let val = 0

      if (result.resume && Array.isArray(result.resume)) {
        result.resume.forEach(function (item, index) {
          let extract = (i === 0 && item.description !== undefined) ? item.description : (item.extract !== undefined ? item.extract : '')
          if (item.title || extract) val += findByTerm(action.term, item.title, extract)
        })
      }

      val ? mached.push({ id: i, val: val }) : unmatched.push({ id: i, val: val })
    }

    let order = mached.concat(unmatched)
    let score = calculateLumentusScore(state.ranking, state.visible_rows, state.knowledge_graph)

    let sentiment = {
      "positive": 0,
      "neutral": 0,
      "negative": 0,
    }

    for (let i = 0; i < 10; i++) {
      switch (state.ranking.data[order[i].id].sentiment) {
        case "positive":
          sentiment["positive"] += 1
          break
        case "neutral":
          sentiment["neutral"] += 1
          break
        case "negative":
          sentiment["negative"] += 1
          break
        default:
          break
      }
    }

    return {
      ...state,
      stats: {
        ...state.stats,
        lumentus_score: score,
        sentiment
      },
      search: {
        ...state.search,
        sub: action.term
      },
      ranking: {
        ...state.ranking,
        order
      },
      visible_rows: (state.visible_rows > Object.keys(state.ranking.data).length ? Object.keys(state.ranking.data).length : 10)
    }
  }

  if (action.type === "SEARCH") {
    return {
      ...state,
      search: {
        ...state.search,
        main: action.term,
        sub: ""
      }
    }
  }

  if (action.type === "CLEAR_SEARCH") {
    removeStateFromLocalStorage()
    const auth_info = state.auth_info
    const user_logged = state.user_logged
    const data_reseted = data
    data_reseted.user_logged = user_logged
    data_reseted.auth_info = auth_info
    data_reseted.localstorage_version = process.env.REACT_APP_LOCALSTORAGE_VERSION

    data_reseted.savedLocations = state.savedLocations

    return data_reseted
  }

  if (action.type === "EXPORT_STORE_TO_FILE") {
    let checksum = hash(state)
    state.checksum = checksum

    var FileSaver = require('file-saver')
    let date = new Date().toISOString().replace('-', '').split('T')[0].replace('-', '')
    var blob = new Blob([JSON.stringify(state)], { type: "application/json" })
    FileSaver.saveAs(blob, date + "_lumentus.json")

    delete state.checksum
  }
  if (action.type === 'SET_REPORT_EMAIL') {
    state.user_logged.optin_report_email = action.value
    return {
      ...state
    }
  }

  if (action.type === "IMPORT_FILE_TO_STORE") {
    let auth_info = state.auth_info
    let user_logged = state.user_logged
    let current_locations = state.savedLocations

    state = JSON.parse(action.file)
    state.knowledge_graph_imported = true
    state.modules_imported = true
    delete state.checksum

    if (state.savedLocations == undefined) {
      state.savedLocations = []
    }
    if (state.search.loc_id == undefined) state.search.loc_id = ""
    if (state.search.canonical_name == undefined) state.search.canonical_name = ""
    if (state.search.using_location == undefined) state.search.using_location = false

    state.auth_info = auth_info
    state.user_logged = user_logged
    state.uploaded_file = action.pathfile

    state.savedLocations.forEach((loc) => {
      let id = loc.loc_id
      if (!current_locations.filter(item => item.loc_id === id).length) current_locations.push(loc)
    })
    current_locations.sort((a, b) => (a.canonical_name.split(',')[0] > b.canonical_name.split(',')[0]) ? 1 : -1)
    state.savedLocations = current_locations
  }

  if (action.type === "IMPORT_AUTOMATED_FILE_TO_STORE") {
    let file = JSON.parse(action.file)
    state = {
      ...state, ...file, uploaded_file: action.pathfile
    };
  }

  if (action.type === "CHANGE_SCORE_MANUALLY") {
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            score_manually: action.value
          }
        }
      }
    }
  }

  if (action.type === "ACTIVATE_SCORE_BONUS_KNOWLEDGE_GRAPH") {
    return {
      ...state,
      stats: {
        ...state.stats,
        lumentus_score_bonus: true
      }
    }
  }

  if (action.type === "KNOWLEDGE_GRAPH_ERROR") {
    return {
      ...state,
      knowledge_graph: "error"
    }
  }

  if (action.type === "EMPTY_KNOWLEDGE_GRAPH") {
    return {
      ...state,
      knowledge_graph: null
    }
  }

  if (action.type === "EMPTY_MONTHLY_SEARCH_ERROR") {
    return {
      ...state,
      stats: {
        ...state.stats,
        searches: {
          ...state.stats.searches,
          current_monthly_error_message: null
        }
      }
    }
  }

  if (action.type === "SAVE_TOKEN_INFO") {
    saveTokenInfo(action.data)
    return {
      ...state,
      auth_info: action.data
    }
  }

  if (action.type === "SET_USER_LOGGED_INFO") {
    saveUserPreferences(action.data)
    return {
      ...state,
      preferences: action.data.user_preferences,
      user_logged: action.data
    }
  }

  if (action.type === "LOGOUT") {
    removeStateFromLocalStorage()
    removeTokenInfo()

    let message = null
    if (action.status === 'revoked')
      message = "You've been logged out because we detected another session with this user."
    else if (action.status === 'account_deactivated')
      message = "You have left the system because your account has been disabled."

    if (message) {
      let messages = getMessages()
      let already_exist = false
      messages.map((obj) => {
        if (obj.msg === message)
          already_exist = true
      })

      if (!already_exist)
        setMessage('info', message)
    }

    state = data
    return {
      ...state,
      auth_info: null,
      user_logged: {
        id: null,
        email: null,
        name: null,
        username: null,
        revoked: action.status
      },
      localstorage_version: process.env.REACT_APP_LOCALSTORAGE_VERSION
    }
  }

  if (action.type === "SAVE_USER_FILES_STRUCTURE") {
    return {
      ...state,
      user_fs: action.files
    }
  }

  if (action.type === "CHANGE_USER_FS_CURRENT_PATH") {
    return {
      ...state,
      files_current_path: action.path.trim()
    }
  }

  if (action.type === "REFRESH_FILES_LIST") {

    let files = state.user_files

    let new_files = files.data.filter(function (value, index, arr) {
      return value.id != action.id
    })

    let total = state.user_files.total > 0 ? state.user_files.total - 1 : 0
    let last_page = Math.ceil(total / state.user_files.per_page)

    return {
      ...state,
      user_files: {
        ...state.user_files,
        data: new_files,
        total,
        last_page
      }
    }
  }

  if (action.type === "DECREMENT_VISIBLE_ROWS") {
    let current_visible_rows = parseInt(state.visible_rows);
    const total_items = Object.values(state.ranking.data).length;
    let new_visible_rows = 0;
    if (current_visible_rows === total_items && current_visible_rows % 10 != 0) {
      let decrementValue = 0;
      while (current_visible_rows % 10 != 0) {
        current_visible_rows -= 1;
        decrementValue += 1;
      }
      new_visible_rows = total_items - decrementValue;
    } else {
      new_visible_rows = (current_visible_rows - 10) >= 10 ? current_visible_rows - 10 : current_visible_rows;
    }
    return {
      ...state,
      visible_rows: parseInt(new_visible_rows)
    }
  }

  if (action.type === "INCREMENT_VISIBLE_ROWS") {
    let current_visible_rows = parseInt(state.visible_rows)
    let total_items = Object.values(state.ranking.data).length
    let new_visible_rows = (current_visible_rows + 10) <= total_items ? current_visible_rows + 10 : current_visible_rows + (total_items - current_visible_rows)

    return {
      ...state,
      visible_rows: parseInt(new_visible_rows)
    }
  }

  if (action.type === 'FILL_TOOLTIPS') {
    return {
      ...state,
      tooltips: action.tooltips
    }
  }

  if (action.type === 'CACHE_EMPTY') {
    if (state.warning_empty_cache === null) {
      return {
        ...state,
        warning_empty_cache: action.msg
      }
    }
    return { ...state }
  }

  if (action.type === 'CLEAR_EMPTY_CACHE_WARNING') {
    return {
      ...state,
      warning_empty_cache: null
    }
  }

  if (action.type === 'CLEAR_UPLOADED_FILE') {
    if (state.uploaded_file) {
      return {
        ...state,
        uploaded_file: false
      }
    }
  }

  if (action.type === 'SET_ERROR_TIMEOUT') {
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.id]: {
            ...state.ranking.data[action.id],
            error_time_out: action.val
          }
        }
      }
    }
  }

  if (action.type === 'FILL_RELEVANCE_RANK_FOR_PAGE') {

    const array_ranking_data = Object.values(state.ranking.data)
    const start = (action.page * 10) < array_ranking_data.length ? action.page * 10 : null

    if (!start)
      return { ...state }

    const first_data = array_ranking_data.slice(0, start)
    const last_data = array_ranking_data.slice(start + 10, array_ranking_data.length)
    const end = ((start + 10) <= array_ranking_data.length) ? (start + 10) : array_ranking_data.length

    for (var i = start; i < end; i++) {
      const item = array_ranking_data[i]
      const relevances = action.data[i - start].map(item => {
        item.status = !item.error ? 'finish' : item.status
        item.module = item.title.toLowerCase().replace(/ /g, '_')
        return item
      })

      item.relevance = relevances
      first_data[i] = item
    }

    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: first_data.concat(last_data)
      }
    }
  }

  if (action.type === "HANDLE_FETCHING_ID") {
    let data = state.fetching_ids !== undefined ? (state.fetching_ids.length > Object.keys(state.ranking.data).length ? [] : state.fetching_ids) : []
    if (action.action === 'add') {
      for (var i = 0; i < action.ids.length; i++) {
        if (!data.includes(action.ids[i])) {
          data.push(action.ids[i])
        }
      }
    } else if (action.action === 'remove') {
      data = data.filter(item => !action.ids.includes(item))
    }

    return {
      ...state,
      fetching_ids: data
    }
  }

  if (action.type === "RELATED_SEARCHES_FINISHED") {
    return {
      ...state,
      related_searches_data: action.data
    }
  }

  if (action.type === "RELATED_SEARCHES_ERROR") {
    return {
      ...state,
      current_related_searches_message: true
    }
  }

  if (action.type === "EMPTY_RELATED_SEARCHES_ERROR") {
    return {
      ...state,
      current_related_searches_message: null
    }
  }

  if (action.type === "TOGGLE_PAGE_TITLE_OVERRIDE") {
    let page_title_override = state.ranking.data[action.itemID].page_title_override;
    let page_title_override_top_stories = (!state.ranking.data[action.itemID].page_title_override_top_stories) ? [] : state.ranking.data[action.itemID].page_title_override_top_stories;

    page_title_override_top_stories[action.storyIndex] = !page_title_override;

    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            page_title_override: !page_title_override,
            page_title_override_top_stories: page_title_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_TERM_IN_URL_OVERRIDE") {
    let term_in_url_override = state.ranking.data[action.itemID].term_in_url_override;
    let term_in_url_override_top_stories = (!state.ranking.data[action.itemID].term_in_url_override_top_stories) ? [] : state.ranking.data[action.itemID].term_in_url_override_top_stories;

    term_in_url_override_top_stories[action.storyIndex] = !term_in_url_override;


    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            term_in_url_override: !term_in_url_override,
            term_in_url_override_top_stories: term_in_url_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_KEYWORD_MATCH_H1_OVERRIDE") {
    let keyword_match_h1_override = state.ranking.data[action.itemID].keyword_match_h1_override;
    let keyword_match_h1_override_top_stories = (!state.ranking.data[action.itemID].keyword_match_h1_override_top_stories) ? [] : state.ranking.data[action.itemID].keyword_match_h1_override_top_stories;

    keyword_match_h1_override_top_stories[action.storyIndex] = !keyword_match_h1_override;


    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            keyword_match_h1_override: !keyword_match_h1_override,
            keyword_match_h1_override_top_stories: keyword_match_h1_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_CLICK_DEPTH_OVERRIDE") {
    let click_depth_override = state.ranking.data[action.itemID].click_depth_override;
    let click_depth_override_top_stories = (!state.ranking.data[action.itemID].click_depth_override_top_stories) ? [] : state.ranking.data[action.itemID].click_depth_override_top_stories;

    click_depth_override_top_stories[action.storyIndex] = !click_depth_override;


    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            click_depth_override: !click_depth_override,
            click_depth_override_top_stories: click_depth_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_INTERNAL_LINKS_COUNT_OVERRIDE") {
    let internal_links_count_override = state.ranking.data[action.itemID].internal_links_count_override;
    let internal_links_count_override_top_stories = (!state.ranking.data[action.itemID].internal_links_count_override_top_stories) ? [] : state.ranking.data[action.itemID].internal_links_count_override_top_stories;

    internal_links_count_override_top_stories[action.storyIndex] = !internal_links_count_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            internal_links_count_override: !internal_links_count_override,
            internal_links_count_override_top_stories: internal_links_count_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_EXTERNAL_LINKS_COUNT_OVERRIDE") {
    let external_links_count_override = state.ranking.data[action.itemID].external_links_count_override;
    let external_links_count_override_top_stories = (!state.ranking.data[action.itemID].external_links_count_override_top_stories) ? [] : state.ranking.data[action.itemID].external_links_count_override_top_stories;

    external_links_count_override_top_stories[action.storyIndex] = !external_links_count_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            external_links_count_override: !external_links_count_override,
            external_links_count_override_top_stories: external_links_count_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_IMAGES_COUNT_OVERRIDE") {
    let images_count_override = state.ranking.data[action.itemID].images_count_override;
    let images_count_override_top_stories = (!state.ranking.data[action.itemID].images_count_override_top_stories) ? [] : state.ranking.data[action.itemID].images_count_override_top_stories;

    images_count_override_top_stories[action.storyIndex] = !images_count_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            images_count_override: !images_count_override,
            images_count_override_top_stories: images_count_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_BACKLINKS_OVERRIDE") {
    let backlinks_override = state.ranking.data[action.itemID].backlinks_override;
    let backlinks_override_top_stories = (!state.ranking.data[action.itemID].backlinks_override_top_stories) ? [] : state.ranking.data[action.itemID].backlinks_override_top_stories;

    backlinks_override_top_stories[action.storyIndex] = !backlinks_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            backlinks_override: !backlinks_override,
            backlinks_override_top_stories: backlinks_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_LOAD_SPEED_OVERRIDE") {
    let load_speed_override = state.ranking.data[action.itemID].load_speed_override;
    let load_speed_override_top_stories = (!state.ranking.data[action.itemID].load_speed_override_top_stories) ? [] : state.ranking.data[action.itemID].load_speed_override_top_stories;

    load_speed_override_top_stories[action.storyIndex] = !load_speed_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            load_speed_override: !load_speed_override,
            load_speed_override_top_stories: load_speed_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "TOGGLE_SECURITY_CERTIFICATE_OVERRIDE") {
    let security_certificate_override = state.ranking.data[action.itemID].security_certificate_override;
    let security_certificate_override_top_stories = (!state.ranking.data[action.itemID].security_certificate_override_top_stories) ? [] : state.ranking.data[action.itemID].security_certificate_override_top_stories;

    security_certificate_override_top_stories[action.storyIndex] = !security_certificate_override;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        data: {
          ...state.ranking.data,
          [action.itemID]: {
            ...state.ranking.data[action.itemID],
            security_certificate_override: !security_certificate_override,
            security_certificate_override_top_stories: security_certificate_override_top_stories
          }
        }
      }
    }
  }

  if (action.type === "SAVED_LOCATIONS_FINISHED") {
    return {
      ...state,
      savedLocations: action.savedLocations
    }
  }

  if (action.type === "ADD_TO_SAVED_LOCATIONS") {
    let locations = state.savedLocations
    if (action.location.loc_id) {
      locations.push(action.location)
      locations.sort((a, b) => (a.canonical_name.split(',')[0] > b.canonical_name.split(',')[0]) ? 1 : -1)
      saveLocations(locations)
    }
    return {
      ...state,
      savedLocations: locations
    }
  }

  if (action.type === "REMOVE_FROM_SAVED_LOCATIONS") {
    let locations = state.savedLocations.filter(loc => parseInt(loc.loc_id) !== parseInt(action.location))

    saveLocations(locations)
    return {
      ...state,
      savedLocations: locations
    }
  }

  if (action.type === "GET_SENTIMENT_LIST") {
    return {
      ...state,
      sentimentOverridesList: action.data
    }
  }

  if (action.type === "GET_CATEGORY_LIST") {
    return {
      ...state,
      categoryOverridesList: action.data
    }
  }

  if (action.type === "EXPORT_LOADING") {
    return {
      ...state,
      export_loading: action.data
    }
  }

  if (action.type === "NEW_MESSAGE_OVERRIDES") {
    return {
      ...state,
      overridesAlert: action.data
    }
  }

  if (action.type === "REGENERATE_ACTION_PLAN") {
    return {
      ...state,
      regenerateActionPlan: action.data
    }
  }

  if (action.type === "ADD_LS_VER") {
    return {
      ...state,
      localstorage_version: action.data
    }
  }

  return state
}

const store = createStore(reducer)

store.subscribe(() => {
  saveState({
    state: store.getState()
  })
})

export default store
