import styled from 'styled-components';
import {styles} from './variables';
import {Row, Col} from 'reactstrap'


export const StatBoxesStyled = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  border: solid 1px ${styles.gray};
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  flex-grow: 1;
  padding: 0 !important;
  justify-content: center;
  align-items: flex-start;
`
export const StatBoxStyled = styled(Col)`
  flex: 0 0 ${({flexBasis}) => flexBasis || '25%'} !important;
  padding: 0.8rem 0;
  min-height: 15.3125rem;
  position: relative;
  @media screen and (max-width: ${styles.breakpoints.md}) {
    flex: 1 !important;
  }
  .container-stat-box{
    position: relative;
    min-height: 13.7125rem;
    &.border-stat-box {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: ${styles.gray};
      }
    }
  }
  p , span {
    font-size: 0.875em;
    color: ${styles.textColor};
    margin: 0;
    padding:0;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem auto;
    h3 {
      color: ${styles.textColor};
      font-size: 0.875em ;
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
    svg {
      margin-left: .5rem;
      min-width: 1rem;
      min-height: 1rem;
      path {
        fill: ${styles.primaryColor};
      }
    }
  }
  @media screen and (max-width: ${styles.breakpoints.md}) {
    flex-basis: 50%;
  }
  @media screen and (max-width: ${styles.breakpoints.sm}) {
    flex-basis: 100%;
  }
  background-color: #fff;
  
`
export const AdvertisingInfoStyledCPC = styled.div`
 .subtitle {
   font-size: 0.75em;
   font-weight: 600;
   line-height: 1.2em;
   text-transform: uppercase;
 }
   .value {
     font-size: 2.25em;
     color: #324A97;
     flex: 1;
     max-width: 100%;
     font-weight: 700;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     line-height: 1.2em;
   }
  .money {
    font-size: 0.75em;
    line-height: 1.2em;
  }
`
export const MonthlySearchesStyled = styled.div`
  .value { 
    font-size: ${({ isBigNumber}) => isBigNumber? '1.5em' : '2em'};
    color: #219653;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    line-height: 1.2em;
  }
  .percentage {
    font-size: 1.5em;
    color: ${ ({percent}) => percent >= 0 ? styles.green : styles.red};
    font-weight: 700;
    line-height: 0.7em;
    text-align: center;
  }
  .this-month{
    font-size: 0.75em;
    line-height: 0.7em;
  }
`

const getColorScore = (score) => {
    if(score <= 25){
      return styles.redls;
    }else if(score >= 26 && score < 51) {
      return styles.yellowls;
    }else if (score >= 51 && score < 76) {
       return styles.grayls
    }else if (score >= 76) {
       return styles.greenls;
    }
    
    else return styles.textColor
}
export const LightBoxScoreStyled = styled.div`
.value {
  font-size: 4.5em;
  transition: color .4s ease-in-out;
  color: ${({score}) => score? getColorScore(score) : styles.redls};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  line-height: 1.2em;
}
  .max,.min {
    font-size: 0.75em;
    line-height: 1em;
    span {
      font-weight: 700;
    }
  }
  .max span {
    color: ${styles.green};
  }
  .min span {
    color: ${styles.red};
  }
  
`

export const CategoryDistributionStyled = styled.div`
 p {
   margin:0;
   padding: 0;
   font-size: .75em;
   color: ${styles.textColor};
   font-family: ${styles.fontPrimary};
 }

`
export const StatBoxesScaled = styled(Row)`
  transform: scale(0.65);
  display: flex;
  flex-wrap: wrap;
  border: solid 1px ${styles.gray};
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  flex-grow: 1;
  padding: 0 !important;
  justify-content: center;
  align-items: flex-start;
`