import React, {Component} from 'react';
import {  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import menu from '../images/menu.png'
import '../css/MenuOptionsList.css'

class MenuOptionsList extends Component {
  
  constructor(props) {
    super(props)
    this.state= {
      toggle: false
    }
  }
  
  onEdit = () => {
      const { onEdit, item} = this.props
      onEdit(item);
  }
  
  onDelete = () => {
      const { onDelete, item} = this.props
      onDelete(item);
  }
  
  render(){
    const { item } = this.props
    return (
      <div className='MenuOptionsList'>
      <ButtonDropdown color='primary' size="sm" isOpen={this.state.toggle} toggle={()=> this.setState({toggle: !this.state.toggle})}>
        <DropdownToggle size="sm">
            <img src={menu} /> 
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.onEdit}>Edit</DropdownItem>
          <DropdownItem onClick={this.onDelete}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      </div>
    )
  }


}


export default MenuOptionsList