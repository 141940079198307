import React from 'react';
import { Collapse, Button, CardBody, Card, Badge, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMinus, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import '../../css/ActionPlan.css';
import ProgressBar from './ProgressBar';

const ActionPlan = ({
  showProgressBar,
  generateActionPlan,
  openBadge,
  handleBadge,
  loading,
  handlePlan,
  openModal,
  generatePlanMessage
}) => {

  return (
    <Col className='action-plan-badge' id="action-plan-badge" data-html2canvas-ignore="true">
      <div className="action-header" onClick={handleBadge}>
        <h3><Badge className='mr-2 p-1'>New</Badge></h3>
        <span className='action-toggle'>
          Customized action plan
          <FontAwesomeIcon className="toggle-icon" icon={!openBadge ? faEye : faMinus} />
        </span>
      </div>
      <Collapse isOpen={openBadge}>
        <Card className='badge-card'>
          <CardBody>
            <CardTitle className='mt-1'>Detect threats, find opportunities, take action.</CardTitle>
            <CardText className='card-content mt-3'>
              Click the button below to generate a custom action plan that identifies threats and uncovers opportunities to create or optimize content. Your action plan will find next steps to take and provide resources that help you improve search results through best practices.
              <br /><br />
              We can generate your plan in minutes!
            </CardText>
            {handlePlan ? (
              <Button onClick={openModal} className='action-plan-button'>
                <FontAwesomeIcon className="clipboard-icon mr-2" icon={faClipboardList} />
                View Plan
              </Button>
            ) : (
              <Button onClick={generateActionPlan} disabled={showProgressBar} className='action-plan-button'>
                <FontAwesomeIcon className="clipboard-icon mr-2" icon={faClipboardList} />
                Generate Plan
              </Button>
            )}
          </CardBody>
          <Row className="actionMessage">
            {generatePlanMessage !== '' && (<p className="save-warning-plan">{generatePlanMessage}</p>)}
          </Row>
          {
            showProgressBar && !handlePlan && generatePlanMessage === '' && (
              <ProgressBar loading={loading} />
            )}
        </Card>
      </Collapse>
    </Col>
  )
}

export default ActionPlan
