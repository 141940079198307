import { element } from 'prop-types';
import * as XLSX from 'xlsx'
import { getCpcEstimated } from './cpc';

const FILENAME = 'Report.xlsx';
const FIRST_SHEET_NAME = 'General Data';
const SECOND_SHEET_NAME = 'Search Results';
const THIRD_SHEET_NAME = 'Related Searches';

const REQUIRED_CATEGORIES = [
  'socialMedia',
  'corporateSite',
  'wikipedia',
  'newsMedia',
  'thirdParty',
  'unCategorized',
]

const generateWorksheet = () => {
  const worksheet = XLSX.utils.json_to_sheet([]);
  return worksheet;
}

const generateFirstSheetGeneralData = (search, date, stats) => {
  const { main = '', canonical_name = '' } = search;
  const { lumentus_score = '0', searches = {} } = stats;
  const { search_volume = 0, competition = 0, cpc = 0 } = searches;

  return [
    [
      'Search term',
      main,
    ],
    [
      'Date',
      date,
    ],
    [
      'Location',
      canonical_name,
    ],
    [
      'Lightbox Score',
      Math.round(lumentus_score),
    ],
    [
      'Monthly searches',
      search_volume,
    ],
    [
      'Advertising info (CPC in USD)',
      cpc,
    ],
    [
      'Competition',
      competition,
    ],
  ]
}

const calculatePercentages = (ranking) => {
  const categoriesPercentages = ['Category Distribution'];
  const eachCategoryAppearances = {};
  const { data = {} } = ranking;
  const arrayFromData = Object.values(data);
  const numberOfResults = arrayFromData.length;

  arrayFromData.forEach(({ category }) => {
    if (eachCategoryAppearances[category]) {
      eachCategoryAppearances[category]++;
    } else {
      eachCategoryAppearances[category] = 1;
    }
  });

  REQUIRED_CATEGORIES.forEach(category => {
    const percentageValue = eachCategoryAppearances[category] ? eachCategoryAppearances[category] : 0;
    const percentage = ((percentageValue * 100) / numberOfResults).toFixed(2);
    const percentageString = `${percentage}%`;
    categoriesPercentages.push(percentageString);
  });

  return categoriesPercentages;
}

const getCategoryDistribution = (ranking) => {
  const categoriesDistribution = [
    [
      'Element',
      'Social media',
      'Corporate site',
      'Wikipedia',
      'News',
      'Third-party competitive',
      'Uncategorized'
    ],
  ]
  const percentages = calculatePercentages(ranking);
  categoriesDistribution.push(percentages);
  return categoriesDistribution;
}

const getSentimentDistribution = (stats) => {
  const { sentiment = {} } = stats;
  const {
    positive = 0,
    negative = 0,
    neutral = 0,
    unselected = 0
  } = sentiment;
  const totalResults = Object.values(sentiment).reduce((sum, value) => sum + value, 0);
  const sentimentRows = [
    [
      'Element',
      'Positive',
      'Negative',
      'Neutral',
      'Unselected',
    ],
    [
      'Sentiment Distribution',
      `${((positive * 100) / totalResults).toFixed(2)}%`,
      `${((negative * 100) / totalResults).toFixed(2)}%`,
      `${((neutral * 100) / totalResults).toFixed(2)}%`,
      `${((unselected * 100) / totalResults).toFixed(2)}%`,
    ],
  ];

  return sentimentRows;
}

const generateFirstSheet = (search, date, stats, ranking) => {
  const worksheet = generateWorksheet();
  const generalData = generateFirstSheetGeneralData(search, date, stats);
  const sentimentDistribution = getSentimentDistribution(stats);
  const categoriesDistribution = getCategoryDistribution(ranking);

  XLSX.utils.sheet_add_aoa(worksheet, generalData, { origin: 'A1' });
  XLSX.utils.sheet_add_aoa(worksheet, sentimentDistribution, { origin: 'A8' });
  XLSX.utils.sheet_add_aoa(worksheet, categoriesDistribution, { origin: 'A11' });

  return worksheet;
}

const getSecondSheetHeader = (search, date) => {
  const { main = '' } = search;

  return [
    [
      'Search term',
      main
    ],
    [
      'Date',
      date
    ]
  ];
}

const generateSecondSheetTableContent = (ranking, date) => {

  const { data = {} } = ranking;
  const tableContent = [
    [
      'Search rank',
      'URL',
      'Date',
      'Title',
      'Excerpt',
      'Sentiment',
      'Category',
      'SEO Analysis',
      'Domain Authority'
    ],
  ]

  for (let i = 0; ; i++) {
    if (!data[i]) {
      break;
    }

    const currentData = data[i];
    const {
      resume = [],
      sentiment = 'N/A',
      category = '',
      totalScore = 'N/A',
      type = 'N/A',
      relevance = []
    } = currentData;
    const [resumeData = []] = resume;
    const { url = 'N/A', title = 'N/A', extract = 'N/A' } = resumeData;

    if (type === 'top_stories') {
      resume.forEach(({
        title = 'N/A',
        description = 'N/A',
        url = 'N/A',
        sentiment = 'N/A',
        domainAuthority = 'N/A',
        score = 'N/A'
      }) => {
        tableContent.push([
          i + 1,
          url,
          date,
          title,
          description,
          sentiment,
          category,
          score,
          domainAuthority
        ]);
      })
    }
    else {
      const { score = 'N/A' } = relevance.find(module => module.module === 'domain_authority');
      tableContent.push([
        i + 1,
        url,
        date,
        title,
        extract,
        sentiment,
        category,
        totalScore,
        score
      ]);
    }
  }

  return tableContent;
}

const generateSecondSheet = (search, ranking, date) => {
  const worksheet = generateWorksheet();
  const header = getSecondSheetHeader(search, date);
  const tableContent = generateSecondSheetTableContent(ranking, date);

  XLSX.utils.sheet_add_aoa(worksheet, header, { origin: 'A1' });
  XLSX.utils.sheet_add_aoa(worksheet, tableContent, { origin: 'A4' });

  return worksheet;
}

const getThirdSheetContent = (relatedSearches) => {
  const tableContent = [
    [
      'Related Searches',
      'Volume',
      'CPC (USD)',
      'Competition'
    ]
  ];

  relatedSearches.forEach(({
    displayText,
    search_volume,
    cpc,
    competition
  }) => {
    tableContent.push(
      [
        displayText,
        search_volume,
        cpc,
        `${competition} (${getCpcEstimated(competition)})`
      ]
    )
  });

  return tableContent;
}

const generateThirdSheet = (relatedSearches) => {
  const worksheet = generateWorksheet();
  const tableContent = getThirdSheetContent(relatedSearches);

  XLSX.utils.sheet_add_aoa(worksheet, tableContent, { origin: 'A1' });
  return worksheet;
}

export const exportToXLXS = (state = {}) => {
  const {
    search = {},
    stats = {},
    ranking = {},
    related_searches_data = [],
  } = state;

  const workbook = XLSX.utils.book_new();
  const date = new Date();

  const firstWorksheet = generateFirstSheet(search, date, stats, ranking);
  const secondWorksheet = generateSecondSheet(search, ranking, date);
  const thirdWorksheet = generateThirdSheet(related_searches_data);

  XLSX.utils.book_append_sheet(workbook, firstWorksheet, 'General Data');
  XLSX.utils.book_append_sheet(workbook, secondWorksheet, 'Searches');
  XLSX.utils.book_append_sheet(workbook, thirdWorksheet, 'Related Searches');
  
  XLSX.writeFile(workbook, FILENAME);
}