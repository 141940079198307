import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Alert, Button, Table ,} from "reactstrap";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

import Row from './Row';
import Loading from '../../../common/Loading';

import fetchClient from '../../../../helpers/fetch-client';

const TitleHeader = [
    'Term',
    'Location Code',
    'Period',
    'Start Date',
    'End Date',
    'Automated Pages',
    'File Path',
    'Status'
]

const CSVData = [
    ["Term (search term)", "Location code (2840)", "Start Date (mm/dd/yyyy)", "End Date (mm/dd/yyyy)", "Automated Pages(1-10)", "Period (daily, weekly, monthly)", "File path (/folder)"]
]

const locations_url = process.env.REACT_APP_API_ENDPOINT + '/get-list-of-locations';

export default class TableBatchSchedule extends React.Component {
    constructor(props) {
        super(props);
        const auth_info = JSON.parse(localStorage.getItem('token_info'));
        this.state = {
            file_upload_state   :false,
            is_loading          :true,
            schedules           : [],
            failed_schedules    : [],
            locations_csv       : "",
            headers : {
                'content-type': 'multipart/form-data',
                'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : '',
            }
        };
        this.input_reference = React.createRef();
        this.donwloadLocationsCsv();
    }

    fileUploadAction = () =>this.input_reference.current.click();

    fileUploadInputChange = (e) =>{
        let confirm = window.confirm("Are you sure you want to process "+e.target.files[0].name+"?");
        if(confirm) {
            this.setState({file_upload_state:e.target.value});
            this.uploadCsvFile(e.target.files[0]);
        }
        e.target.value = null;
    };

    uploadCsvFile = (file) => {
        this.setState({is_loading: true});
        const headers =  {
            headers: this.state.headers,
        };
        const formData = new FormData();
        formData.append('csv_file',file);
        this.setState({is_loading: true});

        let endpoint = process.env.REACT_APP_API_ENDPOINT + '/batchSchedule';
        axios.post(endpoint, formData, headers)
            .then((response)=> {
                this.setState({
                    is_loading          : false,
                    schedules           : response.data.schedules,
                    failed_schedules    : response.data.failed_schedules,
                    errors              : false,
                    success             : true,
                });
            })
            .catch(
                (error) => {
                    this.setState({
                        is_loading          : false,
                        schedules           : [],
                        failed_schedules    : [],
                        errors              : error.response.data.errors,
                        success             : false,
                    });
                }
            )
            .finally((response) => {
                this.setState({is_loading: false})
            });
    }

    confirmError = () => this.setState({errors: ''})

    donwloadLocationsCsv = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + '/get-list-of-locations';
        fetchClient().get(url)
            .then( (response) => {
                if (response.status === 200){
                    let csvData = response.data.data.split('\r\n');
                    let locations = [];
                    for (let i=0; i<csvData.length; i++) {
                        if (i==0) {continue}
                        let locationRow = csvData[i].replace(',,',',"",')
                        let locationRowData = locationRow.split('","')
                        let loc_name_canonical = locationRowData[3]
                        let loc_name = locationRowData[2]
                        if (loc_name != null && loc_name != undefined) {
                            let loc_name_lowercase = loc_name.toLowerCase();
                            locations.push({"loc_id": locationRowData[0].replace('"',''), "canonical_name": loc_name_canonical, "loc_name_lowercase": loc_name_lowercase})
                        }
                    }
                    locations.sort((a, b) => (a.canonical_name.split(',')[0] > b.canonical_name.split(',')[0]) ? -1 : 1);
                    this.setState({
                        is_loading      : false,
                        locations_csv   : locations,
                    });
                }
            })
    }

    render() {
        const { schedules, failed_schedules, errors, success, locations_csv } = this.state;
        return (
            <TableWrapper>
                <h1 className="title"> Batch Schedules</h1>
                {
                    errors ?
                    <Alert color="danger" style={{ marginTop: '10px'}}>{errors}</Alert>
                    : success ? <Alert color="success" style={{ marginTop: '10px'}}>Created {schedules.length} schedules and {failed_schedules.length} failed</Alert> : null
                }
                <div className='header-table'>
                    <input type="file" hidden ref={this.input_reference} onChange={this.fileUploadInputChange} />
                    <Button className='btn-add' onClick={this.fileUploadAction}>
                        <FontAwesomeIcon className="icon-toggle mr-1" icon='plus' color="#35A9E0"/>Upload CSV File
                    </Button>
                    <CSVLink data={locations_csv} filename={"locations.csv"}>
                        <Button className='btn-add'>
                            <FontAwesomeIcon className="icon-toggle mr-1" icon='arrow-down' color="#35A9E0"/> Locations CSV
                        </Button>
                    </CSVLink>
                    <CSVLink data={CSVData} filename={"schedules-template.csv"}>
                        <Button className='btn-add'>
                            <FontAwesomeIcon className="icon-toggle mr-1" icon='arrow-down' color="#35A9E0"/> CSV Template
                        </Button>
                    </CSVLink>
                    <Link to="/schedule">
                        <Button className='btn-add'>
                            <FontAwesomeIcon className="icon-toggle mr-2" icon='arrow-left' color="#35A9E0"/> Back
                        </Button>
                    </Link>
                </div>
                <div style={{overflowX:"auto"}}>
                <TableStyle  hover>
                    <thead>
                        <tr>
                            {
                                TitleHeader.map((item, i) => <th>{item}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            failed_schedules.map((item, order) =>
                                <Row
                                    item={item.schedule}
                                    messages={item.messages}
                                    order={order}
                                    success={false}
                                />
                            )
                        }
                        {
                            schedules.map((item, order) =>
                                <Row
                                    item={item}
                                    messages={{}}
                                    order={order}
                                    success={true}
                                />
                            )
                        }
                    </tbody>
                </TableStyle>
                </div>
                <Loading loading={this.state.is_loading}/>
            </TableWrapper>
        );
    }
}

const TableWrapper = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  color: #5b666f;
  .btn {
    font-size: 1em;
  }

  .title {
    font-weight: 600;
    font-size: 1.5em;
    color: #4F4F4F;
    margin: 0;
    padding: 1.2rem;
    font-weight:700;
    text-align: start;
    flex: 1;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  .header-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(2% + .5rem) 1.2rem;

    .btn-add {
      color: #5b666f !important;
      font-weight:700;
      background-color: transparent !important;
      outline: none !important;
      border: solid 0.0625rem #D4E2EB !important;
      box-shadow: 0 0 0.0625rem #D4E2EB;

      &:hover {
        opacity: .8;
      }

      &:active {
        opacity: .7;
      }
    }
  }

  .subtitle {
    font-weight: 700;
    font-size: 1.125em;
    flex: 1;
    color: #212529;
    margin: 0;
    padding: 0;
    text-align: start;
    //border-bottom: solid 1px rgba(0,0,0,0.1);
  }
`
const TableStyle = styled(Table)`
  overflow-x: scroll;
  color: #5b666f;
  th, td {
    line-height: 1em;
    color: #5b666f !important;
    text-align: start !important;
    vertical-align: middle;
    max-width: 10rem;
    padding: .4rem;
    &:first-child {
      padding-left: 1.2rem;
    }
    line-height: 1.3em;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  thead {
    background-color: #F9F9F9;

    th {
      padding: 1.3rem .5rem;
      font-weight: 700;
      color: #4F4F4F;
      font-size: 0.9375em;
    }
  }
`
