import React from 'react'
import styled from 'styled-components'

import Table from './Table'
import Layout from '../Layout'
//import '../../../css/Schedules.css'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const TitleHeader = [
    '',
    'Schedule Name',
    'Location',
    'Frequency',
    'Date range',
    'Created',
    'Options'
]

export default class ScheduleBatch extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Layout>
                <Table/>
            </Layout>
        )
    }
}
