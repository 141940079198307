const changeCategory = (rankItemId, newCategory, override_id = 0) => {
  return {
    type: "CHANGE_CATEGORY",
    itemID: Number(rankItemId),
    newCategory,
    override_id
  }
}
const changeSentiment = (rankItemId, newSentiment, override_id = 0, topStoryIndex = null) => {
  return {
    type: "CHANGE_SENTIMENT",
    itemID: rankItemId,
    newSentiment,
    override_id,
    topStoryIndex
  }
}
const changeControlled = (rankItemId, controlled) => {
  return {
    type: "CHANGE_CONTROLLED",
    itemID: rankItemId,
    controlled
  }
}
const changeCompetitive = (rankItemId, competitive) => {
  return {
    type: 'CHANGE_COMPETITIVE',
    itemID: rankItemId,
    competitive
  }
}
const changeAdControlled = (controlled) => {
  return {
    type: "CHANGE_AD_CONTROLLED",
    controlled
  }
}
const changeAdCompetitive = (competitive) => {
  return {
    type: 'CHANGE_AD_COMPETITIVE',
    competitive
  }
}
const switchRanking = (oldRankPos, newRankPos) => {
  return {
    type: "RANKING_SWITCH",
    oldRankPos,
    newRankPos
  }
}
const setInProgressModules = (rankItemId, itemsToRequest) => {
  return {
    type: "SET_MODULE_STATUS_IN_PROGRESS",
    itemID: rankItemId,
    itemsToRequest
  }
}
const set_relevance = (rankItemId, data) => {
  return {
    type: "SET_RELEVANCE",
    itemID: rankItemId,
    data
  }
}
const calculateLumentusScore = () => {
  return {
    type: "CALCULATE_LUMENTUS_SCORE"
  }
}
const search = (term) => {
  return {
    type: "SEARCH",
    term
  }
}
const subSearch = (term) => {
  return {
    type: "SORT_BY_TERM",
    term
  }
}
const search_finished = (term, response, loc_id, canonical_name) => {
  return {
    type: "SEARCH_FINISHED",
    term,
    loc_id,
    canonical_name,
    data: response.data,
    related_searches: response.related_searches,
    knowledge_graph: response.knowledge_graph,
    using_location: response.using_location,
    ads: response.paid,
  }
}
const monthly_search_finished = (data) => {
  return {
    type: "MONTHLY_SEARCH_FINISHED",
    data
  }
}

const monthly_search_error = () => {
  return {
    type: "MONTHLY_SEARCH_ERROR"
  }
}

const clearSearch = () => {
  return {
    type: "CLEAR_SEARCH"
  }
}

const exportChanges = () => {
  return {
    type: "EXPORT_STORE_TO_FILE"
  }
}

const importFileToStore = (file, pathfile) => {
  return {
    type: "IMPORT_FILE_TO_STORE",
    file,
    pathfile
  }
}

const importAutomatedFileToStore = (file, pathfile) => {
  return {
    type: "IMPORT_AUTOMATED_FILE_TO_STORE",
    file,
    pathfile
  }
}

const change_score_manually = (id, value) => {
  return {
    type: "CHANGE_SCORE_MANUALLY",
    itemID: id,
    value
  }
}

const knowledge_graph_finished = (response) => {
  return {
    type: "KNOWLEDGE_GRAPH_FINISHED",
    data: response
  }
}

const knowledge_graph_error = () => {
  return {
    type: "KNOWLEDGE_GRAPH_ERROR"
  }
}

const emptyKnowledgeGraph = () => {
  return {
    type: "EMPTY_KNOWLEDGE_GRAPH"
  }
}

const activate_score_bonus_knowledge_graph = () => {
  return {
    type: "ACTIVATE_SCORE_BONUS_KNOWLEDGE_GRAPH"
  }
}

const emptyMonthlySearchError = () => {
  return {
    type: "EMPTY_MONTHLY_SEARCH_ERROR"
  }
}

const saveTokenInfo = (data) => {
  return {
    type: "SAVE_TOKEN_INFO",
    data
  }
}

const setUserLoggedInfo = (data) => {
  return {
    type: "SET_USER_LOGGED_INFO",
    data
  }
}

const logOut = (status = null) => {
  return {
    type: "LOGOUT",
    status: status
  }
}

const saveUserFS = (files) => {
  return {
    type: "SAVE_USER_FILES_STRUCTURE",
    files
  }
}

const changeCurrentPath = (data) => {
  return {
    type: "CHANGE_USER_FS_CURRENT_PATH",
    path: data
  }
}

const refreshFilesList = (id) => {
  return {
    type: "REFRESH_FILES_LIST",
    id
  }
}

const decrementVisibleRows = () => {
  return {
    type: "DECREMENT_VISIBLE_ROWS"
  }
}

const incrementVisibleRows = () => {
  return {
    type: "INCREMENT_VISIBLE_ROWS"
  }
}

const fillTooltips = (tooltips) => {

  return {
    type: "FILL_TOOLTIPS",
    tooltips
  }
}

const setWarningEmptyCache = (msg) => {
  return {
    type: "CACHE_EMPTY",
    msg
  }
}

const clearEmptyCacheWarning = () => {
  return {
    type: "CLEAR_EMPTY_CACHE_WARNING"
  }
}

const clearUploadedFile = () => {
  return {
    type: "CLEAR_UPLOADED_FILE"
  }
}

const setErrorTimeOut = (id, val) => {
  return {
    type: "SET_ERROR_TIMEOUT",
    id,
    val
  }
}

const fillDataForPage = (data, page) => {
  return {
    type: "FILL_RELEVANCE_RANK_FOR_PAGE",
    data,
    page
  }
}

const handleFetchingIdToStore = (ids, action) => {
  return {
    type: "HANDLE_FETCHING_ID",
    ids,
    action
  }
}

const related_searches_finished = (data) => {
  return {
    type: "RELATED_SEARCHES_FINISHED",
    data
  }
}
const setEmailsReport = (value) => {
  return {
    type: "SET_REPORT_EMAIL",
    value
  }
}
const related_searches_error = () => {
  return {
    type: "RELATED_SEARCHES_ERROR"
  }
}

const emptyRelatedSearchesError = () => {
  return {
    type: "EMPTY_RELATED_SEARCHES_ERROR"
  }
}

const toggle_page_title_override = (id, story_index) => {
  return {
    type: "TOGGLE_PAGE_TITLE_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_term_in_url_override = (id, story_index) => {
  return {
    type: "TOGGLE_TERM_IN_URL_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_keyword_match_h1_override = (id, story_index) => {
  return {
    type: "TOGGLE_KEYWORD_MATCH_H1_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_click_depth_override = (id, story_index) => {
  return {
    type: "TOGGLE_CLICK_DEPTH_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_internal_links_count_override = (id, story_index) => {
  return {
    type: "TOGGLE_INTERNAL_LINKS_COUNT_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_external_links_count_override = (id, story_index) => {
  return {
    type: "TOGGLE_EXTERNAL_LINKS_COUNT_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_images_count_override = (id, story_index) => {
  return {
    type: "TOGGLE_IMAGES_COUNT_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_backlinks_override = (id, story_index) => {
  return {
    type: "TOGGLE_BACKLINKS_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_load_speed_override = (id, story_index) => {
  return {
    type: "TOGGLE_LOAD_SPEED_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const toggle_security_certificate_override = (id, story_index) => {
  return {
    type: "TOGGLE_SECURITY_CERTIFICATE_OVERRIDE",
    itemID: id,
    storyIndex: story_index
  }
}

const savedLocationsFinished = (savedLocations) => {
  return {
    type: "SAVED_LOCATIONS_FINISHED",
    savedLocations
  }
}

const addToSavedLocations = (location) => {
  return {
    type: "ADD_TO_SAVED_LOCATIONS",
    location
  }
}

const removeFromSavedLocations = (location) => {
  return {
    type: "REMOVE_FROM_SAVED_LOCATIONS",
    location
  }
}

const changeAutomation = () => {
  return {
    type: "CHANGE_AUTOMATION"
  }
}
const changePagesAutomate = (numberPages) => {
  return {
    type: "CHANGE_PAGES_AUTENTICATE",
    data: numberPages
  }
}

const exportLoading = (status) => {
  return {
    type: "EXPORT_LOADING",
    data: status
  }
}

const updateOverrides = (data) => {
  return {
    type: "UPDATE_OVERRIDES_LIST",
    data: data
  }
}

const setOverride = (data) => {
  return {
    type: "SET_OVERRIDE",
    data: data
  }
}

const regenerateActionPlan = (data) => {
  return {
    type: "REGENERATE_ACTION_PLAN",
    data: data
  }
}

const addLocalStorageVer = (data) => {
  return {
    type: "ADD_LS_VER",
    data: data
  }
}

export {
  changeCompetitive,
  setOverride,
  updateOverrides,
  changePagesAutomate,
  changeAutomation,
  changeCategory,
  changeSentiment,
  setInProgressModules,
  calculateLumentusScore,
  search,
  search_finished,
  subSearch,
  switchRanking,
  clearSearch,
  exportChanges,
  importFileToStore,
  set_relevance,
  monthly_search_finished,
  monthly_search_error,
  change_score_manually,
  knowledge_graph_finished,
  knowledge_graph_error,
  emptyKnowledgeGraph,
  activate_score_bonus_knowledge_graph,
  emptyMonthlySearchError,
  saveTokenInfo,
  setUserLoggedInfo,
  logOut,
  changeCurrentPath,
  saveUserFS,
  refreshFilesList,
  decrementVisibleRows,
  incrementVisibleRows,
  fillTooltips,
  setWarningEmptyCache,
  clearEmptyCacheWarning,
  clearUploadedFile,
  setErrorTimeOut,
  fillDataForPage,
  handleFetchingIdToStore,
  related_searches_finished,
  related_searches_error,
  emptyRelatedSearchesError,
  toggle_page_title_override,
  toggle_term_in_url_override,
  toggle_keyword_match_h1_override,
  toggle_click_depth_override,
  toggle_internal_links_count_override,
  toggle_external_links_count_override,
  toggle_images_count_override,
  toggle_backlinks_override,
  toggle_load_speed_override,
  toggle_security_certificate_override,
  savedLocationsFinished,
  addToSavedLocations,
  removeFromSavedLocations,
  exportLoading,
  changeControlled,
  importAutomatedFileToStore,
  setEmailsReport,
  changeAdControlled,
  changeAdCompetitive,
  regenerateActionPlan,
  addLocalStorageVer
}
