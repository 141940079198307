import React from 'react'
import { SEOBodyStyle } from '../../styles/SEOModal'
import SEOBodyTable from './SEOBodyTable'
import SEOBodyWarnings from './SEOBodyWarnings'

const SEOBody = ({ data, warnings }) => {
    return (
        <SEOBodyStyle>
            <div className="seo-table">
                <SEOBodyTable data={data} />
            </div>
            { warnings &&
                <div className="seo-warnings">
                    <SEOBodyWarnings warnings={warnings} />
                </div>
            }        
        </SEOBodyStyle>
    )
}

export default SEOBody
