import React from 'react'
import { SEOCloseButtonStyle } from '../../styles/SEOModal'

const SEOCloseButton = ({ btnText, handleOnClick }) =>
    <SEOCloseButtonStyle>
        <button className='btn' onClick={handleOnClick} data-html2canvas-ignore="true">
            <span className="cross"><img src={require('../../images/icons/seo-cross-button.svg').default} alt='' /></span> {btnText}
        </button>
    </SEOCloseButtonStyle>

export default SEOCloseButton
