import React from 'react'
import ReactTooltip from 'react-tooltip'
import { Col, Row } from 'reactstrap'
import Checked from '../../images/icons/checkbox-checked.svg'
import UnChecked from '../../images/icons/checkbox-unchecked.svg'
import '../../css/Tooltip.css'


const SEOCheckboxTooltip = ({ checked, onChange, tooltip }) => {
    return (
        <>
            <span style={{ width: 'fit-content' }} data-tip data-for={'tooltip-' + tooltip?.code}>
                <label>
                    <input type='checkbox' checked={checked} onChange={onChange} style={{display:'none'}}/>
                    <span className='seo-checkbox' style={{maxWidth: 'none'}}>
                        <img
                            src={checked ? Checked : UnChecked}   
                            alt='' 
                        />
                    </span>                
                </label> 
            </span>
            <ReactTooltip className='tooltip-width' border={true} type="info" effect="solid" id={'tooltip-' + tooltip?.code}>
                <Row className="tooltipBody">
                    <Col xs={12} md={12} lg={12}>
                        {(tooltip?.name) ?
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <h6><b>{tooltip.name}</b></h6>
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                        {(tooltip?.content) ?
                            <Row>
                                <Col xs={12}>
                                    <div className="tooltipContent" >
                                        <i>{tooltip.content}</i>
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                        {(tooltip?.description) ?
                            <Row>
                                <Col xs={12}>
                                    <div className="tooltip-body-width">
                                        {tooltip.description}
                                    </div>
                                </Col>
                            </Row>
                            : ''
                        }
                    </Col>
                </Row>
            </ReactTooltip>
        </>
    )
}

export default SEOCheckboxTooltip
