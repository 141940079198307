const validateEmail = (term) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(term).toLowerCase())
}

const validateUrl = (term) => {
    const regex = /^(ftp:\/\/ftp\.|http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|htt:\/\/|:\/\/|)?[a-z0-9@]+([\-\.]{1}[a-z0-9@]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    return regex.test(String(term).toLowerCase())
}

const validatePassword = (password) => {
	var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/
	return regex.test(String(password))
}

export { validateEmail, validateUrl, validatePassword }