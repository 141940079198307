import React from 'react'

const SEOWarningItem = ({ title, description }) => {
    return (
        <div className='warning-item-box'>
            <div className="warning-icon-box">
                <img src={require('../../images/icons/seo-warning.svg').default} alt=''/>
            </div>
            <div className="warning-content">
                <p className="warning-item-title">{title}</p>
                <p className="warning-item-description">{description}</p>
            </div>
        </div>
    )
}

export default SEOWarningItem
