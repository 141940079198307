import React, { Component } from 'react'
import axios from 'axios'
import Select from 'react-select'
import Spinner from './Spinner'

class TermsByUserSelect extends Component {

  constructor(props) {
    super(props)
    this._isMounted = false

    const auth_info = JSON.parse(localStorage.getItem('token_info'))
    const data = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
      }
    }

    this.state = {
      headers:data,
      loading:false,
      keywords: [{value:0, label:"Select Term"}],
      message: ''
    }

    this.handleSelectTerm = this.handleSelectTerm.bind(this)
    this.fetchTermsByUser = this.fetchTermsByUser.bind(this)
    this.resetParentTerm = this.resetParentTerm.bind(this)
  }
  
  componentDidMount() {
    this._isMounted = true
    this.fetchTermsByUser()
    this.searchTermsSelect()

  }

  searchTermsSelect(){
    const selected_term = this.state.keywords.filter(item=>{return item.value === this.props.term})
    const val = this.props.term !== '' && selected_term.length ? selected_term[0] : this.state.keywords.filter((item)=> {return item.value == 0} )
    this.props.term && val[0].value === 0 ? this.resetParentTerm() : null
  }

  fetchTermsByUser() {
    this._isMounted && this.setState({ loading: true })
    let url = process.env.REACT_APP_API_ENDPOINT + '/terms-by-user'
    let me = this
    axios.get(url, this.state.headers)
    .then((response) => {
      if (response.status !== 200) {
        throw response.message
      } else {
        if (response.data.length) {
          const keywords = response.data.map(item=>{return {value:item, label:item}})
          const kws = this.state.keywords.concat(keywords)
  
          me._isMounted && me.setState({keywords:kws})
        }else{
          me._isMounted && me.props.showMessage({message:'User has not previous searches', message_type: 'info'})
        }
      }

    }).catch(function (error) {
      me._isMounted && me.props.showMessage({ message: 'Can\'t get the user terms.', message_type: 'error' })
    }).finally(() => {
      me._isMounted && me.setState({ loading: false })
    })
  }

  handleSelectTerm(obj) {
    this.props.handleChange(obj.value)
  }
  resetParentTerm() {
    this.props.resetStateTerm()
  }
    
  render() {
    const selected_term = this.state.keywords.filter(item=>{return item.value === this.props.term})
    const val = this.props.term !== '' && selected_term.length ? selected_term[0] : this.state.keywords.filter((item)=> {return item.value == 0} )

    return (
    <div className="dashboard-component">
      <div className="table-responsive">
      { this.state.loading ?
          <div className='icon-search-loading  terms-select-loading'><Spinner/></div>
        :
        <Select className="dashboard-select"
        isSearchable={true}
        value={val}
        onInputChange ={ inputValue =>(inputValue.length <= 20 ? inputValue : inputValue.substr(0, 20))}
        onChange={this.handleSelectTerm}
        options={this.state.keywords}
        />
      }
      </div>
    </div>)
  }
}

export default TermsByUserSelect