import React, { useState, useEffect } from 'react'
import ActionPlanModal from './ActionPlanModal'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ActionPlanModalContainer = ({ modal, openModal, data }) => {
  const [screenshot, setScreenshot] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [expanded, setExpanded] = useState({
    Threats: true,
    Opportunities: true,
    Warnings: true,
    toExport: false,
    prevThreats: true,
    prevOpportunities: true,
    prevWarning: true
  });

  useEffect(() => {
    const { toExport } = expanded

    if (!toExport) return

    setTimeout(exportPdf, 300)

  }, [expanded])

  const formatDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('');
  }

  const exportPdf = () => {
    const input = document.getElementById('action-plan-modal');
    document.getElementById("learn-more-action-plan").style.visibility = 'hidden';

    html2canvas(input, getHtml2CanvasOptions(input))
      .then((canvas) => {
        let width = canvas.width;
        let height = canvas.height;
        let millimeters = {
          width: Math.floor(width * 0.265),
          height: Math.floor(height * 0.249)
        }

        let imgData = canvas.toDataURL('image/jpeg')
        let doc = new jsPDF("p", "mm", "a4")
        doc.deletePage(1)
        doc.addPage(millimeters.width, millimeters.height)
        doc.addImage(imgData, 'JPEG', 0, 0, null, null, "lightbox", 'MEDIUM')
        let sufix = formatDate()
        doc.save('Lightbox-ActionPlan' + sufix + '.pdf')

        let titles = document.getElementsByClassName('action-plan-issues');
        for (let i = 0; i < titles.length; i++) {
          titles[i].style.maxHeight = '';
          titles[i].style.display = '';
        }
      })
      .finally(() => {
        const { prevThreats, prevOpportunities, prevWarning } = expanded
        let paginateButtons = document.getElementsByClassName('paginate-btn')
        for (let i = 0; i < paginateButtons.length; i++) {
          paginateButtons[i].style.display = 'block';
        }
        document.getElementById("learn-more-action-plan").style.visibility = '';
        setIsMounted && setScreenshot(false)
        document.getElementById('action-plan-issues').parentNode.style.overflowY = 'auto';
        showBtnCloseModal(true)
        showModalBorder(true)
        showBodyScroll(true)
        setExpanded({
          Threats: prevThreats,
          Opportunities: prevOpportunities,
          Warnings: prevWarning,
          toExport: false
        })
      })
  }

  const showBtnCloseModal = (isVisible) => {
    let header = document.getElementsByClassName('action-plan-modal-header').item(0),
      btnClose = header.getElementsByClassName('close').item(0)

    btnClose.style.display = isVisible ? 'block' : 'none'
  }

  const showModalBorder = (isVisible) => {
    let content = document.getElementById('action-plan-modal').firstChild
    if (!isVisible) {
      content.classList.remove('modal-content')
      content.style.backgroundColor = "#FFF"
    } else {
      content.classList.add('modal-content')
      content.style.backgroundColor = null
    }
  }

  const showBodyScroll = (isVisible) => {
    let modalBody = document.getElementsByClassName('modal-body').item(0)

    if (!!modalBody) {
      if (!isVisible) {
        modalBody.style.overflowX = 'visible'
        modalBody.style.maxHeight = 'unset'
      } else {
        modalBody.removeAttribute('style')
        modalBody.style.maxHeight = 'calc(100vh - 210px);'
      }
    }
  }

  const getHtml2CanvasOptions = (element) => {
    return {
      x: 0,
      scrollY: 0,
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      backgroundColor: "#FFF"
    }
  }

  const handleClick = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id]
    });
  }

  const handleExportPdf = async () => {
    const { Threats, Opportunities, Warnings } = expanded

    await setExpanded({
      Threats: true,
      Opportunities: true,
      Warnings: true,
      toExport: true,
      prevThreats: Threats,
      prevOpportunities: Opportunities,
      prevWarning: Warnings
    })

    showBtnCloseModal(false)
    showModalBorder(false)
    showBodyScroll(false)

    document.getElementById('action-plan-issues').parentNode.style.overflowY = 'visible';
    let titles = document.getElementsByClassName('action-plan-issues');
    for (let i = 0; i < titles.length; i++) {
      titles[i].style.maxHeight = '72px';
      titles[i].style.display = 'block';
    }
    let paginateButtons = document.getElementsByClassName('paginate-btn');
    for (let i = 0; i < paginateButtons.length; i++) {
      paginateButtons[i].style.display = 'none';
    }

  }

  return (
    <div>
      <ActionPlanModal
        modal={modal}
        openModal={openModal}
        showResults={handleClick}
        openCollapse={expanded}
        exportPdf={handleExportPdf}
        data={data}
      />
    </div>
  )
}

export default ActionPlanModalContainer
