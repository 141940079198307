import React, {Component} from 'react'
import Profile from '../components/Profile'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import './../css/Header.css'
import {pageView} from '../helpers/ga-page-view'
import Layout from "../components/Schedule/Layout";

class ProfilePage extends Component {
    componentDidMount() {
        pageView()
    }

    render() {
        if (this.props.auth_info === null) {
            return <Redirect to='/login'/>
        }
        return (
                <Layout>
                    <Profile/>
                </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth_info: state.auth_info
    }
}

export default connect(mapStateToProps, null)(ProfilePage)
