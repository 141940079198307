import React, { Component } from 'react'
import { connect } from 'react-redux'
import './../css/Profile.css'
import { Container, Row, Col, Card, CardHeader, CardFooter, CardText, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap'
import axios from 'axios'
import Spinner from './Spinner'

class Profile extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.state = {
            current_password: null,
            new_password: null,
            password_confirmation: null,
            success_message: null,
            error_message: null,
            error_change_password: false,
            loading:false
        }
        this.handleResetKeyUp = this.handleResetKeyUp.bind(this)
        this.changePassword = this.changePassword.bind(this)
    }

    componentDidMount() {
        this._isMounted = true
    }

    handleResetKeyUp(event) { 
        let key = event.target.name 
        let value = event.target.value 
        let obj = {}
        obj[key] = value
        
        this._isMounted && this.setState(obj)
        
        if (this.state.error_change_password) { 
            this._isMounted && this.setState({error_change_password:false, error_message:null})
        } else {
            this._isMounted && this.setState({success_message:null})
        }
    }

    changePassword(){
        let messageType = ''
        let success_message = ''
        let error_message = ''
        let value = ''
        
        if(!this.state.current_password || !this.state.new_password || !this.state.password_confirmation){
            this._isMounted && this.setState({error_change_password: true, error_message: 'Please, introduce the required field(s).', success_message: null})
        } else {
            if( (this.state.current_password && this.state.current_password != '') && (this.state.new_password && this.state.new_password != '') && (this.state.new_password === this.state.password_confirmation) ){

                this.setState({loading:true})

                let url = process.env.REACT_APP_API_ENDPOINT + '/password/change' 

                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.props.auth_info.access_token
                }

                let parameters = {
                    'current_password' : this.state.current_password,
                    'new_password' : this.state.new_password,
                    'password_confirmation' : this.state.password_confirmation
                } 

                axios.post(url, parameters, {headers: headers})
                        .then(response => {
                            if(response.status == 200){
                                if(response.data.success == true){
                                    this.setState({loading:false, error_change_password: false, success_message: response.data.message, current_password: null, new_password: null, password_confirmation: null, error_message: null})
                                    document.getElementById("change-password-form").reset(); 
                                } else {                            
                                    if(Array.isArray(response.data.message.new_password)){
                                        let messages = response.data.message.new_password
                                        
                                        for(var i = 0; i < messages.length; i++){
                                            error_message += messages[i]+' '
                                        }
                                    } else {
                                        error_message = response.data.message
                                    }
                                    this.setState({loading:false, error_change_password: true, error_message: error_message, success_message: null})                           
                                }
                            } 
                        })
                        .catch(error => { 
                            this._isMounted && this.setState({loading:false, error_change_password: true, error_message: 'Unable to reset password. Please, try again.'})
                        })
            } else { 
                this._isMounted && this.setState({error_change_password: true, error_message: 'The New and Repeat passwords do not match.', success_message: null})
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false 
    }

    render() {
        return (
            <div>
                <Container className="change-password-cont">
                    <Row className="change-password-form">
                        <Col xs={12} lg={12}>
                            <Card>
                                <CardHeader>Change Password</CardHeader>
                                <CardBody>
                                    <Row className="change-password-form">
                                        <Col xs={12} lg={12}>
                                            <Form id="change-password-form">
                                                {  
                                                    (this.state.success_message !== null) ? 
                                                        <div className="alert alert-success fade show" role="alert">
                                                            { this.state.success_message }
                                                        </div>
                                                    : ''
                                                }
                                                {
                                                    (this.state.error_message !== null) ?
                                                        <div className="alert alert-danger fade show error-message" role="alert">
                                                            { this.state.error_message }
                                                        </div>
                                                    : ''
                                                }
                                                <FormGroup>
                                                    <Input type="password" name="current_password" id="current_password" placeholder="password placeholder" onKeyUp={this.handleResetKeyUp} required="required"/>
                                                    <Label for="current_password">Current Password</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input type="password" name="new_password" id="new_password" placeholder="password placeholder" onKeyUp={this.handleResetKeyUp} required="required"/>
                                                    <Label for="new_password">New Password</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input type="password" name="password_confirmation" id="password_confirmation" placeholder="password placeholder" onKeyUp={this.handleResetKeyUp} required="required"/>
                                                    <Label for="password_confirmation">Repeat Password</Label>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                {
                                    (this.state.loading) ?
                                        <div className='icon-search-loading'><Spinner/></div>
                                        :
                                        <Button className="submit-btn btn-secondary" color="primary" onClick={this.changePassword}>Save</Button>
                                }
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth_info: state.auth_info
    }
}

export default connect(mapStateToProps, null)(Profile)