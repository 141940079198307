const calculateSentiment = (ranking) => {
	let sentiments = {"unselected": 10, "positive": 0, "neutral": 0, "negative": 0}

	if (Object.keys(ranking.data).length > 0 && ranking.order.length) {
		for (var i = 0; i < 10; i++) {
			if (ranking.order[i] !== undefined && ranking.order[i].id !== undefined && ranking.data[ranking.order[i].id] !== undefined && ranking.data[ranking.order[i].id].sentiment !== undefined) {
				if (ranking.data[ranking.order[i].id].sentiment !== 'unselected') {
					sentiments[ranking.data[ranking.order[i].id].sentiment] += 1
					sentiments['unselected'] -= 1
				}
			}
		}
	}

	return sentiments
}

export default calculateSentiment