import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import styled from 'styled-components'
import search_lens from "../../../images/search_lens.svg";
import LocationMenu from './LocationMenu'
import ScheduleControl from "./ScheduleControl";
import AutomatedSearch from './AutomatedSearch'
import FilesNavigation from './FilesNavitation'
import moment from "moment";

const defaultState = () => ({
    id: null,
    automated_pages: 1,
    file_path: '',
    term: '',
    location: !window.currentLocation ? 2840 : null,
    canonical_name : 'United States',
    period: 'daily',
    start_date: new Date(moment()),
    end_date: null,
})

export default class FormSchedule extends React.Component {
    constructor(props) {
        super(props);
        const state = props.content || defaultState();
        this.state = {
            ...state,
        }

        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.changeLocationId = this.changeLocationId.bind(this)
        this.setPeriod = this.setPeriod.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.setEndDate = this.setEndDate.bind(this)
        this.setAutomatedSearch = this.setAutomatedSearch.bind(this)
        this.setPath = this.setPath.bind(this)
        this.isNotComplete = this.isNotComplete.bind(this)
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        this.setState({...defaultState()})
    }

    setPeriod = period => this.setState({period})
    setStartDate = start_date => this.setState({start_date})
    setEndDate = end_date => this.setState({end_date})
    setAutomatedSearch = automated_pages => automated_pages <= 10 && automated_pages >= 1 && this.setState({automated_pages})
    setPath = file_path => this.setState({file_path})

    isNotComplete = () => (!this.state.file_path.trim() || !this.state.term.trim() || !this.state.period.trim() || !this.state.location || !this.state.start_date)

    handleOnSubmit = async (e) => {
        e.preventDefault()
        if (this.isNotComplete()) {
            return;
        }
        await this.props.callback(this.state);
    }

    changeLocationId = (location) => {
        this.setState({location: +location.loc_id, location_name: {name: location.canonical_name}})

    }

    render() {
        const {loading, listLocations, content, closeModal} = this.props
        return (
            <FormStyled onSubmit={this.handleOnSubmit}>
                <div className='header'>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"
                                             className={"input-addon " + (loading ? 'loading' : '')}>
                                <InputGroupText>
                                    <img src={search_lens} alt=""/></InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Enter a search term"
                                type='text'
                                className='search-input'
                                value={this.state.term}
                                readOnly={loading}
                                onBlur={e => this.setState({term: e.target.value.trim()})}
                                onChange={e => this.setState({term: e.target.value})}
                                />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <LocationMenu
                            className={"LOCMENU"}
                            location={(this.state.location) ?
                                {
                                    canonical_name: this.state.location_name ? this.state.location_name.name : this.state.canonical_name,
                                    loc_id: this.state.location,
                                    loc_name_lowercase: this.state.location_name && this.state.location_name.name ? this.state.location_name.name.toLowerCase() :  this.state.canonical_name && this.state.canonical_name.toLowerCase()
                                }
                                :
                                null
                            }
                            id="location-menu"
                            formOverrides
                            onSelectLocation={(ev) => {
                                this.changeLocationId(ev)
                            }}
                            hideClearLocation
                            removeLocationCallback={() => this.changeLocationId({loc_id: null})}
                        />
                    </FormGroup>
                </div>
                <div className='body'>
                    <AutomatedSearch
                        value={this.state.automated_pages}
                        setValue={this.setAutomatedSearch}
                        color="#324A97"
                    />
                    <div className='body-header'>
                        <h3>
                            Set Search Schedule
                        </h3>
                    </div>
                    <ScheduleControl period={this.state.period}
                                     startDate={this.state.start_date}
                                     endDate={this.state.end_date}
                                     setPeriod={this.setPeriod}
                                     setStartDate={this.setStartDate}
                                     setEndDate={this.setEndDate}
                    />
                    <FilesNavigation currentPath={this.state.file_path} changePath={this.setPath} />
                </div>
                <div className='footer'>
                    <Button className='btn-cancel' onClick={closeModal} >
                        Cancel
                    </Button>
                    <Button className='btn-submit' type='submit' disabled={this.isNotComplete()}>
                        {content ? 'Edit' : 'Create'}
                    </Button>
                </div>
            </FormStyled>

        );
    }


}

const FormStyled = styled(Form)`
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .body-header {
    padding: 1rem .5rem;

    h3 {
      font-size: 1em;
      margin: 0;
      color: #4F4F4F;
      padding: 0;
      font-weight: 700;
    }
  }

  .btn-cancel, .btn-submit {
    margin: 0 .5rem;
    font-weight: 700;
  }
  .btn-submit {
    background-color:  #35A9E0;
    border: none !important;
    &:disabled,
    &[disabled] {
      background-color:  #D4D4D4;
      opacity: .8 !important;
    }
  }
  .btn-cancel {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #000 !important;

    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: 1;
    }

  }

  .form-control {
    padding: .9rem 1rem !important;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 .5rem;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);

    #location-holder {
      width: 100%;
    }

    .form-group {
      flex-grow: 1;
      margin-right: .5rem;
      @media screen and (max-width: 600px) {
        flex-basis: 100%;
      }
    }
  }
`
