import React from 'react';
import {StatBox} from './StatBox'
import {MonthlySearchesStyled} from '../../styles/StatBox'
import fetchClient from "../../helpers/fetch-client";
import {emptyMonthlySearchError, monthly_search_error, monthly_search_finished} from "../../actionCreators";
import {connect} from "react-redux";
import refresh from "../../images/refresh-icon.png";
import Spinner from "../Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';

const MonthlySearches = ({ value, percent, reload, error, fetchData, ...rest}) => {
    let nullData = 0;
    return (
        <StatBox classNameContainer={`d-flex flex-column align-items-center ${value || value === null ?'justify-content-between' : ''}`}
                 title={'Monthly Searches'} {...rest} >
            {
               error ?
                    <MonthlySearchesStyled className={'px-2 w-100 d-flex justify-content-center'}>
                        <div className="alert alert-danger fade show error-message" role="alert">
                            Monthly Search failed. Please try again.
                            <img className="refresh-icon" src={refresh} alt="refresh" onClick={reload}/>
                        </div>
                    </MonthlySearchesStyled>
                    :

                    value || value===0 ?
                        <>
                            <MonthlySearchesStyled isBigNumber={value && value >= 10000000}>
                                <p className={'value'}>
                                    {value && value.toLocaleString('en')}
                                </p>
                            </MonthlySearchesStyled>
                            <MonthlySearchesStyled percent={percent}>
                                <p className={'percentage'}>
                                    <FontAwesomeIcon
                                    style={{transform: "scale(0.5)"}}
                                    className="icon-toggle"
                                    icon={percent=== 0 ? null : percent > 0 ? faPlus : faMinus}
                                    />{percent && Math.abs(percent)}%
                                </p>
                                <p className="this-month">This Month</p>
                            </MonthlySearchesStyled>
                        </>
                        : value===null ? 
                        <>
                            <MonthlySearchesStyled isBigNumber={value && value >= 10000000}>
                                <p className={'value'}>
                                    {nullData.toLocaleString('en')}
                                </p>
                            </MonthlySearchesStyled>
                            <MonthlySearchesStyled percent={percent}>
                                <p className={'percentage'}>
                                    {Math.abs(nullData)}%
                                </p>
                                <p className="this-month">This Month</p>
                            </MonthlySearchesStyled>
                        </>
                        :<div className='icon-monthly-search-loading'><Spinner/></div>
                        }
        </StatBox>
    )
}

export default MonthlySearches;
