import React from 'react';
import Sidebar from '../Sidebar';
import {LayoutStyled} from '../../styles/Layout';

export const Layout = ({children, ...rest}) => {
    return (
        <LayoutStyled>
            <Sidebar active id="sidebar"/>
            <div className={'wrapper'} id="wrapper">
                {children}
            </div>
        </LayoutStyled>);
}