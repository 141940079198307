import React, {Component} from 'react'
import PasswordReset from '../components/PasswordReset'
import {pageView} from '../helpers/ga-page-view'

class PasswordResetPage extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        pageView()
    }

    render() {

        let userData = {
            email: this.props.match.params.email,
            token: this.props.match.params.token
        }

        return (
            <div>
                <PasswordReset userData={userData}></PasswordReset>
            </div>
        )
    }
}

export default PasswordResetPage;
