import React, { Component } from 'react'
import Login from '../components/Login'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {pageView} from '../helpers/ga-page-view'
class LoginPage extends Component {
 componentDidMount() {
   pageView()
 }

  render() {
    if (this.props.auth_info !== null) {
      return <Redirect to='/search' />
    }
    return (
      <Login></Login>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      auth_info: state.auth_info
  }
}


export default connect(mapStateToProps, null)(LoginPage)