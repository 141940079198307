import React, { Component } from 'react'
import './../css/NotFound.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from './../images/lightbox-search-logo.svg'
import Headerlogo from '../components/Headerlogo'
import {pageView} from '../helpers/ga-page-view'
class NotFound extends Component {
  componentDidMount() {
    pageView()
  }

  render() {
    return (
      <div className="not-found-page page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div>
              <Headerlogo/>
              </div>
              <span className="error-code d-block">404</span>
              <div className="mb-4 lead">The page you are looking for was not found.</div>
              <a href="/results" className="btn btn-link"><button type="button" class="btn btn-primary btn-lg btn-block btn btn-primary active"><FontAwesomeIcon icon='arrow-left' color='white' /> Back to the search</button></a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotFound;