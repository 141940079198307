import React from 'react'
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import styled, {keyframes} from 'styled-components'
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OptionCheckbox = ['daily', 'weekly', 'monthly']

export default class ScheduleControl extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {period, setPeriod, startDate, setStartDate, endDate, setEndDate,} = this.props
        return (
            <ScheduleStyled>
                <div className="options-checkbox">
                    {OptionCheckbox.map((item, i) => <div className="option" key={i}>
                        <input className="inp-cbx" id={item} type="checkbox"
                               onChange={() => setPeriod(item)}
                               checked={item === period.toLowerCase()} style={{display: 'none'}}/>

                        <label className="cbx" htmlFor={item}><span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg></span><span>{item}</span></label>
                    </div>)}
                </div>

                <div className="range-date">
                    <div className="date">
                        <label htmlFor='startDate'>
                            <span> Start Date: </span>
                            {startDate ? <Moment format="LL">
                                {new Date(startDate)}
                            </Moment> : 'Select a date'}
                        </label>
                        <DatePicker className="date-picker" id="startDate"
                                    selected={new Date(startDate)}
                                    onChange={date => endDate && date > new Date(endDate) ? null : setStartDate(date)}/>
                    </div>
                    <div className="date">
                        <label htmlFor='endDate'>
                            <span> End Date: </span>
                            {endDate ? <Moment format="LL">
                                {new Date(endDate)}
                            </Moment> : 'Select a date'}
                        </label>
                        <div className='clear-date'>
                            <FontAwesomeIcon className={`icon-clear mx-2 ${endDate ? 'active' : null}`} icon='times'
                                             color="#35A9E0" onClick={() => setEndDate(null)}/></div>
                        <DatePicker className="date-picker" id="endDate"
                                    selected={endDate ? new Date(endDate) : new Date(moment().endOf('month'))}
                                    onChange={date => date < new Date(startDate) ? null : setEndDate(date)}/>

                    </div>
                </div>
            </ScheduleStyled>
        );
    }


}

const wave = keyframes`
  50% {
    transform: scale(0.9);
  }
`

const ScheduleStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  .options-checkbox {
    display: flex;
    flex-wrap: wrap;

    .option {
      margin: 0 .5rem;
    }

    .cbx {
      margin: auto;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
    }

    .cbx span {
      display: inline-block;
      vertical-align: middle;
      text-transform: capitalize;
      font-size: .9em;
      color: #4F4F4F;
      transform: translate3d(0, 0, 0);
    }

    .cbx span:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 .5px 1px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease;
    }

    .cbx span:first-child svg {
      position: absolute;
      top: 3px;
      left: 2px;
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }

    .cbx span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: #35A9E0;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    .cbx span:last-child {
      padding-left: 8px;
    }

    .cbx:hover span:first-child {
      border-color: #35A9E0;
    }

    .inp-cbx:checked + .cbx span:first-child {
      background: #35A9E0;
      border-color: #35A9E0;
      animation: ${wave} 0.4s ease;
    }

    .inp-cbx:checked + .cbx span:first-child svg {
      stroke-dashoffset: 0;
    }

    .inp-cbx:checked + .cbx span:first-child:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }

  }

  .options {
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      width: 1px;
      height: 3rem;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .range-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;

    .date {
      padding: 0 1rem;
      margin: 1rem .5rem;
      display: flex;
      align-items: start;
      position: relative;

      .icon-clear {
        position: relative;
        z-index: -2;
        opacity: 0;

        &.active {
          opacity: 1;
          z-index: 2;
        }

        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__header {
        background: transparent !important;

      }

      .react-datepicker__navigation {
        outline: none !important;
        box-shadow: none !important;

        &--previous {
          border-right-color: #35A9E0 !important;
        }

        &--next {
          border-left-color: #35A9E0 !important;
        }
      }

      .react-datepicker__day {
        border: none !important;
        border-radius: 50% !important;
      }

      .react-datepicker__day {
        &--selected, &--keyboard-selected {
          background-color: #35A9E0 !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          font-weight: bold;
        !important;
          border-radius: 50% !important;
        }
      }

      &:before {
        content: '';
        width: 1px;
        height: 3rem;
        background: rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        margin-right: .5rem;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      label {
        font-size: .9em;
        cursor: pointer;
        color: #4F4F4F;

        &:hover {
          opacity: .8;
        }

        &:active {
          opacity: .6;
        }

        span {
          font-weight: 700;
        }
      }

      .react-datepicker-wrapper {
        height: auto !important;
        position: absolute;
        left: 30%;
        transform: translateX(-30%);
      }

      .date-picker {
        display: none;
      }
    }

  }
`
