import React, { Component } from 'react'
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import FilesNavigation from '../components/FilesNavigation'
import './../css/Login.css'
import './../css/IosModal.css'
import { connect } from 'react-redux'
import fetchClient from '../helpers/fetch-client'
import { changeCurrentPath } from '../actionCreators'
import Spinner from './Spinner'
import { isValidFileName } from '../helpers/isValidFileName'

class SaveFileModal extends Component {
  constructor() {
    super()
    this._isMounted = false
    this.state = {
      path: "/",
      name: "",
      description: "",
      loading: false,
      loading_finish:false,
      message: "",
      message_type: "",
      finish: false,
      confirm:0
    }
    this.changeCurrentPath = this.changeCurrentPath.bind(this)
    this.save = this.save.bind(this)
    this.forceSaveFile = this.forceSaveFile.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillUpdate(props) {
    if (this.props.open != props.open) {
      this.setState({loading: false, loading_finish: false})
    }
  }
  
  changeCurrentPath(path) {
    this._isMounted && this.setState({
      path: path
    })
  }

  onChangeName = (e) => {
    this._isMounted && this.setState({
        name: e.target.value
    }, () => {
      if (this.state.name !== "" && this.state.message !== '') {
        this._isMounted && this.setState({ message: "", message_type:"" })
      }
    })
  }

  onChangeDescription = (e) => {
    this._isMounted && this.setState({
        description: e.target.value
    })
  }

  async save(){
    const title = this.state.name.trim()

    if (!isValidFileName(title)) {
      this._isMounted && this.setState({ message: 'Invalid format title, cannnot be empty and cannot contains [".." "trash" "@" "./" "/" "\\" "*" ","] characters.', message_type: 'error' })
      return;
    }
    
    const params = {
      content: this.props.state,
    }
    const stringParams = JSON.stringify(params);
    const stringSize = stringParams.length;  
    const sliceSize = (1000 * 1024) / 2;

    this.setState({
      loading: true
    });

    const me = this;
    let start = 0;
    let nextSlice = start + sliceSize + 1;
    let error = false;
    let isLastSection = false;
    let finalResponseStatus;
    
    do {
      const stringSection = stringParams.slice(start, nextSlice);
      start = nextSlice;
      nextSlice = start + sliceSize + 1;
      isLastSection = start >= stringSize;

      
      try {
        const resp = await fetchClient().post(process.env.REACT_APP_API_ENDPOINT + '/fs/save-file', {
          isLastSection,
          title,
          confirm: this.state.confirm,
          content: stringSection,
          description: this.state.description,
          path: this.state.path,
        });

        if (isLastSection) {
          const { status } = resp;
          finalResponseStatus = status;
          me._isMounted && me.setState({ message: 'File saved successfully', message_type: 'success', finish: true })
        }
        else {
          me._isMounted && me.setState({ message: 'Saving file in progress', message_type: 'info', finish: true }) 
        }
      } catch (e) {
        me._isMounted && me.setState({ message: 'An error occur saving the file, please contact with the administrator.', message_type: 'error', finish: true }) 
        error = true;
      }
    } while (!isLastSection && !error);

    me.props.changeCurrentPath('/')
    me.setState({
      loading: false,
      loading_finish:false
    })
  }

  resetAndclose = () => {
    this._isMounted && this.setState({ name:"", description:"", message: "", message_type:"", loading: false, finish:false, confirm:0, loading_finish:false })
    this.props.close()
  }

  forceSaveFile() {
    this._isMounted && this.setState({confirm:1}, ()=> {
      this.save()
    })
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.open} className="files-modal">
          <ModalHeader>
              Save
              <button type="button" className="btn btn-secondary ios-close-button" onClick={()=>this.resetAndclose()}>
                <svg aria-hidden="true" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" color="black"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
              </button>
          </ModalHeader>

          <ModalBody>
            { this.state.message != "" ?
                (<div className="position-relative form-group">
                  <div className={"save-modal-message alert fade show error-message " + (this.state.message_type === 'error' ? 'alert-danger' : (this.state.message_type === 'info' ? 'alert-primary' : 'alert-success'))} role="alert"> {this.state.message} </div>
                  {this.state.message_type === 'info' && !this.state.loading?
                      <ModalFooter>
                          <Button color="primary" onClick={()=>this.forceSaveFile()}>Yes</Button>
                          <Button color="secondary" onClick={()=>this.resetAndclose()}>No</Button>
                      </ModalFooter>  
                    :null}
                </div>)
              : null
            }

            {this.state.loading ?
              <div className='new-folder-row icon-search-loading'><Spinner/></div>
            :
              !this.state.finish ?
                <React.Fragment>
                  <div className="position-relative form-group">
                    <Input className="form-control" value={this.state.name}  placeholder="with a placeholder" onChange={this.onChangeName} />
                    <label>Title</label>
                  </div>
                  <div className="position-relative form-group">
                    <Input  className="form-control" value={this.state.description} placeholder="with a placeholder" onChange={this.onChangeDescription} />
                    <label>Description</label>
                  </div>
                  <div>
                    <FilesNavigation 
                      intoModal={true}
                      changePath={this.changeCurrentPath}
                      fromSaveModal={true}
                      finishRender={()=>this.setState({loading_finish:true})}
                    />
                  </div>
                  {
                    this.state.loading_finish ?
                      <Button type="button" className="btn btn-secondary" onClick={this.save}>Save</Button>
                    :
                    null
                  }
                </React.Fragment>
                : null
            }
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentPath: (val) => {
      dispatch(changeCurrentPath(val))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveFileModal)
