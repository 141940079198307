import styled from "styled-components";
import {styles} from './variables';


export const ScheduleStyled = styled.div`
  box-sizing: border-box;
  padding: ${({paddingVertical}) => paddingVertical || '1.375rem' } ${({paddingHorizontal}) => paddingHorizontal || 'calc(1.875rem + 1%)' };
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-top: 1px solid ${styles.gray};

  .header {
    display: flex;
    align-items: center;

    .toggle {
      cursor: pointer;
      padding-right: 1rem;
      font-weight: 700;
      color: #212529;

      .icon-toggle {
        margin: 0;
        margin-right: 1rem;
        width: 1rem;
      }


      &:hover {
        opacity: .8;
      }

      &:active {
        opacity: .6;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    .container-section {
      position: relative;
      padding: 0 2rem;
      height: 4.2rem;
      display: flex;
      @media screen and (max-width: 1366px) {
        padding: 0 1rem;
      }
      &:before {
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }

    .title-section {
      display: flex;
      flex: 1;
      font-family: ${styles.fontPrimary};
      font-weight: 700;
      font-size: .8em;
      color: ${styles.textColor};
    }

    .options-checkbox {
      display: flex;
      flex-wrap: wrap;
 
      .option:not(:nth-child(1)) {
        margin: 0 .8rem;
      }

      .option:nth-child(1) {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: .8rem;
      }


    }

    .options {
      display: flex;
      align-items: center;
      position: relative;
      flex-basis: 20%;
      flex-wrap: wrap;
      @media screen and (max-width: ${styles.breakpoints.lg}) {
        flex:1;
      }

      .btn {
        &-reset, &-save {
          outline: none;
          background-color: transparent;
          transition: opacity 0.3s ease-in-out;
          padding: .5rem 1rem;

          &:focus,
          &:active,
          &:hover {
            box-shadow: none !important;
            outline: none !important;
            opacity: .8;
            background-color: transparent !important;
          }

          &:active {
            opacity: 0.5;
          }
        }

        &-reset {
          color: #35A9E0;
          border: none;

          &:focus,
          &:active,
          &:hover {
            border: none;
            color: #35A9E0;
          }

        }

        &-save {
          color: inherit;
          font-weight: bold;

          &.saved {
            border-color: transparent !important;
          }

          &:focus,
          &:active,
          &:hover {
            color: inherit
          }

        }
      }
    }
  }

  .range-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;

    label {
      margin: 0;
      margin-right: .5rem;
      color: ${styles.secondaryColor};
      font-weight: 4000;
      font-size: 1.125em;
    }

    .date {
      margin: 1rem 0;
      display: flex;
      flex: 1;
      align-items: center;
      position: relative;

      svg {
        min-width: 1rem;
        min-height: 1rem;

        path {
          fill: ${styles.secondaryColor};
        }
      }

      .icon-clear {
        position: relative;
        z-index: -2;
        opacity: 0;

        &.active {
          opacity: 1;
          z-index: 2;
        }

        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }
      .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        color : ${styles.textColor};
      }
      .react-datepicker__triangle {
        display: none;
      }
     .react-datepicker {
       border: transparent;
       box-shadow: 0 0 8px rgba(0,0,0,0.2);
     }
      .react-datepicker__header {
        background: transparent !important;
        border-color: transparent !important;
      }

      .react-datepicker__navigation {
        outline: none !important;
        box-shadow: none !important;

        &--previous {
          border-right-color ${styles.secondaryColor} !important;
        }

        &--next {
          border-left-color:  ${styles.secondaryColor}!important;
        }
      }

      .react-datepicker__day {
        border: none !important;
        border-radius: 0 !important;
      }

      .react-datepicker__day {
        &--selected, &--keyboard-selected {
          background-color: ${styles.secondaryColor} !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          font-weight: bold;
        !important;
          border-radius: 0 !important;
        }
      }
      

      label {
        font-size: 1em;
        cursor: pointer;
        @media screen and (max-width: 1366px) {
          font-size: 0.9em;
        }
        &:hover {
          opacity: .8;
        }

        &:active {
          opacity: .6;
        }

        span {
          font-weight: 700;
        }
      }

      .react-datepicker-wrapper {
        height: auto !important;
        position: absolute;
        left: -15%;
        bottom: 20%;
      }

      .date-picker {
        display: none;
      }
    }

  }

\`


`
