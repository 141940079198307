import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import '../css/Tooltip.css'

class Tooltip extends Component {    
    render() {
    	const { tooltip } = this.props
    	return (
			<span>
	    		<a data-tip data-for={'tooltip-'+tooltip.code}>
	    			<FontAwesomeIcon className="icon" icon={faInfoCircle}/>
	    		</a>
		    	<ReactTooltip border={true} type="info" effect="solid" id={'tooltip-'+tooltip.code}>
		    		<Row className="tooltipBody">
						<Col xs={12} md={12} lg={12}>
			    		{	(tooltip.name) ? 
				    		<Row>
				    			<Col xs={12}>
						    		<div>
						    			<h6><b>{tooltip.name}</b></h6>					    		
						    		</div>
						    	</Col>
						    </Row>
						    : ''
						}
					    {	(tooltip.content) ? 
							    <Row>
					    			<Col xs={12}>
					    				<div className="tooltipContent" >
					    					<i>{tooltip.content}</i>
					    				</div>
					    			</Col>
							    </Row>
							: ''
						}
						{	(tooltip.description) ? 
					    		<Row>
					    			<Col xs={12}>
					    				<div>
					    					{tooltip.description}
					    				</div>
					    			</Col>
							    </Row>
							: ''
						}
						</Col>
					</Row>
		    	</ReactTooltip>
	    	</span>
    	)
    }
}

export default connect(null,null)(Tooltip)