const findByTerm = (term, title, extract, level = 0) => {
	let _term = term.toUpperCase().trim()

	if(_term === "") return 0

	let pos_and = _term.indexOf(' AND ')
	if(pos_and > 0){
		let a = findByTerm(_term.substring(0, pos_and).trim(), title, extract, level+1)
		let b = findByTerm(_term.substring(pos_and + 5, _term.length).trim(), title, extract, level+1)
		if(a > 0 && b > 0) return a + b
		return 0
	}

	let pos_or = _term.indexOf(' OR ')
	if(pos_or > 0){
		return findByTerm(_term.substring(0, pos_or).trim(), title, extract, level+1) + findByTerm(_term.substring(pos_or + 4, _term.length).trim(), title, extract, level+1)
	}
	
	let _title = title.toUpperCase()
	let _extract = extract && extract !== '' ? extract.toUpperCase() : ''
	let val = 0

	if(_title.indexOf(_term) > -1) val += 10
	if(_extract.indexOf(_term) > -1) val += 5

	return val
}

export default findByTerm