import React from 'react';
import HeaderTop from './SectionTop'
import Search from './SectionSearch'
import {HeaderContainerStyled, HeaderStyled} from '../../styles/Header'
import {connect} from 'react-redux'
import ScheduleControl from './ScheduleControl'
import {Col} from "reactstrap";

export const Header = ({search, ...rest}) => {
    return (
        <HeaderStyled>
            <HeaderContainerStyled isSearch={!search.main}>
                <HeaderTop isSearch={!search.main} {...rest} />
            </HeaderContainerStyled>

            <Search isSearch={!search.main}  {...rest} />

            <div className={'d-flex flex-column flex-wrap justify-content-center align-items-center flex-grow-1 container-messages'}>
                {
                    rest.messages.map((message, i) => {
                        let alert = null
                        switch (message.type) {
                            case 'search_error_message':
                                alert = (
                                    <div className="alert alert-danger fade show error-message flex-grow-1" role="alert"
                                         key={"alert-" + i}> {message.msg} </div>
                                )
                                break;
                        }
                        if (alert !== null) return alert
                    })
                }

                {
                    rest.errorMessage && (
                        <div className="alert alert-danger fade show error-message header-error-message flex-grow-1"
                             role="alert">{rest.errorMessage}</div>)
                }
                {
                    rest.successMessage && (
                            <div className="alert alert-success fade show error-message flex-grow-1"
                                 role="alert">{rest.successMessage}</div>)

                }
            </div>
            {!rest.loading && search.main && rest.automation && !rest.screenshotIsInProgress &&
            <ScheduleControl/>}
        </HeaderStyled>
    )
}

const mapStateToProps = (state) => {
    return {
        search: state.search !== undefined ? state.search : {main: '', sub: ''},
    }
}

export default connect(mapStateToProps,)(Header)
