import React from 'react';
import { Col, Progress, Row } from 'reactstrap';
import '../../css/ActionPlan.css';

const ProgressBar = ({ loading }) => {
  const { loaderOne, loaderTwo, loaderThree } = loading
  return (
    <Col>
      <Row className='flex-nowrap'>
        <Progress color="success" animated value={loaderOne} className='ml-2 action-progress' />
        <Progress color="success" animated value={loaderTwo} className='ml-2 action-progress' />
        <Progress color="success" animated value={loaderThree} className='ml-2 action-progress' />
      </Row>
      {loaderThree !== 0 ? (
        <p style={{ fontSize: '12px' }} className='mt-1'>Your plan is ready!</p>
      ) : (
        <p style={{ fontSize: '12px' }} className='mt-1'>Data is loading</p>
      )}
    </Col>
  )
}

export default ProgressBar
