import React, { Component } from 'react'
import { ThemeConsumer } from 'styled-components'
import { Link, Redirect } from 'react-router-dom'

class Headerlogo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect:false
        }
    }

    componentDidMount() {
        this.setState({redirect:false})
    }

    render() {
        if (this.state.redirect) return <Redirect to='/results' />

        return (
            <ThemeConsumer>
                {theme =>               
                    <Link to="/results"><img className={theme.class_logo_header} src={theme.logo} style={{display: 'inline-block'}}></img></Link>
                }
          </ThemeConsumer>   
        )
    }
}

export default Headerlogo
        