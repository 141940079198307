import React from 'react'
import styled from "styled-components";
import { OverrideConfirmation as OverrideStyles } from '../../styles/OverrideConfirmation';
import { ReactComponent as Check } from '../../images/icons/override-check.svg'
import { ReactComponent as Cross } from '../../images/icons/override-cross.svg'

const Saved = styled(Check)`
    fill: #72B662;
    margin-left: .5rem;
    margin-top: -.15rem;
`;
const Cancelled = styled(Cross)`
    fill: #CF6464;
    margin-left: .5rem;
    margin-top: -.15rem;    
`;

export const OverrideConfirmation = ({ confirmationMessage, type, handleSave, handleCancel }) => 
    <OverrideStyles confirmationMessage={confirmationMessage}>
        <div className="override-confirmation-box">
            {confirmationMessage === 'AskConfirmation' ?
                <div>
                    <p>Save Changes?</p>
                    <div className="override-icons-box">
                        <button type={type} onClick={handleSave}> <Check /> </button>
                        <button onClick={handleCancel}> <Cross /> </button>
                    </div>
                </div>
            :
                <div>
                    <span>{confirmationMessage === 'Saved' ? 'Saved' : 'Cancelled'}</span>
                    <span>{confirmationMessage === 'Saved' ? <Saved /> : <Cancelled />}</span>
                </div>
            }      
        </div>
    </OverrideStyles>
