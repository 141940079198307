import React from 'react';
import {CheckboxLightbox as Checkbox } from "../common/Checkbox";
import {connect } from 'react-redux'
import {setEmailsReport as setReports} from "../../actionCreators";
import fetchClient from "../../helpers/fetch-client";
import Loading from "../common/Loading";
const Switch = ({className , classNameCheckbox, classNameLabel, id='checkbox-email-report',isReportsEmail,setEmailsReport, ...rest}) => {
    const [loading, setLoading] = React.useState(false)

    const onChangeReportsEmail = async () => {
        if (!loading) {
            const payload = {
                optin_report_email: +!isReportsEmail
            }
            setLoading(true)
            try {
            const response = await fetchClient().post(process.env.REACT_APP_API_ENDPOINT + '/user-settings', payload)
                if(response) setEmailsReport(!isReportsEmail)
            } catch(err) {
            }
            setLoading(false)
        }
    }
    return (
        <>
            <Checkbox {...rest} id={id} className={classNameCheckbox} onChange={onChangeReportsEmail} checked={isReportsEmail}  />

            <Loading loading={loading} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isReportsEmail: state.user_logged.optin_report_email
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setEmailsReport: (val) => {
            dispatch(setReports(+val))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Switch)
