import React, { Component } from 'react'
import FilesNavigation from '../components/FilesNavigation'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeCurrentPath } from '../actionCreators'
import {pageView} from '../helpers/ga-page-view'
import {Layout} from "../components/Layout/Default";
class FilePage extends Component {

    componentDidMount() {
        pageView()
        this.props.changeCurrentPath('/')
    }

    render() {

        if (this.props.auth_info == null) {
            return <Redirect to='/' />
        }
        return (
            <Layout >
                        <FilesNavigation />
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
  return {
      auth_info: state && state.auth_info !== undefined ? state.auth_info : null
  }
}


const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPath: (val) => {
            dispatch(changeCurrentPath(val))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilePage)
