import React from 'react';
import {RowFooterStyled} from "../../styles/RowResult";
import Spinner from "../Spinner";
import {Col, Row} from "reactstrap";
import Sentiments from './Sentiments'
import Categories from './Categories'
export const RowFooter = ({
    id,
    PopoverSentiment,
    propsSwitchPopover,
    showControlSelect,
    seoAnalysis,
    loadingScore,
    domainAuthority,
    SelectSentiment,
    propsScore,
    ConfirmationSelect,
    propsDomainAuthority,
    ButtonResultAnalisys,
    propsPopoverSentiment,
    topStoryUrl,
    topStoryIndex,
    ...rest
}) => {
    const { isTopStories } = rest
    const InfoWrapper = isTopStories() ? Row : React.Fragment
    const colSize = isTopStories() ? 6 : 3
    return (
        <RowFooterStyled domainAuthority={domainAuthority} seoAnalysis={propsScore.value} isTopStory={isTopStories()} loadingScore={loadingScore}>
            <Sentiments  {...rest } topStoryUrl={topStoryUrl} topStoryIndex={topStoryIndex} id={id}/>

            {!isTopStories() && <Categories {...rest} id={id}/>}

            <InfoWrapper className="d-flex  mt-3">
                <Col sm={colSize} className={'d-flex flex-column justify-content-around seo-analysis mh-80'}>
                    {loadingScore ? <div className="spinner-loading top-story-spinner seo-analysis-spinner"><Spinner inRowFooter={!isTopStories()}/></div> :
                        <input {...propsScore} />}
                        <ButtonResultAnalisys storyUrl={topStoryUrl} />
                </Col>

                    {loadingScore ? <div className="spinner-loading top-story-spinner domain-authority-spinner"><Spinner inRowFooter={!isTopStories()}/></div> :
                <Col sm={colSize} className={'d-flex flex-column column domain-authority justify-content-around align-items-center mh-80'}>
                        <p className={isNaN(domainAuthority) ? 'timeout' : 'value'} {...propsDomainAuthority}>
                            {domainAuthority}
                        </p>
                        <p className={'title'}>Domain Authority</p>
                </Col>
                    }
            </InfoWrapper>

        </RowFooterStyled>

    )
}
