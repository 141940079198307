import styled from 'styled-components';

const handleColor = (confirmationMessage) => {
    if (confirmationMessage === 'Cancelled') return '#CF6464';
    if (confirmationMessage === 'Saved') return '#72B662';
    return '#8F8F8F';
}

export const OverrideConfirmation = styled.div`
  display: flex;
  color: ${({ confirmationMessage }) => handleColor(confirmationMessage)};
  font-size: 10px;
  line-height: 11.72px;
  padding-top: 0.6rem;
  div {
    display: flex;
  }
  p {
    margin-right: .8rem;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    margin-top: -1rem;
    padding: 0 .1rem 0 .1rem;
    outline: none;
  }
  .override-confirmation-box {
    visibility: ${({ confirmationMessage }) => confirmationMessage === 'Hidden' ? 'hidden' : 'visible'};
    padding-top: .2rem;
    height: 1rem;
  }
  .override-icons-box {
    margin-top: 12px;
  }
`

