import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import Tooltip from './Tooltip'
import '../css/Tooltip.css'
import fetchClient from '../helpers/fetch-client';
import Spinner from './Spinner'
import {
    related_searches_finished,
    related_searches_error,
    emptyRelatedSearchesError,
    monthly_search_finished, monthly_search_error
} from '../actionCreators'
import refresh from './../images/refresh-icon.png'
import { RelatedSearchesStyled } from "../styles/RelatedSearches";
import {getCpcEstimated} from "../helpers/cpc";
import { DownloadDOMElement } from './DownloadDOMElement'

class RelatedSearches extends Component {
    constructor(props) {
        super(props)
        this.reload = this.reload.bind(this)
    }

    componentDidMount() {
        if (this.props.related_searches_data === null || this.props.related_searches_data === undefined) {
            let aux_array = [];
            if(!Array.isArray(this.props.related_searches)){
                for (const key in this.props.related_searches) {
                    aux_array.push(this.props.related_searches[key])
                }
                this.props.fetchData(this.props.search.main, this.props.search.loc_id, aux_array.map(item => item.displayText));
            } else {
                this.props.fetchData(this.props.search.main, this.props.search.loc_id, this.props.related_searches.map(item => item.displayText));
            }
        }
    }

    reload() {
        if (this.props.current_related_searches_message) {
            this.props.emptyRelatedSearchesError()
            this.props.fetchData(this.props.search.main, this.props.search.loc_id, this.props.related_searches.map(item => item.displayText));
        }
    }

    highlight(text, term) {
        let parts = text.split(new RegExp(`(${term})`, 'gi'))
        return <span>{parts.map((part, index) => part.toLowerCase() !== term.toLowerCase() ? <b key={index}>{part}</b> : part)}</span>
    }

    render() {
        let relatedSearchesStyle = { margin: 'auto', padding: '20px', float: 'right', marginBottom: '45px' };
        let youShouldAlsoMonitorStyle = { fontSize: '150%', fontWeight: 'bold' };
        let tooltipStyle = { float: 'right', marginBottom: '-20px' };
        let aux_array = [];
            if (!Array.isArray(this.props.related_searches_data)){
                for (const key in this.props.related_searches_data) {
                    aux_array.push(this.props.related_searches_data[key])
                }
            } else {
                aux_array = [...this.props.related_searches_data]
            }
        return (
            <RelatedSearchesStyled className="col-lg-4" id="related-searches" style={{marginTop:"45px"}}>
                <div className="d-flex justify-content-between align-items-center">
                    <h3>You Should Also Monitor</h3>
                    <DownloadDOMElement element="related-searches" addFrame className="mt-1 mr-1"/>
                </div>

                {
                    this.props.current_related_searches_message ?
                        <div>
                            <div className="alert alert-danger fade show error-message" role="alert" style={{ marginTop: '75px', paddingLeft: '20px' }}>
                                Related Searches failed. Please try again.
                                <img className="refresh-icon" src={refresh} alt="refresh" onClick={this.reload} />
                            </div>
                        </div>
                        :
                        this.props.related_searches_data === null || this.props.related_searches_data === undefined ? <span className={'text-center'} style={{ marginTop: '50px' }}><Spinner /></span> :
                            <table >
                                {aux_array.length > 0 ?
                                    <tr>
                                        <th>Related Searches</th>
                                        <th>Volume</th>
                                        <th>CPC (USD)</th>
                                        <th>Competition</th>
                                    </tr>
                                    : null}

                                {
                                    aux_array.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td >
                                                    <a style={{ display: 'inline', lineHeight: '20px' }} target="_blank" href={item.webSearchUrl}>{this.highlight(item.displayText, this.props.search.main)}</a>
                                                </td>
                                                <td >
                                                    {item.search_volume===null? "0" :item.search_volume && item.search_volume.toLocaleString('en')}
                                                </td>
                                                <td >
                                                    <span> ${item.cpc ? (+item.cpc.toFixed(2)).toLocaleString('en') : 0}</span>
                                                </td>
                                                <td>
                                                    <span>{item.competition ? item.competition.toFixed(2) : 0}  ({getCpcEstimated(item.competition || 0)}) </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                }
                <div style={{ marginTop: '10px' }}>
                    <span className='alignWithIcon' style={tooltipStyle}>
                        {
                            this.props.tooltips.map((tooltip, i) => {
                                return (tooltip.code === '009') ? <Tooltip key={tooltip.code} tooltip={tooltip} /> : ''
                            })
                        }
                    </span>
                </div>
            </RelatedSearchesStyled>
        )
    }
}

const mapStateToProps = state => {
    return {
        search: state.search,
        related_searches: state.related_searches,
        related_searches_data: state.related_searches_data,
        current_related_searches_message: state.current_related_searches_message,
        tooltips: state.tooltips.filter(tooltip => tooltip.code === '009')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: async (term, loc_id, related_terms) => {
            let url = process.env.REACT_APP_API_ENDPOINT + '/monthly-search-and-cpc'
            try {
                const response = await fetchClient().post(url, {
                    search_term: term,
                    loc_id,
                    related_terms
                });
                if (response.status !== 200) {
                    throw response.message
                } else {
                    //Get main search from localStorage
                    const currentState = JSON.parse(localStorage.getItem('state'))

                    if (currentState && (currentState.state.search.main === term)) {
                        function compare(a, b) {
                            let comparison = 0;
                            if (a.search_volume > b.search_volume) {
                                comparison = -1;
                            } else if (a.search_volume < b.search_volume) {
                                comparison = 1;
                            }
                            return comparison;
                        }
                        const newRelatedSearches = response.data.data.related_word ? response.data.data.related_word.sort(compare) : []

                        return dispatch(related_searches_finished(newRelatedSearches));
                    }
                }
            } catch (err) {
                return dispatch(related_searches_error())
            }
        },
        emptyRelatedSearchesError: () => {
            dispatch(emptyRelatedSearchesError())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedSearches)
