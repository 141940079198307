import React, { Component } from 'react'
import axios from 'axios'
import './../css/File.css'
import Spinner from './Spinner'
import Loading from './common/Loading'
import { isValidFileName } from '../helpers/isValidFileName'
class NewFolderRow extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false
        this.titleInput = React.createRef()

        const auth_info = JSON.parse(localStorage.getItem('token_info'))
        const data = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
            }
        }

        this.state = {
            headers: data,
            title:'',
            loading:false,
        }
        this.onFinish = this.onFinish.bind(this)
        this.onShowMessage = this.onShowMessage.bind(this)
        this.handleUnmount = this.handleUnmount.bind(this)
    }

    componentDidMount() {
      this._isMounted = true
      this.titleInput.current.focus()
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    handleOnKeyUp = (e) => {
        let TABKEY = 9
        if (e.key === 'Enter' || e.keyCode === TABKEY) {
          this.createFolder()
        }
    }

    handleOnBlur = (e) => {
      this.createFolder()
    }

    handleOnChangeTitle = (e) => {
      this._isMounted && this.setState({title:e.target.value})
    }

    onFinish() {
      this.props.onFinish()
    }

    onShowMessage(data) {
      this.props.onShowMessage(data)
    }

    handleUnmount(){
      this.props.unmountMe()
    }

    createFolder() {
      const title = this.state.title.trim()

      if (!isValidFileName(title)){
        this._isMounted && this.onShowMessage({ message: 'Invalid format title, cannnot be empty and cannot contains [".." "trash" "@" "./" "/" "\\" "*"] characters.', type: 'error' })
        return
      }

      if(!this.state.loading) {
        this._isMounted && this.setState({loading:true})
        let url = process.env.REACT_APP_API_ENDPOINT + '/fs/folder'
        let parameters = {
          'path' : this.props.currentPath.substr(1) + '/' + title
        }

        let me = this
        return axios.post(url, parameters, this.state.headers)
          .then((response) => {
            if (response.status === 201) {
              me._isMounted && me.onShowMessage({ message: 'Folder created succesfully.', type: 'success', permanent_message:true })
              me._isMounted && me.onFinish()
            } else if(response.status === 202) {
              me._isMounted && me.onShowMessage({ message: response.data.message, type: 'error' })
              me.handleUnmount()
            }else {
              throw response
            }
          }).catch(function (error) {
            me._isMounted && me.onShowMessage({ message: 'Can\'t create the folder, please contact with the administrator.', type: 'error' })
            me.handleUnmount()
          }).finally((response) => {
            me._isMounted && me.setState({ loading: false })
          })
      }
    }

    render() {
        return (
          this.state.loading ?
                <Loading loading={true} />
              :
          <tr>
            <td>
            </td>
            <td className="title-size">
              <input type="text" readOnly={false}
                ref={this.titleInput}
                onChange={this.handleOnChangeTitle} onKeyUp={this.handleOnKeyUp}
                onBlur={this.handleOnBlur}
                value={this.state.title}
                className={'file-component title row-style' + (this.state.title_readOnly ? 'readOnly' : '') }
              />
            </td>
            <td>
            </td>
            <td></td>
            <td className="close-new-row" onMouseDown={()=>this.handleUnmount()}>
              <svg aria-hidden="true" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" color="black"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
            </td>
          </tr>
        )
    }
}

export default NewFolderRow
