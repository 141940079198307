import React, { useState, useEffect, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { StatBox } from "./statsBoxes/StatBox"
import { styles } from '../styles/variables'
import SentimentDoughnutLegend from './statsBoxes/SentimentDoughnutLegend'


const options = {
    cutoutPercentage: 65,
    options: {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        }
    },
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        onClick: (e) => e.stopPropagation(),
        display: false
    },
    tooltips: {
        fontSize: 10,
        titleFontSize: 10,
        bodyFontSize: 10,
        titleFontColor: styles.textColor,
        bodyFontColor: styles.textColor,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: styles.gray,
        boxShadow: '0 0 10px gray',
        mode: 'label',
        filter: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];
            if (label === "Empty") return false;
            return true;
        }
    }
}

const chart_data = {
    data_pie: {
        labels: [
            '0% Positive',
            '0% Neutral',
            '0% Negative',
            'Empty'
        ],
        datasets: [{
            data: [0, 0, 0, 0],
            backgroundColor: [
                '#329844',
                '#f8bc1c',
                '#d0021b',
                '#888'
            ],
            borderWidth: 0,
            hoverBackgroundColor: [
                '#329844',
                '#f8bc1c',
                '#d0021b',
                '#888'
            ]
        }]
    }
}

const SentimentDistributionBox = (props) => {
    const [screenWidth, setScreenWidth] = useState(null)
    const [shouldRedraw, setShouldRedraw] = useState(false);
    const [size, setSize] = useState({ width: 210, height: 100 });

    useEffect(() => {
        setScreenWidth(window.innerWidth)
        let resizeTimer;
        const setRedraw = () => {
            if (screenWidth !== window.innerWidth) {
                setShouldRedraw(true)
            }
        }
        const waitResize = () => {
            clearTimeout(resizeTimer)
            if (size.width !== document.getElementById('sentiment-box').offsetWidth - 32 ||
                size.height !== document.getElementById('sentiment-box').offsetHeight - 64) {
                setSize({
                    width: document.getElementById('sentiment-box').offsetWidth - 32,
                    height: document.getElementById('sentiment-box').offsetHeight - 80
                });
                resizeTimer = setTimeout(setRedraw, 500)
            }
        }
        window.addEventListener('resize', waitResize, false)
        return () => {
            window.removeEventListener('resize', waitResize, false)
        }
    }, [])

    function recalculateDataChart() {

        let SENTIMENT_TOTAL_ROWS = 10

        let sentiment = {
            positive: 0,
            neutral: 0,
            negative: 0
        }

        for (let i = 0; i < SENTIMENT_TOTAL_ROWS; i++) {
            let id = props.ranking.order[i].id
            let value = props.ranking.data[id] !== undefined ? props.ranking.data[id] : null

            if (value) {
                switch (value.sentiment) {
                    case "positive":
                        sentiment.positive += 1
                        break
                    case "neutral":
                        sentiment.neutral += 1
                        break
                    case "negative":
                        sentiment.negative += 1
                        break
                    default:
                        break
                }
            }
        }

        let total_filled = 0;
        for (var elem in sentiment) {
            if (sentiment.hasOwnProperty(elem)) {
                total_filled += sentiment[elem]
            }
        }

        let positive = (!isNaN(Math.round((sentiment.positive * 100) / total_filled)) ? Math.round((sentiment.positive * 100) / total_filled) : 0) + '% Positive'
        let neutral = (!isNaN(Math.round((sentiment.neutral * 100) / total_filled)) ? Math.round((sentiment.neutral * 100) / total_filled) : 0) + '% Neutral'
        let negative = (!isNaN(Math.round((sentiment.negative * 100) / total_filled)) ? Math.round((sentiment.negative * 100) / total_filled) : 0) + '% Negative'

        let labelsData = [
            positive,
            neutral,
            negative,
            'Empty'
        ]

        if (!Object.values(sentiment).some(value => value !== 0))
            sentiment = { ...sentiment, Empty: 100 }
        chart_data.data_pie.datasets[0].data = Object.values(sentiment)
        chart_data.data_pie.labels = labelsData
        return chart_data.data_pie
    }

    return (
        <StatBox
            id={'sentiment-box'}
            classNameContainer={'d-flex flex-column align-items-center'}
            title={'Sentiment Distribution'}
            {...props}
        >
            <div style={{ marginTop: '.5rem' }}>
                <Doughnut
                    {...size}
                    data={recalculateDataChart()}
                    options={options}
                    redraw={shouldRedraw}
                />
            </div>
            <SentimentDoughnutLegend />
        </StatBox>
    )
}

const mapStateToProps = state => {
    return {
        ranking: state.ranking
    }
}
export default connect(mapStateToProps, null)(SentimentDistributionBox)
