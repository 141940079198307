import React, {Component} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import ResultsPage from './pages/ResultsPage'
import SearchPage from './pages/SearchPage'
import LoginPage from './pages/LoginPage'
import PasswordResetPage from './pages/PasswordResetPage'
import FilesPage from './pages/FilesPage'
import ProfilePage from './pages/ProfilePage'
import DashboardPage from './pages/DashboardPage'
import OverridesPage from './pages/overrides/OverridesPage'
import SchedulePage from "./pages/SchedulePage";
import ScheduleBatchPage from "./pages/ScheduleBatchPage";
import NotFound from './pages/NotFound'
import './App.css'
import {ThemeProvider} from 'styled-components';
import logo from './images/lightbox-search-logo.svg'

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            properties: {
                logo: this.props.user_logged !== undefined && this.props.user_logged.user_preferences !== undefined && this.props.user_logged.user_preferences && this.props.user_logged.user_preferences.logo !== undefined ? this.props.user_logged.user_preferences.logo : logo,
                class_logo_header: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-header' : 'standard-logo-header',
                class_logo_login: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-login' : 'standard-logo-login '
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.user_logged.email) {
            let properties = {
                logo: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences && props.user_logged.user_preferences.logo !== undefined ? props.user_logged.user_preferences.logo : logo,
                class_logo_header: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-header' : 'standard-logo-header',
                class_logo_login: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-login' : 'standard-logo-login '
            }
            this.setState({properties: properties}, () => {
            })
        }
    }

    componentDidUpdate(props) {
        if (props.user_logged.email) {
            let properties = {
                logo: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences && props.user_logged.user_preferences.logo !== undefined ? props.user_logged.user_preferences.logo : logo,
                class_logo_header: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-header' : 'standard-logo-header',
                class_logo_login: props.user_logged !== undefined && props.user_logged.user_preferences !== undefined && props.user_logged.user_preferences ? 'custom-logo-login' : 'standard-logo-login '
            }
            this.setState({properties: properties})
        }
    }

    render() {
        return (
            <BrowserRouter>
                <ThemeProvider theme={this.state.properties}>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={LoginPage}/>
                            <Route exact path="/login" component={LoginPage}/>
                            <Route exact path="/password/reset/:email/:token" component={PasswordResetPage}/>
                            <Route exact path="/search/:test?" render={props => <SearchPage {...props} />}/>
                            <Route exact path="/results" render={props => <ResultsPage {...props} />}/>
                            <Route exact path="/my-files" render={props => <FilesPage {...props} />}/>
                            <Route exact path="/overrides" render={props => <OverridesPage {...props} />}/>
                            <Route exact path="/profile" render={props => <ProfilePage {...props} />}/>
                            <Route exact path="/dashboard" render={props => <DashboardPage {...props} />}/>
                            <Route exact path="/schedule" render={props => <SchedulePage {...props} />}/>
                            <Route exact path="/schedule/batch" render={props => <ScheduleBatchPage {...props} />}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </div>
                </ThemeProvider>
            </BrowserRouter>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user_logged: state.user_logged
    }
}

export default connect(mapStateToProps, null)(App)
