import React, { Component } from 'react'
import PeopleAlsoSearchItem from './PeopleAlsoSearchItem'

class PeopleAlsoSearch extends Component {

  constructor(props) {
      super(props)
  }

  render() {
    return (
      this.props.data !== undefined && this.props.data.length && this.props.data.map((item, index)=>{
        if (index < 4) {
          return <PeopleAlsoSearchItem data={item} key={'also_search_item_'+index}></PeopleAlsoSearchItem>
        }
        
      })
    )
  }
}

export default PeopleAlsoSearch
