import React, { useEffect, useState } from 'react'
import SentimentDistributionBox from './SentimentDistributionBox'
import CategoryDistributionBox from './CategoryDistributionBox'
import './../css/StatsBoxes.css'
import AdvertisingInfo from './statsBoxes/AdvertisingInfo'
import MonthlySearches from './statsBoxes/MonthlySearches'
import LightboxScore from './statsBoxes/LightBoxScore'
import { StatBoxesStyled } from "../styles/StatBox";
import fetchClient from "../helpers/fetch-client";
import { emptyMonthlySearchError, monthly_search_error, monthly_search_finished } from "../actionCreators";
import { connect } from "react-redux";


function StatsBoxes({monthly, search, related_searches, fetchData, ...rest}) {
    const [activeClass, setActiveClass] = useState('')
    const isFixedHeader =  activeClass === 'fixed' || activeClass === 'fixed up';

    useEffect(() => {
        var lastScrollTop = 0;
        window.addEventListener('scroll', (event) => {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            let classFixed = 'normal';
            if (window.pageYOffset > 700) {
                classFixed = 'fixed';
            } else if (st < lastScrollTop && (window.pageYOffset < 700 && window.pageYOffset > 600)) {
                classFixed = 'fixed up';
            }
            lastScrollTop = st <= 0 ? 0 : st;
            setActiveClass(classFixed)
        });
    }, [])

    useEffect(() => {
        if(!monthly.search_volume)
        fetchData(search.main, search.loc_id, related_searches.map(item => item.displayText))
    }, [])

    const reload = () => {
        if (monthly.current_monthly_error_message) {
            emptyMonthlySearchError()
            fetchData(search.main, search.loc_id)
        }
    }
    

    return (
        <div className={`stats-boxes`}>
            <div className="container">
                <StatBoxesStyled>
                    <LightboxScore flexBasis={'20%'} hideDownloadIcon={isFixedHeader}/>
                    <SentimentDistributionBox border flexBasis={'20%'} hideDownloadIcon={isFixedHeader}/>
                    <CategoryDistributionBox border flexBasis={'20%'} hideDownloadIcon={isFixedHeader}/>
                    {!isFixedHeader &&
                        <MonthlySearches 
                            border 
                            error={monthly.current_monthly_error_message}
                            value={monthly && monthly.search_volume}
                            percent={monthly && monthly.percent_diff}
                            reload={reload} flexBasis={'20%'} 
                            hideDownloadIcon={isFixedHeader}
                        />
                    }
                    <AdvertisingInfo 
                        border 
                        competition={monthly && monthly.competition} 
                        cpc={monthly && monthly.cpc } 
                        error={monthly.current_monthly_error_message} 
                        flexBasis={'20%'} 
                        hideDownloadIcon={isFixedHeader}
                    />
                </StatBoxesStyled>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
        monthly: state.stats.searches,
        related_searches: state.related_searches,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: async (term, loc_id, related_terms) => {
            let url = process.env.REACT_APP_API_ENDPOINT + '/monthly-search-and-cpc'
            try {
                const response = await fetchClient().post(url, {
                    search_term: term,
                    loc_id,
                    related_terms
                });
                if (response.status !== 200) {
                    throw response.message
                } else {
                    //Get main search from localStorage
                    const currentState = JSON.parse(localStorage.getItem('state'))

                    if (currentState && (currentState.state.search.main === term)) {
                        return dispatch(monthly_search_finished(response.data.data))
                    }
                }
            } catch (err) {
                return dispatch(monthly_search_error())
            }
        },
        emptyMonthlySearchError: () => {
            dispatch(emptyMonthlySearchError())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsBoxes)
