import React from 'react'
import { RowBody } from "./RowResult/RowBody";
import { Row, Col } from 'reactstrap'
import { RowFooter } from "./RowResult/RowFooter";


const styleContent = {
  display: "grid",
  alignItems: "flex-end",
  alignContent: "space-between"
}

function TopStoriesRow(props) {
  const { data, } = props
  let sentimentPopoverIsOpenTopStory = {

  };
  return (
    <Row className="">
      {(data.resume || []).map((row, index) => {
        if (index < 3) {
          return (
            <Col className="col-4" style={styleContent}>
              <RowBody result={row} showImage />
              <RowFooter hideCategories {...props} propsScore={{ value: row.score }} domainAuthority={row.domainAuthority} sentimentValue={row.sentiment} topStoryUrl={row.link} topStoryIndex={index} />
            </Col>
          );
        }
      })}
    </Row>
  )
}




export default TopStoriesRow

