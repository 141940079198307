import React from 'react';
import {InputStyled} from '../../styles/Input'
import {Button, Input as InputText, InputGroupAddon, InputGroupText} from 'reactstrap'

export const Input = ({
                          icon,
                          colorIcon,
                          fontSize,
                          fontWeight,
                          iconAppend,
                          className,
                          addonType = "prepend",
                          onClickAppend,
                          loading,
                          cancel,
                          ...rest
                      }) => {
    return (
        <InputStyled className={className} colorIcon={colorIcon} fontSize={fontSize} fontWeight={fontWeight}>
            {icon && <InputGroupAddon addonType={addonType}> <InputGroupText> {icon}</InputGroupText></InputGroupAddon>}
            <InputText {...rest} />
            {iconAppend && onClickAppend && (
                <InputGroupAddon addonType="append">
                    <Button onClick={onClickAppend}> {iconAppend} </Button>
                </InputGroupAddon>)}
            {loading && cancel && (
                <InputGroupAddon addonType="append">
                    <Button className="btn_cancel_search" onClick={cancel}>Cancel search</Button>
                </InputGroupAddon>)}
        </InputStyled>
    )
}