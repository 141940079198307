import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from 'reactstrap'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export default class AutomatedSearch extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        const {value, setValue, color} = this.props
        return (
            <AutomatedSearchStyled className={this.props.className || 'is-modal'} color={color}>
                <h3> Set Smart Automate Pages </h3>
                <div className="controls">
                    <span>Pages 1 to </span>
                    <div>
                        <Button className="btn-control" onClick={() => setValue(value - 1)} disabled={!!(value <= 1)}>
                            <FontAwesomeIcon className="icon" icon={faMinus}
                            />
                        </Button>
                        {value}
                        <Button className="btn-control" onClick={() => setValue(value + 1)} disabled={!!(value >= 10)}>
                            <FontAwesomeIcon className="icon" icon={faPlus}
                            />
                        </Button>
                    </div>
                </div>
            </AutomatedSearchStyled>
        );
    }
}

const AutomatedSearchStyled = styled.div`
  background-color: ${({color}) => color || '#374c8b'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1rem 1.5rem;
  &.is-modal {
    margin: 0 -1rem;
    @media screen and (max-width: 992px) {
      margin: 0 -8px;
    }
  }
  h3 {
    color: white;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .controls {
    display: flex;
    align-items: center;
    color: #fff;

    .btn-control {
      background-color: #fff;
      border-radius: 6px;
      color: ${({color}) => color || '#374c8b'} !important;
      padding: .8rem;
      height: auto !important;
      line-height: 0 !important;
      margin: 0 .7rem;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;

      .icon {
        color: ${({color}) => color || '#374c8b'} !important;
        font-size: .9em;
      }
    }
  }
`