import React from 'react'
import { CategoryLegendStyled, Footer } from '../../styles/DoughnutsLegend'


const getFormattedCategoryLegend = (legend) => {
    //If the following condition is met, legend is an array with each category of "Other" as values
    if (typeof legend === 'object') return 'Other'
    if (legend.includes('Third-Party Profile')) return 'Third-Party'
    return legend.split('%').pop()          
}

const CategoryDoughnutLegend = ({ data, competitive, controlled }) => {
    return (data ?
        <>
            <CategoryLegendStyled>
                <div className="flex-container">
                    {data.map((item) => {
                        return (
                            <li key={item.text} className="listItem">
                                <div className="legendContainer">
                                    <div className="legendColoredBox" style={{ backgroundColor: item.fillStyle }} />
                                    <p className="legendText">
                                        {getFormattedCategoryLegend(item.text)}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                </div>
            </CategoryLegendStyled>
            <Footer>
                <div className="divSize">
                    <p>Competitive: {competitive}%</p>
                </div>
                <div className="divSize">
                    <p>Controlled: {controlled}%</p>
                </div>
            </Footer>
        </>
        : null
    )
}

export default CategoryDoughnutLegend