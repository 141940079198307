import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Table, Button } from 'reactstrap'
import MenuOptionsList from '../../components/MenuOptionsList'
import '../../css/OverridesList.css'
import arrow_down from '../../images/arrow_down.svg'
import arrow_up from '../../images/arrow_up.svg'
import arrow_up_down from '../../images/arrow_up_down.svg'

class OverridesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      sortColumn: {}
    }
  }

  rowClickAction = (item) => {
      const { options } = this.props;
      if(options.rowClickAction) options.rowClickAction(item)
  }

  getButton = (item) => {
    let element = null
    if(item.currentOverride){
      element = <Fragment><Button
        style={{backgroundColor: "#35A9E0", border: "none"}}
        onClick={() => (this.props.options.onSaveButton(item))}>
        Save
      </Button>
      <Button
        className={"cancelBtnOverrides"}
        onClick={() => this.props.options.onCancelButton(item)}>
        Cancel
      </Button>
      </Fragment>
    }else if(item.currentEdit){
      element = <Fragment><Button
        style={{backgroundColor: "#35A9E0", border: "none"}}
        onClick={() => (this.props.options.onSaveButton(item))}>
        Update
      </Button>
      <Button
        className={"cancelBtnOverrides"}
        onClick={() => this.props.options.onCancelButton(item)}>
        Cancel
      </Button>
      </Fragment>
    }else{
      element = <MenuOptionsList onEdit={() => (this.props.options.onEdit(item))} onDelete={this.props.options.onDelete} item={item} />
    }

    return element
  }

  sortColumn = (key) => {
    let order = (this.state.sortColumn.column !== key) ? true : this.state.sortColumn.ascendent

    this.state.data.data.sort((a,b) => {
      return a[key] === b[key] ? 0 : ((order) ? a[key] < b[key] ? -1 : 1 : a[key] > b[key] ? -1 : 1);
    });

    this.setState({
      sortColumn: {
        column: key,
        ascendent: !order
      },
      data: this.state.data
    })
  }

  formatText = (value) => {
    return (typeof(value) == "string" && value.length > 50) ? value.substring(0, 50) + "..." : value;
  }

  render() {
    const { columns, options } = this.props;
    return (
      this.state.data && this.state.data ?
        <div className='OverridesList'>
          <Table>
            <thead>
              <tr>
                {
                  columns.map((value, key) => (
                  <th
                    style={{width: value.width }}
                    key={key}
                    className='col-header'
                    onClick={() => { this.sortColumn(value.alias) } }
                  >
                    {value.name}
                  { ((this.state.sortColumn.column == value.alias) && this.state.sortColumn.ascendent) ?
                      <span> <img src={arrow_down} /></span>
                    :
                      (((this.state.sortColumn.column == value.alias) && !this.state.sortColumn.ascendent) ?
                        <span> <img src={arrow_up} /></span>
                      :
                        <span> <img src={arrow_up_down} /></span>
                      )
                  }
                  </th>
                  ))
                }
                {
                  options.menu ? <th className='col-header'>Options</th> : null
                }
              </tr>
            </thead>
            <tbody className='table-body'>
              {
                this.state.data.data.map((item, key) => (
                   <tr key={key}>
                    {
                      columns.map((value, subkey) => {
                        return <td onClick={() => (this.rowClickAction(item))} style={{width: value.width }} key={subkey} className='col-header'>{this.formatText(item[value.attr])}</td>
                      })
                    }
                    {options.menu &&
                      <td className='col-header'>
                        {this.getButton(item)}
                      </td>
                    }
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
        :
          <div></div>
    );
  }
}
const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(OverridesList)


