const getFormattedURL = url => {
    if (!url) return
    //Remove final '/' if present
    if (url.endsWith('/')) url = url.slice(0,-1)
    const parts = url.split('/')
    //Remove protocol
    let urlWithoutProtcol = url.includes('://') ? parts[2] : parts[0]
    //Divide in subdomain and domain
    let domain
    const splittedURL = urlWithoutProtcol.split('.')
    if (splittedURL.length > 2 ) {  
        let [subDomain, ...rest] = splittedURL
        domain = rest.join('.')
    } else {
        domain = urlWithoutProtcol
    }
    //Get last part of path without querystring
    let lastPath
    if (parts.length > 1) {
        lastPath = parts[parts.length - 1].startsWith('?') ? parts[parts.length - 2] : parts[parts.length - 1]
        if (lastPath.includes('?')) lastPath = lastPath.split('?')[0]
        if (lastPath.includes('.')) lastPath = ''
    }
    // Capitalize path
    if (lastPath) lastPath = lastPath[0].toUpperCase() + lastPath.substring(1)

    const formattedURL = lastPath ? domain + ' > ... > ' + lastPath : domain
    return formattedURL
}

export default getFormattedURL