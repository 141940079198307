import React, { Component } from 'react'
import { connect } from 'react-redux'
import folderIcon from './../images/folder-icon.svg'
import folderOpenIcon from './../images/folder-open-icon.svg'
 
class FilesBreadcrum extends Component {

  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      current_path:this.props.currentPathAttr
    }
    this.goToFSPath = this.goToFSPath.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({current_path:'/'})
  }

  componentDidUpdate() {
    if (this.props.intoModal && this.state.current_path !== this.props.currentPathAttr) {
      this.setState({
        current_path:this.props.currentPathAttr
      })
    }
  }

  goToFSPath(position) {
    let current_path = !this.props.intoModal ? this.props.current_path : this.state.current_path
    
    let array_paths = current_path.split('/')
    if (array_paths.length !== position) {
      if(position == 0) {
        this.setState({current_path:'/'}, ()=>{
          this.props.changeCurrentPath('/')
        })
      }else {
          array_paths = array_paths.slice(0, position+1)
          !this.props.intoModal ? this.props.changeCurrentPath(array_paths.join('/')) : this.setState({current_path:array_paths.join('/')}, ()=>{
            this.props.changeCurrentPath(array_paths.join('/'))
          })
        }
    }
  }
  
  render() {
    var root_path = [<li key='root' onClick={()=>this.goToFSPath(0)}>My Files</li>]

    if (!this.props.intoModal && this.props.current_path !== '' && this.props.current_path !== '/' || 
      this.props.intoModal && this.state.current_path !== '' && this.state.current_path !== '/') {

        let current_path = !this.props.intoModal ? this.props.current_path : this.state.current_path
        if (current_path.startsWith('/')) {
          current_path.substr(1)
        }
        let result = current_path.split('/').map((item,index) => {
          return item ? <div key={index}>{item}</div> : null
        })

        var sufix_path = result.filter((item)=>{
          return item !== null
        }).map((item,index,result)=>{
          return item ? 
            <li key={index} onClick={()=>this.goToFSPath(index+1)} className={index+1 == result.length ? "current-folder" : ""}><img width="20" src={index+1 !== result.length ? folderIcon : folderOpenIcon }></img><p>{item.props.children}</p></li> 
            : null
        })
    }
   
    return (
      <ul>
        {
          root_path.concat(sufix_path)
        }
      </ul>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    current_path: state.files_current_path
  }
}
export default connect(mapStateToProps, null)(FilesBreadcrum)