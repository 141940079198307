import React, { useEffect, useState } from 'react';
import ActionPlan from './ActionPlan';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import ActionPlanModalContainer from './ActionPlanModalContainer';
import fetchClient from '../../helpers/fetch-client';


const ActionPlanContainer = ({
  ranking,
  loadPlan,
  actionPlanModal,
  handleLoaders,
  generateActionPlan,
  handleActionPlanModal,
  modal,
  generatePlanMessage,
  handleActionPlanError,
  handleActionPlanSuccess,
  uploaded_file
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [actionPlanID, setActionPlanID] = useState(null);
  const [data, setData] = useState([]);
  let interval = null;
  const isNotFile = uploaded_file === "" ? true : false

  useEffect(() => {
    if (typeof actionPlanID === 'number') {
      interval = setInterval(getActionPlan, 2000)
    }

    return () => clearInterval(interval);
  }, [actionPlanID])

  useEffect(() => {
    if (modal && localStorage.getItem("actionPlanID")) {
      getActionPlan()
    }
  }, [modal])

  const toggle = () => setIsOpen(isOpen => !isOpen);

  const createActionPlan = async () => {
    const response = await generateActionPlan();
    if (response?.status === 201) {
      setActionPlanID(response?.data?.id)
    }
  }

  const getActionPlan = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_ENDPOINT + `/action-plan/status/${actionPlanID}`)
      .then((response) => {
        const { data } = response;
        if (data.progress === 100) {
          clearInterval(interval);
          const { term: searchTerm, location, date, result } = data;
          const [year, month, day] = date.split('T')[0].split('-')
          setData({
            searchTerm,
            location,
            date: `${month}/${day}/${year}`,
            threats: Object.values(result.threats),
            opportunities: Object.values(result.opportunities),
          });
          handleActionPlanSuccess();
        }
      })
      .catch((error) => {
        clearInterval(interval);
        handleActionPlanError();
      })
  };

  return (<>
    {isNotFile && <Col lg={4} className='p-0 d-flex justify-content-center' id="action-plan">
      <ActionPlan
        showProgressBar={loadPlan}
        generateActionPlan={createActionPlan}
        openBadge={isOpen}
        handleBadge={toggle}
        loading={handleLoaders}
        handlePlan={actionPlanModal}
        openModal={handleActionPlanModal}
        generatePlanMessage={generatePlanMessage}
      />
      <ActionPlanModalContainer
        openModal={handleActionPlanModal}
        modal={modal}
        data={data}
      />
    </Col>}
  </>)
}

const mapStateToProps = state => {
  return {
    search: state.search !== undefined ? state.search : { main: '', sub: '' },
    ranking: state.ranking !== undefined ? state.ranking : [],
    knowledge_graph: state.knowledge_graph,
    related_searches: state.related_searches,
    auth_info: state.auth_info,
    visible_rows: state.visible_rows,
    fetching_ids: state.fetching_ids,
    uploaded_file: state.uploaded_file
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    incrementVisibleRows: () => {
      dispatch(incrementVisibleRows())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlanContainer)