import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: (process.env.REACT_APP_DEV_ENV) ? process.env.REACT_APP_GA_AUTH_DEV : process.env.REACT_APP_GA_AUTH_PROD,
    preview: (process.env.REACT_APP_DEV_ENV) ? process.env.REACT_APP_GA_ENV_DEV : process.env.REACT_APP_GA_ENV_PROD
}
TagManager.initialize(tagManagerArgs)

export const pageView = () => TagManager.dataLayer({
    dataLayer: {
        event: 'pageview',
        path: window.location.pathname + window.location.search
    }
})

export const sendGaEvent = (_category, _ev, _label, automation) => TagManager.dataLayer({
    dataLayer: {
        event: 'ga-event',
        'category-GA': _category + ((automation) ? ' - Automated' : ' - Not automated'),
        'action-GA': (typeof (_ev) == "string") ? _ev : _ev.value,
        'label-GA': _label,
        'value-GA': 1
    }
})