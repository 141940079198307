import React from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import styled from 'styled-components'

export default class ModalSchedule extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {isOpen, toggle, children, title} = this.props
        return (
            <ModalStyle centered isOpen={isOpen} toggle={toggle}>
                <ModalBody>
                    <div className='header'>
                        <h3>
                            {title}
                        </h3>
                    </div>
                    {children}
                </ModalBody>
            </ModalStyle>
        );
    }
}

const ModalStyle = styled(Modal)`
  .btn {
    font-size: 1em;
  }

  .modal-content  {
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
    .modal-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      -webkit-border-radius: inherit !important;
      -moz-border-radius: inherit !important;
      border-radius: inherit !important;
    }
  }
  .header {
    display: flex;
    padding: 1.6rem;
    justify-content: center;

    h3 {
      color: #212529;
      text-align: center;
      font-size: 1.5em;
      font-weight: 700;
    }
  }
`