import styled from 'styled-components';
import {styles} from './variables';
import {InputGroup} from 'reactstrap'

export const InputStyled = styled(InputGroup)`
  width: auto;
  position: relative;
  &.input-search{
    flex-basis: 50% !important;
    flex-grow: 0 !important;
    @media screen and (max-width: ${styles.breakpoints.sm}){
      flex-basis: 100% !important;
      flex-grow: 1 !important;
    }
  }
  .btn_cancel_search{
    color: ${styles.textColor} !important;
  }
  .form-control {
    outline: none !important;
    box-shadow: none !important;
    background-color: #F4F8FA !important;
    border-color: #CFE1EA !important;
    font-size: ${({fontSize}) => fontSize || '1em'} !important;
    font-weight: ${({fontWeight}) => fontWeight || 400} !important;
    &:focus {
      border-color: ${styles.primaryColor} !important;
    }
  }
  .input-group-text {
    background-color: #F4F8FA !important;
    border-color: #CFE1EA !important;
    border-right: 0;
    font-size: 1em;
  }
  .input-group-prepend + input {
    border-left: 0;
    padding-left: .5rem !important;
  }
  .dropdown-toggle{
    outline: none !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #CFE1EA !important;
  } 
  .dropdown-toggle::after{
    color: ${({colorToggle}) => colorToggle || styles.primaryColor} !important;
  }
  .input-group-text,
  .input-group-append {
    button {
      outline: none !important;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #CFE1EA !important;
    }

    svg, img {
      min-width: 1rem;
      min-height: 1rem;
      path {
        fill: ${({colorIcon}) => colorIcon || styles.primaryColor} !important;
        color: ${({colorIcon}) => colorIcon || styles.primaryColor} !important;
      }
    }
  }
`