import React, { Component } from 'react'
import { Col } from 'reactstrap'
import { connect } from 'react-redux'
import './../css/KnowledgeGraph.css'
import PeopleAlsoSearch from './PeopleAlsoSearch'

class KnowledgeGraphDetails extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const keys = this.props.wikipedia ? Object.keys(this.props.wikipedia) : []

        return (
            <Col md={12} className="knowledge-graph-details">
                {
                    keys.map(function(key) {
                        if (typeof this.props.wikipedia[key] === 'string' && !this.props.wikipedia[key].startsWith("http")) {
                            return <div key={key}><span><b>{key}: </b></span><span>{this.props.wikipedia[key]}</span></div>
                        }
                        
                    }, this)
                }
                
                {
                    this.props.peopleAlsoSearch !== undefined && this.props.peopleAlsoSearch.length && this.props.peopleAlsoSearchLink !== undefined && this.props.peopleAlsoSearchLink !== "" ?
                        <React.Fragment>
                            <h4>People also search for</h4>
                            <a target="_blank" className="viewMore" href={this.props.peopleAlsoSearchLink}>View more results</a><br/>
                            <PeopleAlsoSearch data={this.props.peopleAlsoSearch}></PeopleAlsoSearch>
                        </React.Fragment>
                    : ''
                }
            </Col>
        )
    }
}

export default connect(null, null)(KnowledgeGraphDetails)