import React, { Component } from 'react'
import { Modal, ModalHeader, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import SEOPageTitle from '../images/icons/seo-pagetitle.svg'
import SEOURL from '../images/icons/seo-url.svg'
import SEOH1 from '../images/icons/seo-h1.svg'
import SEOClick from '../images/icons/seo-click.svg'
import SEOInternalLinks from '../images/icons/seo-internal-links.svg'
import SEOExternalLinks from '../images/icons/seo-external-links.svg'
import SEOImages from '../images/icons/seo-images.svg'
import SEOBackLinks from '../images/icons/seo-backlinks.svg'
import SEOLoadSpeed from '../images/icons/seo-loadspeed.svg'
import SEOSSL from '../images/icons/seo-ssl.svg'
import {
  toggle_page_title_override,
  toggle_term_in_url_override,
  toggle_keyword_match_h1_override,
  toggle_click_depth_override,
  toggle_internal_links_count_override,
  toggle_external_links_count_override,
  toggle_images_count_override,
  toggle_backlinks_override,
  toggle_load_speed_override,
  toggle_security_certificate_override,
  calculateLumentusScore
} from '../actionCreators'
import SEOHeader from './seoModal/SEOHeader'
import SEOCloseButton from './seoModal/SEOCloseButton'
import SEOBody from './seoModal/SEOBody'
import './../css/RankingModal.css'
import '../css/Tooltip.css'

const getIconClassByStatus = (status) => {
  let icon = ""
  status = status !== undefined ? status.trim() : ""
  switch (status) {
    case 'finish':
      icon = <FontAwesomeIcon icon='check' color='green' />
      break
    case 'fail':
    case 'timeout':
      icon = <FontAwesomeIcon icon='times' color='red' />
      break
    case 'empty_cache':
      icon = <FontAwesomeIcon icon='times' color='red' />
      break
    case 'inProgress':
      icon = <div className='icon-loading'></div>
      break
    case 'unrequested':
      icon = <FontAwesomeIcon icon='question' />
      break
    default:
      break
  }
  return icon
}

class RankingModal extends Component {

  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {}
    this.data = {
      score: 0,
      authorithy: '',
      modal_open: false,

      modal_title: "",
      page_title_score: 0,
      page_title_status: "",
      page_title_message: "",
      page_title_response_time: 0,
      page_title_message_color: "",

      term_in_url_score: 0,
      term_in_url_status: "",
      term_in_url_message: "",
      term_in_url_response_time: 0,
      term_in_url_message_color: "",

      backlinks_score: 0,
      backlinks_status: "",
      backlinks_message: "",
      backlinks_response_time: 0,
      backlinks_message_color: "",

      load_speed_score: 0,
      load_speed_status: "",
      load_speed_message: "",
      load_speed_response_time: 0,
      load_speed_message_color: "",
      load_speed_link: "",

      security_certificate_score: 0,
      security_certificate_status: "",
      security_certificate_message: "",
      security_certificate_response_time: 0,
      security_certificate_message_color: "",

      images_count_score: 0,
      images_count_status: "",
      images_count_message: "",
      images_count_response_time: 0,
      images_count_message_color: "",

      internal_links_count_score: 0,
      internal_links_count_status: "",
      internal_links_count_message: "",
      internal_links_count_response_time: 0,
      internal_links_count_message_color: "",

      external_links_count_score: 0,
      external_links_count_status: "",
      external_links_count_message: "",
      external_links_count_response_time: 0,
      external_links_count_message_color: "",

      click_depth_score: 0,
      click_depth_status: "",
      click_depth_message: "",
      click_depth_response_time: 0,
      click_depth_message_color: "",

      keyword_match_h1_score: 0,
      keyword_match_h1_status: "",
      keyword_match_h1_message: "",
      keyword_match_h1_response_time: 0,
      keyword_match_h1_message_color: "",

      seo_warnings_messages: [],
    }
    this.parseData = this.parseData.bind(this)
    this.togglePageTitleManuallyOverride = this.togglePageTitleManuallyOverride.bind(this)
    this.toggleTermInUrlManuallyOverride = this.toggleTermInUrlManuallyOverride.bind(this)
    this.toggleKeywordMatchH1ManuallyOverride = this.toggleKeywordMatchH1ManuallyOverride.bind(this)
    this.toggleClickDepthManuallyOverride = this.toggleClickDepthManuallyOverride.bind(this)
    this.toggleInternalLinksCountManuallyOverride = this.toggleInternalLinksCountManuallyOverride.bind(this)
    this.toggleExternalLinksCountManuallyOverride = this.toggleExternalLinksCountManuallyOverride.bind(this)
    this.toggleImagesCountManuallyOverride = this.toggleImagesCountManuallyOverride.bind(this)
    this.toggleBacklinksManuallyOverride = this.toggleBacklinksManuallyOverride.bind(this)
    this.toggleLoadSpeedManuallyOverride = this.toggleLoadSpeedManuallyOverride.bind(this)
    this.toggleSecurityCertificateManuallyOverride = this.toggleSecurityCertificateManuallyOverride.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  parseData() {
    let rgbRed = 'rgb(208, 2, 27)'
    let rgbGreen = 'rgb(50, 152, 68)'
    let rgbOrange = 'rgb(237, 172, 0)'
    let grayUnavailable = '#909090'

    let data = {}
    data.modal_title = this.props.isTopStory ? this.props.data.resume[this.props.story_index].title : this.props.data.resume[0].title
    if (this.props.isTopStory) {
      data.score = this.props.data.resume[this.props.story_index].score
      data.link = this.props.data.resume[this.props.story_index].link
    }
    let modules = this.props.isTopStory ? (this.props.data.resume[this.props.story_index].hasOwnProperty("relevance")) ? Object.values(this.props.data.resume[this.props.story_index].relevance) : this.props.data.relevance : this.props.data.relevance;
    for (let i = 0; i < modules.length; i++) {
      let obj = modules[i]
      let isKeywordFound = false;
      switch (obj.module) {
        case "page_title":
        case "Page Title":

          let isKeywordFound = obj.score > 0;
        case "Page Title":

          isKeywordFound = obj.score > 0;

          if (this.props.isTopStory && !isKeywordFound) {
            isKeywordFound = (this.props.data.hasOwnProperty("page_title_override_top_stories")) ? !!this.props.data.page_title_override_top_stories[this.props.story_index] : false;
          } else if (!isKeywordFound) {
            isKeywordFound = !!this.props.data.page_title_override;
          }

          data.page_title_score = obj.score
          data.page_title_status = getIconClassByStatus(obj.status)
          data.page_title_message = (obj.status === "finish" ? (isKeywordFound ? "Keyword Found" : "Keyword Not Found") : obj.message)
          data.page_title_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.page_title_message_style = (isKeywordFound ? { color: rgbGreen } : { color: rgbRed })
          data.page_title_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          break
        case "term_in_url":
        case "Term In URL":
          let isKeywordFound2 = obj.score > 0;

          if (this.props.isTopStory && !isKeywordFound2) {
            isKeywordFound2 = (this.props.data.hasOwnProperty("term_in_url_override_top_stories")) ? !!this.props.data.term_in_url_override_top_stories[this.props.story_index] : false;
          } else if (!isKeywordFound2) {
            isKeywordFound2 = !!this.props.data.term_in_url_override;
          }

          data.term_in_url_score = obj.score
          data.term_in_url_status = getIconClassByStatus(obj.status)
          data.term_in_url_message = (obj.status === "finish" ? (isKeywordFound2 ? "Keyword Found" : "Keyword Not Found") : obj.message)
          data.term_in_url_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.term_in_url_message_style = (isKeywordFound2 ? { color: rgbGreen } : { color: rgbRed })
          data.term_in_url_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          break
        case "backlinks":
        case "BackLinks":
          let backlinks_message = ""
          let backlinks_style = {}

          //need fix for saved data (previous backlinks was out of 10 points maximum and had message "This page has 5 (or more) backlinks.")
          let isOldBacklinksData = (obj.message.indexOf('This page has') > -1)

          let isOverridenBacklinks = false;
          if (this.props.isTopStory && !isOverridenBacklinks) {
            isOverridenBacklinks = (this.props.data.hasOwnProperty("backlinks_override_top_stories")) ? !!this.props.data.backlinks_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenBacklinks) {
            isOverridenBacklinks = !!this.props.data.backlinks_override;
          }

          if (isOldBacklinksData) {
            backlinks_message = obj.message
          } else {
            if (obj.score == 3.75) {
              backlinks_message = "Weak" + (obj.message != '' ? " (" + obj.message + ")" : '')
              backlinks_style = { color: rgbOrange };
            } else if (obj.score == 7.5) {
              backlinks_message = "Good" + (obj.message != '' ? " (" + obj.message + ")" : '')
              backlinks_style = { color: rgbGreen };
            } else if (obj.score == 15) {
              backlinks_message = "Excellent" + (obj.message != '' ? " (" + obj.message + ")" : '');
              backlinks_style = { color: rgbGreen };
            } else {
              backlinks_message = "No Back Links Found"
              backlinks_style = { color: rgbRed }
            }
          }

          if (isOverridenBacklinks) {
            backlinks_message = "Excellent"
            backlinks_style = { color: rgbGreen }
          }

          data.backlinks_score = obj.score
          data.backlinks_status = getIconClassByStatus(obj.status)
          data.backlinks_message = (obj.status === "finish" ? backlinks_message : obj.message)
          data.backlinks_style = backlinks_style
          data.backlinks_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.backlinks_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          break
        case "load_speed":
        case "Load Speed":
          let load_speed_message = ""
          let load_speed_style = {}

          //need fix for saved data (previously load speed was out of 10 points maximum and had message "The load speed sum for desktop and mobile devices is 163.")
          let isOldLoadSpeedData = (obj.message.indexOf('The load speed sum for desktop and mobile devices') > -1)


          let isOverridenLoadSpeed = false;
          if (this.props.isTopStory && !isOverridenLoadSpeed) {
            isOverridenLoadSpeed = (this.props.data.hasOwnProperty("load_speed_override_top_stories")) ? !!this.props.data.load_speed_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenLoadSpeed) {
            isOverridenLoadSpeed = !!this.props.data.load_speed_override;
          }

          if (isOldLoadSpeedData) {
            load_speed_message = obj.message.replace('The load speed sum for desktop and mobile devices is', 'Score of').replace('.', '') + ' out of 200'
          } else {
            if (obj.message == 'NONE') {
              load_speed_message = "Not Available"
              load_speed_style = { color: 'rgb(151,160,164)' };
            } else {
              load_speed_message = obj.message
              switch (obj.score) {
                case 0:
                  load_speed_style = { color: rgbRed };
                  break;
                case 5:
                  load_speed_style = { color: 'rgb(24,44,76)' };
                  break;
                case 7.5:
                  load_speed_style = { color: rgbGreen };
                  break;
                case 10:
                  load_speed_style = { color: rgbGreen };
                  break;
              }
            }
          }

          if (isOverridenLoadSpeed) {
            load_speed_message = "Very Fast"
            load_speed_style = { color: rgbGreen };
          }

          data.load_speed_score = obj.score
          data.load_speed_status = getIconClassByStatus(obj.status)
          data.load_speed_message = (obj.status === "finish" ? load_speed_message : obj.message)
          data.load_speed_style = (obj.status === "finish" ? load_speed_style : { color: rgbRed })
          data.load_speed_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.load_speed_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          data.load_speed_link = this.props.isTopStory ? this.props.data.resume[this.props.story_index].link : this.props.data.resume[0].url
          break
        case "security_certificate":
        case "Security Certificate":
          let security_certificate_message = "SSL Certificate Found"
          let security_certificate_style = { color: rgbGreen };

          let isOverridenSSLCertificate = false;
          if (this.props.isTopStory && !isOverridenSSLCertificate) {
            isOverridenSSLCertificate = (this.props.data.hasOwnProperty("security_certificate_override_top_stories")) ? !!this.props.data.security_certificate_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenSSLCertificate) {
            isOverridenSSLCertificate = !!this.props.data.security_certificate_override;
          }

          if (obj.score == 0) {
            security_certificate_message = "SSL Certificate Not Found";
            security_certificate_style = { color: rgbRed };
          }

          if (isOverridenSSLCertificate) {
            security_certificate_message = "SSL Certificate Found";
            security_certificate_style = { color: rgbGreen };
          }

          data.security_certificate_score = obj.score
          data.security_certificate_status = getIconClassByStatus(obj.status)
          data.security_certificate_message = (obj.status === "finish" ? security_certificate_message : obj.message)
          data.security_certificate_style = (obj.status === "finish" ? security_certificate_style : { color: rgbRed })
          data.security_certificate_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.security_certificate_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          break
        case "seo_warnings":
        case "SEO Warnings":
          data.seo_warnings_messages = (obj.message && Object.keys(obj.message).length > 0) ? Object.entries(obj.message) : null
          break
        case "images_count":
        case "Images Count":
          let images_count_message = ""
          let images_count_style = {}

          let isOverridenImagesCount = false;
          if (this.props.isTopStory && !isOverridenImagesCount) {
            isOverridenImagesCount = (this.props.data.hasOwnProperty("images_count_override_top_stories")) ? !!this.props.data.images_count_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenImagesCount) {
            isOverridenImagesCount = !!this.props.data.images_count_override;
          }

          if (obj.message == 'NONE') {
            images_count_message = "Not Available"
            images_count_style = { color: 'rgb(151,160,164)' };
          } else {
            switch (obj.score) {
              case 0:
                if (obj.message == 'DFS Error') {
                  images_count_message = `Data unavailable`
                  images_count_style = { color: grayUnavailable };
                } else {
                  images_count_message = "No Images Found"
                  images_count_style = { color: rgbRed };
                }
                break;
              case 5:
                images_count_message = "Images Found" + (obj.message != '' ? " (" + obj.message + ")" : '')
                images_count_style = { color: rgbGreen };
                break;
            }
          }

          if (isOverridenImagesCount) {
            images_count_message = "Excellent"
            images_count_style = { color: rgbGreen };
          }

          data.images_count_score = obj.score
          data.images_count_status = getIconClassByStatus(obj.status)
          data.images_count_message = (obj.status === "finish" ? images_count_message : obj.message)
          data.images_count_style = (obj.status === "finish" ? images_count_style : { color: rgbRed })
          data.images_count_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.images_count_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          data.images_count_link = this.props.isTopStory ? this.props.data.resume[this.props.story_index].link : this.props.data.resume[0].url
          break
        case "internal_links_count":
        case "Internal Links Count":
          let internal_links_count_message = ""
          let internal_links_count_style = {}

          let isOverridenInternalLinks = false;
          if (this.props.isTopStory && !isOverridenInternalLinks) {
            isOverridenInternalLinks = (this.props.data.hasOwnProperty("internal_links_count_override_top_stories")) ? !!this.props.data.internal_links_count_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenInternalLinks) {
            isOverridenInternalLinks = !!this.props.data.internal_links_count_override;
          }

          if (obj.message == 'NONE') {
            internal_links_count_message = "Not Available"
            internal_links_count_style = { color: 'rgb(151,160,164)' };
          } else {
            switch (obj.score) {
              case 0:
                if (obj.message == 'DFS Error') {
                  internal_links_count_message = "Data unavailable"
                  internal_links_count_style = { color: grayUnavailable };
                } else {
                  internal_links_count_message = "No Internal Links Found"
                  internal_links_count_style = { color: rgbRed };
                }
                break;
              case 1.25:
                internal_links_count_message = "Low" + (obj.message != '' ? " (" + obj.message + ")" : '')
                internal_links_count_style = { color: 'rgb(24,44,76)' };
                break;
              case 2.5:
                internal_links_count_message = "Good" + (obj.message != '' ? " (" + obj.message + ")" : '')
                internal_links_count_style = { color: rgbGreen };
                break;
              case 5:
                internal_links_count_message = "Excellent" + (obj.message != '' ? " (" + obj.message + ")" : '')
                internal_links_count_style = { color: rgbGreen };
                break;
            }
          }

          if (isOverridenInternalLinks) {
            internal_links_count_message = "Excellent"
            internal_links_count_style = { color: rgbGreen };
          }

          data.internal_links_count_score = obj.score
          data.internal_links_count_status = getIconClassByStatus(obj.status)
          data.internal_links_count_message = (obj.status === "finish" ? internal_links_count_message : obj.message)
          data.internal_links_count_style = (obj.status === "finish" ? internal_links_count_style : { color: rgbRed })
          data.internal_links_count_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.internal_links_count_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          data.internal_links_count_link = this.props.isTopStory ? this.props.data.resume[this.props.story_index].link : this.props.data.resume[0].url
          break
        case "external_links_count":
        case "External Links Count":
          let external_links_count_message = ""
          let external_links_count_style = {}

          let isOverridenExternalLinks = false;
          if (this.props.isTopStory && !isOverridenExternalLinks) {
            isOverridenExternalLinks = (this.props.data.hasOwnProperty("external_links_count_override_top_stories")) ? !!this.props.data.external_links_count_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenExternalLinks) {
            isOverridenExternalLinks = !!this.props.data.external_links_count_override;
          }

          if (obj.message == 'NONE') {
            external_links_count_message = "Not Available"
            external_links_count_style = { color: 'rgb(151,160,164)' };
          } else {
            switch (obj.score) {
              case 0:
                if (obj.message == 'DFS Error') {
                  external_links_count_message = 'Data unavailable'
                  external_links_count_style = { color: grayUnavailable };
                } else {
                  external_links_count_message = "No External Links Found"
                  external_links_count_style = { color: rgbRed };
                }
                break;
              case 1.25:
                external_links_count_message = "Low" + (obj.message != '' ? " (" + obj.message + ")" : '')
                external_links_count_style = { color: 'rgb(24,44,76)' };
                break;
              case 2.5:
                external_links_count_message = "Good" + (obj.message != '' ? " (" + obj.message + ")" : '')
                external_links_count_style = { color: rgbGreen };
                break;
              case 5:
                external_links_count_message = "Excellent" + (obj.message != '' ? " (" + obj.message + ")" : '')
                external_links_count_style = { color: rgbGreen };
                break;
            }
          }

          if (isOverridenExternalLinks) {
            external_links_count_message = "Excellent"
            external_links_count_style = { color: rgbGreen };
          }

          data.external_links_count_score = obj.score
          data.external_links_count_status = getIconClassByStatus(obj.status)
          data.external_links_count_message = (obj.status === "finish" ? external_links_count_message : obj.message)
          data.external_links_count_style = (obj.status === "finish" ? external_links_count_style : { color: rgbRed })
          data.external_links_count_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.external_links_count_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
          data.external_links_count_link = this.props.isTopStory ? this.props.data.resume[this.props.story_index].link : this.props.data.resume[0].url
          break
        case "click_depth":
        case "Click Depth":

          let isOverridenClickDepth = false;
          if (this.props.isTopStory && !isOverridenClickDepth) {
            isOverridenClickDepth = (this.props.data.hasOwnProperty("click_depth_override_top_stories")) ? !!this.props.data.click_depth_override_top_stories[this.props.story_index] : false;
          } else if (!isOverridenClickDepth) {
            isOverridenClickDepth = this.props.data.click_depth_override;
          }

          if (obj.message == 'NONE') {
            data.click_depth_message = "Not Available"
            data.click_depth_style = { color: 'rgb(151,160,164)' };
          } else {
            switch (obj.score) {
              case 0:
                if (obj.message == 'DFS Error') {
                  data.click_depth_message = "Data unavailable"
                }
                data.click_depth_style = { color: grayUnavailable };
                break;
              case 1.25:
                data.click_depth_message = "Very Bad"
                data.click_depth_style = { color: rgbRed };
                break;
              case 2.5:
                data.click_depth_message = "Bad"
                data.click_depth_style = { color: rgbOrange };
                break;
              case 3.75:
                data.click_depth_message = "Good"
                data.click_depth_style = { color: rgbGreen };
                break;
              case 5:
                data.click_depth_message = "Excellent"
                data.click_depth_style = { color: rgbGreen };
                break;
            }
          }

          if (isOverridenClickDepth) {
            data.click_depth_message = "Excellent"
            data.click_depth_style = { color: rgbGreen };
          }

          data.click_depth_score = obj.score
          data.click_depth_status = getIconClassByStatus(obj.status)
          data.click_depth_message = (obj.status === "finish" ? data.click_depth_message : obj.message)
          data.click_depth_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0
        case "keyword_match_in_h1":
        case "Keyword Match in h1":

          let isKeywordFoundH1 = obj.score > 0;
          if (this.props.isTopStory && !isKeywordFoundH1) {
            isKeywordFoundH1 = (this.props.data.hasOwnProperty("keyword_match_h1_override_top_stories")) ? !!this.props.data.keyword_match_h1_override_top_stories[this.props.story_index] : false;
          } else if (!isKeywordFoundH1) {
            isKeywordFoundH1 = !!this.props.data.keyword_match_h1_override;
          }

          if (obj.message == 'DFS Error' && !isKeywordFoundH1) {
            data.keyword_match_h1_message = 'Data unavailable'
            data.keyword_match_h1_message_style = { color: grayUnavailable }
          } else {
            data.keyword_match_h1_message = (obj.status === "finish" ? (isKeywordFoundH1 ? "Keyword Found" : "Keyword Not Found") : obj.message)
            data.keyword_match_h1_message_style = (isKeywordFoundH1 ? { color: rgbGreen } : { color: rgbRed })
          }

          data.keyword_match_h1_message_color = "text-left alert " + (obj.status === "finish" ? "" : "alert-danger")
          data.keyword_match_h1_score = obj.score
          data.keyword_match_h1_status = getIconClassByStatus(obj.status)

          data.keyword_match_h1_response_time = obj.time_finished !== undefined ? (obj.time_finished - obj.time_started) / 1000 : 0

          break
        default:
          break
      }
    }

    this.data = data;
  }

  togglePageTitleManuallyOverride() {
    this.props.toggle_page_title_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleTermInUrlManuallyOverride() {
    this.props.toggle_term_in_url_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleKeywordMatchH1ManuallyOverride() {
    this.props.toggle_keyword_match_h1_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleClickDepthManuallyOverride() {
    this.props.toggle_click_depth_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleInternalLinksCountManuallyOverride() {
    this.props.toggle_internal_links_count_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleExternalLinksCountManuallyOverride() {
    this.props.toggle_external_links_count_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleImagesCountManuallyOverride() {
    this.props.toggle_images_count_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleBacklinksManuallyOverride() {
    this.props.toggle_backlinks_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleLoadSpeedManuallyOverride() {
    this.props.toggle_load_speed_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  toggleSecurityCertificateManuallyOverride() {
    this.props.toggle_security_certificate_override(this.props.data.id, this.props.story_index)
    setTimeout(function () {
      this.props.reCalculateState()
      this.props.calculateLumentusScore()
      this.parseData()
    }.bind(this), 0)
  }

  render() {
    this.parseData();
    let pageTitleManuallyOverride = !!this.props.data.page_title_override;
    let termInUrlManuallyOverride = !!this.props.data.term_in_url_override;
    let keywordMatchH1ManuallyOverride = !!this.props.data.keyword_match_h1_override;
    let clickDepthManuallyOverride = !!this.props.data.click_depth_override;
    let internalLinksCountManuallyOverride = !!this.props.data.internal_links_count_override;
    let externalLinksCountManuallyOverride = !!this.props.data.external_links_count_override;
    let imagesCountManuallyOverride = !!this.props.data.images_count_override;
    let backlinksManuallyOverride = !!this.props.data.backlinks_override;
    let loadSpeedManuallyOverride = !!this.props.data.load_speed_override;
    let securityCertificateManuallyOverride = !!this.props.data.security_certificate_override;

    if (this.props.isTopStory) {
      pageTitleManuallyOverride = (this.props.data.hasOwnProperty("page_title_override_top_stories")) ? !!this.props.data.page_title_override_top_stories[this.props.story_index] : false;
      termInUrlManuallyOverride = (this.props.data.hasOwnProperty("term_in_url_override_top_stories")) ? !!this.props.data.term_in_url_override_top_stories[this.props.story_index] : false;
      keywordMatchH1ManuallyOverride = (this.props.data.hasOwnProperty("keyword_match_h1_override_top_stories")) ? !!this.props.data.keyword_match_h1_override_top_stories[this.props.story_index] : false;
      clickDepthManuallyOverride = (this.props.data.hasOwnProperty("click_depth_override_top_stories")) ? !!this.props.data.click_depth_override_top_stories[this.props.story_index] : false;
      internalLinksCountManuallyOverride = (this.props.data.hasOwnProperty("internal_links_count_override_top_stories")) ? !!this.props.data.internal_links_count_override_top_stories[this.props.story_index] : false;
      externalLinksCountManuallyOverride = (this.props.data.hasOwnProperty("external_links_count_override_top_stories")) ? !!this.props.data.external_links_count_override_top_stories[this.props.story_index] : false;
      imagesCountManuallyOverride = (this.props.data.hasOwnProperty("images_count_override_top_stories")) ? !!this.props.data.images_count_override_top_stories[this.props.story_index] : false;
      backlinksManuallyOverride = (this.props.data.hasOwnProperty("backlinks_override_top_stories")) ? !!this.props.data.backlinks_override_top_stories[this.props.story_index] : false;
      loadSpeedManuallyOverride = (this.props.data.hasOwnProperty("load_speed_override_top_stories")) ? !!this.props.data.load_speed_override_top_stories[this.props.story_index] : false;
      securityCertificateManuallyOverride = (this.props.data.hasOwnProperty("security_certificate_override_top_stories")) ? !!this.props.data.security_certificate_override_top_stories[this.props.story_index] : false;
    }

    const SEOBodyData = [
      {
        icon: <span className="seo-icon"><img src={SEOPageTitle} alt='' /></span>,
        title: 'Keyword Match In Page Title',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '010'),
        analysisMessage: this.data.page_title_message,
        analysisColor: this.data.page_title_message_style.color,
        override: this.data.page_title_score == 0 ? {
          hasOverride: true,
          checked: pageTitleManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '019'),
          onChange: this.togglePageTitleManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOURL} alt='' /></span>,
        title: 'Keyword Match in URL',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '011'),
        analysisMessage: this.data.term_in_url_message,
        analysisColor: this.data.term_in_url_message_style.color,
        override: this.data.term_in_url_score == 0 ? {
          hasOverride: true,
          checked: termInUrlManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '020'),
          onChange: this.toggleTermInUrlManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOBackLinks} alt='' /></span>,
        title: 'Backlinks to Page',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '013'),
        analysisMessage: this.data.backlinks_message,
        analysisColor: this.data.backlinks_style.color,
        override: this.data.backlinks_score == 0 ? {
          hasOverride: true,
          checked: backlinksManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '031'),
          onChange: this.toggleBacklinksManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOLoadSpeed} alt='' /></span>,
        title: 'Load Speed',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '014'),
        analysisMessage: this.data.load_speed_message,
        analysisColor: this.data.load_speed_style.color,
        override: this.data.load_speed_score == 0 ? {
          hasOverride: true,
          checked: loadSpeedManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '032'),
          onChange: this.toggleLoadSpeedManuallyOverride
        } : { hasOverride: false },
        checkLoadSpeedLink: (process.env.REACT_APP_API_LOADSPEED ? process.env.REACT_APP_API_LOADSPEED : "https://developers.google.com/speed/pagespeed/insights/")
          + "?url="
          + (this.data.load_speed_link)
          + "&tab=mobile",
        checkLoadSpeedMessage: 'See Full Speed Analysis'
      },
      {
        icon: <span className="seo-icon"><img src={SEOSSL} alt='' /></span>,
        title: 'SSL Certificate',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '017'),
        analysisMessage: this.data.security_certificate_message,
        analysisColor: this.data.security_certificate_style.color,
        override: this.data.security_certificate_score == 0 ? {
          hasOverride: true,
          checked: securityCertificateManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '033'),
          onChange: this.toggleSecurityCertificateManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOH1} alt='' /></span>,
        title: 'Keyword Match in h1',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '021'),
        dataUnavailableTooltip: this.data.keyword_match_h1_message == 'Data unavailable' ? this.props.tooltips.filter(tooltip => tooltip.code === '034') : null,
        analysisMessage: this.data.keyword_match_h1_message,
        analysisColor: this.data.keyword_match_h1_message_style.color,
        override: this.data.keyword_match_h1_score == 0 ? {
          hasOverride: true,
          checked: keywordMatchH1ManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '022'),
          onChange: this.toggleKeywordMatchH1ManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOInternalLinks} alt='' /></span>,
        title: 'Internal Links Count',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '025'),
        dataUnavailableTooltip: this.data.internal_links_count_message == 'Data unavailable' ? this.props.tooltips.filter(tooltip => tooltip.code === '034') : null,
        analysisMessage: this.data.internal_links_count_message,
        analysisColor: this.data.internal_links_count_style.color,
        override: this.data.internal_links_count_score == 0 ? {
          hasOverride: true,
          checked: internalLinksCountManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '026'),
          onChange: this.toggleInternalLinksCountManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOExternalLinks} alt='' /></span>,
        title: 'External Links Count',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '029'),
        dataUnavailableTooltip: this.data.external_links_count_message == 'Data unavailable' ? this.props.tooltips.filter(tooltip => tooltip.code === '034') : null,
        analysisMessage: this.data.external_links_count_message,
        analysisColor: this.data.external_links_count_style.color,
        override: this.data.external_links_count_score == 0 ? {
          hasOverride: true,
          checked: externalLinksCountManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '030'),
          onChange: this.toggleExternalLinksCountManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOImages} alt='' /></span>,
        title: 'Images Count',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '027'),
        dataUnavailableTooltip: this.data.images_count_message == 'Data unavailable' ? this.props.tooltips.filter(tooltip => tooltip.code === '034') : null,
        analysisMessage: this.data.images_count_message,
        analysisColor: this.data.images_count_style.color,
        override: this.data.images_count_score == 0 ? {
          hasOverride: true,
          checked: imagesCountManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '028'),
          onChange: this.toggleImagesCountManuallyOverride
        } : { hasOverride: false }
      },
      {
        icon: <span className="seo-icon"><img src={SEOClick} alt='' /></span>,
        title: 'Click Depth',
        tooltip: this.props.tooltips.filter(tooltip => tooltip.code === '023'),
        dataUnavailableTooltip: this.data.click_depth_message == 'Data unavailable' ? this.props.tooltips.filter(tooltip => tooltip.code === '034') : null,
        analysisMessage: this.data.click_depth_message,
        analysisColor: this.data.click_depth_style.color,
        override: this.data.click_depth_score <= 1.25 ? {
          hasOverride: true,
          checked: clickDepthManuallyOverride,
          overrideTooltip: this.props.tooltips.filter(tooltip => tooltip.code === '024'),
          onChange: this.toggleClickDepthManuallyOverride
        } : { hasOverride: false }
      },

    ]

    const warnings = this.data.seo_warnings_messages

    const componentId = `seo-modal-${this.props.data.id}`

    return (
      <div id="ranking-modal" className="ranking-modal">
        <Modal id={componentId} isOpen={this.props.open} contentClassName="seo-modal">

          <ModalHeader>
            <SEOHeader
              elementId={componentId}
              score={this.props.isTopStory ? this.data.score : this.props.SEOScore}
              URL={this.props.isTopStory ? this.data.link : this.props.SEOURL}
              title={this.props.isTopStory ? this.data.modal_title : this.props.SEOTitle}
            />
          </ModalHeader>

          <SEOBody data={SEOBodyData} warnings={warnings} />

          <ModalFooter>
            <SEOCloseButton handleOnClick={this.props.close} btnText='Close' />
          </ModalFooter>

        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tooltips: state.tooltips
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggle_page_title_override: (id, story_index) => {
      dispatch(toggle_page_title_override(id, story_index))
    },
    toggle_term_in_url_override: (id, story_index) => {
      dispatch(toggle_term_in_url_override(id, story_index))
    },
    toggle_keyword_match_h1_override: (id, story_index) => {
      dispatch(toggle_keyword_match_h1_override(id, story_index))
    },
    toggle_click_depth_override: (id, story_index) => {
      dispatch(toggle_click_depth_override(id, story_index))
    },
    toggle_internal_links_count_override: (id, story_index) => {
      dispatch(toggle_internal_links_count_override(id, story_index))
    },
    toggle_external_links_count_override: (id, story_index) => {
      dispatch(toggle_external_links_count_override(id, story_index))
    },
    toggle_images_count_override: (id, story_index) => {
      dispatch(toggle_images_count_override(id, story_index))
    },
    toggle_backlinks_override: (id, story_index) => {
      dispatch(toggle_backlinks_override(id, story_index))
    },
    toggle_load_speed_override: (id, story_index) => {
      dispatch(toggle_load_speed_override(id, story_index))
    },
    toggle_security_certificate_override: (id, story_index) => {
      dispatch(toggle_security_certificate_override(id, story_index))
    },
    calculateLumentusScore: () => {
      dispatch(calculateLumentusScore())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingModal)
