import styled from 'styled-components';
import {styles} from './variables';

export const LayoutStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .toggle-sidebar {
    background-color: rgba(255,255,255,.3);
    position: fixed;
    display: flex;
    top:0;
    left:0;
    backdrop-filter: blur(3px);
    z-index: 20;
    cursor: pointer;
    padding: .8rem;
    width: 3rem;
    height: 3rem;
    border: solid 1px ${styles.gray};
    box-shadow: 0 0 8px rgba(0 , 0 , 0, 0.2);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
    div {
      width: 100%;
      height: 2px;
      background-color: ${styles.primaryColor};
      box-shadow: 0 0 2px ${styles.primaryColor};
      transition: all .4s ease-in-out;
      &:nth-child(1){
        transform-origin: top left;
      }
      &:nth-child(3){
        transform-origin: bottom left;
      }
    }
    &.active {
      div:nth-child(1){
        transform: rotate(45deg);
      }
      div:nth-child(2){
        opacity: 0;
      }
      div:nth-child(3){
        transform: rotate(-45deg);
      }
    }
  }
  .wrapper {
    margin-left: ${styles.widthSidebar};
    position: relative;
    transition: margin .5s ease-in-out;
    &.full{
      margin-left: 0;
    }
    @media screen and (max-width: ${styles.breakpoints.lg}) {
      margin-left: 0;
    }
  }
  .loadMore{
    background-color: ${styles.green};
    border-color:${styles.green};
    @media screen and (max-width: 1366px) {
      width: 98%;
      margin-left: 1rem;
    }
    :hover{
      background-color: ${styles.green};
      border-color:${styles.green};
    }
  }
`
