import React, { useEffect, useState } from 'react';
import { Col } from "reactstrap";
import Spinner from "../Spinner";
import RadioGroup from "./SentimentRadioButton"
import Tooltip from '../Tooltip';
import { sentimentTooltip } from '../../config/overrideTooltips';
import SentimentConfirmation from './SentimentConfirmation';

const RowSentiments = ({
    id,
    isTopStories,
    isAutomated,
    uploaded_file,
    sentimentValue,
    rankSentiment,
    changeSentiment,
    loadingSentiment,
    overrideAction,
    topStoryIndex
}) => {
    const [clicked, setClicked] = useState(false);
    const [previousValue, setPreviousValue] = useState(sentimentValue)
    const [radioGroupValue, setRadioGroupValue] = useState(sentimentValue);
    const [disabledStyle, setDisabledStyle] = useState(true);
    const [confirmationMessage, setConfirmationMessage] = useState('Hidden');
    const canOverrideSentiment = isAutomated && !uploaded_file;

    useEffect(() => {
        if(sentimentValue) setRadioGroupValue(sentimentValue);
    }, [sentimentValue]);

    const handleChange = (event, topStoryIndex) => {
        if (!clicked) {
            setPreviousValue(sentimentValue);
            setClicked(true);
        }
        setRadioGroupValue(event);
        changeSentiment(event, topStoryIndex);
        setConfirmationMessage('AskConfirmation');
    };
    
    
    return (
        <Col 
            sm={isTopStories() ? 12 : 3} 
            className={'sentiment d-flex flex-column align-items-start'} 
            style={isTopStories() ? {marginLeft: '1.2rem', marginBottom: '-7px'} :{}}
            onClick={e => e.preventDefault()} 
        >
            <div 
                className={'d-flex align-items-center ' + (isTopStories() ? "justify-content-center" : "")}
                style={isTopStories() ? {marginLeft: '.7rem', marginBottom: '-16px'} :{}}
            >
                <h4 className={'mr-2 text-center'}>Sentiment</h4>
                {canOverrideSentiment && <Tooltip  tooltip={{code: `sentiment-${id}`, ...sentimentTooltip}} />}
            </div>
            {!loadingSentiment ?
                <RadioGroup
                    isTopStories={isTopStories}
                    topStoryIndex={topStoryIndex}
                    options={rankSentiment}
                    value={radioGroupValue}
                    onChangeSentiment={(event) => handleChange(event, topStoryIndex)}
                    disabledStyle={disabledStyle}
                    setDisabledStyle={setDisabledStyle}
                />
                :
                <div className="spinner-loading" style={isTopStories() ? {marginLeft: '1.8rem'} :{marginLeft: '1.3rem', margingTop: '4px'}}>
                    <Spinner />
                </div>
            }
            {canOverrideSentiment &&
                <div className="confirmation_sentiment_buttons">
                    <SentimentConfirmation
                        confirmationMessage={confirmationMessage} 
                        setConfirmationMessage={setConfirmationMessage} 
                        overrideAction={overrideAction}
                        topStoryIndex={topStoryIndex}
                        setDisabledStyle={setDisabledStyle}
                        changeSentiment={changeSentiment}
                        value={radioGroupValue}
                        setRadioGroupValue={setRadioGroupValue}
                        previousValue={previousValue}
                        setPreviousValue={setPreviousValue}
                    />
                </div>
            }
        </Col>
    );
};

export default RowSentiments;

