import axios from 'axios';
import { saveTokenInfo, logOut } from '../actionCreators'
import store from '../store'

const fetchClient = () => {
  let _retry = false
  const auth_info = JSON.parse(localStorage.getItem('token_info'))

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization':  auth_info && auth_info.access_token ? `${auth_info.token_type} ${auth_info.access_token}` : ''
    },
  }

  // Create instance
  let instance = axios.create(defaultOptions)

  const good = response => {
    if (store.getState().auth_info){
      return response
    }
    const error = {response:{status:401,message:""}}
    return Promise.reject(error)
  }

  const bad = error => {
    const status = error.response
      ? error.response.status
      : null
      
    const originalRequest = error.config

    if (status === 401 && !_retry) {
      _retry = true
      const refreshToken = auth_info && auth_info.refresh_token ? auth_info.refresh_token : null

      if (!refreshToken){
        return store.dispatch(logOut())
      }

      const url = process.env.REACT_APP_API_ENDPOINT + '/token'

      const params = {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID ? process.env.REACT_APP_OAUTH_CLIENT_ID : 2,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET ? process.env.REACT_APP_OAUTH_CLIENT_SECRET : 'zeu3zbBEJtuH87zZdVHcUBMojItoAspEzLiLmHnJ',
        refresh_token: refreshToken,
        email: store.getState().user_logged.email
      }

      return instance.post(url, params)
        .then(({ data }) => {
          store.dispatch(saveTokenInfo(data))
          instance.defaults.headers.common['Authorization'] = data.token_type + ' ' + data.access_token
          originalRequest.headers['Authorization'] = data.token_type + ' ' + data.access_token
          return instance(originalRequest)
        })
        .catch(err => {
          if(err.response.status === 401) {
            let status = null
            switch(err.response.data.message) {
              case "another_session_logged":
                status = 'revoked'
                break;
              case 'account_deactivated':
                status = 'account_deactivated'
                break;
              default:
                status = ''
                break;
            }
            store.dispatch(logOut(status))
          }
        })
    } 
    else if(status === 404 && !_retry){
      error = { status: status }
      return Promise.reject(error)
    }
    else return Promise.reject(error)
  }
  
  instance.interceptors.response.use(good, bad)
  
  return instance
}

export default fetchClient
