import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearSearch, savedLocationsFinished, addToSavedLocations, removeFromSavedLocations } from '../actionCreators'
import { Button, ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupButtonDropdown, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import './../css/LocationMenu.css'
import fetchClient from '../helpers/fetch-client';
import Spinner from './Spinner'
import location_marker from '../images/location_marker.svg';
import {InputStyled} from '../styles/Input'
import {IconLocation} from '../components/common/IconsSVG'
class LocationMenu extends Component {

    constructor(props) {
        super(props)

        let loading = window.locations == null || window.locations == undefined;

        this.state = {
            currentLocation: props.location || null,
            locations: [],
            dropdownOpen: false,
            searchString: '',
            loading: loading,
            showSavedNotification: false,
            locationSearching: false,
            removedLocation: false
        }

        this.getPossibleLocations = this.getPossibleLocations.bind(this)
        this.toggle = this.toggle.bind(this)
        this.enterCityOnChange = this.enterCityOnChange.bind(this)
        this.chooseLocation = this.chooseLocation.bind(this)
        this.toggleSaveLocation = this.toggleSaveLocation.bind(this)
        this.clearLocation = this.clearLocation.bind(this)
        this.removeSavedLocation = this.removeSavedLocation.bind(this)
        this.canonicalName = this.getCanonicalName.bind(this)
    }

    getCanonicalName = (loc_id) => {
        if (window && window.locations) {
            const location = window.locations.find(item => item.loc_id = loc_id)
            if (location) {
                this.chooseLocation(location)
                return location.canonical_name
            } else return ''
        } else return ''
    }

    componentDidMount() {
        if (this.props.savedLocations === null || this.props.savedLocations === undefined) {
            this.props.fetchSavedLocations();
        }
        if (this.props.formOverrides && this.props.location) {
            const location = {
                loc_id: this.props.location.loc_id,
                canonical_name: this.props.location.location_name //  windows.locations.find(item => )
            };
            this.chooseLocation(location, true);
        }

        if (window.currentLocation &&  !this.props.location) {
            this.chooseLocation(window.currentLocation, true);
        }

        if (this.props.resultsPage === "1" && this.props.search.loc_id != null) {
            this.chooseLocation({loc_id: this.props.search.loc_id, canonical_name: this.props.search.canonical_name}, true);
        }

        //get list of locations
        if (window.locations == null || window.locations == undefined) {
          let url = process.env.REACT_APP_API_ENDPOINT + '/get-list-of-locations';

          let that = this;

          fetchClient().get(url)
              .then( (response) => {
                  if (response.status !== 200){
                      throw response.message
                  } else {
                      let csvData = response.data.data.split('\r\n');
                      let locations = [];

                      for (let i=0; i<csvData.length; i++) {
                        if (i==0) {continue}
                        let locationRow = csvData[i].replace(',,',',"",')
                        let locationRowData = locationRow.split('","')

                        let loc_name_canonical = locationRowData[3]
                        let loc_name = locationRowData[2]
                        if (loc_name != null && loc_name != undefined) {
                          let loc_name_lowercase = loc_name.toLowerCase();
                          locations.push({"loc_id": locationRowData[0].replace('"',''), "canonical_name": loc_name_canonical, "loc_name_lowercase": loc_name_lowercase})
                        }
                      }
                      locations.sort((a, b) => (a.canonical_name.split(',')[0] > b.canonical_name.split(',')[0]) ? -1 : 1);
                      window.locations = locations;

                      this.setState({loading: false})
                  }
              }).catch(function(error) {
                  console.log(error);
                  console.log('ERROR calling API for list of locations');
              })
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    enterCityOnChange(event) {
      let searchString = event.target.value;
      this.setState({searchString: searchString, locationSearching: true})

      clearTimeout(window.getPossibleLocationsTimeout);
      if (searchString.length > 2) {
        window.getPossibleLocationsTimeout = setTimeout(function() {
          this.getPossibleLocations(searchString.toLowerCase());
        }.bind(this), 300);
      }

    }

    getPossibleLocations(term) {
      let results = window.locations.filter(loc => loc.loc_name_lowercase.indexOf(term) > -1).reverse()

      this.setState({locations: results, locationSearching: false});
    }

    chooseLocation(location, skipConfirm) {
      if(this.props.formOverrides){
        this.props.onSelectLocation(location)
      }

      let changeInLocation = this.state.currentLocation == null || location.loc_id != this.state.currentLocation.loc_id; //Don't need to confirm if selecting the same location.?

      let isResultsPage = this.props.resultsPage === "1";//Only want to confirm change if on results page, then need to re-search
      let confirmed = true;

      if (isResultsPage && !skipConfirm) {
        confirmed = window.confirm('Are you sure you want to change Search Location? (This will reset search and delete unsaved changes!)');
      }

      if (!confirmed) {
        this.setState({searchString: ''});
      }

      if (changeInLocation && confirmed) {
        window.currentLocation = location;

        if (isResultsPage && !skipConfirm) {
          //want to go back to start search page with location filled
          this.props.clearSearch();
        } else {
          window.loc_id = location.loc_id;
          window.loc_canonical_name = location.canonical_name;
          this.setState({currentLocation: location, searchString: '', showSavedNotification: false});
        }
      }

      this.setState({removedLocation:false})
    }

    toggleSaveLocation() {
      let mySavedLocations = this.props.savedLocations;
      if (mySavedLocations.length > 500) {
        alert('You have reached limit of 500 saved locations!')
      } else {
          if(this.state.currentLocation.loc_id){
              this.setState({showSavedNotification: true});
              this.props.addToSavedLocations(this.state.currentLocation);
         }else{
            alert('You have to select a location to be saved');
         }
      }
    }

    onClickCurrentLocationInput() {
      document.getElementById("location-dropdown-toggle").click();
      setTimeout(function() {
        document.getElementById("location-dropdown-input").focus();
      },0)
    }

    clearLocation(remove_location=false) {
      let isResultsPage = this.props.resultsPage === "1";

      let confirmed = true;

      if (isResultsPage) {
        confirmed = window.confirm('Are you sure you want to change Search Location? (This will reset search and delete unsaved changes!)');
      }

      if (confirmed) {
        window.loc_id = undefined;
        window.loc_canonical_name = undefined;
        window.currentLocation = undefined;
        this.setState({currentLocation: null});

        if (remove_location) {
            this.props.removeFromSavedLocations(remove_location)
            this.setState({removedLocation: true, saveLocation:false})
        }else {
            this.setState({removedLocation:false})
        }

        if (isResultsPage) {
          this.props.clearSearch();
        }
      }

    }

    removeSavedLocation(loc_id) {
        if (this.state.currentLocation != null && loc_id == this.state.currentLocation.loc_id) {
            this.clearLocation(loc_id)
            if(this.props.removeLocationCallback)
              this.props.removeLocationCallback()
        }else {
            this.props.removeFromSavedLocations(loc_id)
            this.setState({removedLocation: true, saveLocation:false})
        }
    }

    normalizeCanonicalName(canonical_name) {
      if (canonical_name == null) {
        return ''
      } else {
        return canonical_name.replace(/,/g, ', ');
      }
    }

    render() {
        let isDashboardPage = this.props.dashboardPage;
        let isResultsPage = this.props.resultsPage === "1";
        let locationHolderStyle = {};
        if (this.state.loading || (isResultsPage && !this.props.search.using_location)) {
          locationHolderStyle['pointerEvents'] = 'none';
        }
        let circleStyle = {border: '1.5px solid rgb(154,154,154)', width: '15px', height: '15px', borderRadius: '8px', display: 'inline-block', position: 'relative', top: '3px', right: '7px', cursor: 'pointer'};

        let saveLocation = false;
        if (this.state.currentLocation != null && this.props.savedLocations != null && !this.state.removedLocation) {
          saveLocation = this.props.savedLocations.filter(e => e != null && e.loc_id == this.state.currentLocation.loc_id).length > 0
        }
        let loadingSavedLocations = this.props.savedLocations === null || this.props.savedLocations === undefined;
        return (
            <div id="location-holder" style={locationHolderStyle} className={this.props.className}>
                <InputStyled className='flex-grow-1 w-100' colorIcon={"#1A0DAB"}>
                    <InputGroupAddon addonType="prepend"
                                     className={"input_addon " + (this.props.disabled || this.state.loading || loadingSavedLocations || (isResultsPage && !this.props.search.using_location) ? 'loading' : '')}>
                        <InputGroupText><IconLocation /></InputGroupText>
                    </InputGroupAddon>
                    <Input onClick={this.onClickCurrentLocationInput} className="location_input"
                           value={isResultsPage ? this.normalizeCanonicalName(this.props.search.canonical_name) : this.state.currentLocation == null ? '' : this.normalizeCanonicalName(this.state.currentLocation.canonical_name || this.getCanonicalName(this.state.currentLocation))}
                           placeholder="Select a country, state or city" style={{outline: 0}}
                           disabled={this.props.disabled || this.state.loading || loadingSavedLocations || (isResultsPage && !this.props.search.using_location)}/>
                    <InputGroupButtonDropdown addonType="append"
                                              isOpen={this.props.disabled ? !this.props.disabled : this.state.dropdownOpen}
                                              toggle={this.toggle}>
                        <DropdownToggle caret id="location-dropdown-toggle"
                                        style={{width: '34px', background: 'white'}}>

                        </DropdownToggle>
                        <DropdownMenu right>
                            <div style={{padding: '10px 20px'}}>
                              <Input 
                                id="location-dropdown-input"
                                placeholder="Enter any location"
                                style={{width: '24vw', height: '35px'}}
                                value={this.state.searchString}
                                onChange={this.enterCityOnChange}
                                autoComplete='off'
                              />
                            </div>
                            <DropdownItem divider/>
                            {this.state.searchString.length > 2 ?
                                <div className="search-locations">
                                    {this.state.locations.length == 0 && !this.state.locationSearching ?
                                        <div style={{textAlign: 'center', opacity: 0.2, fontSize: '13px'}}>(There are no
                                            location matches)</div> : null}
                                {this.state.locations.map(function(location, index) {
                                    return <DropdownItem key={index} onClick={this.chooseLocation.bind(null, location, false)}>{location.canonical_name.replace(/,/g, ', ')}</DropdownItem>;
                                }.bind(this))}
                              </div>
                              :
                              loadingSavedLocations ?
                              null
                              :
                              <div>
                                <DropdownItem header><b>Select a saved location</b></DropdownItem>
                                <div className="search-locations">
                                  {this.props.savedLocations.length == 0 ? <div style={{textAlign: 'center', opacity: 0.2, fontSize: '13px'}}>(You have no saved locations)</div> : null}
                                  {this.props.savedLocations.map(function(savedLocation, index) {
                                    let selected = savedLocation != null && this.state.currentLocation != null && savedLocation.loc_id == this.state.currentLocation.loc_id;
                                    return savedLocation == null ? null : (
                                      <DropdownItem tag="span" key={index} style={{fontSize: '85%', outline: 'none'}}>
                                        <div onClick={() => this.chooseLocation(savedLocation, false)} style={{width: '75%', display:'inline-block'}}>
                                            {selected ? <div className="check">✓</div> : <div style={circleStyle}></div>}
                                            {savedLocation.canonical_name ? savedLocation.canonical_name.replace(/,/g, ', ') : ""}
                                        </div>
                                        <div style={{float: 'right', width:'25px', cursor:'pointer', color:'red'}} onClick={()=>this.removeSavedLocation(savedLocation.loc_id)}>x</div>
                                      </DropdownItem>
                                    );
                                  }.bind(this))}
                                </div>
                              </div>
                            }


                        </DropdownMenu>
                    </InputGroupButtonDropdown>
                </InputStyled>
                {isResultsPage && !this.props.search.using_location ?
                    <div style={{paddingTop: '10px'}}>Location is currently disabled for this search!</div>
                :
                this.state.loading || loadingSavedLocations ?
                    <div className='container-loading' style={{textAlign: 'center'}}><div><Spinner /></div></div>
                :
                this.state.currentLocation != null ?
                    <div style={{textAlign: 'right', paddingTop: '8rem', position:"absolute"}}>
                      {
                        this.props.resultsPage === false || this.props.resultsPage === "0" &&
                          (saveLocation
                                  ?
                            this.state.showSavedNotification ? <span className="align-middle">Location Saved</span> : <span></span>
                                  :
                            !this.state.removedLocation ? <span className="align-middle">Save Location?</span> : <span className="align-middle">Location removed</span>
                          )
                      }
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {
                        this.props.resultsPage === false || this.props.resultsPage === "0" &&
                          // &nbsp;&nbsp;&nbsp;&nbsp;

                          (saveLocation
                          ?
                            this.state.showSavedNotification ? <div className="check">✓</div> : <span></span>
                                  :
                            !this.state.removedLocation ? <div onClick={this.toggleSaveLocation} style={circleStyle}></div> : <div className="check">✓</div>
                          )
                      }
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {
                        this.props.hideClearLocation !== true &&
                        <Button disabled={this.props.disabled} variant="light" onClick={this.clearLocation} className="clear_location_btn">Clear Location</Button>
                      }
                    </div>
                    :
                  (this.state.removedLocation ? <div className={isDashboardPage ? "removed-loc-dash" : ""} style={{textAlign: 'right', paddingTop: '8rem', position:"absolute"}}><span>Location removed </span>&nbsp;&nbsp;&nbsp;&nbsp;<div className="check"> ✓</div></div> : null)
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
        savedLocations: state.savedLocations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSavedLocations: async () => {
            let url = process.env.REACT_APP_API_ENDPOINT + '/saved-locations';

            fetchClient().get(url)
                .then( (response) => {
                    if (response.status !== 200){
                        throw response.message;
                    } else {
                        let savedLocations = JSON.parse(response.data.data);
                        if (savedLocations == null) {
                          savedLocations = [];
                        }
                        dispatch(savedLocationsFinished(savedLocations));
                    }
                }).catch(function(error) {
                    console.log(error);
                    console.log('ERROR calling API');
                })

        },
        clearSearch: () => {
            dispatch(clearSearch())
        },
        addToSavedLocations: (location) => {
            dispatch(addToSavedLocations(location))
        },
        removeFromSavedLocations: (location) => {
            dispatch(removeFromSavedLocations(location))
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMenu)
