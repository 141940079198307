import React, {Component} from 'react';
import Select from 'react-select'
import {validOptionsSelectCategory} from '../helpers/select-constants'
import '../css/DropdownMenu.css'

//receve array with elements with each element:
//position, label, background-Color, text-Color


class DropdownMenu extends Component {

  constructor(props) {
    super(props)
    this.state={
      valueSelect: props.options.find((option) => (option.value === props.value)),
      filteredOptions: this.filterOptions(props.options, props.value),
      onChange: this.props.onChange
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.value !== nextProps.value){
      const filteredOptions = this.filterOptions(this.state.filteredOptions, nextProps.value)
      this.setState({
        valueSelect: this.props.options.find((option) => (option.value === nextProps.value)),
        filteredOptions: filteredOptions
      })
    }
  }

  //get only actual valid options. (without managed and owned)
  filterOptions = (options, keyValue) => {
    return options.filter((option) => (
        validOptionsSelectCategory.indexOf(option.value) !== -1
    ))
  }

  getColorOfOption = (stateOfSelect) => {
    const { isSelected, data } = stateOfSelect
    if(isSelected) return 'black'
    else return data.background_color
  }

  getStylesOfDropDown = (isIntoForm) => {
    return {
      option: (provided, state) => {
        const { data, options, isFocused } = state
        return {
          ...provided,
          backgroundColor: '#F2F9FD',
          border: '1px solid #dee2e6',
          padding: '5px 5px',
          height: 'auto',
          fontSize: 13,
          opacity: isFocused ? '1' : '0.6',
          fontWeight: 'bold',
          color: '#000',
          margin: '8px 0px'
        }
      },

      menuList: (provided) => ({
        ...provided,
        padding: '10px'
      }),

      container: (provided) => ({
        ...provided,
        border: '1px solid #dee2e6',
        backgroundColor: '#F2F9FD',
      }),

      control: (provided) => ({
        ...provided,
        backgroundColor: '#F2F9FD',
        border: "0px"
      }),

      valueContainer: (provided, state) => {
        return {
          ...provided,
          height: isIntoForm ? '30px': '50px',
          fontWeight: 'bold',
        }
      },

      singleValue: (provided, { data }) => ({
        ...provided,
        fontSize: isIntoForm ? 13 : 11,
        whiteSpace: 'pre-line',
      }),

      placeholder: (provided) => ({
        ...provided,
        fontSize: 13,
      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        visibility: (this.props.disabled) ? 'hidden' : '',
        margin: (this.props.disabled) ? "-10px" : ''
      })
    }
  }

  render(){
    const { width, align, options, onChange, disabled, isIntoForm, className } = this.props;
    return (
      <div className={(align ? `DropdownMenu align-${align} ` : 'DropdownMenu ') + className}>
        <div style={{width: width || '100%'}}>
          <Select
            isSearchable={false}
            isDisabled={disabled}
            styles={this.getStylesOfDropDown(isIntoForm)}
            placeholder='Select Category...'
            options={this.state.filteredOptions}
            onChange={onChange}
            defaultValue={this.state.valueSelect}
          />
        </div>
      </div>
    )
  }
}


export default DropdownMenu