import React, { Component } from 'react'
import CustomAlert from './common/CustomAlert';
import Tooltip from './Tooltip'
import '../css/Tooltip.css'
import '../css/ScoreColors.css'
import { optionsSelectCategory, rankSentiment } from '../helpers/select-constants'
import Spinner from './Spinner'
import { LayoutRow } from "./RowResult/Layout"
import { Col, Row, Button } from 'reactstrap'

import {
    calculateLumentusScore,
    change_score_manually,
    changeCategory,
    changeCompetitive,
    changeControlled,
    changeSentiment,
    clearSearch,
    handleFetchingIdToStore,
    set_relevance,
    setErrorTimeOut,
    setWarningEmptyCache,
    regenerateActionPlan
} from '../actionCreators'
import RankingModal from './RankingModal'
import './../css/RankingItem.css'
import { timeout, TimeoutError } from 'promise-timeout'
import fetchClient from '../helpers/fetch-client';
import { sendGaEvent as gaEvent } from '../helpers/ga-page-view'
import { RowTopBarMenu } from "./RowResult/RowTopBar";
import { RowBody } from "./RowResult/RowBody";
import { RowFooter } from "./RowResult/RowFooter";
import connect from "react-redux/lib/connect/connect";
import TopStoriesRow from './TopStoriesRow';
import { DownloadDOMElement } from './DownloadDOMElement';

class RankingItem extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.job = null
        this.enqueued_ids = []
        this.already_fetch = false
        this.state = {
            score: "",
            domain_authority: <div className="spinner-loading"><Spinner /></div>,
            modal_open: false,
            modal_index: 0,
            rankSentiment: rankSentiment,
            fetching_sentiment: false,
            fetching_relevance: false,
            modules_processed: false,
            score_readOnly: true,
            changed_manually: false,
            score_modified: false,
            error_time_out: false,
            popUpIsOpen: false,
            customAlert: {
                visible: false,
                type: "danger",
                message: "",
            },
            lastChangeEventCategory: null,
            lastChangeEventSentiment: null,
            categoryValue: null,
            sentimentValue: null,
            controlledLoading: false,
            competitiveLoading: false,
        }
        this.popoverTimeout = null;
        this.toggle = this.toggle.bind(this)
        this.calculateScore = this.calculateScore.bind(this)
        this.getAuthority = this.getAuthority.bind(this)
        this.loadSentiment = this.loadSentiment.bind(this)
        this.reCalculateState = this.reCalculateState.bind(this)
        this.getRankSentiment = this.getRankSentiment.bind(this)
        this.fetchRelevance = this.fetchRelevance.bind(this)
        this.isTopStories = this.isTopStories.bind(this)
        this.change_readOnly = this.change_readOnly.bind(this)
        this.handleOnChangeScore = this.handleOnChangeScore.bind(this)
        this.handleBlurScore = this.handleBlurScore.bind(this)
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
        this.submmitChangeScore = this.submmitChangeScore.bind(this)
        this.handleCompetitiveChange = this.handleCompetitiveChange.bind(this)
    }

    isTopStories() {
        return this.props.data.type == "top_stories";
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.isTopStories() && !this.props.isAutomated && !this.props.uploaded_file) {
            this.props.data.resume.forEach(item => {
                if (!item.hasOwnProperty("sentiment")) {
                    item.sentiment = "unselected";
                }
                if (!item.sentiment.hasOwnProperty("label")) {
                    item.sentiment = this.loadSentiment(item.sentiment);
                }
            });
        }

        if (this.isTopStories() && this.props.uploaded_file) {
            this.props.data.resume.forEach(item => {
                if (!item.sentiment.hasOwnProperty("label")) {
                    item.sentiment = this.loadSentiment(item.sentiment);
                }
            });
        }

        if (this.props.data.error_time_out !== undefined && this.props.data.error_time_out == true) {
            this._isMounted && this.setState({
                error_time_out: true,
                domain_authority: <div>Timed Out</div>,
                score: 0
            })

            this.reCalculateState();
        } else {
            if (this.props.data.score_manually !== null) {
                this._isMounted && this.setState({ changed_manually: true })
            }

            if (this.isTopStories() && this.props.uploaded_file) {
                this.reCalculateState()
            } else {
                if (this.props.data.relevance.filter((item) => item.status === "unrequested").length > 0) {
                    this.fetchRelevance()
                } else {
                    this.reCalculateState()
                }
            }
        }
        this.fetchSentiment();
        this.getRankSentiment(this.props.index + 1);
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentWillReceiveProps(props) {
        if (props.data.id !== this.props.id) {
            this._isMounted && this.setState({ changed_manually: false })
        }

        if (this.props.fetching_ids !== undefined && !this.props.fetching_ids.includes(this.props.id) && this.state.fetching_relevance && !this.props.data.relevance.filter((item) => item.status == "unrequested").length) {
            this.reCalculateState()
            this.setState({ fetching_relevance: false })
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index) {
            //reset score to calculated automatic
            this._isMounted && this.setState({ changed_manually: false })
            this.props.change_score_manually(this.props.data.id, null)

            if (this.props.data.relevance.filter((item) => item.status == "unrequested").length > 0) {
                this.fetchRelevance()
            } else {
                this.reCalculateState()
            }

            this.getRankSentiment(this.props.index + 1)
            this.fetchSentiment();
        }

    }
    getRankSentiment(id) {
        this._isMounted && this.setState({
            rankSentiment: [
                {
                    value: "unselected",
                    label: <span className="select-sentiment step sentiment-container">
                        <span className="select-sentiment step step-inner bg-unselected"></span>
                    </span>
                },
                {
                    value: "positive",
                    label: <span className="select-sentiment step sentiment-container">
                        <span className="select-sentiment step step-inner bg-positive"></span>
                    </span>
                },
                {
                    value: "neutral",
                    label: <span className="select-sentiment step sentiment-container">
                        <span className="select-sentiment step step-inner bg-neutral"></span>
                    </span>
                },
                {
                    value: "negative",
                    label: <span className="select-sentiment step sentiment-container">
                        <span className="select-sentiment step step-inner bg-negative"></span>
                    </span>
                }
            ]
        }, () => {
            this.setState({ sentimentValue: this.loadSentiment(this.props.data.sentiment) })
        })
    }

    reCalculateState() {
        var score = this.calculateScore()
        this._isMounted && this.setState({
            score: !score ? 0 : score,
            domain_authority: this.getAuthority(),
            rankSentiment: [
                {
                    value: "unselected",
                    label: <span className="select-sentiment step sentiment-container"><span
                        className="select-sentiment step step-inner bg-unselected"></span></span>
                },
                {
                    value: "positive",
                    label: <span className="select-sentiment step sentiment-container"><span
                        className="select-sentiment step step-inner bg-positive"></span></span>
                },
                {
                    value: "neutral",
                    label: <span className="select-sentiment step sentiment-container"><span
                        className="select-sentiment step step-inner bg-neutral"></span></span>
                },
                {
                    value: "negative",
                    label: <span className="select-sentiment step sentiment-container"><span
                        className="select-sentiment step step-inner bg-negative"></span></span>
                }
            ]
        })

        var some_unrequested = this.isTopStories() ? !this.props.data.hasOwnProperty("averageRelevanceRank") : this.props.data.relevance.filter((item) => {
            return item !== undefined && item.status === "unrequested"
        }).length

        if (!some_unrequested && this.state.fetching_relevance) {
            this._isMounted && this.setState({ fetching_relevance: false })
        }
    }

    calculateScore() {
        if (this._isMounted && this.state.fetching_relevance && !isNaN(this.state.score) && this.state.score > 10) {
            return this.state.score
        }

        const relevance = this.props.data.relevance

        let score = (this.isTopStories()) ? this.props.data.averageRelevanceRank : 0;
        var finished = true

        if (!this.isTopStories()) {
            relevance.forEach((item) => {

                if (item.status !== undefined && item.status !== 'unrequested' && item.score !== undefined && !isNaN(item.score)) {
                    if (item.module !== 'domain_authority') {
                        score += item.score
                    }
                } else {
                    finished = false
                }
            })
        } else {
            for (let i = 0; i < this.props.data.resume.length; i++) {
                let item = this.props.data.resume[i];
                item.score = 0;
                item.domainAuthority = 0;
                if (item.relevance == undefined) {
                    continue;
                }
                for (const [key, module] of Object.entries(item.relevance)) {
                    if (module.status == 'finish') {
                        if (module.module != 'Domain Authority') {
                            switch (module.module) {
                                case "Term In URL":
                                    if (this.props.data.term_in_url_override_top_stories != undefined && this.props.data.term_in_url_override_top_stories[i] == true) {
                                        item.score += 15;
                                        score += 15 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Page Title":
                                    if (this.props.data.page_title_override_top_stories != undefined && this.props.data.page_title_override_top_stories[i] == true) {
                                        item.score += 15;
                                        score += 15 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Keyword Match in h1":
                                    if (this.props.data.keyword_match_h1_override_top_stories != undefined && this.props.data.keyword_match_h1_override_top_stories[i] == true) {
                                        item.score += 15;
                                        score += 15 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Click Depth":
                                    if (this.props.data.click_depth_override_top_stories != undefined && this.props.data.click_depth_override_top_stories[i] == true) {
                                        item.score += 5;
                                        score += 5 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Internal Links Count":
                                    if (this.props.data.internal_links_count_override_top_stories != undefined && this.props.data.internal_links_count_override_top_stories[i] == true) {
                                        item.score += 5;
                                        score += 5 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "External Links Count":
                                    if (this.props.data.external_links_count_override_top_stories != undefined && this.props.data.external_links_count_override_top_stories[i] == true) {
                                        item.score += 5;
                                        score += 5 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Images Count":
                                    if (this.props.data.images_count_override_top_stories != undefined && this.props.data.images_count_override_top_stories[i] == true) {
                                        item.score += 5;
                                        score += 5 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "BackLinks":
                                    if (this.props.data.backlinks_override_top_stories != undefined && this.props.data.backlinks_override_top_stories[i] == true) {
                                        item.score += 15;
                                        score += 15 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "Load Speed":
                                    if (this.props.data.load_speed_override_top_stories != undefined && this.props.data.load_speed_override_top_stories[i] == true) {
                                        item.score += 10;
                                        score += 10 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                case "SSL Certificate":
                                    if (this.props.data.security_certificate_override_top_stories != undefined && this.props.data.security_certificate_override_top_stories[i] == true) {
                                        item.score += 10;
                                        score += 10 / this.props.data.resume.length;
                                    } else {
                                        item.score += module.score;
                                    }
                                    break;
                                default:
                                    item.score += module.score;
                            }

                        } else {
                            item.domainAuthority = module.score;
                        }
                    }
                }
                item.score = Math.ceil(item.score)
            }

        }

        if (finished === true || this.props.modules_imported && this.props.data.relevance.filter((item) => item.status == "finish").length) {
            if (!this.props.data.score_manually && this.state.changed_manually) {
                this._isMounted && this.setState({ changed_manually: false })
            }
            this._isMounted && this.setState({ fetching_relevance: false })
            this.already_fetch = true
            this.props.rankingItemAlreadyFetch();

            if (!!this.props.data.term_in_url_override) {
                score += 15
            }
            if (!!this.props.data.page_title_override) {
                score += 15
            }
            if (!!this.props.data.keyword_match_h1_override) {
                score += 15
            }
            if (!!this.props.data.backlinks_override) {
                score += 15
            }
            if (!!this.props.data.load_speed_override) {
                score += 10
            }
            if (!!this.props.data.security_certificate_override) {
                score += 10
            }
            if (!!this.props.data.click_depth_override) {
                score += 5
            }
            if (!!this.props.data.internal_links_count_override) {
                score += 5
            }
            if (!!this.props.data.external_links_count_override) {
                score += 5
            }
            if (!!this.props.data.images_count_override) {
                score += 5
            }
            this.props.data.totalScore = Math.round(score)
            return Math.round(score)
        } else {
            this.fetchRelevance()
            this.fetchSentiment();
            return score
        }
    }

    getAuthority() {

        let response = <div className="spinner-loading"><Spinner /></div>

        if (this.isTopStories()) {
            if (this.props.data.hasOwnProperty("averageDomainAuthority")) {
                response = this.props.data.averageDomainAuthority;
            }
        } else {
            let item = this.props.data.relevance.filter((item) => {
                return item.module === "domain_authority"
            })

            if (item[0] !== undefined && (item[0].status == 'finish' || item[0].status == 'empty_cache') && !isNaN(item[0].score)) {
                response = item[0].score
            } else if (this.state.error_time_out == true || item[0] !== undefined && item[0].status == 'timeout') {
                response = <div>Timed Out</div>
            }
        }


        return response
    }

    toggle() {
        this._isMounted && this.setState({
            modal_open: !this.state.modal_open
        })
    }

    toggleTopStorySEOAnalysis(storyUrl) {
        let modalIndex = 0;
        for (let i = 0; i < this.props.data.resume.length; i++) {
            if (this.props.data.resume[i].link == storyUrl) {
                modalIndex = i;
                break;
            }
        }
        this._isMounted && this.setState({
            modal_open: !this.state.modal_open,
            modal_index: modalIndex
        })
    }

    loadSentiment = (sentiment = 'unselected') => {
        const result = this.state.rankSentiment.find((item) => item.value === sentiment)
        return result || this.state.rankSentiment[0];
    }

    pushItemIfNotExist = (relevance, relevances) => {
        let already_exist = false
        for (let i = 0; i < relevances.length; i++) {
            if (relevances[i].module === relevance.module) {
                relevances[i] = relevance
                already_exist = true
            }
        }

        if (!already_exist) {
            relevances.push(relevance)
        }
        return relevances
    }

    callRelevanceRankAPI = () => {
        let ranking_url = this.props.data.resume[0].url;
        ranking_url = '?ranking_url=' + encodeURIComponent(ranking_url);
        let is_top_story = '&is_top_story=0';
        let location = this.props.search.loc_id ? '&loc_id=' + this.props.search.loc_id : "";
        let canonical = this.props.search.canonical_name ? '&canonical_name=' + this.props.search.canonical_name : "";
        let url = process.env.REACT_APP_API_ENDPOINT + '/rr/' + this.props.search.main + '/' + this.props.data.id + ranking_url + is_top_story + location + canonical;
        let time = process.env.REACT_APP_API_TIMEOUT !== undefined && !isNaN(process.env.REACT_APP_API_TIMEOUT) ? process.env.REACT_APP_API_TIMEOUT : 80000
        let relevance
        let self = this

        timeout(fetchClient().get(url), time)
            .then((response) => {
                response.data.forEach(module => {

                    let shortUrl = url.substring(0, url.lastIndexOf("/"))
                    let term_searched = url.substring(shortUrl.lastIndexOf("/") + 1, shortUrl.length)

                    let name = module.title.toLowerCase().replace(/ /g, '_')

                    relevance = {
                        module: name,
                        error: module.error,
                        status: 'unrequested',
                        score: 0,
                        message: '',
                        time_started: new Date().getTime()
                    }
                    const currentState = JSON.parse(localStorage.getItem('state'))
                    let match_response = currentState.state.search.main !== 'undefined' && currentState.state.search.main !== '' && term_searched === currentState.state.search.main

                    if (match_response) {
                        relevance.status = !module.error ? 'finish' : module.status
                        relevance.score = module.score
                        relevance.time_finished = new Date().getTime()
                        relevance.message = module.message

                        self.relevances = self.pushItemIfNotExist(relevance, self.relevances)

                        if (relevance.status === 'empty_cache' && !self.props.modules_imported) {
                            self.props.setWarningEmptyCache('The search and module loading can not be done. Please, try again.')
                        } else if (relevance.status === 'empty_cache' && self.props.modules_imported) {
                            self.props.setWarningEmptyCache('There may be failed modules for the search.')
                        }

                        if (relevance.module === "domain_authority") self._isMounted && self.setState({ domain_authority: self.getAuthority() })
                    }

                });
            })
            .catch((error) => {
                let status = 'fail'
                let message = 'Module could not be loaded, please retry the search'
                if (error instanceof TimeoutError) {
                    self.props.setErrorTimeOut(self.props.id, true)
                    self._isMounted && self.setState({
                        error_time_out: true
                    })

                    status = 'timeout'
                    message = 'Timed Out Connection, please retry the search.'
                }

                let modules = [
                    "term_in_url",
                    "backlinks",
                    "load_speed",
                    "domain_authority",
                    "security_certificate",
                    "page_title",
                    "images_count",
                    "click_depth",
                    "internal_links_count",
                    "keyword_match_in_h1",
                ]

                modules.map(item => {
                    let relevance = {
                        module: item,
                        error: 1,
                        status: status,
                        score: 0,
                        message: message,
                        time_started: new Date().getTime()
                    }
                    self.relevances = self.pushItemIfNotExist(relevance, self.relevances)
                })
            })
            .finally(() => {
                self.props.set_relevance(self.props.id, self.relevances)

                self.reCalculateState()

                self.enqueued_ids = self.enqueued_ids.filter(item => {
                    item != self.props.data.id
                })
                this.props.handleFetchingIdToStore([this.props.id], 'remove')
            })
    }

    callTopStoryRelevanceRankAPI = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + '/rr/top-story';

        let urls = [];
        for (let i = 0; i < this.props.data.resume.length; i++) {
            urls.push(this.props.data.resume[i].link);
        }

        let data = {
            search_term: this.props.search.main,
            loc_id: this.props.search.loc_id,
            search_urls: urls
        };

        fetchClient().post(url, data).then(
            (response) => {
                response.data.stories.forEach(story => {
                    this.props.data.resume.forEach(item => {
                        if (item.link === story.url) {
                            item.relevance = story.modules;
                        }
                    });
                });

                this.props.data.relevance.forEach(item => {
                    item.status = "finish";
                });

                this.props.data.averageDomainAuthority = response.data.averageDomainAuthority;
                this.props.data.averageRelevanceRank = response.data.averageRelevanceRank;
            })
            .catch((error) => {

            }).finally(() => {
                this.reCalculateState()
                this.props.set_relevance(this.props.id, this.props.data.relevance)

                this.enqueued_ids = this.enqueued_ids.filter(item => {
                    item != this.props.data.id
                })
                this.props.handleFetchingIdToStore([this.props.id], 'remove')
            })
    }

    callSentimentAPI = () => {
        let endpoint = process.env.REACT_APP_API_ENDPOINT + '/sentiment';
        let data = {
            url: this.props.data.resume[0].url,
            term: this.props.search.main
        };
        fetchClient().post(endpoint, data).then(
            (response) => {
                let sentiment = this.loadSentiment(response.data.sentiment);
                this.setState({ sentimentValue: sentiment, fetching_sentiment: false });
                this.props.dispatchChangeSentiment(sentiment.value);
            })
            .catch((error) => {
                this.setState({ fetching_sentiment: false, sentimentValue: "unselected" });
                this.props.dispatchChangeSentiment("unselected");
            })
    }

    callTopStorySentimentAPI = () => {
        let endpoint = process.env.REACT_APP_API_ENDPOINT + '/sentiment/top-story';

        let urls = [];
        for (let i = 0; i < this.props.data.resume.length; i++) {
            urls.push(this.props.data.resume[i].link);
        }

        let data = {
            urls: urls,
            term: this.props.search.main
        };
        let loadingSentiment = this.props.data.resume.find(item => {
            if (item.sentiment) {
                return true;
            }
        });

        if (loadingSentiment) {
            let sentiment = this.loadSentiment('unselected');
            this.setState({ sentimentValue: sentiment, fetching_sentiment: true });
        }

        fetchClient().post(endpoint, data).then(
            (response) => {
                response.data.stories.forEach(story => {
                    this.props.data.resume.forEach(item => {
                        if (!item.sentiment) {
                            if (item.link === story.url) {
                                item.sentiment = this.loadSentiment(story.sentiment);
                            }
                        }
                    });
                });

                let sentiment = this.loadSentiment(this.getAverageSentiment());
                this.setState({ sentimentValue: sentiment, fetching_sentiment: false });
                this.props.dispatchChangeSentiment(sentiment.value);
            })
            .catch((error) => {
                this.setState({ fetching_sentiment: false, sentimentValue: "unselected" });
                this.props.dispatchChangeSentiment("unselected");
            })

    }

    fetchRelevance = () => {
        if (this.isTopStories() && this.props.modules_imported) {
            let modules = [
                "term_in_url",
                "backlinks",
                "load_speed",
                "domain_authority",
                "security_certificate",
                "page_title",
                "images_count",
                "click_depth",
                "internal_links_count",
                "keyword_match_in_h1",
            ]
            let relevances = []
            modules.map(item => {
                let relevance = {
                    module: item,
                    error: 1,
                    status: 'empty_cache',
                    score: 0,
                    message: "Module could not be loaded, please retry the search",
                    time_started: new Date().getTime()
                }
                relevances.push(relevance)
            })
            this.props.set_relevance(this.props.data.id, relevances)
            this.setState({ score: 15, domain_authority: 0, fetching_relevance: false })

        } else {
            if (this._isMounted && this.state.fetching_relevance) {
                return
            } else if (this.enqueued_ids.includes(this.props.data.id)) {
                this.setState({ fetching_relevance: true })
                return
            } else {
                this.setState({
                    fetching_relevance: true,
                    score: "",
                    error_time_out: false,
                    domain_authority: <div className="spinner-loading"><Spinner /></div>
                })

                if (this.props.data.error_time_out !== undefined && this.props.data.error_time_out == true)
                    this.props.setErrorTimeOut(this.props.id, false)
            }

            this.enqueued_ids.push(this.props.data.id)
            this.props.handleFetchingIdToStore([this.props.id], 'add')

            this.relevances = [];

            if (this.isTopStories()) {
                this.callTopStoryRelevanceRankAPI();
            } else {
                this.callRelevanceRankAPI();
            }
        }

    }

    fetchSentiment = () => {
        if (this._isMounted) {
            const amount = this.props.search.pagesToAutomate * 10;//check this line
            const in_segment = this.props.data.position < amount;

            if (!this.props.data.sentiment_override_id > 0 &&
                this.props.automation && in_segment &&
                !this.state.fetching_sentiment &&
                this.props.data.sentiment == "unselected") {
                this.setState({ fetching_sentiment: true });

                if (this.isTopStories()) {
                    this.callTopStorySentimentAPI();
                } else {
                    this.callSentimentAPI();
                }
            }
            if (this.isTopStories() && this.props.automation) {
                this.callTopStorySentimentAPI();
            }
        }
    }

    change_readOnly = () => {
        this._isMounted && this.props.index < this.props.visible_rows && this.setState({ score_readOnly: !this.state.score_readOnly })
    }

    handleOnChangeScore = (ev) => {
        if (!ev.target.value) {
            ev.target.value = 0
        }

        var value = ev.target.value
        if (isNaN(ev.target.value)) value = 0

        value = parseInt(value)

        if (value < 0) {
            value = 0
        }

        if (value > 100) {
            value = 100
        }

        this._isMounted && this.setState({ score: value })
        this._isMounted && this.setState({ changed_manually: false })
        this._isMounted && this.setState({ score_modified: true })
    }

    handleOnKeyUp = (ev) => {
        if (ev.key === 'Enter') {
            this.submmitChangeScore(this.state.score)
        }
    }

    handleBlurScore = (ev) => {
        this.submmitChangeScore(this.state.score)
    }

    submmitChangeScore = (value) => {
        if (this._isMounted && this.state.score_modified) {
            this._isMounted && this.setState({ changed_manually: true })
            this._isMounted && this.props.change_score_manually(this.props.data.id, value)
            this.props.calculateLumentusScore()
        }
        this._isMounted && this.setState({ score_readOnly: !this.state.score_readOnly })
    }

    handleCategoryChange = (event) => {
        if (!this.props.uploaded_file && this.props.isAutomated) {
            this.overrideChange('category', event.value);
        } else {
            this.props.dispatchChangeCategory(event, this.props.data.category_override_id);
        }
    }

    handleSentimentChange = (event, topStoryIndex = null) => {
        if (!this.props.uploaded_file && this.props.isAutomated) {
            this.overrideChange('sentiment', event.value, topStoryIndex);
        } else {
            this.props.dispatchChangeSentiment(event, this.props.data.sentiment_override_id, handleSentimentChange, topStoryIndex);
        }
    }

    overrideChange = (type, event, topStoryIndex = null) => {
        let automation = this.props.isAutomated;
        let location = this.props.search.loc_id;
        let term = this.props.search.main;

        let url = (this.props.data.resume[0].url == undefined || (this.isTopStories() && topStoryIndex != null)) ? this.props.data.resume[topStoryIndex].link : this.props.data.resume[0].url;

        let override_id = false;
        let api_url = process.env.REACT_APP_API_ENDPOINT + "/" + type + "override";

        let data = {
            url: url,
            term: term,
        }
        data[type] = event;
        if (type == "sentiment") {
            if (this.isTopStories() && topStoryIndex != null) {
                override_id = this.props.data.resume[topStoryIndex].sentiment.sentiment_override_id;
            } else {
                override_id = this.props.data.sentiment_override_id;
            }
            data.location = location;
        } else {
            override_id = this.props.data.category_override_id;
        }


        fetchClient().put(api_url + '/' + override_id, data)
            .then((response) => {
                override_id = response.data.override.id;
                if (type == "sentiment") {
                    if (this.isTopStories()) {
                        let average = this.loadSentiment(this.getAverageSentiment());
                        this.props.dispatchChangeSentiment({ sentiment: event, average: average.value }, override_id, topStoryIndex);
                    } else {
                        this.props.dispatchChangeSentiment(event, override_id);
                    }
                } else {
                    this.props.dispatchChangeCategory(event, override_id);
                }

                if (this.props.uploaded_file === "" && this.props.isAutomated) {
                    this.props.dispatchShowViewActionPlan(false)
                }
            })
            .catch(
                (error) => {
                    this.showFetchError(Object.values(error.response?.data?.errors)[0]);
                }
            )
    }

    showFetchError = (error = "There was an error") => {
        if (!this.props.uploaded_file && this.props.search.automation) {
            this.setState({
                customAlert: {
                    ...this.state.customAlert,
                    visible: true,
                    message: error,
                    type: 'danger'
                }
            });
            setTimeout(() => {
                this.setState({
                    customAlert: {
                        ...this.state.customAlert,
                        visible: false,
                        message: "",
                        type: 'danger'
                    }
                });
            }, 2000)
        }
    }

    handleCompetitiveChange = () => {
        let url = this.props.data.resume[0].url;
        let controlled = this.props.data.controlled;
        let competitive = this.props.data.competitive;
        let api_url = process.env.REACT_APP_API_ENDPOINT + "/domaintag";
        let data = {
            url,
            controlled,
            type: 'organic'
        };
        if (!this.props.uploaded_file && this.props.isAutomated) {
            this.setState({ competitiveLoading: true })
            this.setState({ controlledLoading: true })
            data.competitive = competitive ? false : true;
            fetchClient().post(api_url, data)
                .then((response) => {
                    this.props.handleChangeCompetitive(this.props.id, !competitive);
                })
                .catch((error) => {
                    this.showFetchError();
                })
                .finally(() => {
                    this.setState({ competitiveLoading: false })
                    this.setState({ controlledLoading: false })
                })
        } else {
            this.props.handleChangeCompetitive(this.props.id, !competitive);
        }
    }

    handleControlledChange = () => {
        let url = this.props.data.resume[0].url;
        let controlled = this.props.data.controlled;
        let competitive = this.props.data.competitive;
        let api_url = process.env.REACT_APP_API_ENDPOINT + "/domaintag";
        let data = {
            url,
            competitive,
            type: 'organic'
        };
        if (!this.props.uploaded_file && this.props.isAutomated) {
            this.setState({ controlledLoading: true })
            this.setState({ competitiveLoading: true })
            data.controlled = controlled ? false : true;
            fetchClient().post(api_url, data)
                .then((response) => {
                    this.props.handleChangeControlled(this.props.id, !controlled);
                })
                .catch((error) => {
                    this.showFetchError();
                })
                .finally(() => {
                    this.setState({ controlledLoading: false })
                    this.setState({ competitiveLoading: false })
                })
        } else {
            this.props.handleChangeControlled(this.props.id, !controlled);
        }
    }

    changeCategory = (ev) => {
        let auxState = { categoryValue: ev.value };
        if (!this.props.uploaded_file && this.props.isAutomated) {
            auxState.lastChangeEventCategory = ev;
            this.props.dispatchChangeCategory(ev.value, this.props.data.category_override_id)
        } else {
            this.props.dispatchChangeCategory(ev.value, this.props.data.category_override_id);
        }

        this.sendGaEvent('Category', ev, this.props.data.resume[0].url);
        this.setState(auxState);
    }

    changeSentiment = (ev, topStoryIndex = null) => {
        let auxState = { sentimentValue: this.loadSentiment(ev.value) }

        if (this.isTopStories() && topStoryIndex != null) {
            let newSentiment = this.loadSentiment(ev.value)
            this.props.data.resume[topStoryIndex].sentiment = {
                ...this.props.data.resume[topStoryIndex].sentiment,
                value: newSentiment.value,
                label: newSentiment.label,
            };

            this.sendGaEvent('Sentiment', ev, this.props.data.resume[topStoryIndex].link);

            auxState = { sentimentValue: this.loadSentiment(this.getAverageSentiment()) };
        }
        auxState.lastChangeEventSentiment = ev;

        if (!this.props.uploaded_file && this.props.isAutomated && !this.isTopStories()) {
            this.props.dispatchChangeSentiment(auxState.sentimentValue.value, this.props.data.sentiment_override_id);
        } else {
            if (this.isTopStories()) {
                this.props.dispatchChangeSentiment({ sentiment: ev.value, average: auxState.sentimentValue.value }, this.props.data.resume[topStoryIndex].sentiment.sentiment_override_id, topStoryIndex);
            } else {
                this.props.dispatchChangeSentiment(auxState.sentimentValue.value, this.props.data.sentiment_override_id);
            }
        }
        this.sendGaEvent('Sentiment', ev, this.props.data.resume[0].url);
        this.setState(auxState);
    }
    getAverageSentiment = () => {
        let totalSentimentScore = 0;
        let count = 0
        let topStoriesLength = this.props.data.resume.length <= 3
            ? this.props.data.resume.length
            : 3

        // just take the first 3 topstories to set sentiment
        this.props.data.resume.forEach(item => {
            if (count < topStoriesLength) {
                switch (item.sentiment.value) {
                    case "positive":
                        totalSentimentScore += 100;
                        break;
                    case "negative":
                        totalSentimentScore += -100;
                        break;
                    case "neutral":
                        totalSentimentScore += 50;
                        break;
                }
            }
            count++
        });

        let averageSentimentScore = Math.ceil(totalSentimentScore / topStoriesLength);

        if (averageSentimentScore >= 50) {
            return (averageSentimentScore - 50 > 25) ? "positive" : "neutral";
        } else if (averageSentimentScore > 0) {
            return "neutral";
        } else if (averageSentimentScore < 0) {
            return "negative";
        } else {
            return "unselected";
        }
    }

    sendGaEvent = (_category, _ev, _label) => gaEvent(_category, _ev, _label, this.props.automation)

    overrideAction = (e, topStoryIndex = null) => {
        e.preventDefault()
        let type = e.target?.attributes?.type?.value;
        if (type == 'category') {
            this.handleCategoryChange(this.state.lastChangeEventCategory);
        } else if (type == 'sentiment') {
            this.handleSentimentChange(this.state.lastChangeEventSentiment, topStoryIndex);
        }
    }

    getSentimentDropdownStyles = () => {
        return {
            display: "flex",
            outline: 'none',
            control: (provided) => ({
                ...provided,
                backgroundColor: 'transparent',
                border: 'none',
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                visibility: ((this.props.isAutomated && !this.props.uploaded_file) && !this.isTopStories() && this.state.selectSentimentDisabled) ? 'hidden' : ''
            }),
            valueContainer: (provided) => ({
                ...provided,
            })
        }
    }

    render() {
        if(!this.props.data)
            return null;
        const value = this.props.data.score_manually !== undefined && this.props.data.score_manually && this.state.changed_manually ? this.props.data.score_manually : this.state.score
        const timeout = this.props.data.relevance.filter((item) => item.status == "timeout").length >= 7
        const fetching_sentiment = this.state.fetching_sentiment;
        const self = this;
        return (
            <LayoutRow
                id={`result-${this.props.id}`}
                className={'mb-2  ' + (this.props.data.resume.length > 1 ? ' top-stories-card' : '')}>
                <div className={'index-container'}>
                    <span> {this.props.index + 1} </span>
                </div>
                <div className="row-body">
                    {
                        (this.isTopStories()) ?
                            <div className="titleAlign">
                                <h5 className="top-stories-h alignWithIcon"><b>Top Stories</b></h5>
                                {
                                    this.props.tooltip.map((tooltip, i) => {
                                        return (tooltip.code === '006') ?
                                            <Tooltip key={tooltip.code} tooltip={tooltip} /> : ''
                                    })
                                }
                                <DownloadDOMElement element={`result-${this.props.id}`} className="float-right" fileName="top-stories" />
                            </div>
                            :
                            <RowTopBarMenu
                                domain={this.props.data.resume.length && this.props.data.resume[0].url}
                                changeControlled={this.handleControlledChange}
                                controlled={!!this.props.data.controlled}
                                loadControlled={this.state.controlledLoading}
                                loadCompetitive={this.state.competitiveLoading}
                                changeCompetitive={this.handleCompetitiveChange}
                                competitive={!!this.props.data.competitive}
                                id={this.props.id}
                            />
                    }
                    {this.isTopStories() ?
                        <TopStoriesRow
                            id={this.props.data.id}
                            {...this.props}
                            {...this.state}
                            {...this}
                            setState={(props) => this.setState(props)}
                            loadingScore={this.state.fetching_relevance}
                            domainAuthority={this.state.domain_authority}
                            optionsSelectCategory={optionsSelectCategory}
                            ButtonResultAnalisys={
                                ({ storyUrl }) => (this._isMounted && !this.props.export_loading && !this.state.fetching_relevance || (this.props.id >= 10 && !(this.state.score === "" || !this.state.modules_processed)))
                                    ? <Button type="button" className={'result-analysis-btn'}
                                        data-toggle="modal" data-target="#myModal" onClick={this.isTopStories() ? function () { self.toggleTopStorySEOAnalysis(storyUrl) } : this.toggle}>SEO
                                        Analysis</Button>
                                    :
                                    (timeout && !this.props.export_loading ?
                                        <Button type="button" className={'result-analysis-btn'}
                                            onClick={this.fetchRelevance}>Reload</Button>
                                        : '')
                            }
                            propsScore={
                                {
                                    readOnly: this.state.score_readOnly,
                                    onDoubleClick: this.change_readOnly,
                                    onChange: this.handleOnChangeScore,
                                    onKeyUp: this.handleOnKeyUp,
                                    onBlur: this.handleBlurScore,
                                    value,
                                }
                            }
                            loadingSentiment={fetching_sentiment}
                        />
                        :
                        <RowBody result={this.props.data.resume[0] || this.props.data.resume} />

                    }


                    {
                        this.state.customAlert.visible ? <CustomAlert data={this.state.customAlert}></CustomAlert> : ""
                    }
                </div>

                <RankingModal key={this.state.modal_index} reCalculateState={this.reCalculateState} data={this.props.data}
                    index={this.props.index} open={this.state.modal_open}
                    isTopStory={this.isTopStories()}
                    story_index={this.state.modal_index}
                    close={this.toggle}
                    SEOScore={value}
                    SEOURL={this.props?.data?.resume?.[0]?.url}
                    SEOTitle={this.props?.data?.resume?.[0].title}
                />

                {!this.isTopStories() && (
                    <>
                        <Row className="pl-4 pr-4 mt-3">
                            {(this.props.data.links || []).map(link => {
                                const url = link.url
                                return (<Col className="col-6 d-flex flex-column align-items-start">
                                    <RowBody result={{ ...link, url }} />
                                </Col>);
                            }
                            )}
                        </Row>
                        <RowFooter
                            id={this.props.data.id}
                            {...this.props}
                            {...this.state}
                            {...this}
                            setState={(props) => this.setState(props)}
                            loadingScore={this.state.fetching_relevance}
                            domainAuthority={this.state.domain_authority}
                            optionsSelectCategory={optionsSelectCategory}
                            ButtonResultAnalisys={
                                () => (this._isMounted && !this.props.export_loading && !this.state.fetching_relevance || (this.props.id >= 10 && !(this.state.score === "" || !this.state.modules_processed)))
                                    ? <Button type="button" className={'result-analysis-btn'}
                                        data-toggle="modal" data-target="#myModal" onClick={this.toggle}>SEO
                                        Analysis</Button>
                                    :
                                    (timeout && !this.props.export_loading ?
                                        <Button type="button" className={'result-analysis-btn'}
                                            onClick={this.fetchRelevance}>Reload</Button>
                                        : '')
                            }
                            propsScore={
                                {
                                    readOnly: this.state.score_readOnly,
                                    onDoubleClick: this.change_readOnly,
                                    onChange: this.handleOnChangeScore,
                                    onKeyUp: this.handleOnKeyUp,
                                    onBlur: this.handleBlurScore,
                                    value,
                                }
                            }
                            loadingSentiment={fetching_sentiment}
                        />
                    </>
                )}

            </LayoutRow>
        )
    }
}


const mapStateToProps = state => {
    return {
        user_logged: state.user_logged,
        search: state.search,
        modules_imported: state.modules_imported,
        visible_rows: state.visible_rows,
        fetching_ids: state.fetching_ids,
        uploaded_file: state.uploaded_file,
        tooltip: state.tooltips.filter(tooltip => tooltip.code === '006'),
        export_loading: state.export_loading,
        automation: state.search.automation,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatchChangeCategory: (selectedCategory, override_id) => {
            dispatch(changeCategory(props.data.id, selectedCategory, override_id))
        },
        dispatchChangeSentiment: (selectedSentiment, override_id, topStoryIndex = null) => {
            dispatch(changeSentiment(props.id, selectedSentiment, override_id, topStoryIndex))
            dispatch(calculateLumentusScore())
        },
        set_relevance: (id, data) => {
            dispatch(set_relevance(id, data))
            dispatch(calculateLumentusScore())
        },
        change_score_manually: (id, value) => {
            dispatch(change_score_manually(id, value))
        },
        clearSearch: () => {
            dispatch(clearSearch())
        },
        setWarningEmptyCache: (msg) => {
            dispatch(setWarningEmptyCache(msg))
        },
        setErrorTimeOut: (id, val) => {
            dispatch(setErrorTimeOut(id, val))
        },
        handleFetchingIdToStore: (id, action) => {
            dispatch(handleFetchingIdToStore(id, action))
        },
        calculateLumentusScore: () => {
            dispatch(calculateLumentusScore())
        },
        handleChangeControlled: (id, controlled) => {
            dispatch(changeControlled(id, controlled))
        },
        handleChangeCompetitive: (id, competitive) => {
            dispatch(changeCompetitive(id, competitive))
        },
        dispatchShowViewActionPlan: (value) => {
            dispatch(regenerateActionPlan(value))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingItem)
